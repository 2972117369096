import React, { useState, useEffect, useRef } from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../../http/index';
import titleApp from '../../services/title';

function BlogPageId() {

  const {id} = useParams();
  const [title, setTitle] = useState('')
  const [shortNews, setShortNews] = useState('')
  const [file, setFile] = useState(null)
  const [fullNews, setFullNews] = useState('')
  const [published, setPublished] = useState('false');
  const [imgTitle, setImgTitle] = useState(null);
  const [items, setItems] = useState([]);
  const [otherNews, setOtherNews] = useState([]);

  const page = {id};

  let limit = 4;
  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 4;
      else if (windowSize.current >= 1024) limit = 4;
      else if (windowSize.current >= 640) limit = 3;
      else if (windowSize.current >= 360) limit = 3;
  }
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/n/${id}`
    );
    const data = await res.json();
    setItems(data);
  };

  const changeNews = async (newid) => {
    const res = await fetch(
      API_URL + `/n/${newid}`
    );
    const data = await res.json();
    setItems(data);
    document.title = `Новость №${newid} | ${titleApp()}`;
    window.scrollTo(0, 0);
  };

  const getOtherNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`
    );
    const data = await res.json();
    setOtherNews(data);
  }
  useEffect(() => {
    checkMonitor();
    getOtherNews();
  },[setOtherNews]);
  
    useEffect(() => {
      getOneNews();
    },[setItems]);

    useEffect(() => {
      document.title = `Новость №${id} | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);

  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 

  if (items.published == false) return (
    <div>Отсутствует страница!</div>
  )

  return (
    <section className="news">
        <div className="container">
          <h2 className="dark_header-blog-i">{items.title}</h2>
          <div className="card_box-blog-i"><p>{items.pubDate === null || items.pubDate === undefined ? 'загрузка' : items.pubDate.split(' ')[0]}</p></div>
          <img className="card_logotype-blog-i" src={items.img === null || items.img === undefined ? 'загрузка' : IMG_URL + `/` + items.img} />
          <p className="text-blog-i">{items.fullNews}</p>
          <h3 className='blog-header-other'>Читайте также</h3>
        </div>
        <div className="card_block-blog-i">
        {otherNews.map((otherNews) => {
          return (
            otherNews.published == true ?
              otherNews.news_id !== items.news_id ?
              <div className="cardblog-i" key={otherNews.news_id}>
                <div className="card_item">
                  <div className="card_content card_content-spacing">
                    <div className="card_box_blog-alt">
                      <p>{otherNews.pubDate.split(' ')[0]}</p>
                    </div>
                    <img className="card_logotype card_logotype_blog-main" src={IMG_URL + `/` + otherNews.img} />
                    <div className="card_title_blog">{otherNews.title}</div>
                    <div className="card_text_blog">
                      {otherNews.fullNews}
                    </div>
                    <Link onClick={() => changeNews(otherNews.news_id)} to={`/blog/` + otherNews.news_id}><button className="card_btn_blog">Узнать подробнее</button></Link>
                  </div>
                </div>
              </div>
              : null
            : null
            )
          })}
        </div>
    </section>
  );
}

export default (BlogPageId);