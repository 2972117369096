import React from 'react';
import {observer} from "mobx-react-lite";
import '../style.css';
import {Outlet} from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const Dashboard = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
};

export default observer(Dashboard);
