import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import Hero from '../assets/reviews.png';
import Selected from '../assets/list_selected.png';
import List from '../assets/list.png';


const SMR = () => {
  const [checked, setChecked] = useState(1);

  function changeChecked(x) {
    setChecked(x);
  } 


    return (
      <section className="reviews">
        <div className="container">
          <h2 className="dark_header">Только за последние 7 лет созданные мной мероприятия посетили <span>более 2000 участников</span>, и вот что они говорят:</h2>
          <div className="reviews_block">
            <div className="reviews_img">
              <img className="reviews_hero" src={Hero} />
            </div>
            <div className="reviews_text">
              <p>Хочу поделиться результатом. Каждый раз, когда делаю сессию себе или после сессии с другим, перехожу на лицо и разглаживаю морщинки, как Елена показывала с камнем, представляю, что это энергия, леплю лицо. И невероятно! Как будто сделала подтяжку! Носогубенные морщинки раскрываются. Чудо! Благодарю!</p>
              <p className="reviews_name">Анастасия Семенова</p>
            </div>
          </div>
          <div className="reviews_list">
            <img className="about_list_i" src={checked === 1 ? Selected : List} onClick={() => changeChecked(1)} />
            <img className="about_list_i" src={checked === 2 ? Selected : List} onClick={() => changeChecked(2)} />
            <img className="about_list_i" src={checked === 3 ? Selected : List} onClick={() => changeChecked(3)} />
            <img className="about_list_i" src={checked === 4 ? Selected : List} onClick={() => changeChecked(4)} />
            <img className="about_list_i" src={checked === 5 ? Selected : List} onClick={() => changeChecked(5)} />
          </div>
        </div>
      </section>
    );
};

export default observer(SMR);