import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import CabinetPage from './pages/cabinet-page';
//import NewsPage from "./components/pages/news-page";
import CabinetMain from './components/pages/cabinet-main';
import AuthPage from "./components/pages/auth-page";
import IndexPage from "./pages/index-page";
import HomeMain from "./components/pages/home-main";
import SchedulePage from './components/pages/schedule-page';
import BlogsPage from "./components/pages/blogs-page";
import BlogPageId from "./components/pages/blog-page-id";
import BookPageId from "./components/pages/books-page-id";
import BooksPage from "./components/pages/books-page";
import ShiningHands from "./components/pages/shining-hands-page";
import CabinetMovies from "./components/pages/cabinet-movies";
import PracticlesPageId from './components/pages/practicles-page-id';
import CoursePage from "./components/pages/course-page";
import ConnectionOfTheWorlds from "./components/pages/connectionoftheworlds";
import Page404 from "./components/pages/page-404";
import StaticPage from "./components/pages/static-page";
import MasterPage from "./components/pages/master-page";
import PurchasePage from "./components/pages/purchase-page";
import MasterPageId from "./components/pages/master-page-id";
import VideosPage from "./components/pages/videos-page";
import PageSuccess from "./components/pages/page-success";
//import ErrorPage from './components/pages/error-page';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<CabinetPage />}>
          <Route path="/cabinet" element={<CabinetMain />} />
          <Route path="/cabinet/videos" element={<CabinetMovies />} />
          <Route path="/cabinet/course" element={<CoursePage />} />
          <Route path="/cabinet/purchase" element={<PurchasePage />} />
        </Route>
        <Route element={<IndexPage />}>
          <Route path="/" element={<HomeMain />} />
          <Route path="/books" element={<BooksPage />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/blog/:id" element={<BlogPageId />} />
          <Route path="/books/:id" element={<BookPageId />} />
          <Route path="/pages/:id" element={<StaticPage />} />
          <Route path="/practicle/:id" element={<PracticlesPageId />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/shining-hands" element={<ShiningHands />} />
          <Route path="/connectionoftheworlds" element={<ConnectionOfTheWorlds />} />
          <Route path="/masters" element={<MasterPage />} />
          <Route path="/masters/:id" element={<MasterPageId />} />
          <Route path="/videos" element={<VideosPage />} />
          <Route path="/success" element={<PageSuccess />} />
          <Route path="*" element={<Page404 />}></Route>
        </Route>
      </Routes>
    </Router>)
}

export default App;