import React, {useEffect, useState} from 'react';
import { API_URL } from '../../http';
import { successNotify } from '../../services/notify';
import errorNotify from '../../services/notify';
import axios from 'axios';

const SchedulePage = () => {
  const [items, setItems] = useState([]);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [sid, setSid] = useState('');

  const [modalSchedule, setModalSchedule] = useState(true);
  function showModalSchedule(x) {
    setSid(x);
    setModalSchedule(!modalSchedule);
    if(modalSchedule) document.getElementById("myModalSchedule").style.display = "block";
    else document.getElementById("myModalSchedule").style.display = "none";
  }

  function postSchedule() {
    if(lastName === '') {
      return errorNotify('Заполните фамилию!');
    }
    if(firstName === '') {
      return errorNotify('Заполните имя!');
    }
    if(phone === '') {
      return errorNotify('Заполните номер телефона!');
    }
    if(email === '') {
      return errorNotify('Заполните E-Mail!');
    }
    const formData = new FormData()
    formData.append('sid', sid)
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('email', email)
    formData.append('phone', phone)
    axios.post(API_URL + '/members/create', formData)
    .then(res => {
      successNotify('Подтвердите участие, направлено письмо на почту!');
      setLastName('');
      setFirstName('');
      setPhone('');
      setEmail('');
      showModalSchedule();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  useEffect(() => {
    document.body.style = 'background: #F7F7F5;';
    document.querySelector('footer').style = 'background: #F7F7F5;';
  }, [])

  const getSchedule = async () => {
    const res = await fetch(
      API_URL + `/schedule`
    );
    const data = await res.json();
    setItems(data);
  };

  useEffect(() => {
    getSchedule();
  }, [setItems]);

  return (
<>
  <div id="myModalSchedule" className="modal">
    <div className="modal-content">
      <span onClick={() => showModalSchedule()} className="close">&times;</span>
      <h3 className='modal-feedback-header'>Записаться на курс</h3>
      <p className='modal-feedback-text'>Оставьте свои данные и мы запишем вас</p>
      <hr className="modal-feedback-hr" />
      <input onChange={e => setLastName(e.target.value)} value={lastName} className="modal-feedback-input" placeholder="Фамилия" />
      <input onChange={e => setFirstName(e.target.value)} value={firstName} className="modal-feedback-input" placeholder="Имя" />
      <input onChange={e => setPhone(e.target.value)} value={phone} className="modal-feedback-input" placeholder="Номер телефона" />
      <input onChange={e => setEmail(e.target.value)} value={email} className="modal-feedback-input" placeholder="E-Mail" />
      <button onClick={() => postSchedule()} className='modal-btn-submit'>Отправить</button>
      <p className='modal-feedback-notify'>Нажимая «Отправить», вы соглашаетесь с Политикой конфиденциальности</p>
    </div>
  </div>
  <div className="cabinet-section">
    <div className="cabinet-header">
      <div className="cabinet-header_item">
        <h3 className="cabinet-title">Расписание курсов и семинаров</h3>
      </div>
    </div>
    <div className='cabinet-table-provider'>
      <div className="div-table">
        <div className="div-table-row">
          <div className="div-table-col-header" align="center">Название</div>
          <div  className="div-table-col-header" align="center">Дата</div>
          <div  className="div-table-col-header" align="center">Ведущий</div>
          <div  className="div-table-col-header" align="center">Место проведения</div>
          <div  className="div-table-col-header" align="center">Принять участие</div>
        </div>
          {items.map((item) => {
            return (
              item.verify === true ?
              <div className='div-table-provider' key={item._id}>
                <div className="div-table-row">
                  <div className="div-table-col" align="center">{item.title}</div>
                  <div className="div-table-col" align="center">{item.inDate}</div>
                  <div className="div-table-col" align="center">{item.leading}</div>
                  <div className="div-table-col" align="center">{item.place}</div>
                  <div className="div-table-col" align="center">
                    {item.actual === "true" ?
                      <button onClick={() => showModalSchedule(item._id)} className='cabinet-btn-table'>Записаться</button>
                      :
                      <button onClick={() => errorNotify("Запись на курс закрыта!")} className='cabinet-btn-table cabinet-btn_disable'>Записаться</button>
                    }
                  </div>
                </div>
              </div>
              :
              null
              )
          })}
      </div>
    </div>
    <div className='cabinet-table-provider-sm'>
      <div className='cabinet-table-sm'>
        {items.map((item) => {
            return (
            <div key={item._id}>
              <hr className="cabinet-table-sm-hr"  />
              <div className='cabinet-table-sm-row'>
                <div className="cabinet-table-sm-row-i">
                  <p className='cabinet-table-sm-row-header'>Название</p>
                  <p className='cabinet-table-sm-row-header'>Дата</p>
                  <p className='cabinet-table-sm-row-header'>Ведущий</p>
                  <p className='cabinet-table-sm-row-header'>Место проведения</p>
                </div>
                <div className="cabinet-table-sm-row-i">
                  <p className='cabinet-table-sm-row-text'>{item.title}</p>
                  <p className='cabinet-table-sm-row-text'>{item.inDate}</p>
                  <p className='cabinet-table-sm-row-text'>{item.leading}</p>
                  <p className='cabinet-table-sm-row-text'>{item.place}</p>
                </div>
              </div>
              {item.actual === "true" ?
              <button onClick={() => showModalSchedule(item._id)} className='cabinet-table-sm-btn'>Записаться</button>
              :
              <button onClick={() => errorNotify("Запись на курс закрыта!")} className='cabinet-table-sm-btn'>Записаться</button>
              }
            </div>
            )
        })}
        <hr className="cabinet-table-sm-hr" />
      </div>
    </div>
  </div>
</>
  );
};

export default SchedulePage;