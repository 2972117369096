import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
import { API_URL } from '../http';




const Footer = () => {

  const [practics, setPractics] = useState(true);
  const [settings, setSettings] = useState([]);
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);
  const currentYear = new Date().getFullYear();

  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }

    return (
      <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/connectionoftheworlds">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <Link to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                    <Link to="/connectionoftheworlds">- Практики метода «Соединение миров»</Link>
                  </div>
                </div>
                <Link to="/masters">Мастера</Link>
                <Link to="/books">Книги</Link>
                <Link to="/blog">Блог</Link>
              </div>
              <a className="footer_block-policy" href={settings.l_privacy}>Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default observer(Footer);