import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import titleApp from '../../services/title';
import SMM from '../../components/section-main-main';
import SMP from '../../components/section-main-platform';
import SMC from '../../components/section-main-courses';
import SMPR from '../../components/section-main-programms';
import SMA from '../../components/section-main-about';
import SMT from '../../components/section-main-tutor';
import SMF from '../../components/section-main-feedback';
import SMB from '../section-main-books';
import SMR from '../section-main-reviews';
import SMBL from '../section-main-blog';




const HomePage = () => {

  useEffect(() => {
    document.title = `${titleApp()}`
    document.querySelector('footer').style = 'background: #E9E9E7;';
    document.body.style = 'background: #FFFFFF;';
  }, []);

    return (
      <>
        <SMM />
        <SMP />
        <SMC />
        <SMPR />
        <SMA />
        <SMT />
        <SMB />
        <SMR />
        <SMBL />
        <SMF />
      </>
    );
};

export default observer(HomePage);