import React, { useState, useEffect, useRef } from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../../http/index';
import titleApp from '../../services/title';
import errorNotify from '../../services/notify';

function ConnectionOfTheWorlds() {

  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(4);
  const [count, setCount] = useState(0);


  const [selectCity, setSelectCity] = useState('');
  const [selectCountry, setSelectCountry] = useState('');

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('');

  const getUsers = async () => {
    const res = await fetch(
      API_URL + `/u/practicles?limit=300&role=1&type=1&altType=2`
    );
    const data = await res.json();
    setCount(data?.count);
    setItems(data?.users);
  };

  async function addFind() {
    setLimit(limit < 4 ? 4 : limit + 4);
    const res = await fetch(
      API_URL + `/u/practicles?limit=300&role=1&type=1&altType=2`
    );
    const data = await res.json();
    setCount(data?.count);
    setItems(data?.users);
  }

  async function detailFind(x, a) {
    setSelectCountry(x);
    if(a === undefined) {
      setSelectCity('');
      a = '';
    } else setSelectCity(a);
    const res = await fetch(
      a === '' ?
      API_URL + `/u/practicles?limit=300&role=1&type=1&altType=2`
      :
      API_URL + `/u/practicles?limit=300&role=1&type=1&altType=2&countryid=${x}&cityid=${a}`
    );
    setLimit(5);
    const data = await res.json();
    setCount(data?.count);
    setItems(data?.users);
  }

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const getDefaultCountry = async () => {
    const res = await fetch(
      API_URL + `/c/default`
    );
    const data = await res.json();
    setCurrentCountry(data._id);
  };

  useEffect(() => {
    getCountry();
  }, [setCountry]);

  useEffect(() => {
    getCity();
  }, [setCity]);

  useEffect(() => {
    getDefaultCountry();
  }, [setCurrentCountry]);

    useEffect(() => {
      setLimit(limit + 4)
      getUsers();
    },[setItems]);

    useEffect(() => {
      document.title = `Практики метода "Соединение Миров" | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);

  return (
    <section className="news">
        <div className="container">

        <div className="cabinet-header">
          <div className="cabinet-header_item">
            <h3 className="cabinet-title">Практики метода «Соединение Миров»</h3>
          </div>
        </div>

        <div className='select-header'>
          <div className="select-wrapper_cabinet">
            <select defaultValue={selectCountry} onChange={e => detailFind(e.target.value, undefined)} className="form-select_cabinet">
            <option value=''>Страна</option>
              {country.map((country) => {
                return (
                  <option key={country._id} value={country._id}>{country.name}</option>
                )
              })}
            </select>
          </div>
          <div className="select-wrapper_cabinet">
            <select defaultValue={selectCity} onChange={e => detailFind(selectCountry, e.target.value)} className="form-select_cabinet">
              <option value=''>Город</option>
              {city.map((city) => {
                return (
                  selectCountry === city.cid ?
                    <option key={city._id} value={city._id}>{city.name}</option>
                  :
                  ``
                )
              })}
            </select>
          </div>
        </div>


          <div className="books-cards_wrapper">
          {items.map((items) => {
            return (
              selectCountry == '' ?
              <div className="books-cards_item" key={items._id}>
                <div className="books-cards_item-content">
                  <img className="books-cards_item_card card-img_practicles" src={IMG_URL + `/` + items.img} />
                  <div style={{marginTop: '20px'}} className="card_alt_title">{items.lastName + ' ' + items.firstName}</div>
                  <p className="card_alt_description spec-course_description">
                  {country !== [] ?
                        country.map((country) => {
                          return (
                            items.countryid === country._id ?
                              city.map((city) => {
                                return (
                                  items.cityid === city._id ?
                                  <div key={country._id} className="card_alt_description">
                                  {country.name + ', ' + city.name}
                                  </div>
                                  :
                                  null
                                )
                              })
                            :
                            null
                          )
                    }) : null}
                  </p>
                  <div style={{marginTop: "20px"}} className="card_text_blog">
                    {items.description}
                  </div>
                  <Link to={`/practicle/` + items._id}><button className="card_btn_blog">Открыть профиль</button></Link>
                </div>
              </div>
              :
              
              selectCity == '' ?
              
              selectCountry == items.countryid ?
              <div className="books-cards_item" key={items._id}>
                <div className="books-cards_item-content">
                  <img className="books-cards_item_card card-img_practicles" src={IMG_URL + `/` + items.img} />
                  <div style={{marginTop: '20px'}} className="card_alt_title">{items.lastName + ' ' + items.firstName}</div>
                  <p className="card_alt_description spec-course_description">
                  {country !== [] ?
                        country.map((country) => {
                          return (
                            items.countryid === country._id ?
                              city.map((city) => {
                                return (
                                  items.cityid === city._id ?
                                  <div key={country._id} className="card_alt_description">
                                  {country.name + ', ' + city.name}
                                  </div>
                                  :
                                  null
                                )
                              })
                            :
                            null
                          )
                    }) : null}
                  </p>
                  <div style={{marginTop: "20px"}} className="card_text_blog">
                    {items.description}
                  </div>
                  <Link to={`/practicle/` + items._id}><button className="card_btn_blog">Открыть профиль</button></Link>
                </div>
              </div>
              :
              null


              :

              <div className="books-cards_item" key={items._id}>
                <div className="books-cards_item-content">
                  <img className="books-cards_item_card card-img_practicles" src={IMG_URL + `/` + items.img} />
                  <div style={{marginTop: '20px'}} className="card_alt_title">{items.lastName + ' ' + items.firstName}</div>
                  <p className="card_alt_description spec-course_description">
                  {country !== [] ?
                        country.map((country) => {
                          return (
                            items.countryid === country._id ?
                              city.map((city) => {
                                return (
                                  items.cityid === city._id ?
                                  <div key={country._id} className="card_alt_description">
                                  {country.name + ', ' + city.name}
                                  </div>
                                  :
                                  null
                                )
                              })
                            :
                            null
                          )
                    }) : null}
                  </p>
                  <div style={{marginTop: "20px"}} className="card_text_blog">
                    {items.description}
                  </div>
                  <Link to={`/practicle/` + items._id}><button className="card_btn_blog">Открыть профиль</button></Link>
                </div>
              </div>

            )
            })}
          </div>
          {
            limit < count + 4 ?
            <div className="container">
              <button style={{width: 'auto', paddingLeft: '50px', paddingRight: '50px'}} className="card_btn_blog" onClick={() => addFind()}>Загрузить еще</button>
            </div>
            :
            <div className="container">
              <p style={{width: 'auto'}} className="footer_block-policy">Вы просмотрели всех практиков..</p>
            </div>
          }
        </div>
    </section>
  );
}

export default (ConnectionOfTheWorlds);