import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import titleApp from '../../services/title';

const PageSuccess = () => {
    useEffect(() => {
      document.title = `Успешная оплата! | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);
    return (
      <section className="main" style={{marginBottom: '100px'}}>
        <div className="container">
          <h1 className="main_header">Успешная оплата!</h1>
          <div className="main_text">
          <p>Оплата произведена успешно!<br />Если у вас зарегистрирована учетная запись на сайте, перейдите в раздел "Мои заказы", чтобы ознакомиться с деталями платежа.</p>
          </div>
          <div className="group_circle-btn">
            <Link to="/">
              <button className="circle-btn">
                На главную
              </button>
            </Link>
          </div>
        </div>
      </section>
    );
};

export default observer(PageSuccess);