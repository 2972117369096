import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { API_URL } from '../http';

const SMF = () => {
  const [settings, setSettings] = useState([]);
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);
    return (
      <section className="feedback">
        <div className="container">
          <h2 className="dark_header">Остались вопросы? Пишите — мы всегда на связи!</h2>
          <div className="feedback_block">
            <div className="feedback_btn_group">
              <a href={settings.l_telegram} target='_blank'>
                <button className="feedback_button">
                  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.26074 7.57644C7.96159 5.09268 11.763 3.45522 13.6651 2.66409C19.0959 0.405237 20.2244 0.0128509 20.9599 -0.000105992C21.1217 -0.00295571 21.4834 0.0371359 21.7177 0.227254C21.9155 0.387785 21.97 0.604641 21.996 0.756842C22.0221 0.909044 22.0545 1.25576 22.0287 1.52668C21.7344 4.61888 20.461 12.1228 19.8131 15.5861C19.539 17.0516 18.9993 17.5429 18.4767 17.591C17.3411 17.6955 16.4787 16.8405 15.3788 16.1195C13.6577 14.9913 12.6853 14.289 11.0147 13.188C9.08392 11.9157 10.3355 11.2164 11.4359 10.0735C11.7238 9.77446 16.7274 5.2233 16.8243 4.81044C16.8364 4.7588 16.8476 4.56633 16.7333 4.4647C16.6189 4.36307 16.4502 4.39782 16.3284 4.42546C16.1558 4.46464 13.4062 6.28199 8.07974 9.87751C7.29929 10.4134 6.59238 10.6745 5.95902 10.6609C5.26078 10.6458 3.91766 10.2661 2.91918 9.9415C1.69451 9.54341 0.72117 9.33294 0.805926 8.65686C0.850073 8.30471 1.33501 7.94457 2.26074 7.57644Z" fill="white"/>
                  </svg>
                </button>
              </a>
              <p className="feedback-brand">Telegram</p>
            </div>
            <div className="feedback_btn_group">
              <a href={settings.l_whatsapp} target='_blank'>
                <button className="feedback_button">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.441406 29.963L2.49927 22.4902C1.22701 20.2968 0.559595 17.8128 0.566547 15.2735C0.566547 7.31639 7.07386 0.846924 15.062 0.846924C18.9414 0.846924 22.5843 2.34839 25.3166 5.07457C28.0558 7.80074 29.5644 11.4264 29.5574 15.2804C29.5574 23.2375 23.0501 29.707 15.055 29.707H15.0481C12.6218 29.707 10.2371 29.0981 8.1167 27.9495L0.441406 29.963ZM8.48516 25.341L8.92316 25.6039C10.7725 26.6971 12.8929 27.2714 15.055 27.2783H15.062C21.7014 27.2783 27.1103 21.9021 27.1103 15.2873C27.1103 12.0837 25.8588 9.07387 23.5855 6.80437C21.3121 4.53487 18.2809 3.28941 15.062 3.28941C8.42259 3.28249 3.01374 8.65872 3.01374 15.2735C3.01374 17.5361 3.6464 19.7433 4.85609 21.653L5.14113 22.1097L3.92449 26.5311L8.48516 25.341Z" fill="white"/>
                    <path d="M0.949219 29.4579L2.93756 22.2412C1.70701 20.1308 1.06045 17.7298 1.06045 15.2804C1.06741 7.60011 7.34529 1.35205 15.0623 1.35205C18.8096 1.35205 22.3204 2.80509 24.9623 5.43439C27.6042 8.06369 29.0572 11.5648 29.0572 15.2874C29.0572 22.9677 22.7723 29.2157 15.0623 29.2157H15.0553C12.7124 29.2157 10.4112 28.6276 8.36727 27.5205L0.949219 29.4579Z" fill="#7788BB"/>
                    <path d="M0.441406 29.963L2.49927 22.4902C1.22701 20.2968 0.559595 17.8128 0.566547 15.2735C0.566547 7.31639 7.07386 0.846924 15.062 0.846924C18.9414 0.846924 22.5843 2.34839 25.3166 5.07457C28.0558 7.80074 29.5644 11.4264 29.5574 15.2804C29.5574 23.2375 23.0501 29.707 15.055 29.707H15.0481C12.6218 29.707 10.2371 29.0981 8.1167 27.9495L0.441406 29.963ZM8.48516 25.341L8.92316 25.6039C10.7725 26.6971 12.8929 27.2714 15.055 27.2783H15.062C21.7014 27.2783 27.1103 21.9021 27.1103 15.2873C27.1103 12.0837 25.8588 9.07387 23.5855 6.80437C21.3121 4.53487 18.2809 3.28941 15.062 3.28941C8.42259 3.28249 3.01374 8.65872 3.01374 15.2735C3.01374 17.5361 3.6464 19.7433 4.85609 21.653L5.14113 22.1097L3.92449 26.5311L8.48516 25.341Z" fill="url(#paint0_linear_172_633)"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.4395 9.24C11.1684 8.63803 10.8834 8.62419 10.6261 8.61727C10.4176 8.61035 10.1742 8.61035 9.93089 8.61035C9.68756 8.61035 9.29824 8.7003 8.96453 9.0601C8.63082 9.4199 7.69922 10.2917 7.69922 12.07C7.69922 13.8413 8.99929 15.5572 9.18005 15.7994C9.36081 16.0416 11.6898 19.7987 15.3676 21.2448C18.4265 22.4488 19.0523 22.2066 19.7127 22.1443C20.3732 22.0821 21.854 21.2725 22.1599 20.4284C22.4589 19.5842 22.4589 18.8646 22.3685 18.7124C22.2781 18.5602 22.0348 18.4702 21.6733 18.2903C21.3117 18.1104 19.532 17.2386 19.1982 17.1141C18.8645 16.9964 18.6212 16.9342 18.3848 17.294C18.1415 17.6538 17.4463 18.4633 17.2377 18.7055C17.0291 18.9477 16.8136 18.9753 16.4521 18.7954C16.0906 18.6155 14.9226 18.235 13.5391 17.0034C12.4615 16.0485 11.7315 14.8653 11.523 14.5055C11.3144 14.1457 11.5021 13.952 11.6829 13.7721C11.8428 13.6129 12.0444 13.35 12.2251 13.1424C12.4059 12.9349 12.4685 12.7826 12.5867 12.5405C12.7048 12.2983 12.6492 12.0907 12.5588 11.9108C12.4685 11.7378 11.7593 9.95268 11.4395 9.24Z" fill="white"/>
                    <defs>
                      <linearGradient id="paint0_linear_172_633" x1="15.0023" y1="29.9602" x2="15.0023" y2="0.846924" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F9F9F9"/>
                        <stop offset="1" stopColor="white"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
              </a>
              <p className="feedback-brand">WhatsApp</p>
            </div>
          </div>
        </div>
      </section>
    );
};

export default observer(SMF);