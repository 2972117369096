import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../http';
import axios from 'axios';
import errorNotify from '../../services/notify';
import { successNotify } from '../../services/notify';
import { IMG_URL } from '../../http';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const PurchasePage = () => {
    const {store} = useContext(Context);

    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    
    const [activated, setActivated] = useState(true);

    const [role, setRole] = useState(0);
    const [verify, setVerify] = useState(false);

    const [items, setItems] = useState([]);
    const [basket, setBasket] = useState([]);
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [steps, setSteps] = useState([]);
    const [type, setType] = useState(0);

    // const getPayments = async () => {
    //   const res = await fetch(
    //     API_URL + `/purchase/getpayments`, {
    //     headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    //    });
    //   const data = await res.json();
    //   setItems(data);
    // };
    async function getPayments() {
      const formData = new FormData()
      formData.append('email', store.user.email)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/purchase/getpayments', formData)
      .then(res => {
        setItems(res.data);
      })
      .catch(err => errorNotify(err.response.data.message));
    }
    async function getBasket(id, status, amount, type) {
      const formData = new FormData()
      formData.append('orderId', id)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/purchase/getpayments/basket', formData)
      .then(res => {
        setBasket(res.data);
        setAmount(amount);
        setStatus(status);
        setType(type);
      })
      .catch(err => errorNotify(err.response.data.message));
    }
    async function getSteps(id) {
      const formData = new FormData()
      formData.append('orderId', id)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/purchase/getpayments/steps', formData)
      .then(res => {
        setSteps(res.data);
      })
      .catch(err => errorNotify(err.response.data.message));
    }
  
    useEffect(() => {
      getPayments();
    }, [setItems]);

    const [modalVideo, setModalVideo] = useState(true);
    function showModalVideo(id, status, amount, type) {
      getBasket(id, status, amount, type);
      getSteps(id);
      setModalVideo(!modalVideo);
      if(modalVideo) document.getElementById("myVideo").style.display = "block";
      else document.getElementById("myVideo").style.display = "none";
    }
    function closeModalVideo() {
      setStatus('');
      setAmount('');
      setModalVideo(!modalVideo);
      if(modalVideo) document.getElementById("myVideo").style.display = "block";
      else document.getElementById("myVideo").style.display = "none";
    }

    useEffect(() => {
        document.body.style = 'background: #F7F7F5;';
        document.querySelector('footer').style = 'background: #F7F7F5;';
        getProfile();
    }, [])

    async function getProfile() {
      const formData = new FormData()
      formData.append('user', store.user.email)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/getprofile', formData)
      .then(res => {
        setActivated(res?.data?.isActivated);
        setRole(res?.data?.role);
        setVerify(res?.data?.verify);
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    return (
        <>
            <div id="myVideo" className="modal">
              <div className="modal-content">
                <span onClick={() => closeModalVideo()} className="close">&times;</span>
                <h3 className='modal-feedback-header'>Детали покупки</h3>
                <hr className="modal-feedback-hr" />
                <p className='modal-feedback-text'>Идентификатор покупки: {basket?.purchase_id}</p>
                <p className='modal-feedback-text'>Наименование товара: {basket?.nameItem}</p>
                <p className='modal-feedback-text'>Стоимость товара: {basket?.amountItem?.slice(0, -2)} ₽</p>
                {
                  type === 1 ?
                  <>
                  <p className='modal-feedback-text'>Логистическая компания: {basket?.nameDelivery}</p>
                  <p className='modal-feedback-text'>Стоимость логистики: {basket?.amountDelivery?.slice(0, -2)} ₽</p>
                  </>
                  :
                  null
                }
                <p className='modal-feedback-text'>ИТОГО: {amount} ₽</p>
                <hr className="modal-feedback-hr" />
                {
                  type === 1 ?
                  <>
                  <h3 style={{marginBottom: '15px', padding: '0'}} className='modal-feedback-header'>Логистика</h3>
                  {steps.map((step) => {
                  return (
                    <div key={step.key}>
                      <hr/>
                      <p style={{padding: '0'}} className='modal-feedback-text'>{step.stepDescription} {step.addInfo}</p>
                      <hr/>
                    </div>
                    )
                  })}
                  </>
                  :
                  null

                }
              </div>
            </div>
            <div className="cabinet-section">
              <div className="cabinet-header">
                <div className="cabinet-header_item">
                  <h3 className="cabinet-title">Личный кабинет</h3>
                </div>
                <div className="cabinet-header_item">
                  <button onClick={() => store.logout()} className="cabinet-button-exit">Выход</button>
                </div>
              </div>
              <div className="cabinet-btn-group">
                  <Link to="/cabinet"><button className="btn-cabinet">Профиль</button></Link>
                  <Link to="/cabinet/purchase"><button className="btn-cabinet-active">Мои заказы</button></Link>
                  <button className="btn-cabinet">Уведомления</button>
                  {role >= 1 ? <Link to="/cabinet/videos"><button className="btn-cabinet">Учебные материалы</button></Link> : null}
                  {role >= 2 ? <Link to="/cabinet/course"><button className="btn-cabinet">Добавить курс</button></Link> : null}
              </div>
                {activated === false ?
                  <div className='cabinet-activate'>
                    <p>Ваша учетная запись не активирована. Для активации, подтвердите ваш действующий адрес электронной почты!
                      <br />На ваш адрес электронной почты было направлено письмо с инструкцией по подтверждению.
                    </p>
                  </div>
                : 
                  ``
                 }
                
                <div className='cabinet-table-provider' style={{marginBottom: '100px'}}>
                  <div className="div-table">
                    <div className="div-table-row">
                      <div className="div-table-col-header" align="center">Действие</div>
                      <div  className="div-table-col-header" align="center">Сумма</div>
                      <div  className="div-table-col-header" align="center">Дата</div>
                      <div  className="div-table-col-header" align="center">Статус</div>
                      <div  className="div-table-col-header" align="center">Детали</div>
                    </div>
                      {items.map((item) => {
                        return (
                          <div className='div-table-provider' key={item._id}>
                            <div className="div-table-row">
                              <div className="div-table-col" align="center">Покупка на сайте</div>
                              <div className="div-table-col" align="center">{item.amount.slice(0, -2)} ₽</div>
                              <div className="div-table-col" align="center">{item.purchaseDate}</div>
                              <div className="div-table-col" align="center">{item.purchaseStatus === 'CONFIRMED' ? 'Оплачено' : item.purchaseStatus === 'NEW' ? 'Создан' : item.purchaseStatus === 'CANCELED' ? 'Отменен' : item.purchaseStatus === 'CONFIRM_CHECKING' ? 'В обработке' : item.purchaseStatus}</div>
                              <div className="div-table-col" align="center">
                                {item.purchaseStatus === "CONFIRMED" ?
                                  <button onClick={() => showModalVideo(item._id, item.purchaseStatus, item.amount.slice(0, -2), item.purchaseType)} className='cabinet-btn-table'>Подробнее</button>
                                  :
                                  <button onClick={() => errorNotify("Оплата не проведена!")} className='cabinet-btn-table cabinet-btn_disable'>Подробнее</button>
                                }
                              </div>
                            </div>
                          </div>
                          )
                      })}
                  </div>
                </div>

                <div className='cabinet-table-provider-sm'>
      <div className='cabinet-table-sm'>
        {items.map((item) => {
            return (
            <div key={item._id}>
              <hr className="cabinet-table-sm-hr"  />
              <div className='cabinet-table-sm-row'>
                <div className="cabinet-table-sm-row-i">
                  <p className='cabinet-table-sm-row-header'>Действие</p>
                  <p className='cabinet-table-sm-row-header'>Сумма</p>
                  <p className='cabinet-table-sm-row-header'>Дата</p>
                  <p className='cabinet-table-sm-row-header'>Статус</p>
                </div>
                <div className="cabinet-table-sm-row-i">
                  <p className='cabinet-table-sm-row-text'>Покупка на сайте</p>
                  <p className='cabinet-table-sm-row-text'>{item.amount.slice(0, -2)} ₽</p>
                  <p className='cabinet-table-sm-row-text'>{item.purchaseDate}</p>
                  <p className='cabinet-table-sm-row-text'>{item.purchaseStatus === 'CONFIRMED' ? 'Оплачено' : item.purchaseStatus === 'NEW' ? 'Создан' : item.purchaseStatus === 'CANCELED' ? 'Отменен' : item.purchaseStatus === 'CONFIRM_CHECKING' ? 'В обработке' : item.purchaseStatus}</p>
                </div>
              </div>
              {item.purchaseStatus === "CONFIRMED" ?
                <button onClick={() => showModalVideo(item._id, item.purchaseStatus, item.amount.slice(0, -2), item.purchaseType)} className='cabinet-table-sm-btn'>Подробнее</button>
                :
                <button onClick={() => errorNotify("Оплата не проведена!")} className='cabinet-table-sm-btn'>Подробнее</button>
              }
            </div>
            )
        })}
        <hr className="cabinet-table-sm-hr" />
      </div>
    </div>

          </div>
        </>
    );
};

export default observer(PurchasePage);