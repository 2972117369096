import React, { useState, useEffect, useRef } from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../../http/index';
import titleApp from '../../services/title';
import errorNotify from '../../services/notify';

function PracticlesPageId() {

  const {id} = useParams();
  const [items, setItems] = useState([]);
  const [otherPracticles, setOtherPracticles] = useState([]);

  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);

  const [name, setName] = useState('');

  const page = {id};

  let limit = 3;
  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 3;
      else if (windowSize.current >= 1024) limit = 3;
      else if (windowSize.current >= 640) limit = 3;
      else if (windowSize.current >= 360) limit = 3;
  }
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/u/practicles/${id}`
    );
    const data = await res.json();
    setItems(data);
    setName(data.lastName + ' ' + data.firstName);
    document.title = `Практик ${data.lastName + ' ' + data.firstName} | ${titleApp()}`;
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const changeNews = async (newid) => {
    const res = await fetch(
      API_URL + `/u/practicles/${newid}`
    );
    const data = await res.json();
    setItems(data);
    setName(data.lastName + ' ' + data.firstName);
    document.title = `Практик ${data.lastName + ' ' + data.firstName} | ${titleApp()}`;
    window.scrollTo(0, 0);
  };

  const getotherPracticles = async () => {
    const res = await fetch(
      API_URL + `/u/practicles?${limit}=10&type=0&role=1`
    );
    const data = await res.json();
    setOtherPracticles(data.users);

  }
  useEffect(() => {
    checkMonitor();
    getotherPracticles();
  },[setOtherPracticles]);
  
    useEffect(() => {
      getCity();
      getCountry();
      getOneNews();
    },[setItems]);

    useEffect(() => {
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);

  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 

  if (items.role !== 1) return (
    <div>Отсутствует страница!</div>
  )

  return (
    <section className="news">
        <div className="container">
          <div className="books-header-box">
            <div className="books-header-box_i">
              <Link to="/shining-hands"><button className="cabinet_back">Назад</button></Link>
            </div>
            <div className="books-header-box_i">
            <img className="books-card-logotype-i" src={items.img === null || items.img === undefined ? 'загрузка' : IMG_URL + `/` + items.img} />
            </div>
            <div className="books-header-box_i">
              <h3 className="books-header-box_header">{items.lastName + ' ' + items.firstName}</h3>
              <p className="books-header-box_author">Практик метода {items.roleType === 0 ? `«Сияющие Руки»` : items.roleType === 1 ? `«Соединение миров»` : items.roleType === 2 ? `«Сияющие Руки» и «Соединение миров»` : `Неизвестно`}</p>
              <p className="books-header-box_desc">{items.description}</p>
            
              <div className="contacts-data">
                <div>
                  <p className="books-header-box_author">Страна </p>
                  <p className="books-header-box_author">Город </p>
                  <p className="books-header-box_author">Эл. почта </p>
                  <p className="books-header-box_author">Телефон: </p>
                </div>
                <div>
                  <p style={{marginTop: '10px', width: '200px'}} className="books-header-box_desc">
                  {country.map((country) => {
                    return (items.countryid === country._id ? country.name : null)
                  })}
                  </p>
                  <p style={{marginTop: '10px', width: '200px'}} className="books-header-box_desc">
                  {city.map((city) => {
                    return (items.cityid === city._id ? city.name : null)
                  })}
                  </p>
                  <p style={{marginTop: '10px', width: '200px'}} className="books-header-box_desc">{items.email}</p>
                  <p style={{marginTop: '10px', width: '200px'}} className="books-header-box_desc">{items.phone}</p>
                </div>
              </div>

              <div className="contacts-group_btn">
                <a href={`https://vk.com/` + items.vk} target="_blank">
                  <button className="btn-contacts">
                    <svg className="btn-contacts_layer" width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2018 13.4998C4.17495 13.4998 0.167 8.68252 0 0.666504H3.51985C3.63547 6.55005 6.23033 9.04221 8.28572 9.55606V0.666504H11.6002V5.74074C13.6299 5.52236 15.7621 3.21005 16.4815 0.666504H19.7958C19.2434 3.80097 16.9311 6.11328 15.2868 7.0639C16.9311 7.83467 19.5647 9.85152 20.5667 13.4998H16.9183C16.1347 11.0591 14.1822 9.17067 11.6002 8.91375V13.4998H11.2018Z" fill="white"/>
                    </svg>
                  </button>
                </a>
                <a href={`https://t.me/` + items.tg} target="_blank">
                  <button className="btn-contacts">
                    <svg style={{marginTop: '4px', marginLeft: '-1px'}} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.39854 6.19117C5.55541 4.38009 8.3273 3.18612 9.71423 2.60925C13.6742 0.962168 14.497 0.676052 15.0333 0.666605C15.1513 0.664527 15.415 0.69376 15.5859 0.832388C15.7302 0.949442 15.7698 1.10757 15.7888 1.21855C15.8078 1.32953 15.8315 1.58234 15.8127 1.77989C15.5981 4.03461 14.6696 9.50624 14.1972 12.0316C13.9973 13.1001 13.6037 13.4584 13.2227 13.4935C12.3946 13.5697 11.7658 12.9462 10.9638 12.4205C9.7088 11.5978 8.9998 11.0857 7.78161 10.283C6.37377 9.35521 7.28641 8.8453 8.08874 8.01198C8.29871 7.79389 11.9472 4.47534 12.0178 4.17429C12.0266 4.13664 12.0348 3.9963 11.9514 3.92219C11.8681 3.84808 11.745 3.87343 11.6562 3.89358C11.5303 3.92215 9.52545 5.2473 5.64156 7.86903C5.07248 8.25981 4.55703 8.4502 4.0952 8.44023C3.58607 8.42923 2.60671 8.15236 1.87865 7.91569C0.985664 7.62542 0.275934 7.47195 0.337736 6.97897C0.369926 6.7222 0.723526 6.4596 1.39854 6.19117Z" fill="white"/>
                    </svg>
                  </button>
                </a>
                <a href={`https://wa.me/` + items.tg} target="_blank">
                  <button className="btn-contacts">
                    <svg style={{marginTop: '2px', marginLeft: '-1px'}} width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.884766 21.6811L2.38529 16.2323C1.4576 14.6329 0.970945 12.8217 0.976014 10.9701C0.976014 5.168 5.72093 0.450684 11.5456 0.450684C14.3743 0.450684 17.0306 1.54551 19.0229 3.53334C21.0202 5.52117 22.1203 8.16489 22.1152 10.9751C22.1152 16.7772 17.3703 21.4945 11.5405 21.4945H11.5355C9.76627 21.4945 8.02748 21.0505 6.48133 20.213L0.884766 21.6811ZM6.75001 18.3109L7.06937 18.5026C8.41782 19.2998 9.96397 19.7185 11.5405 19.7236H11.5456C16.3868 19.7236 20.3308 15.8034 20.3308 10.9801C20.3308 8.64419 19.4183 6.4495 17.7606 4.79465C16.103 3.13981 13.8927 2.23166 11.5456 2.23166C6.70438 2.22662 2.76043 6.14679 2.76043 10.9701C2.76043 12.6199 3.22174 14.2293 4.1038 15.6218L4.31165 15.9548L3.42451 19.1787L6.75001 18.3109Z" fill="white"/>
                      <path d="M0.884766 21.6811L2.38529 16.2323C1.4576 14.6329 0.970945 12.8217 0.976014 10.9701C0.976014 5.168 5.72093 0.450684 11.5456 0.450684C14.3743 0.450684 17.0306 1.54551 19.0229 3.53334C21.0202 5.52117 22.1203 8.16489 22.1152 10.9751C22.1152 16.7772 17.3703 21.4945 11.5405 21.4945H11.5355C9.76627 21.4945 8.02748 21.0505 6.48133 20.213L0.884766 21.6811ZM6.75001 18.3109L7.06937 18.5026C8.41782 19.2998 9.96397 19.7185 11.5405 19.7236H11.5456C16.3868 19.7236 20.3308 15.8034 20.3308 10.9801C20.3308 8.64419 19.4183 6.4495 17.7606 4.79465C16.103 3.13981 13.8927 2.23166 11.5456 2.23166C6.70438 2.22662 2.76043 6.14679 2.76043 10.9701C2.76043 12.6199 3.22174 14.2293 4.1038 15.6218L4.31165 15.9548L3.42451 19.1787L6.75001 18.3109Z" fill="url(#paint0_linear_502_1465)"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M8.90505 6.57045C8.70734 6.13151 8.4995 6.12142 8.31193 6.11637C8.15985 6.11133 7.98242 6.11133 7.805 6.11133C7.62757 6.11133 7.34369 6.17692 7.10036 6.43927C6.85703 6.70162 6.17773 7.33733 6.17773 8.63396C6.17773 9.92555 7.1257 11.1768 7.25751 11.3534C7.38931 11.5299 9.08754 14.2695 11.7692 15.324C13.9997 16.2019 14.456 16.0253 14.9376 15.9799C15.4192 15.9345 16.4989 15.3442 16.722 14.7286C16.94 14.1131 16.94 13.5884 16.8741 13.4774C16.8082 13.3664 16.6307 13.3008 16.3671 13.1697C16.1035 13.0385 14.8058 12.4028 14.5624 12.312C14.3191 12.2262 14.1417 12.1808 13.9693 12.4431C13.7919 12.7055 13.285 13.2958 13.1329 13.4724C12.9808 13.649 12.8237 13.6691 12.56 13.538C12.2964 13.4068 11.4448 13.1293 10.436 12.2312C9.65024 11.535 9.11796 10.6722 8.96588 10.4099C8.8138 10.1475 8.95067 10.0063 9.08247 9.87509C9.19907 9.75905 9.34608 9.56733 9.47788 9.41598C9.60969 9.26462 9.65531 9.15362 9.74149 8.97704C9.82767 8.80045 9.78711 8.6491 9.72121 8.51792C9.65531 8.39179 9.13824 7.09011 8.90505 6.57045Z" fill="white"/>
                      <defs>
                        <linearGradient id="paint0_linear_502_1465" x1="11.5021" y1="21.6791" x2="11.5021" y2="0.450684" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F9F9F9"/>
                        <stop offset="1" stopColor="white"/>
                      </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </a>
              </div>



            </div>
          </div>
          <h3 className='blog-header-other'>Другие практики: </h3>
        </div>

        <div className="container">
          <div className="books-cards_wrapper">
          {otherPracticles.map((otherPracticles) => {
            return (
              otherPracticles.verify == true ?
                otherPracticles._id !== items._id ?
                <div className="books-cards_item" key={otherPracticles._id}>
                  <div className="books-cards_item-content">
                    <img className="books-cards_item_card card-img_practicles" src={IMG_URL + `/` + otherPracticles.img} />
                    <div className="books-cards_item-title">{otherPracticles.lastName + ' ' + otherPracticles.firstName}</div>
                    <div style={{marginTop: "20px"}} className="card_text_blog">
                      {otherPracticles.description}
                    </div>
                    <Link onClick={() => changeNews(otherPracticles._id)} to={`/practicle/` + otherPracticles._id}><button className="card_btn_blog">Открыть профиль</button></Link>
                  </div>
                </div>
                : null
              : null
              )
            })}
          </div>
        </div>
    </section>
  );
}

export default (PracticlesPageId);