import React, { useState, useEffect, useRef } from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../../http/index';
import titleApp from '../../services/title';
import errorNotify from '../../services/notify';

function BookPageId() {

  const {id} = useParams();
  const [title, setTitle] = useState('')
  const [items, setItems] = useState([]);
  const [otherNews, setOtherNews] = useState([]);
  const [orderMode, setOrderMode] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(true);
  const [indexPurchase, setIndexPurchase] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [surName, setSurName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [postType, setPostType] = useState(1);
  const [purchaseType, setPurchaseType] = useState(1);
  const [itemName, setItemName] = useState('');
  const [itemAmount, setItemAmount] = useState('');
  const [deliveryAmount, setDeliveryAmount] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [countDelivery, setCountDelivery] = useState('');
  function showModalFeedback() {
    setModalFeedback(!modalFeedback);
    if(modalFeedback) {
      document.getElementById("purchase").style.display = "block";
    }
    else document.getElementById("purchase").style.display = "none";
  }
  function closeModalPurchase() {
    if(modalFeedback) document.getElementById("purchase").style.display = "block";
    else document.getElementById("purchase").style.display = "none";
  }
  const page = {id};

  let limit = 3;
  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 3;
      else if (windowSize.current >= 1024) limit = 3;
      else if (windowSize.current >= 640) limit = 3;
      else if (windowSize.current >= 360) limit = 3;
  }
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/b/${id}`
    );
    const data = await res.json();
    setItems(data);
    setTitle(data.title);
  };

  const checkDelivery = async () => {
    
    if(indexPurchase === '') return errorNotify('Введите индекс!');
    if(indexPurchase.length > 6 || indexPurchase.length < 6) return errorNotify('Некорректный индекс!');
    setItemName(items.title);
    setItemAmount(items.cost + '00');
    var date = new Date();
    setPurchaseDate(("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2))
    const deliveryDate = date.getFullYear() + ("00" + (date.getMonth() + 1)).slice(-2) + ("00" + date.getDate()).slice(-2);
    const deliveryTime =("00" + date.getHours()).slice(-2) + ("00" + date.getMinutes()).slice(-2);
    const res = await fetch(
      `https://tariff.pochta.ru/v2/calculate/tariff/delivery?json&object=27020&from=195299&to=${indexPurchase}&weight=1000&pack=10&sumoc=${items.cost + '00'}&date=${deliveryDate}&time=${deliveryTime}`
    );
    const data = await res.json();
    const delamt = data.ground.valnds; 
    setDeliveryAmount(delamt.toString().slice(0, -2));
    if(postType === 2) {
      setDeliveryAmount(Number(delamt.toString().slice(0, -2)) + 100); 
    }
    setCountDelivery(data.items[2].delivery.min.toString() + ' - ' + data.items[2].delivery.max.toString());
    setOrderMode(true);
  }

  const changeNews = async (newid) => {
    const res = await fetch(
      API_URL + `/b/${newid}`
    );
    const data = await res.json();
    setItems(data);
    setTitle(data.title);
    setOrderMode(false);
    document.title = `Книга ${data.title} | ${titleApp()}`;
    window.scrollTo(0, 0);
  };

  function initPayment() {
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('country', country)
    formData.append('state', state)
    formData.append('city', city)
    formData.append('address', address)
    formData.append('indexPurchase', indexPurchase)
    formData.append('postType', postType)
    formData.append('itemName', itemName)
    formData.append('itemAmount', itemAmount)
    formData.append('deliveryAmount', deliveryAmount + '00')
    formData.append('purchaseType', purchaseType)
    formData.append('purchaseDate', purchaseDate)

    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  const getOtherNews = async () => {
    const res = await fetch(
      API_URL + `/b?page=1&limit=${limit}`
    );
    const data = await res.json();
    setOtherNews(data);
  }
  useEffect(() => {
    checkMonitor();
    getOtherNews();
  },[setOtherNews]);
  
    useEffect(() => {
      getOneNews();
    },[setItems]);

    useEffect(() => {
      document.title = `Книга ${title} | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);

  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 

  if (items.published == false) return (
    <div>Отсутствует страница!</div>
  )

  return (
    <>
        <div id="purchase" className="modal">
          {orderMode === false ?
          <div className="modal-content">
            <span onClick={() => closeModalPurchase()} className="close">&times;</span>
            <h3 className='modal-feedback-header'>Оформление заказа</h3>
            <p className='modal-feedback-text'>Моя информация</p>
            <hr className="modal-feedback-hr" />
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input" placeholder="Фамилия" />
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input" placeholder="Имя" />
            <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input" placeholder="Отчество" />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input" placeholder="E-Mail" />
            <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input" placeholder="Номер телефона" />
            <p className='modal-feedback-text'>Адрес доставки</p>
            <hr className="modal-feedback-hr" />
            <input value={country} onChange={(e) => setCountry(e.target.value)} className="modal-feedback-input" placeholder="Страна" />
            <input value={state} onChange={(e) => setState(e.target.value)} className="modal-feedback-input" placeholder="Область/край" />
            <input value={city} onChange={(e) => setCity(e.target.value)} className="modal-feedback-input" placeholder="Город" />
            <input value={address} onChange={(e) => setAddress(e.target.value)} className="modal-feedback-input" placeholder="Адрес" />
            <input value={indexPurchase} onChange={(e) => setIndexPurchase(e.target.value)} className="modal-feedback-input" placeholder="Индекс" />
            <p className='modal-feedback-text'>Логистическая компания</p>
            <hr className="modal-feedback-hr" />
              {
                postType === 1 ?
                <div className='btn_delivery_group'>
                <button onClick={() => setPostType(1)} className='button_delivery_postRussia'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="67" height="30" viewBox="0 0 67 30" fill="none">
                    <g opacity="0.8">
                      <path d="M44.5206 9.75391L44.5612 9.84849L44.6111 9.93874L44.6607 10.0333L44.7197 10.1326L44.7512 10.1777L44.7874 10.2272L44.8193 10.277L44.8555 10.3268L44.896 10.3763L44.9365 10.4261L44.9771 10.4755L45.0223 10.5254L45.0719 10.5795L45.1219 10.6293L45.1718 10.6835L45.2261 10.7376L45.2851 10.7918L45.3438 10.8459L45.4071 10.9L45.4752 10.9585L45.5429 11.0127L45.6153 11.0712L45.6877 11.1297L45.7644 11.1928L45.8505 11.2516L45.932 11.3148L46.0225 11.3733L46.113 11.4365C46.3345 11.5899 46.5883 11.9105 46.3982 12.357C46.2082 12.8082 45.8553 13.44 46.0677 13.6883C46.2806 13.9363 47.0313 14.0627 47.6471 13.819C47.6471 13.819 47.7785 14.2071 48.0047 14.4551C48.0047 14.4551 47.8961 14.667 47.5432 14.5587C47.5432 14.5587 47.6746 14.8792 47.4932 15.2222C47.4932 15.2222 47.0816 15.15 46.9003 14.7709C46.9003 14.7709 46.7374 15.0688 46.4073 15.1724C46.4073 15.1724 46.1358 15.0327 46.1948 14.4912C46.1948 14.4912 45.9957 14.9742 45.3803 15.0554C45.3803 15.0554 45.0274 14.6089 45.4889 14.0085C45.4889 14.0085 45.1541 14.3017 44.5568 14.162C44.5568 14.162 44.4573 13.8551 45.005 13.431C45.005 13.431 44.3896 13.4039 44.1543 12.8895C44.1543 12.8895 44.412 12.5693 45.0502 12.6411C45.0502 12.6411 44.4576 12.5101 44.3262 11.8061C44.3262 11.8061 44.7516 11.5354 45.4303 11.9686C45.4303 11.9686 45.3126 11.743 45.0278 11.5173C44.7422 11.3018 44.3983 10.6744 44.5206 9.75391Z" fill="white"/>
                      <path d="M46.9913 16.8594L46.9732 16.8371L46.9598 16.8147L46.9417 16.7919L46.9284 16.7692L46.9146 16.7468L46.9008 16.7244L46.8875 16.7064L46.8741 16.684L46.8603 16.6613L46.8469 16.6385L46.8379 16.6161L46.8241 16.5937L46.8151 16.5757L46.8013 16.5533L46.7923 16.5306L46.7832 16.5078C46.8918 17.3652 46.928 17.9427 47.3895 18.4979C47.3895 18.4979 47.6748 18.1279 47.4891 17.2255C47.4167 17.2027 47.3262 17.167 47.24 17.1081C47.24 17.1081 47.3486 17.4738 47.2809 17.8435C47.2809 17.8431 47.1133 17.7211 46.9913 16.8594Z" fill="white"/>
                      <path d="M46.1862 16.7726L46.1819 16.7683L46.1775 16.7592L46.1732 16.7502L46.1642 16.7459L46.1598 16.7368L46.1508 16.7278L46.1461 16.7188L46.137 16.7098L46.1323 16.7008L46.1232 16.6874L46.1095 16.6694L46.0961 16.6466L46.078 16.6239L46.0646 16.6015L46.0509 16.5791L46.0328 16.5567L46.0194 16.534L46.006 16.5159L45.9922 16.4936L45.9879 16.4802L45.9832 16.4712L45.9741 16.4621L45.9698 16.4531C45.9292 16.9495 45.7978 18.2581 46.2413 18.6689C46.2413 18.6689 46.5399 18.4476 46.6485 16.539C46.5851 16.6473 46.4718 16.76 46.4175 16.8231C46.4175 16.8231 46.4899 17.4278 46.2727 17.8563C46.272 17.8556 46.0548 17.6884 46.1862 16.7726Z" fill="white"/>
                      <path d="M45.1417 17.7708L45.1736 17.73L45.2051 17.6805L45.2369 17.6264L45.2688 17.5679L45.2959 17.5091L45.3231 17.4459L45.3459 17.3784L45.373 17.3105L45.3958 17.2383L45.4183 17.1661L45.4364 17.0939L45.4545 17.0174L45.4907 16.8683L45.5041 16.7961L45.5222 16.7239L45.5359 16.6517L45.545 16.5842L45.5587 16.5167L45.5678 16.4488L45.5768 16.3857L45.5859 16.3272L45.5949 16.273L45.5993 16.2232L45.604 16.1738L45.6087 16.133L45.613 16.1149V16.0969L45.6174 16.0788V16.0655V16.0517L45.6221 16.0427V16.029V16.0247V16.0156V16.0113V16.007C45.6716 15.9752 45.7259 15.9348 45.7759 15.894C45.7987 15.876 45.8168 15.8622 45.8392 15.8398C45.8392 15.8893 45.8392 15.9391 45.8436 15.9889C45.7893 16.7831 45.7259 17.6044 45.4773 18.0148C45.2329 18.4166 44.7848 18.6242 44.7848 18.6242C44.3642 17.8751 44.8706 16.8734 45.2959 16.1741C45.3502 16.1561 45.4045 16.1333 45.4588 16.1062C45.3046 16.6427 45.0241 17.2788 45.1417 17.7708Z" fill="white"/>
                      <path d="M44.0957 15.474V15.4921V15.5054V15.5235V15.5372L44.1004 15.5506V15.5639V15.5776L44.1048 15.582V15.591V15.5953V15.5996V15.6043L44.1091 15.6134V15.6177V15.622V15.6267L44.1138 15.6314V15.6358V15.6401V15.6448L44.1185 15.6495V15.6538V15.6581V15.6628L44.1229 15.6675C44.1272 15.6765 44.1319 15.6989 44.141 15.7217C43.788 16.0603 43.5256 16.3938 43.0054 17.1158C42.4806 17.8421 41.6523 17.8692 41.6523 17.8692C41.7338 17.2826 41.8923 16.8176 42.3354 16.3213C42.7745 15.8206 43.3446 15.3646 43.7609 15.0625C43.8695 15.1123 44.0096 15.1437 44.1862 15.1213C44.1953 15.1213 44.2134 15.1166 44.2271 15.1166C44.1728 15.1888 44.1366 15.261 44.1185 15.3289C43.8018 15.5094 42.8878 15.974 42.5168 16.7187C42.5164 16.7198 43.0956 16.539 44.0957 15.474Z" fill="white"/>
                      <path d="M43.4398 14.7466L43.476 14.6791C43.4851 14.6611 43.4984 14.643 43.5122 14.625C43.1683 14.756 41.9557 15.2116 41.5619 15.4145C41.1727 15.6177 40.6026 16.078 40.2949 17.0166C40.2949 17.0166 41.1141 17.0887 41.7928 16.5292C42.4668 15.9744 43.2541 15.1936 43.5893 14.9456C43.5574 14.9185 43.5303 14.8871 43.5122 14.86C43.5122 14.86 42.5982 15.6679 41.6299 16.0152C41.6299 16.0152 41.838 15.6451 42.2181 15.3701C42.6844 15.0398 43.0326 14.9315 43.4398 14.7466Z" fill="white"/>
                      <path d="M42.7462 14.1926L42.7505 14.1836L42.7552 14.1702L42.7599 14.1569L42.7643 14.1478L42.7686 14.1345L42.7733 14.1208L42.778 14.1161V14.1114L42.7824 14.1067L42.7867 14.0977C42.3842 14.1832 41.3841 14.3006 40.9363 14.4363C40.4882 14.5717 39.6827 14.9009 39.2031 15.8127C39.2031 15.8127 39.909 16.0293 41.2349 15.4066C42.5652 14.7839 42.9815 14.6529 43.2349 14.5854C43.0311 14.5716 42.8863 14.5041 42.8139 14.459C42.2847 14.6937 41.5788 14.9734 40.8729 14.9915C40.8729 14.9915 41.2212 14.7478 41.5831 14.5944C41.9813 14.4273 42.4157 14.2785 42.7462 14.1926Z" fill="white"/>
                      <path d="M42.7786 13.5313L42.801 13.5446L42.8234 13.5537L42.8459 13.5627L42.873 13.5764L42.8955 13.5807L42.9179 13.5898L42.9404 13.5988L42.9632 13.6035L42.986 13.6125L43.0131 13.6172L43.0359 13.6219L43.0631 13.6266L43.0765 13.6313H43.0899L43.1036 13.636H43.1174H43.1308L43.1489 13.6407H43.1623L43.176 13.6454C43.0855 13.7042 42.9994 13.7807 42.9179 13.88C42.4473 14.0154 41.651 14.1598 40.3345 14.2547C39.0176 14.354 38.3164 14.1103 38.3164 14.1103C38.7732 13.8306 39.5062 13.5461 39.9996 13.4515C40.4973 13.3566 41.7961 13.3703 42.3615 13.2891C42.3706 13.2981 42.443 13.3522 42.5563 13.4201C42.072 13.4652 41.0541 13.5104 40.5245 13.7721C40.5249 13.7706 41.4979 13.9013 42.7786 13.5313Z" fill="white"/>
                      <path d="M42.6796 12.7795L42.693 12.7705L42.7068 12.7658L42.7202 12.7568L42.7336 12.7524L42.7473 12.7434L42.7611 12.7387L42.7745 12.7297L42.7926 12.7207L42.806 12.716L42.8197 12.707L42.8378 12.7023L42.8559 12.6932L42.8693 12.6842L42.8874 12.6799L42.9055 12.6709L42.9236 12.6662C42.2583 12.4676 41.3443 12.1427 39.9691 12.0525C38.9599 11.985 38.2269 12.0435 37.8516 12.1427C37.8516 12.1427 38.7699 12.7882 39.8244 13.0181C40.8741 13.2437 41.7291 13.0994 42.3492 13.1037C42.3944 13.0405 42.4397 12.9773 42.5121 12.9051C41.7837 12.9095 40.5664 12.7246 40.132 12.5802C40.1324 12.581 41.1912 12.3553 42.6796 12.7795Z" fill="white"/>
                      <path d="M42.6796 11.9985V12.0032V12.0079L42.6844 12.0122V12.0212L42.6887 12.0303V12.0436L42.693 12.0573L42.6977 12.0711L42.7068 12.0891L42.7111 12.1028L42.7202 12.1256L42.7292 12.1436L42.7383 12.1617L42.7473 12.1844L42.7611 12.2072L42.7745 12.2299L42.7879 12.2526L42.806 12.275L42.8197 12.3021L42.8378 12.3248L42.8603 12.3476L42.8827 12.3747L42.9055 12.3974L42.928 12.4245L42.9551 12.4472L42.9823 12.4696L43.0138 12.4923L43.0456 12.5194L43.0818 12.5375L43.1133 12.5602L43.1538 12.5826L43.1947 12.6006C42.2579 12.1945 40.4118 11.9599 39.4255 11.5447C38.0861 10.9942 37.8418 10.2993 37.8418 10.2993C39.0001 10.2676 39.9366 10.552 40.6562 10.7733C41.5068 11.0303 42.7919 11.5086 43.2396 11.7119C43.072 11.7166 42.8867 11.7617 42.7329 11.87C42.1041 11.631 41.8098 11.527 41.2354 11.3646C40.7872 11.2382 40.4524 11.2155 40.1719 11.1884C40.1719 11.1884 40.57 11.4502 41.2216 11.6577C41.8699 11.8631 42.1504 11.8992 42.6796 11.9985Z" fill="white"/>
                      <path d="M42.6066 10.9801L42.6113 10.9848L42.6156 10.9938L42.62 11.0029L42.6247 11.0076L42.6294 11.0166L42.6337 11.0256L42.6381 11.0347L42.6471 11.0437L42.6514 11.0527L42.6558 11.0617L42.6648 11.0751L42.6692 11.0841L42.6829 11.1069L42.701 11.1249L42.7144 11.1473L42.7282 11.1653L42.7325 11.179L42.7416 11.1881L42.7459 11.1971L42.755 11.2061L42.7597 11.2151L42.7687 11.2242L42.7734 11.2332L42.7781 11.2422L42.7825 11.2469L42.7868 11.2559L42.7915 11.2606L42.7962 11.2697C41.3165 11.0213 39.6876 9.90692 39.1855 9.21201C38.6834 8.5218 38.8279 7.48828 38.8279 7.48828C39.3165 7.58755 40.3753 8.49906 40.6468 8.77413C40.9226 9.04487 41.7552 9.85278 42.33 10.304L42.5063 10.8184C42.0176 10.426 41.4341 9.98814 41.0674 9.71307C40.7869 9.50117 40.3663 9.20769 39.9543 9.06329C39.9543 9.06329 40.3344 9.55965 40.6468 9.80331C41.0044 10.0878 41.3661 10.3315 41.6647 10.4939C41.9326 10.6372 42.0683 10.7231 42.6066 10.9801Z" fill="white"/>
                      <path d="M46.7796 15.1102L46.7886 15.1012L46.7977 15.0922L46.802 15.0875L46.811 15.0785L46.8201 15.0694L46.8291 15.0651L46.8339 15.0561L46.8429 15.0514L46.8476 15.0424L46.8567 15.0333L46.861 15.0287L46.8701 15.0196L46.8748 15.0149L46.8838 15.0059L46.8885 15.0012L46.8976 14.9922C46.9656 15.0824 47.1915 15.3669 47.3048 15.651C47.3906 15.8903 47.4814 16.2196 47.5267 16.4044C47.5719 16.5986 47.7033 16.9863 47.7891 17.1217C47.7891 17.1217 47.4271 17.1715 47.16 16.8556C46.8976 16.5535 46.8114 16.2466 46.7662 15.9308C46.7343 15.6835 46.7481 15.3406 46.7796 15.1102Z" fill="white"/>
                      <path d="M46.209 15.1562L46.2181 15.1653L46.2224 15.1743L46.2315 15.179L46.2358 15.188L46.2402 15.197L46.2492 15.2014L46.2536 15.2057L46.2579 15.2147L46.2669 15.2191L46.2713 15.2234L46.2756 15.2281L46.2803 15.2371L46.2894 15.2415L46.2937 15.2461H46.2981L46.3028 15.2508L46.3075 15.2552L46.3118 15.2595L46.3162 15.2642H46.3209V15.2689H46.3256L46.3299 15.2732H46.3343V15.2775H46.339V15.2819H46.3437V15.2866L46.398 15.3093L46.4476 15.2956C46.4928 15.2775 46.5337 15.2595 46.5699 15.2461C46.6242 15.3862 46.6423 15.4584 46.6604 15.5848C46.6738 15.7472 46.71 16.0046 46.647 16.2165C46.5703 16.4739 46.4161 16.6273 46.3303 16.7309C46.3303 16.7309 46.095 16.4692 46.0273 16.2573C45.9549 16.0363 45.9325 15.7746 46.0183 15.5082C46.0689 15.3638 46.1457 15.2328 46.209 15.1562Z" fill="white"/>
                      <path d="M45.3984 15.1854L45.4208 15.1807H45.4433L45.4614 15.176L45.4838 15.1716L45.5244 15.1626L45.5653 15.1536L45.6062 15.1402L45.6467 15.1312L45.6829 15.1175L45.7191 15.1041L45.7506 15.0861L45.7825 15.0727L45.8187 15.0547L45.8458 15.041L45.8773 15.0229L45.9044 15.0049L45.9316 14.9868L45.9587 14.9688C45.9906 15.05 46.0358 15.1673 46.004 15.325C45.9635 15.4875 45.8545 15.6727 45.6916 15.799C45.4473 15.9933 45.1258 16.1734 44.7642 16.1149C44.7649 16.1149 44.6835 15.8485 45.3984 15.1854Z" fill="white"/>
                      <path d="M45.1278 14.7344L45.1325 14.7571V14.7799L45.1372 14.7979L45.1415 14.8207L45.1459 14.8387L45.1506 14.8568L45.1553 14.8795L45.1596 14.8976L45.1687 14.9156L45.1734 14.9293L45.1777 14.9474L45.1868 14.9654L45.1915 14.9791L45.2005 14.9928L45.2049 15.0062L45.2096 15.0199L45.2186 15.0333L45.223 15.047L45.2277 15.056L45.2367 15.065L45.2411 15.0784L45.2458 15.0874L45.2505 15.0921L45.2548 15.1012L45.2592 15.1102L45.2639 15.1145L45.2686 15.1192L45.2729 15.1239L45.2773 15.1286V15.1333H45.282V15.138C45.1962 15.2372 44.8928 15.756 44.2594 15.6704C44.2594 15.6704 44.0874 15.3138 44.5084 15.034C44.7929 14.8383 44.933 14.8022 45.1278 14.7344Z" fill="white"/>
                      <path d="M45.1811 14.2859L45.1496 14.2949L45.1181 14.304L45.0819 14.3087L45.0457 14.3134L45.0095 14.3181L44.9733 14.3228H44.9328L44.8923 14.3271H44.8514L44.8061 14.3228H44.7656L44.7431 14.3181H44.7207L44.6935 14.3134H44.6711L44.6486 14.3087L44.6258 14.304L44.6034 14.2993L44.5763 14.2946L44.5538 14.2899L44.5267 14.2809L44.4543 14.2675L44.4315 14.1953C44.1104 14.4617 43.7212 14.5065 43.5898 14.7458C43.5898 14.7458 43.7527 15.0436 44.1734 14.9895C44.5944 14.9397 44.9111 14.7458 45.1373 14.4617C45.1402 14.4213 45.1583 14.3444 45.1811 14.2859Z" fill="white"/>
                      <path d="M44.453 13.9166L44.4621 13.8986L44.4758 13.8805L44.4849 13.8624L44.4939 13.8397L44.5073 13.8217L44.5164 13.7993L44.5298 13.7765L44.5388 13.7538L44.5526 13.731L44.566 13.704L44.575 13.6906L44.5841 13.6816L44.5888 13.6679L44.5978 13.6542L44.6069 13.6408L44.6159 13.6318L44.625 13.6181L44.634 13.6047L44.6478 13.5914L44.6568 13.578L44.6706 13.5643L44.6796 13.5552C44.3991 13.5054 44.1504 13.4964 43.8243 13.5643C43.4352 13.6365 43.037 13.7267 42.8379 14.3133C42.8379 14.3133 43.0866 14.5299 43.5437 14.4354C43.9238 14.3588 44.2587 14.1917 44.453 13.9166Z" fill="white"/>
                      <path d="M44.2863 13.2771L44.2682 13.259L44.2501 13.2457L44.232 13.2276L44.2139 13.2096L44.2001 13.1915L44.182 13.1688L44.1639 13.1507L44.1505 13.1327L44.1324 13.1099L44.119 13.0872L44.1009 13.0645L44.0872 13.0417L44.0734 13.0146L44.06 12.9919L44.0466 12.9648L44.0329 12.9377L44.0014 12.8655C43.6666 12.7302 43.3136 12.658 42.9697 12.7843C42.6259 12.915 42.5354 13.0641 42.4492 13.1904C42.4492 13.1904 42.8293 13.5106 43.2684 13.5244C43.7071 13.5341 44.1096 13.4078 44.2863 13.2771Z" fill="white"/>
                      <path d="M44.3907 12.5865L44.3997 12.5818L44.4131 12.5775L44.4222 12.5728H44.4355L44.4446 12.5685L44.4584 12.5638L44.4674 12.5591L44.4812 12.5544H44.4949L44.5083 12.5497L44.5217 12.545H44.5308L44.5441 12.5403L44.5579 12.5356H44.5717L44.585 12.5309C44.4403 12.4003 44.2911 12.2017 44.2184 11.8992C44.0645 11.9353 43.6935 11.9353 43.5353 11.8764C43.3724 11.8133 43.0557 11.8086 42.8066 11.9757C42.8066 11.9757 42.8881 12.4046 43.4492 12.5533C43.9791 12.6948 44.1735 12.6587 44.3907 12.5865Z" fill="white"/>
                      <path d="M44.1862 11.7397L44.2586 11.6899C44.2676 11.6855 44.3943 11.6086 44.6115 11.5949C44.4305 11.4552 43.9961 11.0942 43.6928 10.9271C43.3627 10.7423 42.8106 10.4896 42.5254 10.4531C42.5254 10.4531 42.7925 11.1433 43.0955 11.4235C43.417 11.7126 43.6385 11.8209 44.1862 11.7397Z" fill="white"/>
                      <path d="M44.7535 11.4235L44.7311 11.3917L44.7086 11.3556L44.6815 11.3195L44.6587 11.2834L44.6406 11.2427L44.6181 11.2022L44.5957 11.1614L44.5776 11.1163L44.5595 11.0712L44.537 11.026L44.5189 10.9809L44.5052 10.9311L44.4871 10.8817L44.469 10.8318L44.4556 10.7777L44.4422 10.7279C44.3741 10.4932 44.2294 9.92468 44.1436 9.77126C44.0578 9.62218 43.7454 9.10812 43.1391 8.94531C43.1391 8.94531 42.9805 9.61747 43.2839 10.159C43.5456 10.6203 44.0838 11.1076 44.7535 11.4235Z" fill="white"/>
                      <path d="M48.1233 14.5718L48.1414 14.5855L48.1548 14.5989L48.1686 14.6126L48.182 14.6216L48.1957 14.6353L48.2091 14.6443L48.2229 14.6581L48.2363 14.6671L48.25 14.6761L48.2681 14.6898L48.2772 14.6945L48.2862 14.6992L48.2953 14.7039L48.3043 14.7129L48.3134 14.7176L48.3224 14.7223L48.3362 14.7266L48.3452 14.7357L48.3543 14.7404L48.3677 14.7451L48.3811 14.7541L48.3901 14.7588C48.3901 14.7588 48.5849 15.1288 48.3496 15.571C48.1143 16.0176 47.9195 16.4735 47.8247 16.7262C47.8247 16.7262 47.698 16.7081 47.5579 16.0089C47.4631 15.5396 47.3454 15.4176 47.2188 15.2598C47.3545 15.3274 47.4631 15.3454 47.4768 15.3454L47.5673 15.3681L47.6126 15.2779C47.694 15.1198 47.7165 14.9664 47.7165 14.84C47.7165 14.7949 47.7165 14.7544 47.7118 14.718C47.929 14.727 48.0466 14.6144 48.0919 14.5602C48.0962 14.5512 48.1009 14.5512 48.1009 14.5469L48.1233 14.5718Z" fill="white"/>
                      <path d="M42.9652 9.22787V9.23691V9.25063V9.26434V9.27769V9.29142V9.30514V9.32788V9.35496V9.38202V9.41379V9.44087L42.9786 9.60764C42.6166 9.15171 42.1869 8.60157 41.7797 8.0915C41.4992 7.74422 41.2639 7.53665 40.9967 7.36482C41.223 8.00991 41.6212 8.52467 41.9017 8.77736C42.1822 9.03439 42.5894 9.46758 42.9876 9.73399L43.0238 9.86033C43.0553 9.98235 43.1006 10.104 43.1639 10.2213C43.2635 10.3838 43.3811 10.5419 43.5259 10.6996C43.4126 10.6412 43.2772 10.578 43.1458 10.5238L43.0191 10.4697C41.9285 9.89679 40.8425 8.69651 40.4085 7.86623C39.9698 7.03126 40.1504 6.06598 40.2637 5.54688C40.7615 5.96671 41.4811 6.79229 41.7888 7.23919C42.1735 7.7897 42.6622 8.57486 42.9652 9.21128V9.22787Z" fill="white"/>
                      <path d="M58.2908 12.7795L58.2775 12.7705L58.2684 12.7658L58.255 12.7568L58.2413 12.7524L58.2279 12.7434L58.2098 12.7387L58.196 12.7297L58.1826 12.7207L58.1689 12.716L58.1508 12.707L58.1374 12.7023L58.1193 12.6932L58.1012 12.6842L58.0831 12.6799L58.065 12.6709L58.0469 12.6662C58.7165 12.4676 59.6305 12.1427 61.0108 12.0525C62.0196 11.985 62.7436 12.0435 63.1237 12.1427C63.1237 12.1427 62.2006 12.7882 61.1509 13.0181C60.1011 13.2437 59.2458 13.0994 58.6213 13.1037C58.5808 13.0405 58.5355 12.9773 58.4632 12.9051C59.1915 12.9095 60.4041 12.7246 60.8432 12.5802C60.8428 12.581 59.784 12.3553 58.2908 12.7795Z" fill="white"/>
                      <path d="M58.2936 11.9985V12.0032V12.0079L58.2889 12.0122V12.0212L58.2845 12.0303L58.2798 12.0436V12.0573L58.2708 12.0711L58.2664 12.0891L58.2617 12.1028L58.2527 12.1256L58.2436 12.1436L58.2346 12.1617L58.2208 12.1844L58.2118 12.2072L58.1984 12.2299L58.1846 12.2526L58.1665 12.275L58.1484 12.3021L58.1303 12.3248L58.1122 12.3476L58.0894 12.3747L58.067 12.3974L58.0398 12.4245L58.0127 12.4472L57.9855 12.4696L57.9584 12.4923L57.9222 12.5194L57.8907 12.5375L57.8545 12.5602L57.8183 12.5826L57.7777 12.6006C58.7142 12.1945 60.5559 11.9599 61.5514 11.5447C62.8864 10.9942 63.1307 10.2993 63.1307 10.2993C61.9724 10.2676 61.0312 10.552 60.312 10.7733C59.4657 11.0303 58.181 11.5086 57.7285 11.7119C57.9005 11.7166 58.0862 11.7617 58.2396 11.87C58.8688 11.631 59.1627 11.527 59.7328 11.3646C60.1853 11.2382 60.5154 11.2155 60.7963 11.1884C60.7963 11.1884 60.4025 11.4502 59.7509 11.6577C59.0986 11.8631 58.8181 11.8992 58.2936 11.9985Z" fill="white"/>
                      <path d="M58.3663 10.9798V10.9845L58.3616 10.9935L58.3568 11.0025L58.3525 11.0072L58.3435 11.0162L58.3391 11.0252L58.3344 11.0343L58.3301 11.0433L58.321 11.0523L58.3163 11.0614L58.3029 11.0841L58.2892 11.1068L58.2711 11.1249L58.2577 11.1473L58.2439 11.1653L58.2396 11.179L58.2305 11.188L58.2262 11.1971L58.2171 11.2061L58.2124 11.2151L58.2034 11.2242L58.199 11.2332L58.1943 11.2422L58.19 11.2469L58.1853 11.2559L58.1762 11.2606L58.1719 11.2696C59.6513 11.0213 61.2802 9.9069 61.7826 9.21199C62.2894 8.52178 62.1446 7.48828 62.1446 7.48828C61.6559 7.58755 60.5972 8.49906 60.3213 8.77413C60.0498 9.04487 59.2173 9.85276 58.6428 10.304L58.4662 10.8184C58.9548 10.426 59.5387 9.98812 59.9003 9.71304C60.1852 9.50114 60.5971 9.20766 61.0181 9.06327C61.0181 9.06327 60.6333 9.55962 60.3257 9.80329C59.968 10.0878 59.6017 10.3314 59.3031 10.4939C59.0403 10.6368 58.9002 10.7227 58.3663 10.9798Z" fill="white"/>
                      <path d="M58.0091 9.22825V9.25101V9.27807V9.30514V9.32788V9.35496V9.38204V9.41381V9.44089L57.9953 9.60766C58.3529 9.15173 58.7826 8.60158 59.1898 8.0915C59.4747 7.74422 59.71 7.53666 59.9724 7.36482C59.7505 8.00991 59.3524 8.52469 59.0718 8.77739C58.7913 9.03441 58.3841 9.46759 57.9859 9.734L57.9497 9.86036C57.9135 9.98237 57.8682 10.104 57.8049 10.2213C57.7101 10.3838 57.5877 10.5419 57.4473 10.6997C57.5606 10.6412 57.6916 10.578 57.8273 10.5239L57.9497 10.4697C59.0403 9.89681 60.1263 8.69653 60.5603 7.86625C61.0084 7.03128 60.8227 6.06598 60.7051 5.54688C60.2074 5.96671 59.4924 6.79231 59.1848 7.23921C58.8003 7.78972 58.3117 8.57486 58.0083 9.21128V9.22825H58.0091Z" fill="white"/>
                      <path d="M44.3623 15.8085L44.3171 15.8536L44.2718 15.8987L44.2219 15.9485L44.1723 16.0027L44.118 16.0568L44.0637 16.1153L44.0094 16.1785L43.9551 16.2417L43.8964 16.3095L43.8378 16.377L43.7835 16.4492L43.7249 16.5214L43.6706 16.598L43.6119 16.6792L43.5576 16.7604L43.5033 16.8416L43.4537 16.9275L43.4041 17.0131L43.3542 17.099L43.3089 17.1893L43.2637 17.2795L43.2275 17.3745L43.1913 17.4647L43.1551 17.564L43.128 17.6585L43.1008 17.7535L43.0827 17.8527L43.0646 17.952L43.0556 18.0513L43.0512 18.1549L43.0469 18.2542L43.0559 18.3581C43.0559 18.3581 43.9337 18.0827 44.3 17.4376C44.662 16.7969 44.8839 16.3861 45.0377 16.2417C44.9382 16.2554 44.8387 16.2554 44.7482 16.2417L44.6667 16.2283C44.4405 16.6344 43.9971 17.3023 43.5989 17.5326C43.5989 17.5326 43.4993 16.9911 44.6714 15.8991C44.6895 15.854 44.712 15.8045 44.7438 15.75C44.6352 15.7904 44.5176 15.8085 44.3771 15.8041" fill="white"/>
                      <path d="M56.4544 9.75391L56.4092 9.84849L56.3592 9.93874L56.3096 10.0333L56.251 10.1326L56.2195 10.1777L56.1876 10.2272L56.1514 10.277L56.1152 10.3268L56.079 10.3763L56.0381 10.4261L55.9929 10.4755L55.9476 10.5254L55.9024 10.5795L55.8528 10.6293L55.8028 10.6835L55.7486 10.7376L55.6895 10.7918L55.6309 10.8459L55.5676 10.9L55.4999 10.9585L55.4322 11.0127L55.3598 11.0712L55.2874 11.1297L55.2106 11.1928L55.1292 11.2516L55.0434 11.3148L54.9529 11.3733L54.8624 11.4365C54.6362 11.5899 54.3871 11.9105 54.5772 12.357C54.7672 12.8082 55.1202 13.44 54.9073 13.6883C54.6948 13.9363 53.939 14.0627 53.3236 13.819C53.3236 13.819 53.1926 14.2071 52.9707 14.4551C52.9707 14.4551 53.0793 14.667 53.4322 14.5587C53.4322 14.5587 53.3008 14.8792 53.4818 15.2222C53.4818 15.2222 53.8938 15.15 54.07 14.7709C54.07 14.7709 54.2329 15.0688 54.5631 15.1724C54.5631 15.1724 54.8389 15.0327 54.7759 14.4912C54.7759 14.4912 54.975 14.9742 55.5947 15.0554C55.5947 15.0554 55.9476 14.6089 55.4861 14.0085C55.4861 14.0085 55.8162 14.3017 56.4182 14.162C56.4182 14.162 56.5131 13.8551 55.9701 13.431C55.9701 13.431 56.5855 13.4039 56.8207 12.8895C56.8207 12.8895 56.5583 12.5693 55.9205 12.6411C55.9205 12.6411 56.5134 12.5101 56.6445 11.8061C56.6445 11.8061 56.2191 11.5354 55.5448 11.9686C55.5448 11.9686 55.6624 11.743 55.9476 11.5173C56.2282 11.3018 56.5721 10.6744 56.4544 9.75391Z" fill="white"/>
                      <path d="M53.9832 16.8594L54.0013 16.8371L54.015 16.8147L54.0284 16.7919L54.0465 16.7692L54.0603 16.7468L54.0737 16.7244L54.0871 16.7064L54.1008 16.684L54.1142 16.6613L54.1233 16.6385L54.137 16.6161L54.1461 16.5937L54.1595 16.5757L54.1685 16.5533L54.1776 16.5306L54.1866 16.5078C54.0827 17.3652 54.0462 17.9427 53.5803 18.4979C53.5803 18.4979 53.2998 18.1279 53.4808 17.2255C53.5622 17.2027 53.6436 17.167 53.7298 17.1081C53.7298 17.1081 53.6259 17.4738 53.6889 17.8435C53.6893 17.8431 53.8565 17.7211 53.9832 16.8594Z" fill="white"/>
                      <path d="M54.7833 16.7726L54.7924 16.7683L54.7971 16.7592L54.8014 16.7502L54.8062 16.7459L54.8152 16.7368L54.8195 16.7278L54.8286 16.7188L54.8333 16.7098L54.8424 16.7008L54.8467 16.6874L54.8648 16.6694L54.8782 16.6466L54.8919 16.6239L54.91 16.6015L54.9234 16.5791L54.9372 16.5567L54.9506 16.534L54.9643 16.5159L54.9777 16.4936L54.9868 16.4802L54.9911 16.4712L54.9958 16.4621L55.0005 16.4531C55.0411 16.9495 55.1725 18.2581 54.7334 18.6689C54.7334 18.6689 54.4304 18.4476 54.3262 16.539C54.3895 16.6473 54.5028 16.76 54.5524 16.8231C54.5524 16.8231 54.48 17.4278 54.6972 17.8563C54.6972 17.8556 54.9191 17.6884 54.7833 16.7726Z" fill="white"/>
                      <path d="M55.8344 17.7708L55.7982 17.73L55.7667 17.6805L55.7349 17.6264L55.7077 17.5679L55.6762 17.5091L55.6538 17.4459L55.6266 17.3784L55.6038 17.3105L55.5814 17.2383L55.5586 17.1661L55.5405 17.0939L55.5177 17.0174L55.4862 16.8683L55.4681 16.7961L55.4543 16.7239L55.4409 16.6517L55.4319 16.5842L55.4185 16.5167L55.4094 16.4488L55.4004 16.3857L55.3913 16.3272L55.3823 16.273L55.3776 16.2232L55.3732 16.1738L55.3685 16.133L55.3642 16.1149V16.0969L55.3595 16.0788V16.0655V16.0517L55.3551 16.0427V16.029V16.0247V16.0156V16.0113V16.007C55.3008 15.9752 55.2513 15.9348 55.2013 15.894C55.1785 15.876 55.1561 15.8622 55.138 15.8398C55.138 15.8893 55.138 15.9391 55.1289 15.9889C55.1875 16.7831 55.2513 17.6044 55.4999 18.0148C55.7443 18.4166 56.1924 18.6242 56.1924 18.6242C56.613 17.8751 56.1066 16.8734 55.6766 16.1741C55.6223 16.1561 55.5727 16.1333 55.518 16.1062C55.6668 16.6427 55.9474 17.2788 55.8344 17.7708Z" fill="white"/>
                      <path d="M56.8791 15.474V15.483V15.4921V15.5054V15.5235V15.5372L56.8744 15.5506V15.5639V15.5686V15.5776L56.87 15.582V15.591V15.5953V15.5996L56.8653 15.6043V15.6134V15.6177V15.622L56.861 15.6267V15.6314V15.6358V15.6401L56.8563 15.6448V15.6495V15.6538V15.6581V15.6628L56.8519 15.6675C56.8472 15.6765 56.8429 15.6989 56.8338 15.7217C57.1868 16.0603 57.4492 16.3938 57.9694 17.1158C58.4895 17.8421 59.3224 17.8692 59.3224 17.8692C59.241 17.2826 59.0781 16.8176 58.6347 16.3213C58.1956 15.8206 57.6258 15.3646 57.2139 15.0625C57.1053 15.1123 56.9652 15.1437 56.7886 15.1213C56.7705 15.1213 56.7614 15.1166 56.748 15.1166C56.8023 15.1888 56.8385 15.261 56.8566 15.3289C57.1687 15.5094 58.0874 15.974 58.4584 16.7187C58.4584 16.7198 57.8792 16.539 56.8791 15.474Z" fill="white"/>
                      <path d="M57.5347 14.7466L57.4985 14.6791C57.4895 14.6611 57.4761 14.643 57.4623 14.625C57.8018 14.756 59.0141 15.2116 59.4079 15.4145C59.8018 15.6177 60.3672 16.078 60.6749 17.0166C60.6749 17.0166 59.8557 17.0887 59.1817 16.5292C58.5073 15.9744 57.7204 15.1936 57.3809 14.9456C57.4171 14.9185 57.4442 14.8871 57.4623 14.86C57.4623 14.86 58.3763 15.6679 59.3446 16.0152C59.3446 16.0152 59.1364 15.6451 58.7517 15.3701C58.2901 15.0398 57.9416 14.9315 57.5347 14.7466Z" fill="white"/>
                      <path d="M58.227 14.1919L58.2222 14.1829L58.2179 14.1695L58.2132 14.1561L58.2041 14.1471L58.1998 14.1338L58.1951 14.12L58.1908 14.111L58.1861 14.0977C58.5842 14.1832 59.5887 14.3005 60.0365 14.4363C60.4799 14.5716 61.29 14.9009 61.7697 15.8127C61.7697 15.8127 61.0638 16.0293 59.7335 15.4066C58.4079 14.7839 57.9917 14.6529 57.7383 14.5854C57.9417 14.5716 58.0869 14.5041 58.1589 14.459C58.6885 14.6937 59.394 14.9734 60.1002 14.9915C60.1002 14.9915 59.7516 14.7478 59.3853 14.5944C58.9918 14.4265 58.5574 14.2778 58.227 14.1919Z" fill="white"/>
                      <path d="M58.1955 13.5313L58.173 13.5446L58.1502 13.5537L58.1231 13.5627L58.1003 13.5764L58.0778 13.5807L58.055 13.5898L58.0326 13.5988L58.0102 13.6035L57.9874 13.6125L57.9602 13.6172L57.9378 13.6219L57.9106 13.6266L57.8835 13.6313L57.8701 13.636H57.8563H57.8382L57.8248 13.6407H57.8111L57.793 13.6454C57.8835 13.7042 57.974 13.7807 58.0554 13.88C58.5216 14.0154 59.318 14.1598 60.6345 14.2547C61.9511 14.354 62.6526 14.1103 62.6526 14.1103C62.1954 13.8306 61.4627 13.5461 60.9694 13.4515C60.4763 13.3566 59.1775 13.3703 58.6074 13.2891C58.5984 13.2981 58.535 13.3522 58.4174 13.4201C58.897 13.4652 59.9196 13.5104 60.4445 13.7721C60.4441 13.7706 59.4758 13.9013 58.1955 13.5313Z" fill="white"/>
                      <path d="M54.1904 15.1102L54.1766 15.0922L54.1676 15.0875L54.1585 15.0785L54.1495 15.0694L54.1452 15.0651L54.1361 15.0561L54.1314 15.0514L54.1223 15.0424L54.118 15.0333L54.109 15.0287L54.1042 15.0196L54.0952 15.0149L54.0905 15.0059L54.0814 15.0012L54.0771 14.9922C54.0047 15.0824 53.7785 15.3669 53.6652 15.651C53.5837 15.8903 53.4885 16.2196 53.448 16.4044C53.3984 16.5986 53.267 16.9863 53.1855 17.1217C53.1855 17.1217 53.5432 17.1715 53.81 16.8556C54.0724 16.5535 54.1676 16.2466 54.2038 15.9308C54.2356 15.6835 54.2266 15.3406 54.1904 15.1102Z" fill="white"/>
                      <path d="M54.7593 15.1562L54.7549 15.1653L54.7459 15.1743L54.7412 15.179L54.7368 15.188L54.7278 15.197L54.7231 15.2014L54.7187 15.2057L54.7097 15.2147L54.705 15.2191L54.7006 15.2234L54.6959 15.2281L54.6869 15.2371L54.6825 15.2415L54.6778 15.2461H54.6735L54.6688 15.2508L54.6644 15.2552L54.6601 15.2595L54.6554 15.2642H54.6507L54.6463 15.2689H54.642L54.6373 15.2732L54.6329 15.2775H54.6282V15.2819H54.6239H54.6192V15.2866L54.5739 15.3093L54.5196 15.2956C54.4787 15.2775 54.4382 15.2595 54.3973 15.2461C54.3473 15.3862 54.3249 15.4584 54.3111 15.5848C54.293 15.7472 54.2615 16.0046 54.3249 16.2165C54.4016 16.4739 54.5511 16.6273 54.6369 16.7309C54.6369 16.7309 54.8766 16.4692 54.9399 16.2573C55.0123 16.0363 55.0395 15.7746 54.949 15.5082C54.9041 15.3638 54.8273 15.2328 54.7593 15.1562Z" fill="white"/>
                      <path d="M55.5753 15.1854L55.5528 15.1807H55.5304L55.5076 15.176L55.4851 15.1716L55.4442 15.1626L55.4037 15.1536L55.3631 15.1402L55.3269 15.1312L55.2907 15.1175L55.2545 15.1041L55.2183 15.0861L55.1868 15.0727L55.1554 15.0547L55.1235 15.041L55.0964 15.0229L55.0645 15.0049L55.0374 14.9868L55.0149 14.9688C54.9834 15.05 54.9335 15.1673 54.9697 15.325C55.0059 15.4875 55.1192 15.6727 55.2817 15.799C55.5217 15.9933 55.8471 16.1734 56.2047 16.1149C56.2044 16.1149 56.2902 15.8485 55.5753 15.1854Z" fill="white"/>
                      <path d="M55.8472 14.7344L55.8429 14.7571L55.8382 14.7799L55.8338 14.7979V14.8207L55.8291 14.8387L55.8248 14.8568L55.8157 14.8795L55.811 14.8976L55.8067 14.9156L55.802 14.9293L55.7929 14.9474L55.7886 14.9654L55.7839 14.9791L55.7748 14.9928L55.7701 15.0062L55.761 15.0199L55.7567 15.0333L55.752 15.047L55.743 15.056L55.7386 15.065L55.7339 15.0784L55.7296 15.0874L55.7205 15.0921L55.7158 15.1012L55.7115 15.1102L55.7068 15.1145V15.1192L55.7024 15.1239L55.6977 15.1286V15.1333H55.6934V15.138C55.7748 15.2372 56.0825 15.756 56.716 15.6704C56.716 15.6704 56.8836 15.3138 56.4626 15.034C56.1777 14.8383 56.0419 14.8022 55.8472 14.7344Z" fill="white"/>
                      <path d="M55.7891 14.2859L55.8206 14.2949L55.8568 14.304L55.8929 14.3087L55.9292 14.3134L55.9653 14.3181L56.0015 14.3228H56.0421L56.0826 14.3271H56.1232L56.1684 14.3228H56.2093L56.2546 14.3181L56.2774 14.3134H56.3045L56.327 14.3087L56.3494 14.304L56.3722 14.2993L56.3994 14.2946L56.4218 14.2899L56.4446 14.2809L56.517 14.2675L56.5442 14.1953C56.8652 14.4617 57.2544 14.5065 57.3858 14.7458C57.3858 14.7458 57.2229 15.0436 56.8019 14.9895C56.3813 14.9397 56.0645 14.7458 55.8383 14.4617C55.8296 14.4213 55.8115 14.3444 55.7891 14.2859Z" fill="white"/>
                      <path d="M56.5212 13.9166L56.5165 13.9076L56.5121 13.8986L56.4984 13.8805L56.4893 13.8624L56.4803 13.8397L56.4669 13.8217L56.4578 13.7993L56.4444 13.7765L56.4307 13.7538L56.4216 13.731L56.4082 13.704L56.3992 13.6906L56.3901 13.6816L56.3854 13.6679L56.3764 13.6542L56.3673 13.6408L56.3583 13.6318L56.3492 13.6181L56.3402 13.6047L56.3264 13.5914L56.3174 13.578L56.304 13.5643L56.2949 13.5552C56.5707 13.5054 56.8241 13.4964 57.1499 13.5643C57.539 13.6365 57.9372 13.7267 58.1363 14.3133C58.1363 14.3133 57.8829 14.5299 57.4304 14.4354C57.0504 14.3588 56.7112 14.1917 56.5212 13.9166Z" fill="white"/>
                      <path d="M56.6855 13.2771L56.7036 13.259L56.7217 13.2457L56.7398 13.2276L56.7579 13.2096L56.776 13.1915L56.7941 13.1688L56.8079 13.1507L56.826 13.1327L56.8394 13.1099L56.8575 13.0872L56.8712 13.0645L56.8846 13.0417L56.8984 13.0146L56.9118 12.9919L56.9252 12.9648L56.9389 12.9377L56.9704 12.8655C57.3096 12.7302 57.6582 12.658 58.0021 12.7843C58.3459 12.915 58.4364 13.0641 58.5222 13.1904C58.5222 13.1904 58.1421 13.5106 57.7078 13.5244C57.2691 13.5341 56.8618 13.4078 56.6855 13.2771Z" fill="white"/>
                      <path d="M56.5835 12.5865L56.5611 12.5775L56.5477 12.5728H56.5386L56.5249 12.5685L56.5158 12.5638L56.5024 12.5591L56.4887 12.5544H56.4753L56.4662 12.5497L56.4528 12.545H56.4391L56.4257 12.5403L56.4119 12.5356H56.3985L56.3848 12.5309C56.5386 12.4003 56.6787 12.2017 56.7515 11.8992C56.9143 11.9353 57.2763 11.9353 57.4392 11.8764C57.6021 11.8133 57.9189 11.8086 58.1675 11.9757C58.1675 11.9757 58.0861 12.4046 57.5207 12.5533C56.9954 12.6948 56.7963 12.6587 56.5835 12.5865Z" fill="white"/>
                      <path d="M56.7843 11.7397L56.7119 11.6899C56.7028 11.6855 56.5761 11.6086 56.3633 11.5949C56.5443 11.4552 56.9743 11.0942 57.2773 10.9271C57.6121 10.7423 58.1641 10.4896 58.4447 10.4531C58.4447 10.4531 58.1822 11.1433 57.8745 11.4235C57.5578 11.7126 57.3363 11.8209 56.7843 11.7397Z" fill="white"/>
                      <path d="M56.2168 11.4235L56.2439 11.3917L56.2668 11.3556L56.2892 11.3195L56.312 11.2834L56.3344 11.2427L56.3569 11.2022L56.3797 11.1614L56.3978 11.1163L56.4159 11.0712L56.4383 11.026L56.4564 10.9809L56.4745 10.9311L56.4879 10.8817L56.506 10.8318L56.5198 10.7777L56.5379 10.7279C56.5965 10.4932 56.746 9.92468 56.8318 9.77126C56.9176 9.62218 57.23 9.10812 57.8363 8.94531C57.8363 8.94531 57.9949 9.61747 57.6915 10.159C57.4251 10.6203 56.8865 11.1076 56.2168 11.4235Z" fill="white"/>
                      <path d="M52.8525 14.5718L52.8344 14.5855L52.8207 14.5989L52.8073 14.6126L52.7935 14.6216L52.7802 14.6353L52.7664 14.6443L52.753 14.6581L52.7396 14.6671L52.7215 14.6761L52.7034 14.6898L52.6987 14.6945L52.6897 14.6992L52.6806 14.7039L52.6716 14.7129L52.6582 14.7176L52.6491 14.7223L52.6401 14.7266L52.631 14.7357L52.6176 14.7404L52.6086 14.7451L52.5948 14.7541L52.5814 14.7588C52.5814 14.7588 52.3914 15.1288 52.622 15.571C52.8573 16.0176 53.0563 16.4735 53.1512 16.7262C53.1512 16.7262 53.2779 16.7081 53.4183 16.0089C53.5131 15.5396 53.6355 15.4176 53.7578 15.2598C53.6174 15.3274 53.5088 15.3454 53.4998 15.3454L53.4093 15.3681L53.364 15.2779C53.2782 15.1198 53.2554 14.9664 53.2554 14.84C53.2554 14.7949 53.2601 14.7544 53.2645 14.718C53.0473 14.727 52.9253 14.6144 52.8844 14.5602C52.8797 14.5512 52.8797 14.5512 52.8706 14.5469L52.8525 14.5718Z" fill="white"/>
                      <path d="M56.6122 15.8085L56.6575 15.8536L56.7027 15.8987L56.7523 15.9485L56.8019 16.0027L56.8562 16.0568L56.9058 16.1153L56.9648 16.1785L57.0191 16.2417L57.0781 16.3095L57.1324 16.377L57.1914 16.4492L57.25 16.5214L57.3043 16.598L57.3586 16.6792L57.4176 16.7604L57.4672 16.8416L57.5215 16.9275L57.5711 17.0131L57.6207 17.099L57.666 17.1893L57.7065 17.2795L57.7474 17.3745L57.7836 17.4647L57.8151 17.564L57.8466 17.6585L57.8694 17.7535L57.8918 17.8527L57.9099 17.952L57.919 18.0513L57.9233 18.1549V18.2542L57.919 18.3581C57.919 18.3581 57.0365 18.0827 56.6749 17.4376C56.3129 16.7969 56.091 16.3861 55.9375 16.2417C56.0323 16.2554 56.1319 16.2554 56.2271 16.2417L56.3085 16.2283C56.5348 16.6344 56.9739 17.3023 57.372 17.5326C57.372 17.5326 57.4759 16.9911 56.3042 15.8991C56.2861 15.854 56.2633 15.8045 56.2274 15.75C56.336 15.7904 56.458 15.8085 56.5985 15.8041" fill="white"/>
                      <path d="M49.7969 7.08876V7.07973L49.8012 7.07069L49.8059 7.066L49.8103 7.05699L49.8193 7.05229L49.8284 7.04326L49.8374 7.03857L49.8508 7.02956L49.8646 7.02486L49.878 7.01583L49.8961 7.01114L49.9142 7.00644L49.9323 6.99741L49.9547 6.99272L49.9775 6.9884L49.9999 6.9837L50.0227 6.97901L50.0452 6.97467L50.0723 6.96998L50.0995 6.96528L50.1266 6.96059L50.1581 6.95627L50.1853 6.95158H50.2168L50.2486 6.94689L50.2801 6.94219H50.3116H50.3478L50.3793 6.9375H50.4155H50.4473H50.4835C50.8636 6.9375 51.1713 7.00499 51.1713 7.08658C51.1713 7.16311 50.8636 7.23098 50.4835 7.23098C50.1046 7.23279 49.7969 7.16529 49.7969 7.08876Z" fill="white"/>
                      <path d="M47.457 7.88025V7.87556L47.4614 7.87086V7.86654L47.4657 7.86185L47.4704 7.85751L47.4795 7.85282H47.4838L47.4929 7.84812L47.5019 7.84343L47.511 7.83874H47.52L47.5338 7.83404L47.5472 7.82935L47.5562 7.82466H47.5743L47.5881 7.81997H47.6015L47.6196 7.81565L47.6377 7.81095H47.651L47.6691 7.80626H47.6916H47.7097L47.7278 7.80157H47.7506H47.7687L47.7911 7.79688H47.8139H47.8364H47.8545H47.8816H47.9041C48.1437 7.79688 48.3475 7.83297 48.3475 7.87809C48.3475 7.92321 48.1437 7.95463 47.9041 7.95463C47.6561 7.9568 47.457 7.92537 47.457 7.88025Z" fill="white"/>
                      <path d="M50.6211 7.40984V7.41885V7.42789V7.43258V7.44159L50.6167 7.44628V7.45532L50.612 7.46001V7.46904L50.6073 7.47374V7.47843L50.603 7.48312L50.5987 7.49213L50.5939 7.49683L50.5896 7.50152L50.5849 7.50621L50.5806 7.51091L50.5758 7.5156L50.5715 7.52029L50.5668 7.52499L50.5625 7.52968L50.5577 7.53402H50.5534L50.5444 7.53834L50.5396 7.54303H50.5353L50.5263 7.54772H50.5215H50.5125L50.5082 7.55206H50.4991H50.4944H50.4854C50.413 7.55206 50.3496 7.49358 50.3496 7.41235C50.3496 7.34449 50.413 7.28132 50.4854 7.28132C50.5621 7.2788 50.6211 7.34198 50.6211 7.40984Z" fill="white"/>
                      <path d="M49.7457 6.94491L49.7233 6.89075L49.7052 6.84131L49.6827 6.79619L49.6646 6.74672L49.6466 6.7016L49.6285 6.65216L49.6056 6.60703L49.5923 6.56623L49.5742 6.5211L49.5561 6.48032L49.5427 6.43951L49.5246 6.39908L49.5108 6.3583L49.4974 6.32221L49.484 6.2861L49.4703 6.25001L49.4569 6.2139L49.4478 6.1825L49.4388 6.15073L49.425 6.11895L49.416 6.0919L49.4069 6.06482L49.4026 6.03774L49.3935 6.01066L49.3888 5.98792L49.3845 5.96554L49.3798 5.9428L49.3754 5.92476L49.3711 5.90671V5.88864V5.87494V5.86159C49.3754 5.78036 49.4706 5.72152 49.5611 5.69444C49.656 5.66737 49.7331 5.65833 49.7964 5.70346C49.914 5.79803 50.027 6.52904 50.0632 6.83624C49.9495 6.85898 49.8815 6.87704 49.7457 6.94491Z" fill="white"/>
                      <path d="M50.1681 6.82631L50.1771 6.82161H50.1862H50.1996H50.2086L50.2314 6.81692H50.2539H50.2767H50.2991L50.3444 6.81223C50.3035 6.5191 50.2039 6.0361 50.1453 5.84224C50.0863 5.64803 50.0095 5.51302 49.8828 5.47656C49.8828 5.47656 49.9824 5.74297 50.0367 6.01805C50.091 6.2942 50.1272 6.56459 50.1681 6.82631Z" fill="white"/>
                      <path d="M49.4395 7.8026L49.4666 7.79791L49.489 7.78888L49.5115 7.77984L49.5343 7.77083L49.5567 7.7618L49.5839 7.74809L49.6063 7.73906L49.6291 7.72533L49.6516 7.71163L49.6697 7.69828L49.6925 7.68455L49.7149 7.67082L49.733 7.65747L49.7555 7.64377L49.7736 7.63039L49.7917 7.61235L49.8098 7.59864L49.8235 7.58527L49.8416 7.57156L49.8554 7.56253L49.8688 7.54918L49.8825 7.53583L49.8959 7.52679L49.9097 7.51344L49.9187 7.50441L49.9278 7.49971L49.9321 7.4907L49.9411 7.48167L49.9455 7.47735H49.9502V7.47266C49.5437 7.53186 49.4395 7.8026 49.4395 7.8026Z" fill="white"/>
                      <path d="M49.8203 7.77985L49.7841 7.79789L49.7526 7.81162L49.7207 7.82966L49.6845 7.84337L49.6527 7.8571L49.6212 7.87045L49.585 7.87948L49.5535 7.89283L49.5216 7.90187L49.4901 7.90656L49.4586 7.91557L49.4268 7.92461L49.3996 7.9293L49.3681 7.93362L49.341 7.93831L49.3139 7.943H49.2867L49.2643 7.9477H49.2418L49.219 7.95239H49.1966H49.1738H49.1557H49.1419H49.1238H49.1104H49.1014H49.0923H49.0833H49.0786H49.0742C49.151 7.81232 49.2237 7.68165 49.4409 7.52823C49.6624 7.37011 50.0201 7.32968 50.1602 7.34773C50.1279 7.45495 49.9832 7.68057 49.8203 7.77985Z" fill="white"/>
                      <path d="M49.7404 7.96875C49.478 8.09076 48.6635 8.10412 48.2244 8.12686H48.1904H48.1452H48.1111H48.0659H48.0206H47.9866H47.9417L47.9077 8.11567H47.8625L47.8284 8.10447H47.7944L47.7604 8.09328L47.7264 8.08209H47.6923L47.6583 8.0709H47.6243L47.5903 8.0597H47.5678L47.5338 8.04853L47.5113 8.03734H47.4893L47.4665 8.02615L47.444 8.01495H47.4328H47.41L47.3988 8.00376H47.3875L47.3647 7.99257H47.3535C47.4035 8.94919 48.055 9.00803 48.055 9.00803C48.046 8.9315 48.055 8.80479 48.055 8.80479C48.2585 8.99431 48.6773 9.13185 48.6773 9.13185C48.6726 9.01453 48.632 8.91743 48.632 8.91743C48.8087 9.02103 48.9147 9.01887 48.9147 9.01887C48.8966 8.8835 48.813 8.72574 48.813 8.72574C48.9354 8.88349 49.0845 8.89504 49.0845 8.89504C49.1116 8.81851 49.0617 8.6243 49.0617 8.6243C49.125 8.70083 49.2427 8.90407 49.6047 8.98529C49.9757 9.06182 50.1364 8.84992 50.1364 8.84992C49.7835 8.87266 49.4465 8.53405 49.4465 8.53405C49.6365 8.46185 49.7067 8.33117 49.7067 8.33117C49.5934 8.23623 49.4805 8.22973 49.4805 8.22973C49.6883 8.13335 49.7404 7.96875 49.7404 7.96875ZM48.4959 8.32974V8.36367C48.4959 8.36367 47.9305 8.36584 47.9077 8.64561C47.8172 8.58676 47.772 8.47882 47.7492 8.35248C47.7495 8.35248 48.1524 8.37486 48.4959 8.32974Z" fill="white"/>
                      <path d="M47.2539 8.05251L47.2583 8.09329L47.263 8.1294L47.2673 8.17019L47.272 8.20628L47.2764 8.24671L47.2854 8.28282L47.2945 8.31891L47.3035 8.35502L47.3126 8.39111L47.3216 8.4272L47.3307 8.45862L47.3444 8.49039L47.3535 8.52214L47.3672 8.55391L47.3806 8.58099L47.3944 8.60807C47.3944 8.60807 47.1367 8.41855 47.0733 8.25177C47.0096 8.08428 46.9915 7.97562 47.2539 8.05251Z" fill="white"/>
                      <path d="M46.4953 8.23463C46.3835 8.2469 46.2803 8.29309 46.2126 8.39236C46.0812 8.58658 46.129 8.95659 46.4048 9.11434C46.4048 9.11434 46.8189 8.75554 47.321 9.10317C47.321 9.10317 47.4318 9.03781 47.6942 8.97897V8.9678H47.683V8.95661L47.6718 8.94541L47.6605 8.93422H47.6493V8.92303L47.6381 8.91184L47.6269 8.90064L47.6156 8.88945L47.6044 8.87826L47.5932 8.86706H47.5813L47.57 8.84433H47.5588L47.5476 8.82159H47.5364L47.5252 8.8104L47.5027 8.7992L47.4915 8.78801L47.4803 8.77682L47.4578 8.76565L47.4466 8.75445L47.4354 8.74326L47.4126 8.73207H47.4003L47.3778 8.72088L47.3666 8.70968C47.2128 8.64218 46.896 8.54724 46.8012 8.25845C46.7284 8.23932 46.6075 8.22236 46.4953 8.23463ZM46.7331 8.61798C46.7921 8.61798 46.8464 8.67215 46.8464 8.73099V8.74218V8.75335H46.8352V8.76455V8.77574H46.824V8.78693V8.79812H46.8128V8.80932H46.8015V8.82051H46.7903H46.7791V8.8317H46.7668H46.7556L46.7444 8.84289H46.7331C46.6745 8.84289 46.6314 8.78875 46.6314 8.72991C46.6311 8.67215 46.6741 8.61798 46.7331 8.61798Z" fill="white"/>
                      <path d="M47.7516 9.04688L47.7559 9.05157L47.7606 9.05589V9.06058H47.765L47.7697 9.06961L47.774 9.07431L47.7831 9.08334L47.7878 9.09704L47.7968 9.10608L47.8059 9.11509L47.8149 9.12846L47.824 9.14217L47.8377 9.1512L47.8468 9.16491L47.8602 9.17828L47.8692 9.19632L47.883 9.20968L47.9101 9.23676L47.9326 9.26383L47.9597 9.29558L47.9731 9.30931L47.9869 9.32266L48.0003 9.33639L48.014 9.34974L48.0274 9.35877L48.0408 9.37248L48.0546 9.38151L48.0679 9.39486C48.0679 9.39486 47.3892 9.38583 47.222 9.97244C47.222 9.97244 46.9911 9.61145 47.1586 9.38114C47.3396 9.13242 47.5116 9.09669 47.7516 9.04688Z" fill="white"/>
                      <path d="M48.4941 9.18304L48.4988 9.18773H48.5032H48.5079L48.5122 9.19242H48.5169H48.5213H48.526L48.535 9.19712L48.5441 9.20181H48.5531L48.5665 9.20615L48.5756 9.21084H48.5893L48.5984 9.21554L48.6121 9.22023L48.6346 9.22457L48.6574 9.23358L48.6845 9.23792L48.6936 9.24262L48.707 9.24731L48.716 9.252H48.7294L48.7385 9.25669L48.7475 9.26139H48.7566L48.7656 9.26608H48.7747L48.7837 9.27077C48.7837 9.27077 48.7837 9.14442 48.7794 9.08594C48.8471 9.09929 48.9242 9.12204 48.9966 9.13106C48.9966 9.13106 49.2137 9.60937 49.3857 9.80323C49.3857 9.80323 49.3947 9.91622 49.2999 10.074C49.2999 10.074 49.1732 9.79421 48.956 9.55956C48.8333 9.43141 48.7519 9.36353 48.4941 9.18304Z" fill="white"/>
                      <path d="M47.918 9.08594H47.9227L47.9317 9.09063H47.9451L47.9542 9.09532L47.9679 9.09964L47.9813 9.10434L47.9994 9.10903L48.0132 9.11337L48.0313 9.1224L48.0494 9.12672L48.0675 9.13141L48.0903 9.14045L48.1084 9.14514L48.1308 9.15415L48.1489 9.16319L48.1713 9.17222L48.2166 9.19026L48.2618 9.20831L48.3071 9.22635L48.3476 9.2444L48.3701 9.25813L48.3929 9.26716L48.411 9.27617L48.4334 9.28952L48.4515 9.29856L48.4696 9.30759L48.4877 9.32094L48.5011 9.32995C48.7139 9.48337 48.8587 9.65484 48.8359 9.95699C48.8359 9.95699 48.6683 9.71803 48.4783 9.60973C48.3299 9.52815 48.0584 9.29351 47.918 9.08594Z" fill="white"/>
                      <path d="M47.9247 6.89062H47.9294H47.9384L47.9431 6.89532H47.9522H47.9565H47.9656L47.9746 6.90001H47.9837H47.9884L47.9974 6.9047H48.0065L48.0108 6.9094H48.0199L48.0289 6.91409L48.038 6.91878L48.0423 6.92312L48.0513 6.92782L48.0561 6.93251L48.0651 6.9372L48.0694 6.9419L48.0785 6.94659L48.0832 6.95128L48.0875 6.95598L48.0923 6.96499L48.0966 6.96968L48.1013 6.97871L48.1056 6.98303V6.98773V6.99242L48.1104 6.99676V7.00145V7.00615V7.01084L48.1147 7.01553V7.02022V7.02492V7.02961C48.1147 7.13322 47.9518 7.38156 47.9066 7.48986C47.8613 7.38156 47.7031 7.13358 47.7031 7.02961C47.7031 6.92564 47.8251 6.89424 47.8885 6.89424H47.9247V6.89062Z" fill="white"/>
                      <path d="M47.783 7.69976L47.7786 7.68172L47.7739 7.66837V7.65032L47.7696 7.63228L47.7649 7.61421L47.7605 7.59616L47.7558 7.57343V7.55538L47.7515 7.53264L47.7468 7.51026L47.7377 7.46513L47.7243 7.42001L47.7153 7.37019L47.7062 7.32507L47.6972 7.30268L47.6925 7.27995L47.6881 7.2619L47.6791 7.23914L47.6747 7.2211L47.6657 7.20305L47.661 7.18501L47.6519 7.16696L47.6429 7.14889L47.6385 7.13554L47.6295 7.12184L47.6204 7.10811L47.6114 7.09908L47.6067 7.09007C47.5162 7.01317 47.3895 7.00414 47.3309 7.00883C47.2675 7.01352 47.1995 7.07671 47.227 7.16227C47.2545 7.24782 47.5346 7.66765 47.5708 7.7171C47.6338 7.7088 47.6201 7.70879 47.783 7.69976Z" fill="white"/>
                      <path d="M48.0273 7.69976L48.0317 7.68172L48.0364 7.66837V7.65032L48.0407 7.63228L48.0454 7.61421L48.0498 7.59616L48.0545 7.57343L48.0588 7.55538L48.0635 7.53264L48.0679 7.51026L48.0769 7.46513L48.086 7.42001L48.095 7.37019L48.1088 7.32507L48.1131 7.30268L48.1178 7.27995L48.1269 7.2619L48.1312 7.23914L48.1403 7.2211L48.1446 7.20305L48.1537 7.18501L48.1584 7.16696L48.1674 7.14889L48.1718 7.13554L48.1808 7.12184L48.1899 7.10811L48.1989 7.09908L48.2036 7.09007C48.2941 7.01317 48.4161 7.00414 48.4795 7.00883C48.5428 7.01352 48.6062 7.07671 48.5833 7.16227C48.5562 7.24782 48.2757 7.66765 48.2395 7.7171C48.1768 7.7088 48.1859 7.70879 48.0273 7.69976Z" fill="white"/>
                      <path d="M48.2083 6.38778H47.9911V6.14844H47.8148V6.38778H47.5977V6.5412H47.8148V6.8206H47.9911V6.5412H48.2083V6.38778Z" fill="white"/>
                      <path d="M45.5717 7.9336V7.92025V7.90221V7.88414V7.86609L45.576 7.84805V7.83V7.82531V7.81628L45.5804 7.80727V7.79823C45.5941 7.68091 45.5985 7.57732 45.5851 7.49609C45.4265 7.55493 45.318 7.59537 45.1055 7.94264C45.1055 7.94264 45.4494 7.73037 45.5717 7.9336Z" fill="white"/>
                      <path d="M45.8705 8.49104V8.48203L45.8752 8.46831V8.45927V8.45026V8.43653L45.8795 8.42318V8.40983L45.8839 8.39178L45.8886 8.37841V8.36037L45.8933 8.34666L45.8976 8.32859V8.31055L45.902 8.2925L45.9067 8.26977L45.9114 8.25172L45.9204 8.21561L45.9295 8.17482L45.9429 8.13402L45.9519 8.09359L45.9657 8.05281L45.9791 8.01202L45.9928 7.97159L45.9972 7.95352L46.0062 7.93548C46.0967 7.70084 46.0377 7.3579 45.9338 7.15502C45.8343 6.95215 45.7753 6.76226 45.7934 6.55469C45.7934 6.55469 45.5899 6.62255 45.4723 6.69475C45.4542 6.93842 45.5947 7.17776 45.6623 7.31313C45.7394 7.47558 45.7257 7.68316 45.7076 7.81383C45.6804 8.03548 45.7257 8.27012 45.8705 8.49104Z" fill="white"/>
                      <path d="M45.8914 6.64107V6.63675V6.63206V6.62737H45.8867V6.62303V6.61833V6.61364V6.60895V6.60425V6.59956V6.59487V6.59017V6.58548V6.57645V6.56744V6.5584V6.55371L45.8914 6.54902V6.54432V6.53963V6.53531V6.53062V6.52593L45.8958 6.52159V6.51689V6.5122L45.9001 6.50788C45.9001 6.50788 46.2212 6.19671 46.4883 6.1422C46.6603 6.1061 46.7642 6.18298 46.8184 6.22341C46.8184 6.22341 46.7189 6.21438 46.5785 6.3317C46.4337 6.44469 46.2574 6.58909 46.1169 6.62518C45.9866 6.65912 45.9548 6.6501 45.8914 6.64107Z" fill="white"/>
                      <path d="M46.8439 6.25781L46.8664 6.28057L46.8892 6.30331L46.912 6.3257L46.9301 6.34843L46.9525 6.37117L46.9706 6.39391L46.975 6.40294L46.984 6.4163L46.9931 6.42533L47.0021 6.43868L47.0068 6.45239L47.0159 6.46142L47.0206 6.47515L47.0296 6.48416L47.034 6.49319L47.0387 6.5069L47.043 6.51593L47.0477 6.52928L47.0521 6.53831L47.0568 6.55167L47.0611 6.5607V6.5744L47.0658 6.58344V6.59245V6.60618V6.61521C47.0611 6.7282 46.9301 7.01663 46.8577 7.12492C46.7896 7.23322 46.6901 7.37761 46.6991 7.54908C46.7039 7.63933 46.7668 7.72958 46.8396 7.80178C46.8396 7.80178 46.4458 7.7072 46.4009 7.46787C46.3556 7.25127 46.4461 7.09785 46.5095 6.9762C46.5681 6.86321 46.7538 6.60149 46.7943 6.49319C46.8396 6.3932 46.8577 6.31196 46.8439 6.25781Z" fill="white"/>
                      <path d="M49.1426 8.98047L49.1563 9.02991L49.1744 9.08407L49.1925 9.13351L49.2106 9.18333L49.2287 9.23279L49.2515 9.28261L49.274 9.33242L49.2964 9.37755L49.3192 9.42267L49.3417 9.47214L49.3645 9.51726L49.3869 9.55804L49.4141 9.60317L49.4365 9.64395L49.4593 9.68438L49.4865 9.72049C49.6041 9.90532 49.6765 10.2125 49.6674 10.3161C49.6674 10.3161 49.8437 9.92336 49.7532 9.64829C49.6855 9.4317 49.4912 9.11584 49.1426 8.98047Z" fill="white"/>
                      <path d="M49.5801 9.07031H49.5844H49.5891H49.5935H49.5982H49.6072H49.6163L49.6253 9.07501H49.6344H49.6481H49.6615H49.6753L49.6887 9.0797H49.7024H49.7158L49.7339 9.08439H49.7473L49.7654 9.08908H49.7792L49.7973 9.09378L49.8154 9.0981L49.8288 9.10279L49.8469 9.10748H49.8603L49.8783 9.11652L49.8921 9.12121L49.9102 9.12553L49.9236 9.13022L49.9374 9.13925L49.9507 9.14395L49.9641 9.15298L49.9779 9.15767C50.1408 9.26597 50.2946 9.49626 50.3536 9.77603C50.3536 9.77603 50.0774 9.73091 49.9464 9.59554C49.8107 9.46269 49.6478 9.25983 49.5801 9.07031Z" fill="white"/>
                      <path d="M48.1871 9.55859H48.1824H48.178H48.1733H48.1643H48.1599L48.1509 9.56293H48.1418H48.1375L48.1284 9.56763H48.1194H48.1103L48.1013 9.57232L48.0832 9.57664L48.0651 9.58133H48.047L48.0289 9.58602L48.0108 9.59072L47.9927 9.59541L47.9836 9.6001H47.9746L47.9655 9.6048H47.9565L47.9521 9.60949H47.9431H47.934L47.9297 9.61418C47.9297 9.61418 48.1603 9.68168 48.2736 9.77229C48.3684 9.85351 48.4546 9.97987 48.4951 10.1874C48.5541 10.4806 48.7261 10.7964 48.7756 10.8914L48.7985 10.887C48.7623 10.6838 48.8889 10.2687 48.9251 10.2102C48.9251 10.2102 48.7394 10.0929 48.6584 9.97555C48.5943 9.88783 48.49 9.74342 48.1871 9.55859Z" fill="white"/>
                      <path d="M49.3803 10.1875L49.3894 10.2012L49.3941 10.2149L49.4031 10.233L49.4122 10.251L49.4212 10.2647L49.4256 10.2828L49.4346 10.3056L49.4484 10.3236L49.4574 10.3416L49.4665 10.3644L49.4846 10.4052L49.5027 10.446L49.5074 10.4684L49.5164 10.4911L49.5255 10.5182L49.5298 10.5409L49.5389 10.5636L49.5436 10.586L49.5479 10.6131L49.5526 10.6359L49.557 10.6629L49.5617 10.69V10.7124V10.7395V10.7665L49.557 10.7936V10.8163L49.5526 10.8434L49.5479 10.8705L49.5389 10.8976C49.4574 11.1817 49.2764 11.3037 49.1588 11.5427C49.1588 11.5427 49.0683 11.1095 49.0864 10.7756C49.1088 10.4359 49.2308 10.3095 49.3803 10.1875Z" fill="white"/>
                      <path d="M49.9007 10.1953V10.2L49.9051 10.2047V10.2094L49.9098 10.2141V10.2184L49.9141 10.2231L49.9188 10.2274V10.2364L49.9232 10.2455L49.9322 10.2588L49.9413 10.2769L49.9503 10.2996L49.9637 10.3224L49.9728 10.3448L49.9865 10.3718L49.9999 10.3946L50.0137 10.4216L50.0227 10.4531L50.0361 10.4801L50.0499 10.5072L50.068 10.539L50.0951 10.6021L50.1179 10.661L50.1313 10.6924L50.1451 10.7195L50.1585 10.7465L50.1675 10.7783L50.1766 10.8054L50.1856 10.8281L50.1947 10.8552L50.2037 10.8779L50.2081 10.9007L50.2171 10.9187C50.2624 11.122 50.2171 11.2526 50.1266 11.3743C50.0361 11.4963 49.8823 11.6317 49.7375 11.8844C49.7375 11.8844 49.5746 11.6678 49.5521 11.5551C49.534 11.4378 49.5655 11.1671 49.6017 11.0541C49.6379 10.9411 49.7787 10.57 49.9007 10.1953Z" fill="white"/>
                      <path d="M50.0371 11.8516L50.0328 11.8563H50.0281V11.8606L50.0238 11.8653L50.019 11.87L50.0147 11.879L50.0009 11.888L49.9919 11.9014L49.9785 11.9151L49.9647 11.9332L49.9514 11.9469L49.938 11.9649L49.9242 11.983L49.9061 12.0057L49.888 12.0238L49.8746 12.0461L49.8565 12.0642L49.8203 12.1093L49.7888 12.1498L49.7707 12.1725L49.757 12.1952L49.7389 12.218L49.7255 12.236L49.7121 12.2584L49.6983 12.2764L49.6849 12.2945L49.6712 12.3126L49.6578 12.3306L49.6487 12.3487L49.6397 12.362L49.635 12.3754L49.6306 12.3844L49.6259 12.3891V12.3938L49.6216 12.3985C49.5811 12.5115 49.5083 12.7143 49.6035 12.8587C49.7074 13.0031 49.9246 13.2334 50.1193 13.3327C50.1193 13.3327 49.9112 13.0035 49.9293 12.7324C49.9427 12.5566 50.0784 12.4032 50.1056 12.2992C50.1276 12.1945 50.1095 12.014 50.0371 11.8516Z" fill="white"/>
                      <path d="M49.4214 11.6445L49.4257 11.6492L49.4304 11.6582L49.4348 11.6629L49.4395 11.672L49.4485 11.681L49.4529 11.69L49.4576 11.699L49.4619 11.7081L49.4666 11.7218L49.4757 11.7308L49.48 11.7445L49.4847 11.7536L49.4891 11.7673L49.4934 11.7763L49.5072 11.8034L49.5162 11.8304L49.5253 11.8575L49.5343 11.8846L49.5434 11.9117L49.5524 11.9387V11.9568L49.5568 11.9705L49.5615 11.9839V11.9976V12.0156L49.5658 12.0293C49.5749 12.1828 49.5524 12.3091 49.4663 12.5391C49.3624 12.8232 49.1948 13.1437 49.1177 13.2881C49.1177 13.2881 48.9682 13.0762 48.8867 12.891C48.8867 12.891 49.1177 12.5752 49.2125 12.3542C49.3309 12.0911 49.3761 11.8474 49.4214 11.6445Z" fill="white"/>
                      <path d="M49.3183 11.5195V11.5239L49.3136 11.5286V11.5423V11.556L49.3093 11.574L49.3046 11.5921L49.3002 11.6192L49.2955 11.6462L49.2865 11.678L49.2821 11.7094L49.2731 11.7455L49.264 11.7863L49.2506 11.8267L49.2416 11.8675L49.2278 11.9126L49.2144 11.9621L49.1963 12.0072L49.1826 12.0567L49.1645 12.1108L49.142 12.1603L49.1239 12.2144L49.1015 12.2686L49.0787 12.3227L49.0516 12.3769L49.0244 12.431L48.9925 12.4852L48.9654 12.5393L48.9292 12.5978L48.8973 12.652L48.8611 12.7014L48.825 12.7556L48.7844 12.8097C48.4905 13.1797 48.2505 13.3108 48.0923 13.3108C47.8523 13.3108 47.6127 13.1075 47.5312 12.8819C47.4498 12.6563 47.4769 12.3 47.6308 12.178C47.6308 12.178 47.7756 12.6202 48.0785 12.6382C48.4268 12.6567 48.8702 12.2686 49.3183 11.5195Z" fill="white"/>
                      <path d="M48.9602 11.2109L48.9645 11.2246V11.238L48.9692 11.2517V11.2651L48.9783 11.2922L48.9826 11.3239V11.3553L48.9873 11.3871L48.9916 11.4189V11.455V11.4867V11.5228L48.9873 11.5589L48.9826 11.595V11.6311L48.9735 11.6672L48.9692 11.7033L48.9602 11.7394C48.924 11.8838 48.8335 12.0733 48.6662 12.1816C48.5167 12.2719 48.1729 12.2946 48.0371 11.965C48.0371 11.9643 48.6706 12.0141 48.9602 11.2109Z" fill="white"/>
                      <path d="M49.9967 14.1875L50.0057 14.2055L50.0148 14.2283L50.0282 14.251L50.0372 14.2734L50.051 14.2961L50.0644 14.3189L50.0825 14.3416L50.0959 14.3644L50.1277 14.4095L50.1592 14.4546L50.1954 14.4998L50.2269 14.5449L50.2587 14.59L50.2768 14.608L50.2902 14.6304L50.3036 14.6532L50.3174 14.6759L50.3308 14.694L50.3445 14.7163L50.3536 14.7344L50.3626 14.7571L50.3717 14.7752L50.3807 14.7932L50.3854 14.8113L50.3901 14.8293V14.8474L50.3945 14.8611C50.3854 15.3214 50.1816 15.6011 50.1368 15.7546C50.1368 15.7546 49.7701 15.4614 49.7067 15.1275C49.6709 14.9546 49.7071 14.4402 49.9967 14.1875Z" fill="white"/>
                      <path d="M49.5859 14.5114L49.5906 14.498L49.595 14.4843L49.5997 14.471L49.6087 14.4572L49.6134 14.4392L49.6225 14.4258L49.6268 14.4078L49.6359 14.3897L49.6449 14.3717L49.654 14.3493L49.663 14.3313L49.6721 14.3132L49.6902 14.2724L49.713 14.232L49.7354 14.1869L49.7578 14.1461L49.7716 14.128L49.7807 14.11L49.794 14.0919L49.8078 14.0739L49.8212 14.0558L49.8346 14.0378L49.8483 14.0197L49.8617 14.006L49.8755 13.9923L49.8889 13.9789L49.9026 13.9652L49.916 13.9515C49.916 13.9515 50.1198 13.3649 49.4773 13.0039C49.4773 13.0039 49.2102 13.3515 49.1425 13.6855C49.0701 14.015 49.423 14.2136 49.5859 14.5114Z" fill="white"/>
                      <path d="M48.7796 13.0234L48.7568 13.0505L48.7344 13.0733L48.7163 13.1003L48.6939 13.1274L48.6711 13.1498L48.6486 13.1769L48.6215 13.2039L48.5987 13.2267L48.5762 13.2494L48.5538 13.2765L48.54 13.2855L48.5266 13.2946L48.5176 13.3083L48.5038 13.3173L48.4948 13.3263L48.4814 13.3353L48.4723 13.3444L48.4589 13.3491L48.4499 13.3581L48.4361 13.3671L48.4271 13.3715L48.4137 13.3761C48.4137 13.3761 48.3728 13.6379 48.3999 13.8137C48.4318 14.071 48.599 14.3371 48.6714 14.5356C48.6714 14.5356 48.9519 14.1656 48.9834 13.949C49.0153 13.7324 49.0106 13.5566 49.0196 13.4527C49.0196 13.4519 48.8658 13.1992 48.7796 13.0234Z" fill="white"/>
                      <path d="M48.0317 14.289L48.0227 14.2757L48.0136 14.262L48.0046 14.2439L47.9955 14.2302L47.9818 14.2075L47.9727 14.1894L47.9593 14.1667L47.9456 14.1443L47.9322 14.1172L47.9184 14.0945L47.905 14.0674L47.8916 14.0356L47.8779 14.0086L47.8645 13.9768L47.8464 13.9497L47.8326 13.9183L47.8055 13.8552L47.7784 13.7876L47.7555 13.7198L47.7465 13.6884L47.7331 13.6566L47.7241 13.6205L47.715 13.5887L47.7103 13.5573L47.7013 13.5212L47.6965 13.4895L47.6918 13.4581V13.4263L47.6875 13.3945C47.6875 13.3945 48.0086 13.566 48.2757 13.4397C48.2757 13.4397 48.3076 13.8638 48.2576 14.0039C48.2171 14.1219 48.1628 14.1898 48.0317 14.289Z" fill="white"/>
                      <path d="M48.1055 14.3758L48.1145 14.3711L48.1192 14.3668L48.1236 14.3578L48.1326 14.3531L48.1373 14.3484L48.1464 14.3437L48.1511 14.3347L48.1601 14.33L48.1692 14.321L48.1735 14.3163L48.1916 14.2982L48.2054 14.2845L48.2235 14.2665L48.2416 14.2527L48.255 14.2347L48.2731 14.221L48.2821 14.212L48.2865 14.2076L48.2955 14.1986L48.3046 14.1896L48.3093 14.1849L48.3136 14.1759L48.3227 14.1712L48.3274 14.1621L48.3317 14.1574L48.3364 14.1484C48.3364 14.1484 48.5489 14.5365 48.5851 14.6495C48.5851 14.6495 48.4812 14.6722 48.3636 14.591C48.2503 14.5112 48.2184 14.4885 48.1055 14.3758Z" fill="white"/>
                      <path d="M49.0879 14.1484L49.0835 14.1531V14.1578L49.0788 14.1669V14.1716L49.0745 14.1806L49.0698 14.1853L49.0654 14.1943L49.0607 14.2033L49.0517 14.2214L49.0426 14.2394L49.0289 14.2618L49.0155 14.2798L49.0017 14.3026L48.9883 14.3297L48.9702 14.3524L48.9565 14.3795L48.9384 14.4022L48.9203 14.4293L48.8841 14.4791L48.8479 14.5333L48.8298 14.556L48.8117 14.5831L48.7983 14.6055L48.7802 14.6282L48.7621 14.6509L48.7483 14.6737L48.7349 14.6917L48.7168 14.7098L48.7078 14.7278L48.6944 14.7412L48.6897 14.7459L48.6854 14.7549L48.6806 14.7596L48.6763 14.7643L48.6716 14.769L48.6673 14.7737L48.6625 14.7784L48.6582 14.7827C48.6582 14.7827 48.6854 14.9542 48.812 15.1978C48.9387 15.4462 49.115 15.6177 49.2102 15.7032C49.2102 15.7032 49.4184 15.3376 49.4817 15.1798C49.5541 14.995 49.5451 14.8098 49.5132 14.7419C49.4408 14.5816 49.1965 14.3517 49.0879 14.1484Z" fill="white"/>
                      <path d="M49.5974 15.3828L49.6018 15.4009L49.6108 15.4142L49.6199 15.4323L49.6289 15.446L49.6423 15.464L49.6514 15.4821L49.6651 15.4958L49.6742 15.5138L49.6876 15.5319L49.7013 15.5546L49.7285 15.5907L49.7513 15.6312L49.7784 15.6673L49.8056 15.7077L49.8327 15.7481L49.8555 15.7886L49.8646 15.8113L49.878 15.8294L49.887 15.8517L49.8961 15.8698L49.9051 15.8878L49.9142 15.9106L49.9189 15.9286L49.9232 15.951L49.9323 15.9691V15.9871C49.9594 16.1405 49.937 16.2669 49.8961 16.3752C49.8508 16.4788 49.7151 16.6864 49.6246 16.8445C49.6246 16.8445 49.3126 16.5513 49.2445 16.3438C49.1949 16.1857 49.213 15.9647 49.2898 15.8203C49.3622 15.6803 49.5251 15.5315 49.5974 15.3828Z" fill="white"/>
                      <path d="M48.7152 15.3438L48.7243 15.3618L48.7333 15.3798L48.7424 15.3932L48.7514 15.4112L48.7605 15.4293L48.7695 15.443L48.792 15.4744L48.8148 15.5105L48.8372 15.5423L48.8644 15.5741L48.8868 15.6055L48.9096 15.6369L48.932 15.6686L48.9545 15.7L48.9635 15.7134L48.9726 15.7314L48.9816 15.7452L48.9907 15.7632L48.9997 15.7766L49.0088 15.7899L49.0135 15.808L49.0225 15.8217L49.0269 15.8354L49.0316 15.8535C49.0812 16.0296 49.0993 16.1466 49.0135 16.3588C48.932 16.5437 48.8416 16.652 48.8097 16.7649C48.8097 16.7649 48.5563 16.4401 48.5111 16.3047C48.4705 16.1646 48.4568 15.9437 48.5067 15.804C48.5614 15.6596 48.6519 15.4744 48.7152 15.3438Z" fill="white"/>
                      <path d="M49.9621 16.5898V16.5942H49.9574V16.5985L49.953 16.6032L49.9483 16.6079L49.944 16.6122L49.9396 16.6166L49.9349 16.6256L49.9306 16.6303L49.9215 16.6393L49.9168 16.6483L49.9078 16.6574L49.8944 16.6754L49.8806 16.6982L49.8625 16.7209L49.8444 16.7433L49.8263 16.7703L49.8082 16.7974L49.7854 16.8245L49.7673 16.8563L49.7449 16.8877L49.7268 16.9191L49.7043 16.9508L49.6862 16.9823L49.6681 17.0183L49.65 17.0498L49.6319 17.0815L49.6138 17.1176L49.6005 17.149L49.5867 17.1804L49.5733 17.2165L49.5643 17.2483L49.5505 17.2797L49.5462 17.3068L49.5415 17.3382L49.5371 17.3515V17.3653V17.379V17.3923C49.5505 17.74 49.7814 17.9656 49.8129 18.1822C49.8129 18.1822 50.0439 17.9251 50.1297 17.7537C50.2245 17.5551 50.3016 17.3927 50.2745 17.1718C50.247 16.9508 50.1883 16.8108 49.9621 16.5898Z" fill="white"/>
                      <path d="M49.1603 16.5352L49.1647 16.5442L49.1737 16.5579L49.1828 16.5716L49.1918 16.585L49.2009 16.5983L49.2099 16.6164L49.219 16.6344L49.228 16.6525L49.2418 16.6705L49.2508 16.6886L49.2599 16.711L49.2733 16.729L49.2823 16.7514L49.2914 16.7741L49.3095 16.8193L49.3185 16.8463L49.3276 16.8691L49.3366 16.8918L49.3457 16.9142L49.35 16.9413L49.3591 16.9637L49.3638 16.9864L49.3681 17.0088L49.3728 17.0358L49.3771 17.0586V17.081V17.1033V17.1214V17.1438L49.3728 17.1665L49.3681 17.1846C49.3138 17.3878 49.0966 17.6719 49.0242 17.7983C49.0242 17.7983 48.8523 17.4463 48.8161 17.2838C48.7803 17.1218 48.8121 16.86 49.1603 16.5352Z" fill="white"/>
                      <path d="M53.5115 7.88025V7.87556L53.5068 7.87086V7.86654L53.5024 7.86185L53.4977 7.85751L53.4934 7.85282H53.4843L53.4753 7.84812L53.4662 7.84343L53.4572 7.83874H53.4481L53.4344 7.83404L53.4253 7.82935L53.4119 7.82466H53.3986L53.3805 7.81997H53.3667L53.3486 7.81565L53.3352 7.81095H53.3171L53.299 7.80626H53.2809H53.2628L53.2404 7.80157H53.2223H53.1995L53.1814 7.79688H53.1586H53.1361H53.1133H53.0909H53.0684C52.8241 7.79688 52.625 7.83297 52.625 7.87809C52.625 7.92321 52.8241 7.95463 53.0684 7.95463C53.3124 7.9568 53.5115 7.92537 53.5115 7.88025Z" fill="white"/>
                      <path d="M50.4902 6.52382L50.4945 6.50577L50.4992 6.48301L50.5039 6.46028L50.5083 6.43754L50.5173 6.41515L50.5264 6.39242L50.5354 6.36534L50.5445 6.33826L50.5535 6.3112L50.5625 6.28412L50.585 6.22997L50.6074 6.17149L50.6302 6.11264L50.6527 6.0585L50.6755 5.99965L50.6845 5.97258L50.6979 5.94552L50.707 5.91844L50.7117 5.89136L50.7207 5.86862L50.7298 5.84155L50.7341 5.81881L50.7385 5.79607L50.7432 5.77331L50.7475 5.75095V5.73288V5.71483C50.7475 5.57044 50.6299 5.45312 50.4851 5.45312C50.3403 5.45312 50.2227 5.57044 50.2227 5.71483C50.2227 5.89966 50.4532 6.32382 50.4804 6.52237H50.4902V6.52382Z" fill="white"/>
                      <path d="M51.2234 6.94491L51.2459 6.89075L51.2687 6.84131L51.2868 6.79619L51.3096 6.74672L51.3277 6.7016L51.3458 6.65216L51.3639 6.60703L51.382 6.56623L51.4001 6.5211L51.4138 6.48032L51.4319 6.43951L51.4453 6.39908L51.4634 6.3583L51.4772 6.32221L51.4906 6.2861L51.5043 6.25001L51.5134 6.2139L51.5271 6.1825L51.5362 6.15073L51.5452 6.11895L51.5543 6.0919L51.5633 6.06482L51.5724 6.03774L51.5767 6.01066L51.5858 5.98792L51.5905 5.96554L51.5948 5.9428V5.92476L51.5995 5.90671V5.88864V5.87494V5.86159C51.5995 5.78036 51.5 5.72152 51.4138 5.69444C51.3143 5.66737 51.2372 5.65833 51.1738 5.70346C51.0562 5.79803 50.9476 6.52904 50.9023 6.83624C51.02 6.85898 51.0924 6.87704 51.2234 6.94491Z" fill="white"/>
                      <path d="M50.8052 6.82631L50.7914 6.82161H50.7824H50.769H50.7599L50.7371 6.81692H50.7147H50.6923H50.6694L50.647 6.81223H50.6289C50.6651 6.5191 50.7647 6.0361 50.8237 5.84224C50.887 5.64803 50.9594 5.51302 51.0861 5.47656C51.0861 5.47656 50.9913 5.74297 50.937 6.01805C50.8823 6.2942 50.8414 6.56459 50.8052 6.82631Z" fill="white"/>
                      <path d="M51.0195 7.47266H51.0242V7.47735H51.0286V7.48169H51.0333L51.0376 7.49072L51.0467 7.49973L51.0557 7.50443L51.0648 7.51346L51.0782 7.52681L51.0872 7.53585L51.101 7.5492L51.1144 7.56255L51.1325 7.57158L51.1462 7.58529L51.1643 7.59864L51.1824 7.61237L51.2005 7.63041L51.2186 7.64376L51.2414 7.65749L51.2595 7.67084L51.282 7.68457L51.3001 7.69827L51.3225 7.71165L51.3453 7.72535L51.3678 7.73908L51.3906 7.74809L51.413 7.76182L51.4354 7.77085L51.4626 7.77986L51.4854 7.7889L51.5078 7.79791L51.5303 7.8026C51.5307 7.80188 51.4268 7.53114 51.0195 7.47266Z" fill="white"/>
                      <path d="M50.8105 7.34844H50.8243H50.8377L50.8558 7.34375H50.8739H50.892H50.9101L50.9282 7.34844H50.9506H50.9731L50.9959 7.35314H51.0183L51.0411 7.35783L51.0636 7.36252H51.0907L51.1132 7.36722L51.1403 7.37156L51.1631 7.38057L51.1903 7.38526L51.2174 7.38995L51.2402 7.39899L51.2674 7.408L51.2945 7.41269L51.3173 7.42172L51.3445 7.43076L51.3716 7.43977L51.3941 7.45312L51.4165 7.46216L51.4436 7.47588L51.4665 7.48923L51.4889 7.49825L51.507 7.51631L51.5294 7.52966C51.7513 7.68308 51.8237 7.81376 51.9005 7.95382C51.9005 7.95382 51.5023 7.98524 51.1541 7.78236C50.9868 7.68237 50.842 7.45674 50.8105 7.34844Z" fill="white"/>
                      <path d="M51.2338 7.96875C51.2338 7.96875 51.279 8.13336 51.4824 8.2283C51.4824 8.2283 51.3717 8.23516 51.2674 8.32974C51.2674 8.32974 51.3351 8.4604 51.5161 8.5326C51.5161 8.5326 51.1834 8.8712 50.8262 8.84846C50.8262 8.84846 50.9912 9.06072 51.3579 8.98383C51.7246 8.90261 51.8332 8.69937 51.9009 8.62284C51.9009 8.62284 51.8556 8.8167 51.8781 8.89359C51.8781 8.89359 52.0341 8.8824 52.1608 8.72428C52.1608 8.72428 52.0659 8.88204 52.0475 9.01741C52.0475 9.01741 52.1492 9.01958 52.3302 8.91597C52.3302 8.91597 52.2897 9.01308 52.2849 9.1304C52.2849 9.1304 52.7103 8.99286 52.9184 8.80334V8.81453V8.82572V8.83692V8.84811V8.8593V8.8705V8.88169V8.89288V8.90407V8.91527V8.92644V8.9492L52.9072 8.96039V8.97156V8.98275V8.99395V9.00514C52.9072 9.00514 53.5587 8.9463 53.6083 7.98968C53.6083 7.98968 53.1808 8.14743 52.7374 8.12505C52.3038 8.10411 51.4915 8.0904 51.2338 7.96875ZM52.4667 8.32974C52.8105 8.37486 53.2246 8.35248 53.2246 8.35248V8.37486H53.2134V8.3976L53.2022 8.42034V8.44308L53.191 8.45427V8.46546V8.47666L53.1798 8.48785V8.49904V8.51023H53.1685V8.52143L53.1573 8.5326V8.54379L53.1461 8.55498L53.1349 8.56618V8.57737H53.1236L53.1124 8.58856V8.59975H53.1001L53.0889 8.61095V8.62214H53.0777L53.0665 8.63333L53.0552 8.64452C53.0328 8.36476 52.467 8.36259 52.467 8.36259V8.32974H52.4667Z" fill="white"/>
                      <path d="M53.7201 8.05251L53.7154 8.09329L53.7111 8.1294L53.7064 8.17019L53.7021 8.20628L53.693 8.24671L53.6883 8.28282L53.6792 8.31891L53.6702 8.35502L53.6612 8.39111L53.6521 8.4272L53.6387 8.45862L53.6297 8.49039L53.6163 8.52214L53.6072 8.55391L53.5935 8.58099L53.5801 8.60807C53.5801 8.60807 53.8378 8.41855 53.9011 8.25177C53.9645 8.08428 53.9782 7.97562 53.7201 8.05251Z" fill="white"/>
                      <path d="M54.2669 8.23828L54.2441 8.24947H54.2329H54.2217L54.1989 8.26064H54.1877H54.1764C54.0816 8.54944 53.758 8.64438 53.5994 8.71188C53.4318 8.7931 53.2715 8.98262 53.2715 8.98262C53.5339 9.04146 53.6563 9.10682 53.6563 9.10682C54.1583 8.75955 54.5724 9.11799 54.5724 9.11799C54.8439 8.95988 54.9004 8.58987 54.7646 8.39602L54.7422 8.37328L54.731 8.35089L54.7198 8.33972L54.697 8.32853L54.6857 8.31734L54.6633 8.30614L54.6521 8.29495L54.6296 8.28376L54.6072 8.27256L54.596 8.26137H54.5735L54.5511 8.25018H54.5283L54.5171 8.23899H54.4946H54.4718H54.4494H54.4266H54.4041H54.3817H54.3686H54.3462H54.3238H54.3125H54.2901H54.2669V8.23828ZM54.2329 8.62163H54.2441H54.2553H54.2666V8.63283H54.2778H54.289V8.64402H54.3002L54.3115 8.65521V8.6664H54.3227V8.6776H54.3339V8.68879V8.69998V8.71118V8.72237L54.3451 8.73356C54.3451 8.7924 54.2908 8.84655 54.2318 8.84655C54.1732 8.84655 54.1301 8.7924 54.1301 8.73356C54.1312 8.6758 54.1743 8.62163 54.2329 8.62163Z" fill="white"/>
                      <path d="M53.2226 9.04688H53.2179V9.05157H53.2136V9.05589L53.2089 9.06058L53.2045 9.06961L53.1955 9.07431L53.1911 9.08334L53.1821 9.09704L53.173 9.10608L53.1683 9.11509L53.1549 9.12846L53.1459 9.14217L53.1368 9.1512L53.1231 9.16491L53.114 9.17828L53.1006 9.19632L53.0916 9.20968L53.0644 9.23676L53.0373 9.26383L53.0148 9.29558L53.0011 9.30931L52.9877 9.32266L52.9739 9.33639L52.9605 9.34974L52.9472 9.35877L52.9334 9.37248L52.92 9.38151L52.9062 9.39486C52.9062 9.39486 53.5806 9.38583 53.7526 9.97244C53.7526 9.97244 53.9835 9.61145 53.8159 9.38114C53.6346 9.13242 53.4626 9.09669 53.2226 9.04688Z" fill="white"/>
                      <path d="M52.4813 9.18304H52.4769V9.18773H52.4722H52.4679H52.4632V9.19242H52.4588H52.4541H52.4451L52.4407 9.19712L52.4317 9.20181H52.4179L52.4089 9.20615L52.3998 9.21084H52.3861L52.377 9.21554L52.3636 9.22023L52.3408 9.22457L52.3137 9.23358L52.2912 9.23792L52.2778 9.24262L52.2688 9.24731L52.255 9.252H52.246L52.2326 9.25669L52.2235 9.26139H52.2145L52.2054 9.26608H52.1964L52.1917 9.27077C52.1917 9.27077 52.1873 9.14442 52.1964 9.08594C52.1287 9.09929 52.0469 9.12204 51.9745 9.13106C51.9745 9.13106 51.762 9.60937 51.5901 9.80323C51.5901 9.80323 51.581 9.91622 51.6759 10.074C51.6759 10.074 51.7982 9.79421 52.0197 9.55956C52.1421 9.43141 52.2235 9.36353 52.4813 9.18304Z" fill="white"/>
                      <path d="M53.0555 9.08594H53.0464L53.0374 9.09063H53.0283L53.0149 9.09532L53.0012 9.09964L52.9878 9.10434L52.974 9.10903L52.9559 9.11337L52.9378 9.1224L52.9197 9.12672L52.9016 9.13141L52.8835 9.14045L52.8607 9.14514L52.8426 9.15415L52.8198 9.16319L52.7974 9.17222L52.7521 9.19026L52.7069 9.20831L52.6664 9.22635L52.6211 9.2444L52.5987 9.25813L52.5806 9.26716L52.5578 9.27617L52.5397 9.28952L52.5216 9.29856L52.5035 9.30759L52.4854 9.32094L52.4716 9.32995C52.2591 9.48337 52.114 9.65484 52.1324 9.95699C52.1324 9.95699 52.3044 9.71803 52.4944 9.60973C52.6436 9.52815 52.915 9.29351 53.0555 9.08594Z" fill="white"/>
                      <path d="M53.0514 6.89062H53.0467H53.0376L53.0333 6.89532H53.0242H53.0195H53.0105L53.0014 6.90001H52.9924H52.988L52.979 6.9047H52.9699L52.9656 6.9094H52.9565L52.9475 6.91409L52.9384 6.91878L52.9337 6.92312L52.9247 6.92782L52.9203 6.93251L52.9113 6.9372L52.9066 6.9419L52.8975 6.94659L52.8932 6.95128L52.8885 6.95598L52.8841 6.96499L52.8794 6.96968L52.8751 6.97871L52.8704 6.98303V6.98773V6.99242L52.866 6.99676V7.00145V7.00615V7.01084L52.8613 7.01553V7.02022V7.02492V7.02961C52.8613 7.13322 53.0242 7.38156 53.0695 7.48986C53.1147 7.38156 53.2729 7.13358 53.2729 7.02961C53.2729 6.92564 53.1505 6.89424 53.0829 6.89424H53.0514V6.89062Z" fill="white"/>
                      <path d="M53.1914 7.69976L53.1958 7.68172V7.66837L53.2001 7.65032L53.2048 7.63228V7.61421L53.2091 7.59616L53.2139 7.57343L53.2182 7.55538L53.2229 7.53264L53.2272 7.51026L53.2363 7.46513L53.2453 7.42001L53.2544 7.37019L53.2681 7.32507L53.2725 7.30268L53.2815 7.27995L53.2862 7.2619L53.2906 7.23914L53.2996 7.2211L53.3043 7.20305L53.3134 7.18501L53.3224 7.16696L53.3271 7.14889L53.3362 7.13554L53.3452 7.12184L53.3496 7.10811L53.3586 7.09908L53.3677 7.09007C53.4535 7.01317 53.5805 7.00414 53.6439 7.00883C53.7072 7.01352 53.7706 7.07671 53.7478 7.16227C53.7206 7.24782 53.4401 7.66765 53.4039 7.7171C53.3405 7.7088 53.3496 7.70879 53.1914 7.69976Z" fill="white"/>
                      <path d="M52.9406 7.69976V7.68172L52.9363 7.66837L52.9316 7.65032L52.9272 7.63228V7.61421L52.9225 7.59616L52.9182 7.57343L52.9135 7.55538L52.9091 7.53264L52.9044 7.51026L52.8954 7.46513L52.8863 7.42001L52.8729 7.37019L52.8639 7.32507L52.8548 7.30268L52.8505 7.27995L52.8458 7.2619L52.8367 7.23914L52.8324 7.2211L52.8233 7.20305L52.8186 7.18501L52.8096 7.16696L52.8005 7.14889L52.7962 7.13554L52.7871 7.12184L52.7781 7.10811L52.7734 7.09908L52.7643 7.09007C52.6786 7.01317 52.5562 7.00414 52.4885 7.00883C52.4299 7.01352 52.3618 7.07671 52.389 7.16227C52.4114 7.24782 52.6919 7.66765 52.7328 7.7171C52.7958 7.7088 52.7824 7.70879 52.9406 7.69976Z" fill="white"/>
                      <path d="M52.7656 6.38778H52.9781V6.14844H53.1591V6.38778H53.3763V6.5412H53.1591V6.8206H52.9781V6.5412H52.7656V6.38778Z" fill="white"/>
                      <path d="M50.1465 4.96555H50.3684V4.72656H50.5403V4.96555H50.7619V5.11897H50.5403V5.39872H50.3684V5.11897H50.1465V4.96555Z" fill="white"/>
                      <path d="M55.4029 7.9336V7.92025V7.90221L55.3985 7.88414V7.86609V7.84805V7.83902V7.83L55.3938 7.82531V7.81628V7.80727V7.79823C55.3757 7.68091 55.3714 7.57732 55.3848 7.49609C55.5433 7.55493 55.6519 7.59537 55.8644 7.94264C55.8644 7.94264 55.5205 7.73037 55.4029 7.9336Z" fill="white"/>
                      <path d="M55.105 8.49104L55.1006 8.48203V8.46831V8.45927V8.45026L55.0963 8.43653V8.42318L55.0916 8.40983V8.39178L55.0872 8.37841V8.36037L55.0825 8.34666L55.0782 8.32859L55.0735 8.31055L55.0691 8.2925L55.0644 8.26977L55.0601 8.25172L55.051 8.21561L55.042 8.17482L55.0329 8.13402L55.0192 8.09359L55.0101 8.05281L54.9967 8.01202L54.9833 7.97159L54.9786 7.95352L54.9696 7.93548C54.8838 7.70084 54.9334 7.3579 55.0373 7.15502C55.1412 6.95215 55.2002 6.76226 55.1777 6.55469C55.1777 6.55469 55.3859 6.62255 55.4988 6.69475C55.5169 6.93842 55.3765 7.17776 55.3135 7.31313C55.232 7.47558 55.2454 7.68316 55.2682 7.81383C55.295 8.03548 55.2498 8.27012 55.105 8.49104Z" fill="white"/>
                      <path d="M55.0813 6.64107V6.63675V6.63206V6.62737V6.62303V6.61833V6.61364V6.60895V6.60425V6.59956V6.59487V6.59017V6.58548V6.57645V6.56744V6.5584V6.55371V6.54902L55.0769 6.54432V6.53963V6.53531V6.53062V6.52593V6.52159L55.0722 6.51689V6.5122V6.50788C55.0722 6.50788 54.7464 6.19671 54.4793 6.1422C54.3117 6.1061 54.2035 6.18298 54.1582 6.22341C54.1582 6.22341 54.2487 6.21438 54.3935 6.3317C54.5383 6.44469 54.7146 6.58909 54.8507 6.62518C54.9861 6.65912 55.0132 6.6501 55.0813 6.64107Z" fill="white"/>
                      <path d="M54.1254 6.25781L54.1026 6.28057L54.0801 6.30331L54.0621 6.3257L54.0396 6.34843L54.0215 6.37117L54.0034 6.39391L53.9944 6.40294L53.9853 6.4163L53.9763 6.42533L53.9716 6.43868L53.9625 6.45239L53.9535 6.46142L53.9487 6.47515L53.9444 6.48416L53.9354 6.49319L53.9306 6.5069L53.9263 6.51593L53.9216 6.52928L53.9173 6.53831V6.55167L53.9125 6.5607L53.9082 6.5744V6.58344V6.59245V6.60618V6.61521C53.9082 6.7282 54.044 7.01663 54.1116 7.12492C54.1797 7.23322 54.2883 7.37761 54.2745 7.54908C54.2655 7.63933 54.2068 7.72958 54.1341 7.80178C54.1341 7.80178 54.5279 7.7072 54.5728 7.46787C54.6181 7.25127 54.5232 7.09785 54.4595 6.9762C54.4009 6.86321 54.2199 6.60149 54.1746 6.49319C54.1344 6.3932 54.112 6.31196 54.1254 6.25781Z" fill="white"/>
                      <path d="M51.8337 8.98047L51.8156 9.02991L51.7975 9.08407L51.7837 9.13351L51.7613 9.18333L51.7432 9.23279L51.7251 9.28261L51.7027 9.33242L51.6799 9.37755L51.6574 9.42267L51.6346 9.47214L51.6122 9.51726L51.585 9.55804L51.5626 9.60317L51.5398 9.64395L51.5126 9.68438L51.4902 9.72049C51.3678 9.90532 51.2911 10.2125 51.3092 10.3161C51.3092 10.3161 51.1282 9.92336 51.2187 9.64829C51.2907 9.4317 51.4808 9.11584 51.8337 8.98047Z" fill="white"/>
                      <path d="M51.39 9.07031H51.3852H51.3809H51.3762H51.3719H51.3671H51.3581L51.349 9.07501H51.3357H51.3266H51.3128H51.2995L51.2857 9.0797H51.2723H51.2542L51.2408 9.08439H51.2227L51.209 9.08908H51.1909L51.1775 9.09378L51.1594 9.0981L51.1413 9.10279L51.1279 9.10748H51.1098L51.0917 9.11652L51.0779 9.12121L51.0645 9.12553L51.0464 9.13022L51.033 9.13925L51.0193 9.14395L51.0059 9.15298L50.9921 9.15767C50.8336 9.26597 50.6754 9.49626 50.6211 9.77603C50.6211 9.77603 50.8926 9.73091 51.0283 9.59554C51.159 9.46269 51.3266 9.25983 51.39 9.07031Z" fill="white"/>
                      <path d="M52.7885 9.55859H52.7928H52.8019H52.8066H52.8109H52.82L52.8247 9.56293H52.8337H52.8381L52.8471 9.56763H52.8562H52.8652L52.8743 9.57232H52.8833L52.8924 9.57664L52.9105 9.58133H52.9286L52.9467 9.58602L52.9648 9.59072L52.9829 9.59541L52.9919 9.6001H53.001L53.01 9.6048H53.0191L53.0238 9.60949H53.0328H53.0372L53.0462 9.61418C53.0462 9.61418 52.8156 9.68168 52.6976 9.77229C52.6028 9.85351 52.5166 9.97987 52.4805 10.1874C52.4218 10.4806 52.2499 10.7964 52.1956 10.8914L52.1775 10.887C52.209 10.6838 52.0826 10.2687 52.0508 10.2102C52.0508 10.2102 52.2318 10.0929 52.3176 9.97555C52.3769 9.88783 52.4855 9.74342 52.7885 9.55859Z" fill="white"/>
                      <path d="M51.5935 10.1875L51.5845 10.2012L51.5801 10.2149L51.5711 10.233L51.562 10.251L51.553 10.2647L51.5439 10.2828L51.5349 10.3056L51.5258 10.3236L51.5168 10.3416L51.5077 10.3644L51.4896 10.4052L51.4715 10.446L51.4625 10.4684L51.4534 10.4911L51.4444 10.5182L51.4401 10.5409L51.431 10.5636L51.4263 10.586L51.422 10.6131L51.4173 10.6359L51.4129 10.6629L51.4082 10.69V10.7124V10.7395V10.7665V10.7936V10.8163L51.4129 10.8434L51.422 10.8705L51.4263 10.8976C51.5121 11.1817 51.6978 11.3037 51.8154 11.5427C51.8154 11.5427 51.9059 11.1095 51.8831 10.7756C51.865 10.4359 51.743 10.3095 51.5935 10.1875Z" fill="white"/>
                      <path d="M50.4896 10.9724V10.9634V10.9587L50.494 10.9406L50.503 10.9226L50.5074 10.8998L50.5208 10.8818L50.5298 10.8591L50.5436 10.8363L50.557 10.8136L50.5707 10.7865L50.5841 10.7641L50.6022 10.737L50.6203 10.71L50.6384 10.6829L50.6518 10.6558L50.688 10.6017L50.7242 10.5475L50.7423 10.5204L50.7604 10.4887L50.7738 10.4616L50.7919 10.4345L50.8057 10.4074L50.819 10.3804L50.8324 10.3533L50.8415 10.3309L50.8505 10.3038L50.8596 10.2811L50.8643 10.254L50.8686 10.2316V10.2089V10.1909C50.8462 9.97426 50.561 9.82083 50.4886 9.78906H50.4842C50.4118 9.82083 50.1222 9.97426 50.1041 10.1909C50.086 10.4075 50.4708 10.8089 50.4799 10.9713H50.4896V10.9724Z" fill="white"/>
                      <path d="M51.0732 10.1953L51.0689 10.2V10.2047V10.2094L51.0641 10.2141L51.0594 10.2184V10.2231L51.0551 10.2274L51.0508 10.2364L51.0461 10.2455L51.0417 10.2588L51.0327 10.2769L51.0189 10.2996L51.0099 10.3224L50.9965 10.3448L50.9874 10.3718L50.9737 10.3946L50.9603 10.4216L50.9465 10.4531L50.9331 10.4801L50.9197 10.5072L50.906 10.539L50.8788 10.6021L50.8517 10.661L50.8383 10.6924L50.8292 10.7195L50.8158 10.7465L50.8068 10.7783L50.793 10.8054L50.784 10.8281L50.7749 10.8552L50.7706 10.8779L50.7615 10.9007L50.7568 10.9187C50.7116 11.122 50.7525 11.2526 50.843 11.3743C50.9378 11.4963 51.0917 11.6317 51.2321 11.8844C51.2321 11.8844 51.3993 11.6678 51.4174 11.5551C51.4355 11.4378 51.4084 11.1671 51.3679 11.0541C51.3356 10.94 51.1952 10.57 51.0732 10.1953Z" fill="white"/>
                      <path d="M50.4883 12.2063L50.5107 12.1835L50.5335 12.1608L50.556 12.1384L50.5741 12.1157L50.5969 12.0929L50.615 12.0702L50.6374 12.0474L50.6555 12.0204L50.6689 11.998L50.687 11.9752L50.6917 11.9619L50.7008 11.9529L50.7098 11.9391L50.7142 11.9301L50.7185 11.9168L50.7276 11.9077L50.7319 11.8987L50.7362 11.885L50.7409 11.876L50.7453 11.8669L50.75 11.8579L50.7543 11.8489C50.7862 11.754 50.7952 11.6186 50.7138 11.515C50.619 11.393 50.4966 11.2533 50.4876 11.1406H50.4785C50.4742 11.2536 50.3518 11.3933 50.2523 11.515C50.1708 11.6186 50.1799 11.754 50.2161 11.8489C50.2523 11.9391 50.3609 12.0973 50.4832 12.2052H50.4883V12.2063Z" fill="white"/>
                      <path d="M50.9381 11.8516L50.9424 11.8563L50.9468 11.8606L50.9515 11.8653L50.9562 11.87L50.9605 11.879L50.9696 11.888L50.9786 11.9014L50.992 11.9151L51.0058 11.9332L51.0192 11.9469L51.0329 11.9649L51.051 11.983L51.0644 12.0057L51.0825 12.0238L51.1006 12.0461L51.114 12.0642L51.1502 12.1093L51.1821 12.1498L51.2002 12.1725L51.2183 12.1952L51.232 12.218L51.2454 12.236L51.2635 12.2584L51.2773 12.2764L51.2906 12.2945L51.2997 12.3126L51.3131 12.3306L51.3221 12.3487L51.3312 12.362L51.3402 12.3754L51.3449 12.3844V12.3891L51.3493 12.3938V12.3985C51.3945 12.5115 51.4626 12.7143 51.3674 12.8587C51.2725 13.0031 51.0463 13.2334 50.8516 13.3327C50.8516 13.3327 51.0644 13.0035 51.0416 12.7324C51.0278 12.5566 50.8968 12.4032 50.8697 12.2992C50.8429 12.1945 50.861 12.014 50.9381 11.8516Z" fill="white"/>
                      <path d="M51.5484 11.6445L51.5441 11.6492L51.5394 11.6582L51.535 11.6629L51.5303 11.672L51.526 11.681L51.5169 11.69L51.5122 11.699L51.5079 11.7081L51.5035 11.7218L51.4988 11.7308L51.4898 11.7445L51.4854 11.7536L51.4807 11.7673L51.4764 11.7763L51.4626 11.8034L51.4536 11.8304L51.4445 11.8575L51.4355 11.8846L51.4264 11.9117L51.4174 11.9387L51.413 11.9568V11.9705L51.4083 11.9839V11.9976L51.404 12.0156V12.0293C51.3949 12.1828 51.4221 12.3091 51.5079 12.5391C51.6118 12.8232 51.7747 13.1437 51.8565 13.2881C51.8565 13.2881 52.0013 13.0762 52.0827 12.891C52.0827 12.891 51.8518 12.5752 51.7526 12.3542C51.6389 12.0911 51.5937 11.8474 51.5484 11.6445Z" fill="white"/>
                      <path d="M51.6562 11.5195V11.5239V11.5286L51.6606 11.5423V11.556L51.6653 11.574L51.6696 11.5921L51.6744 11.6192L51.6787 11.6462L51.6834 11.678L51.6924 11.7094L51.7015 11.7455L51.7105 11.7863L51.7196 11.8267L51.733 11.8675L51.7464 11.9126L51.7601 11.9621L51.7735 12.0072L51.7916 12.0567L51.8097 12.1108L51.8278 12.1603L51.8503 12.2144L51.8731 12.2686L51.8955 12.3227L51.9227 12.3769L51.9498 12.431L51.977 12.4852L52.0085 12.5393L52.0403 12.5978L52.0765 12.652L52.1127 12.7014L52.1489 12.7556L52.1894 12.8097C52.4837 13.1797 52.7234 13.3108 52.8819 13.3108C53.1216 13.3108 53.3615 13.1075 53.443 12.8819C53.5201 12.6563 53.4973 12.3 53.3434 12.178C53.3434 12.178 53.1987 12.6202 52.891 12.6382C52.5478 12.6567 52.0997 12.2686 51.6562 11.5195Z" fill="white"/>
                      <path d="M52.0162 11.2109L52.0115 11.2246L52.0072 11.238V11.2517L52.0025 11.2651L51.9981 11.2788V11.2925L51.9934 11.3243L51.9891 11.3557L51.9844 11.3875V11.4192V11.4553V11.4871V11.5232V11.5593L51.9891 11.5954L51.9934 11.6315L51.9981 11.6676L52.0072 11.7037L52.0162 11.7398C52.0524 11.8842 52.1386 12.0737 52.3102 12.182C52.4549 12.2722 52.8032 12.295 52.9346 11.9654C52.9346 11.9643 52.3011 12.0141 52.0162 11.2109Z" fill="white"/>
                      <path d="M50.4838 13.4016L50.5019 13.3925L50.5153 13.3835L50.5334 13.3788L50.5468 13.3698L50.5649 13.3607L50.5787 13.3517L50.5877 13.347L50.5968 13.338L50.6058 13.3333L50.6105 13.3286L50.6196 13.3196L50.6286 13.3149L50.6377 13.3059L50.6467 13.3012L50.6558 13.2922L50.6648 13.2831L50.6692 13.2741L50.6782 13.2651L50.6873 13.2561L50.6963 13.2427L50.7054 13.2337L50.7144 13.22L50.7235 13.2109L50.7325 13.1976L50.7416 13.1839L50.755 13.1658C50.8317 13.0442 50.9088 12.8186 50.8679 12.6561C50.8136 12.4576 50.7007 12.3763 50.6373 12.25C50.5877 12.2995 50.5559 12.3355 50.4878 12.3763H50.4835C50.4201 12.3359 50.3839 12.2998 50.3296 12.25C50.2619 12.3763 50.1577 12.4576 50.1034 12.6561C50.0629 12.8186 50.1353 13.0442 50.2167 13.1658C50.3162 13.3294 50.3933 13.3564 50.4838 13.4016Z" fill="white"/>
                      <path d="M50.4857 14.6437L50.49 14.6256L50.4991 14.6076L50.5081 14.5848L50.5172 14.5621L50.5306 14.5393L50.5443 14.5166L50.5577 14.4942L50.5758 14.4715L50.5939 14.4444L50.612 14.4173L50.6301 14.3946L50.6482 14.3675L50.6844 14.3134L50.725 14.2592L50.7612 14.2051L50.7793 14.178L50.7974 14.1509L50.8155 14.1238L50.8289 14.1011L50.8426 14.074L50.856 14.047L50.8694 14.0246L50.8785 13.9975L50.8875 13.9748L50.8966 13.952L50.9013 13.9296V13.9069V13.8979V13.8888V13.8798V13.8708C50.8426 13.5596 50.6432 13.6224 50.4893 13.4961H50.485C50.3311 13.6224 50.1273 13.5593 50.073 13.8708C50.0332 14.0708 50.4405 14.4498 50.4857 14.6437Z" fill="white"/>
                      <path d="M50.9725 14.1875L50.9681 14.2055L50.9547 14.2283L50.9457 14.251L50.9323 14.2734L50.9189 14.2961L50.9051 14.3189L50.8917 14.3416L50.878 14.3644L50.8465 14.4095L50.8103 14.4546L50.7788 14.4998L50.7426 14.5449L50.7111 14.59L50.6974 14.608L50.6793 14.6304L50.6659 14.6532L50.6521 14.6759L50.6387 14.694L50.6297 14.7163L50.6163 14.7344L50.6072 14.7571L50.5982 14.7752L50.5935 14.7932L50.5891 14.8113L50.5844 14.8293L50.5801 14.8474V14.8611C50.5891 15.3214 50.7882 15.6011 50.8335 15.7546C50.8335 15.7546 51.2045 15.4614 51.2678 15.1275C51.3026 14.9546 51.262 14.4402 50.9725 14.1875Z" fill="white"/>
                      <path d="M51.3914 14.5114L51.3867 14.498L51.3823 14.4843L51.3776 14.471L51.3686 14.4572L51.3642 14.4392L51.3552 14.4258L51.3505 14.4078L51.3414 14.3897L51.3324 14.3717L51.3233 14.3493L51.3143 14.3313L51.3052 14.3132L51.2871 14.2724L51.2647 14.232L51.2423 14.1869L51.2195 14.1461L51.2061 14.128L51.1923 14.11L51.1789 14.0919L51.1655 14.0739L51.1518 14.0558L51.1384 14.0378L51.1246 14.0197L51.1112 14.006L51.0975 13.9923L51.0841 13.9789L51.0707 13.9652L51.0526 13.9515C51.0526 13.9515 50.8535 13.3649 51.496 13.0039C51.496 13.0039 51.7628 13.3515 51.8355 13.6855C51.9072 14.015 51.5543 14.2136 51.3914 14.5114Z" fill="white"/>
                      <path d="M52.1959 13.0234L52.214 13.0505L52.2364 13.0733L52.2592 13.1003L52.2817 13.1274L52.3041 13.1498L52.3269 13.1769L52.3494 13.2039L52.3722 13.2267L52.3993 13.2494L52.4217 13.2765L52.4308 13.2855L52.4442 13.2946L52.4579 13.3083L52.467 13.3173L52.4804 13.3263L52.4894 13.3353L52.5032 13.3444L52.5122 13.3491L52.5256 13.3581L52.5347 13.3671L52.5481 13.3715L52.5571 13.3761C52.5571 13.3761 52.598 13.6379 52.5752 13.8137C52.5437 14.071 52.3718 14.3371 52.3037 14.5356C52.3037 14.5356 52.0189 14.1656 51.9917 13.949C51.9555 13.7324 51.9602 13.5566 51.9512 13.4527C51.9515 13.4519 52.1097 13.1992 52.1959 13.0234Z" fill="white"/>
                      <path d="M52.9366 14.289L52.9457 14.2757L52.9547 14.262L52.9638 14.2439L52.9772 14.2302L52.9862 14.2075L52.9996 14.1894L53.0134 14.1667L53.0224 14.1443L53.0358 14.1172L53.0496 14.0945L53.063 14.0674L53.0811 14.0356L53.0944 14.0086L53.1082 13.9768L53.1216 13.9497L53.1353 13.9183L53.1625 13.8552L53.1896 13.7876L53.2121 13.7198L53.2255 13.6884L53.2345 13.6566L53.2436 13.6205L53.2526 13.5887L53.2617 13.5573L53.2664 13.5212L53.2707 13.4895L53.2754 13.4581V13.4263V13.3945C53.2754 13.3945 52.9587 13.566 52.6963 13.4397C52.6963 13.4397 52.6601 13.8638 52.71 14.0039C52.7509 14.1219 52.8099 14.1898 52.9366 14.289Z" fill="white"/>
                      <path d="M52.8656 14.3758L52.8608 14.3711L52.8565 14.3668L52.8475 14.3578L52.8431 14.3531L52.8341 14.3484L52.8294 14.3437L52.8203 14.3347L52.816 14.33L52.8069 14.321L52.7979 14.3163L52.7932 14.3073L52.7841 14.2982L52.766 14.2845L52.7479 14.2665L52.7345 14.2527L52.7164 14.2347L52.703 14.221L52.694 14.212L52.6849 14.2076L52.6802 14.1986L52.6712 14.1896L52.6621 14.1849L52.6578 14.1759L52.6531 14.1712L52.644 14.1621L52.6397 14.1574L52.635 14.1484C52.635 14.1484 52.4268 14.5365 52.3906 14.6495C52.3906 14.6495 52.4945 14.6722 52.6078 14.591C52.7251 14.5112 52.7523 14.4885 52.8656 14.3758Z" fill="white"/>
                      <path d="M51.8867 14.1484L51.8914 14.1531V14.1578L51.8957 14.1669V14.1716L51.9005 14.1806L51.9048 14.1853V14.1943L51.9095 14.2033L51.9186 14.2214L51.9319 14.2394L51.941 14.2618L51.9548 14.2798L51.9681 14.3026L51.9862 14.3297L51.9996 14.3524L52.0177 14.3795L52.0358 14.4022L52.0496 14.4293L52.0858 14.4791L52.122 14.5333L52.1401 14.556L52.1582 14.5831L52.1763 14.6055L52.1897 14.6282L52.2078 14.6509L52.2215 14.6737L52.2396 14.6917L52.253 14.7098L52.2668 14.7278L52.2758 14.7412L52.2802 14.7459L52.2892 14.7549L52.2939 14.7596L52.2983 14.7643L52.3026 14.769V14.7737L52.3073 14.7784L52.3117 14.7827C52.3117 14.7827 52.2845 14.9542 52.1625 15.1978C52.0311 15.4462 51.8592 15.6177 51.7643 15.7032C51.7643 15.7032 51.5562 15.3376 51.4929 15.1798C51.4205 14.995 51.4248 14.8098 51.461 14.7419C51.5291 14.5816 51.7734 14.3517 51.8867 14.1484Z" fill="white"/>
                      <path d="M50.4908 16.9524L50.4951 16.9387L50.4998 16.9254L50.5046 16.912L50.5089 16.8983L50.5179 16.8893L50.5223 16.8759L50.5313 16.8622L50.5404 16.8485L50.5494 16.8351L50.5585 16.8214L50.5722 16.8034L50.5813 16.7896L50.6037 16.7626L50.6262 16.7308L50.649 16.6994L50.6761 16.668L50.6986 16.6319L50.721 16.5958L50.7348 16.5777L50.7438 16.5597L50.7572 16.5416L50.7663 16.5236L50.7753 16.5055L50.7844 16.4832L50.7934 16.4651L50.8025 16.4427C50.8477 16.3121 50.8477 16.1539 50.7934 16.0457C50.7348 15.9236 50.5129 15.6168 50.4857 15.5312C50.4586 15.6172 50.2414 15.924 50.178 16.0457C50.1238 16.1539 50.1238 16.3117 50.1733 16.4427C50.2548 16.6683 50.4495 16.8218 50.481 16.9524H50.4908Z" fill="white"/>
                      <path d="M51.3712 15.3828L51.3665 15.4009L51.3575 15.4142L51.3484 15.4323L51.3394 15.446L51.3303 15.464L51.3169 15.4821L51.3079 15.4958L51.2941 15.5138L51.2807 15.5319L51.2674 15.5546L51.2445 15.5907L51.2174 15.6312L51.1903 15.6673L51.1631 15.7077L51.1403 15.7481L51.1179 15.7886L51.1041 15.8113L51.0951 15.8294L51.086 15.8517L51.0726 15.8698L51.0683 15.8878L51.0592 15.9106L51.0502 15.9286L51.0455 15.951L51.0411 15.9691L51.0364 15.9871C51.0093 16.1405 51.0364 16.2669 51.0726 16.3752C51.1179 16.4788 51.2536 16.6864 51.3441 16.8445C51.3441 16.8445 51.6561 16.5513 51.7242 16.3438C51.7737 16.1857 51.7604 15.9647 51.6836 15.8203C51.6065 15.6803 51.4436 15.5315 51.3712 15.3828Z" fill="white"/>
                      <path d="M52.2589 15.3438L52.2498 15.3618L52.2408 15.3798L52.2317 15.3932L52.2227 15.4112L52.2136 15.4293L52.2046 15.443L52.1822 15.4744L52.1593 15.5105L52.1322 15.5423L52.1098 15.5741L52.0826 15.6055L52.0602 15.6369L52.0374 15.6686L52.0149 15.7L52.0059 15.7134L51.9968 15.7314L51.9878 15.7452L51.9787 15.7632L51.9697 15.7766L51.9606 15.7899L51.9563 15.808L51.9516 15.8217L51.9425 15.8354L51.9382 15.8535C51.8882 16.0296 51.8748 16.1466 51.9606 16.3588C52.0374 16.5437 52.1326 16.652 52.1644 16.7649C52.1644 16.7649 52.4178 16.4401 52.4583 16.3047C52.5036 16.1646 52.517 15.9437 52.4627 15.804C52.4127 15.6596 52.3222 15.4744 52.2589 15.3438Z" fill="white"/>
                      <path d="M50.4839 19.4563L50.4882 19.4383L50.4925 19.4202L50.4972 19.4022L50.5063 19.3798L50.5153 19.3574L50.5244 19.3347L50.5334 19.3076L50.5468 19.2762L50.5606 19.2491L50.574 19.2173L50.5877 19.186L50.6011 19.1498L50.6192 19.1137L50.6326 19.0776L50.6507 19.0415L50.6645 19.0011L50.6826 18.965L50.7007 18.9246L50.7141 18.8795L50.7278 18.839L50.7459 18.7939L50.7597 18.7488L50.7731 18.7037L50.7868 18.6585L50.8002 18.6134L50.8093 18.5683L50.8183 18.5185L50.8274 18.4734L50.8364 18.4235L50.8411 18.3741L50.8455 18.329V18.2795C50.8589 18.0178 50.6192 17.6026 50.4878 17.4492H50.4788C50.3474 17.6026 50.1168 18.0178 50.1212 18.2795C50.14 18.7975 50.4701 19.2939 50.4839 19.4563Z" fill="white"/>
                      <path d="M51.0153 16.5898V16.5942H51.0197V16.5985L51.0244 16.6032L51.0287 16.6079L51.0334 16.6122L51.0378 16.6166L51.0425 16.6256L51.0468 16.6303L51.0559 16.6393L51.0602 16.6483L51.0649 16.6574L51.083 16.6754L51.0964 16.6982L51.1145 16.7209L51.1326 16.7433L51.1507 16.7703L51.1688 16.7974L51.1913 16.8245L51.2094 16.8563L51.2275 16.8877L51.2503 16.9191L51.2684 16.9508L51.2908 16.9823L51.3089 17.0183L51.327 17.0498L51.3451 17.0815L51.3585 17.1176L51.3766 17.149L51.3904 17.1804L51.4038 17.2165L51.4128 17.2483L51.4219 17.2797L51.4309 17.3068L51.4352 17.3382V17.3515V17.3653V17.379V17.3923C51.4262 17.74 51.1956 17.9656 51.1594 18.1822C51.1594 18.1822 50.9332 17.9251 50.8474 17.7537C50.7478 17.5551 50.6707 17.3927 50.7026 17.1718C50.7258 16.9508 50.7848 16.8108 51.0153 16.5898Z" fill="white"/>
                      <path d="M51.8153 16.5352L51.8062 16.5442L51.8015 16.5579L51.7925 16.5716L51.7834 16.585L51.7744 16.5983L51.7653 16.6164L51.7563 16.6344L51.7429 16.6525L51.7338 16.6705L51.7248 16.6886L51.7157 16.711L51.7023 16.729L51.6933 16.7514L51.6842 16.7741L51.6614 16.8193L51.6524 16.8463L51.6433 16.8691L51.6343 16.8918L51.6299 16.9142L51.6209 16.9413L51.6166 16.9637L51.6075 16.9864L51.6028 17.0088L51.5985 17.0358V17.0586L51.5938 17.081V17.1033V17.1214L51.5985 17.1438V17.1665L51.6075 17.1846C51.6571 17.3878 51.8743 17.6719 51.9467 17.7983C51.9467 17.7983 52.1186 17.4463 52.1592 17.2838C52.1953 17.1218 52.1635 16.86 51.8153 16.5352Z" fill="white"/>
                      <path d="M50.7836 20.2954L50.7883 20.2593L50.7926 20.2232L50.7973 20.1871L50.8017 20.151L50.8107 20.1149L50.8154 20.0831L50.8245 20.0517L50.8335 20.02L50.8473 19.9882L50.8563 19.9568L50.8654 19.9297L50.8788 19.8983L50.8925 19.8712L50.9016 19.8442L50.9287 19.79L50.9512 19.7402L50.9783 19.6907L51.0008 19.6456L51.0236 19.6005L51.0326 19.5778L51.0417 19.555L51.0507 19.5326L51.0598 19.5102L51.0688 19.4922L51.0732 19.4698L51.0775 19.4518L51.0822 19.4337C51.1275 19.1991 51.1137 18.9778 50.9284 18.707C50.7836 19.2081 50.6207 19.5734 50.4983 19.8261C50.4936 19.8351 50.4893 19.8395 50.485 19.8485C50.485 19.8395 50.4802 19.8348 50.4759 19.8261C50.3492 19.5734 50.1863 19.2077 50.0415 18.707C49.8605 18.9778 49.8424 19.1987 49.8877 19.4337C49.9286 19.6413 50.1592 19.8893 50.1863 20.2954C50.2135 20.7062 49.752 20.8596 49.752 20.8596C49.9105 20.9138 50.0868 20.9409 50.3673 20.6748C50.4169 20.625 50.4578 20.5755 50.485 20.5167C50.5164 20.5752 50.5573 20.625 50.6026 20.6748C50.8875 20.9409 51.0641 20.9138 51.218 20.8596C51.218 20.8596 50.7608 20.7062 50.7836 20.2954Z" fill="white"/>
                      <path d="M49.3303 17.5859L49.335 17.5993L49.344 17.6174L49.3484 17.6311L49.3531 17.6491L49.3621 17.6672L49.3712 17.6852L49.3755 17.7033L49.3846 17.7213L49.398 17.7574L49.4161 17.7935L49.4342 17.8343L49.4479 17.8704L49.466 17.9112L49.4751 17.9293L49.4794 17.9473L49.4885 17.9607L49.4975 17.9787L49.5019 17.9967L49.5066 18.0105L49.5156 18.0285L49.52 18.0422L49.5247 18.0556L49.529 18.0646L49.5337 18.078L49.5381 18.087V18.1007L49.5428 18.1051C49.579 18.2675 49.529 18.466 49.2351 18.6736C49.2351 18.6736 49.0451 18.5382 48.9683 18.3624C48.8959 18.2 48.9412 18.0827 49.0136 18.0014C49.086 17.9108 49.2626 17.7166 49.3303 17.5859Z" fill="white"/>
                      <path d="M49.9552 18.1875V18.1965V18.2102V18.2192L49.9509 18.2373V18.251V18.2691L49.9462 18.2915V18.3095L49.9418 18.3319L49.9375 18.3546L49.9328 18.3817L49.9285 18.4088L49.9237 18.4359L49.9194 18.4629L49.9147 18.49L49.9104 18.5171L49.8966 18.5755L49.8832 18.634L49.8651 18.6972L49.8561 18.7243L49.8514 18.7513L49.838 18.7828L49.8289 18.8098L49.8199 18.8369L49.8108 18.864L49.7974 18.8911L49.7884 18.9134L49.7746 18.9362L49.7656 18.9586C49.5802 19.2791 49.3087 19.5228 49.0687 19.5228C48.8515 19.5228 48.8063 19.3921 48.6934 19.338C48.6934 19.338 48.7477 19.0582 48.9963 18.986C49.2993 18.8914 49.7652 18.5304 49.9552 18.1875Z" fill="white"/>
                      <path d="M49.1639 18.7721L49.1548 18.7631L49.1458 18.7587L49.1277 18.745L49.1186 18.736L49.1096 18.7313L49.0958 18.7223L49.0868 18.7179L49.0777 18.7089L49.0687 18.6999L49.0643 18.6955L49.0596 18.6909L49.0553 18.6862L49.0506 18.6818L49.0415 18.6775L49.0368 18.6728L49.0325 18.6681L49.0278 18.6638L49.0234 18.6548L49.0144 18.6501L49.0097 18.6457L49.0053 18.6367C49.0053 18.6367 48.7744 18.7223 48.5753 18.8533C48.3853 18.9843 48.1952 19.2053 48.0957 19.3183C48.0957 19.3183 48.3806 19.3905 48.5663 19.3092C48.5663 19.3092 48.634 19.0342 48.8377 18.9122C48.8877 18.8847 49.0281 18.8396 49.1639 18.7721Z" fill="white"/>
                      <path d="M48.9206 18.5374L48.9159 18.533L48.9116 18.524L48.9069 18.5193L48.9025 18.5103L48.8978 18.5013L48.8935 18.4922L48.8888 18.4832L48.8844 18.4742L48.8797 18.4651L48.8707 18.4561L48.8664 18.4424L48.8616 18.4334L48.8573 18.4244L48.8526 18.411L48.8483 18.3976L48.8435 18.3886L48.8345 18.3753L48.8302 18.3615V18.3478L48.8254 18.3345L48.8211 18.3211L48.8168 18.3074L48.8121 18.2893V18.276L48.8077 18.2626V18.2446V18.2265L48.803 18.2128V18.1948V18.1767L48.8077 18.1587V18.1406C48.8077 18.1406 48.4005 18.398 48.2781 18.4926C48.1558 18.5919 47.9028 18.8536 47.8438 18.9395C47.8438 18.9395 48.1514 18.8716 48.3911 18.7951C48.6492 18.7088 48.8254 18.5915 48.9206 18.5374Z" fill="white"/>
                      <path d="M48.2325 18.9766L48.2144 18.9856L48.1916 18.9899L48.1645 18.9989L48.142 19.008L48.1149 19.0127L48.0877 19.0217L48.0562 19.026L48.0291 19.0351L47.9976 19.0441L47.9657 19.0488L47.9024 19.0621L47.839 19.0802L47.7713 19.0939L47.708 19.1072L47.6403 19.1206L47.577 19.1343L47.5498 19.1387L47.5183 19.1477L47.4912 19.152L47.4597 19.161L47.4325 19.1657L47.4097 19.1701L47.3826 19.1791L47.3601 19.1838L47.3377 19.1885L47.3196 19.1975C47.1115 19.2607 46.962 19.36 46.7676 19.5765C46.7676 19.5765 47.0662 19.6578 47.2291 19.7119C47.2291 19.7119 47.4868 19.5357 47.7674 19.4321C47.952 19.3603 48.0378 19.1661 48.2325 18.9766Z" fill="white"/>
                      <path d="M47.7736 20.4232L47.7827 20.4142L47.7917 20.4051L47.8008 20.3918L47.8098 20.3828L47.8145 20.3781L47.8189 20.3691L47.8232 20.3644L47.8279 20.3597V20.3506L47.8323 20.3459L47.837 20.3369L47.8413 20.3326V20.3236L47.846 20.3192V20.3102V20.3012L47.8504 20.2968V20.2878V20.2788V20.2698V20.2608V20.2517V20.2427L47.846 20.2337V20.2246L47.8413 20.2109V20.2019L47.837 20.1882C47.8051 20.1026 47.6241 20.003 47.5159 19.9535C47.3393 19.868 46.851 19.737 46.6924 19.6875C46.6924 19.6875 46.611 19.7821 46.5704 19.8817C46.5476 19.9221 46.5748 19.9582 46.6428 19.9673C46.7833 19.9943 47.109 19.9312 47.1901 19.9853C47.2759 20.0395 47.2444 20.116 47.2444 20.1886C47.2444 20.1886 47.3939 20.0983 47.4888 20.121C47.6198 20.1478 47.7012 20.2109 47.7736 20.4232Z" fill="white"/>
                      <path d="M47.3398 19.7487H47.3445H47.3489L47.3532 19.7534H47.3579L47.367 19.7577H47.376L47.3851 19.762L47.3941 19.7667H47.4032L47.4122 19.7714L47.4256 19.7757L47.4347 19.7801L47.4484 19.7848L47.4618 19.7895L47.4756 19.7938L47.489 19.8028L47.5024 19.8075L47.5161 19.8119L47.5342 19.8209L47.5476 19.8256L47.5657 19.8346L47.5791 19.8436L47.5972 19.8483L47.6153 19.8573L47.6291 19.8664L47.6472 19.8754L47.6653 19.8844L47.6834 19.8934L47.6968 19.9025L47.7149 19.9158L47.733 19.9248L47.7511 19.9339C47.7511 19.9339 47.8325 19.9025 47.8734 19.8256C47.914 19.7534 48.0816 19.505 48.2354 19.4736C48.2354 19.4736 48.104 19.4646 48.0273 19.4375C48.0273 19.4375 47.8734 19.4779 47.7558 19.5277C47.6432 19.5682 47.5027 19.6404 47.3398 19.7487Z" fill="white"/>
                      <path d="M48.6017 19.4375H48.597L48.588 19.4418L48.5833 19.4465H48.5789H48.5742L48.5699 19.4512H48.5652H48.5608L48.5561 19.4555H48.5518H48.5471L48.5427 19.4599H48.538H48.529L48.5246 19.4646H48.5156H48.5109L48.5065 19.4693H48.4975H48.4884H48.4837L48.4747 19.4736H48.4656H48.4566H48.4475L48.4385 19.4779H48.4294H48.4204H48.4066C48.4066 19.4779 47.977 19.7757 47.927 20.0692C47.8818 20.3263 48.0175 20.4483 48.0809 20.48C48.1486 20.5161 48.4475 20.6335 48.6419 20.2364C48.6419 20.2364 48.3118 20.3447 48.2166 20.1869C48.1308 20.0245 48.2752 19.7898 48.3661 19.7267C48.4884 19.6364 48.7009 19.5732 48.8319 19.6003C48.8323 19.5999 48.7237 19.5007 48.6017 19.4375Z" fill="white"/>
                      <path d="M45.7841 22.8452L45.8022 22.764L45.8246 22.6828L45.8474 22.5972L45.8565 22.5568L45.8698 22.5164L45.8832 22.4756L45.897 22.4305L45.9108 22.39L45.9241 22.3496L45.9422 22.3092L45.9556 22.2684L45.9737 22.2276L45.9918 22.1871C46.1862 21.7763 46.6662 20.68 47.6613 20.4905C47.7518 20.4681 47.5527 20.2378 47.4622 20.2512C47.3717 20.2602 47.1954 20.3775 47.1183 20.4273C47.1183 20.4273 47.1183 20.1927 47.1136 20.1522C47.1136 20.1118 47.0774 20.0938 47.0369 20.0938C47.0007 20.0981 46.7563 20.0938 46.6839 20.184C46.453 20.4457 46.0729 21.1496 45.8557 21.6731C45.6385 22.1965 45.489 22.359 45.2585 22.2778C45.0322 22.1965 44.8965 21.9348 44.494 22.016C44.3582 22.0431 44.2044 22.1381 44.082 22.2734C44.1182 22.3366 44.1588 22.4178 44.3445 22.4178C44.5298 22.4178 44.8918 22.4943 45.0865 22.6301C45.2867 22.7683 45.4945 22.8182 45.7841 22.8452Z" fill="white"/>
                      <path d="M49.7566 19.4492L49.7613 19.4806L49.7656 19.512L49.7747 19.5438L49.7837 19.5752L49.7928 19.6066L49.8065 19.6384L49.8156 19.6655L49.829 19.6972L49.8424 19.729L49.8561 19.7604L49.8695 19.7921L49.8876 19.8192L49.9014 19.8506L49.9195 19.8824L49.9328 19.9138L49.9509 19.9452C49.9509 19.9452 49.6523 21.3394 48.553 21.4433C48.553 21.4433 48.5754 21.1545 48.8969 20.9379C49.2226 20.7166 49.7113 20.3737 49.6751 19.855C49.6751 19.855 49.5846 19.873 49.4308 20.0672C49.2769 20.2614 48.9331 20.8253 48.4082 20.8296C48.4082 20.8296 48.4849 20.5636 48.7202 20.4145C48.9512 20.2611 49.2498 20.0672 49.3855 19.8326C49.5213 19.6026 49.6617 19.4943 49.7566 19.4492Z" fill="white"/>
                      <path d="M49.2363 24.531L49.906 25.4649L50.4128 24.5671L50.2318 24.2422C50.1004 24.4812 49.9556 24.7342 49.8789 24.8786C49.8202 24.7884 49.6526 24.5537 49.4311 24.2422L49.2363 24.531Z" fill="white"/>
                      <path d="M49.0689 23.7434L48.9469 23.5719L48.8202 23.3961L48.6935 23.2199L48.5668 23.0441L48.4401 22.868L48.3178 22.6965L48.2591 22.6153L48.2001 22.5341L48.1415 22.4528L48.0872 22.3763L48.0329 22.2994L47.9829 22.2272L47.9333 22.155L47.8834 22.0875L47.8381 22.0244L47.7929 21.9655L47.7523 21.907L47.7161 21.8572L47.6799 21.8077L47.6481 21.7626L47.6209 21.7218L47.5985 21.6857L47.5757 21.6587L47.5576 21.6316L47.5442 21.6135L47.5352 21.6002L47.7885 21.4197L46.9922 21.1172L47.0056 21.9792L47.2771 21.785L48.8788 24.0322L49.0689 23.7434Z" fill="white"/>
                      <path d="M43.5846 18.6493L43.6027 18.6312L43.6251 18.6132L43.6432 18.5998L43.666 18.5864L43.6884 18.5774L43.7109 18.5684L43.7333 18.5637L43.7561 18.559L43.7789 18.5547H43.8061L43.8289 18.559H43.856L43.8832 18.568L43.9103 18.5724L43.9375 18.5814L43.9646 18.5904L43.9918 18.6041L44.0236 18.6175L44.0508 18.6312L44.0826 18.6449L44.1098 18.663L44.1416 18.681L44.1731 18.7034L44.2003 18.7215L44.2321 18.7438L44.2636 18.7662L44.2951 18.7933L44.327 18.8157L44.3585 18.8427L44.3856 18.8698L44.4171 18.8969L44.4486 18.924C44.7834 19.2308 44.9959 19.5467 44.8015 19.7633C44.6205 19.9575 44.2676 19.7904 43.9371 19.4835C43.5983 19.177 43.3902 18.8612 43.5846 18.6493Z" fill="white"/>
                      <path d="M44.1285 22.4384L44.1241 22.434L44.1198 22.4293L44.1151 22.425H44.1104V22.4207H44.106V22.416H44.1017V22.4113H44.097C43.9203 22.2535 43.8165 22.0142 43.8165 21.7525C43.8165 21.2471 44.228 20.8366 44.7305 20.8366C45.2372 20.8366 45.6492 21.2471 45.6492 21.7525C45.6492 21.8203 45.6354 21.9286 45.6354 21.9286C45.6759 21.8521 45.8302 21.5045 45.8573 21.4413C45.7216 20.9496 45.2691 20.5883 44.7305 20.5883C44.2689 20.5883 43.8755 20.8547 43.6807 21.2427C43.7531 20.7825 43.9837 20.3674 44.1466 20.2139C44.3413 20.0291 44.6758 19.9703 44.6758 19.9703C44.4543 20.002 44.1737 19.871 43.8476 19.5779C43.5446 19.2981 43.3908 19.0544 43.377 18.8516C43.3951 19.1404 43.3904 19.2981 43.3093 19.5374C43.196 19.8533 42.893 20.4399 43.0016 21.1034C43.0603 21.4691 43.3227 22.0106 43.7708 22.4524C43.8613 22.5517 43.8975 22.7008 43.9113 22.7773C43.9113 22.7773 44.5629 22.7773 44.7214 22.7907C44.7214 22.7907 44.8752 22.7773 44.8662 22.6777C44.8662 22.5828 44.7804 22.5604 44.708 22.5604C44.6262 22.56 44.3004 22.5647 44.1285 22.4384Z" fill="white"/>
                      <path d="M47.7384 22.6562L47.6616 22.6833L47.5802 22.7104L47.5035 22.7331L47.4267 22.7559L47.3453 22.7783L47.2682 22.8007L47.1914 22.8187L47.1147 22.8367L47.0332 22.8548L46.9565 22.8685L46.8798 22.8819L46.803 22.8952L46.6535 22.9223L46.4997 22.945C46.2463 22.9855 45.9929 22.9992 45.7486 22.9902C45.7529 23.0306 45.7529 23.0804 45.7305 23.1208C45.7305 23.1208 45.7757 23.175 45.7757 23.2429C46.0248 23.2476 46.2825 23.2382 46.5402 23.1934C46.9746 23.1256 47.4318 23.04 47.8886 22.8685L47.7384 22.6562Z" fill="white"/>
                      <path d="M48.4267 22.6302L48.5306 22.5713L48.6345 22.5081L48.7384 22.4406L48.8423 22.3684L48.9418 22.2919L49.0457 22.2107L49.1452 22.1251L49.2491 22.0349L49.3487 21.9403L49.4435 21.8367L49.5431 21.7331L49.6379 21.6201L49.7374 21.4981L49.8323 21.3761L49.9228 21.245L49.9724 21.1775L50.0176 21.1053L50.1034 20.9609C49.972 21.0194 49.8004 20.979 49.8004 20.979C49.7914 20.9923 49.7689 21.0285 49.7552 21.0422C49.3027 21.7147 48.7959 22.1432 48.2754 22.4229L48.4267 22.6302Z" fill="white"/>
                      <path d="M44.7525 22.8637H44.7435H44.7388H44.7254H44.7164H44.7073L44.6935 22.8594H44.6802H44.6668H44.653H44.6392H44.6259H44.6125H44.5944H44.5806H44.5444L44.5129 22.8637H44.4767L44.4449 22.8684H44.4087L44.3772 22.8731H44.3591H44.3457L44.3276 22.8774H44.3138L44.3001 22.8818H44.2867C44.1238 22.9041 43.9428 22.9402 43.92 23.0261C43.9066 23.1117 43.9605 23.202 44.0424 23.3283C44.0967 23.4048 44.2143 23.3644 44.2776 23.3373C44.3457 23.3102 44.7258 23.1117 44.7844 23.0442C44.843 22.981 44.8658 22.8861 44.7525 22.8637Z" fill="white"/>
                      <path d="M45.1364 23.1154L45.1317 23.1111L45.127 23.1064H45.1226L45.1183 23.1021H45.1136L45.1089 23.0977L45.1045 23.0931H45.0955L45.0908 23.0884H45.0817L45.077 23.084H45.068L45.0636 23.0797H45.0546H45.0455L45.0412 23.075H45.0321H45.0231L45.014 23.0703H45.005H44.9959H44.9869H44.9778H44.9688H44.9597H44.9507L44.9416 23.075H44.9326L44.9235 23.0797H44.9145L44.9054 23.084H44.8964C44.7925 23.1245 44.4215 23.3458 44.3943 23.445C44.3715 23.549 44.4215 23.6032 44.4801 23.6255C44.5387 23.6479 44.6068 23.6706 44.6611 23.675C44.7516 23.6797 44.8421 23.6255 44.892 23.5667C44.9373 23.5082 45.1092 23.3006 45.1364 23.2508C45.1588 23.21 45.1769 23.1378 45.1364 23.1154Z" fill="white"/>
                      <path d="M45.1823 23.3397L45.1779 23.3444L45.1736 23.3534L45.1645 23.3581L45.1602 23.3671L45.1512 23.3762L45.1421 23.3852L45.1378 23.3942L45.1287 23.4032L45.1197 23.4123L45.1106 23.4256L45.1016 23.4346L45.0925 23.4437L45.0791 23.4661L45.061 23.4888L45.052 23.4978L45.0429 23.5115L45.0339 23.5206L45.0248 23.5296L45.0201 23.5386L45.0111 23.5476L45.002 23.5567L44.9973 23.5657L44.9883 23.5747L44.9836 23.5837L44.9788 23.5884L44.9745 23.5974L44.9702 23.6021V23.6068H44.9655V23.6112L44.9607 23.6155C44.9426 23.6426 44.9698 23.692 45.015 23.6834C45.065 23.6787 45.318 23.6516 45.4675 23.5527C45.6123 23.4534 45.7119 23.2639 45.6123 23.1917C45.5081 23.1184 45.3542 23.1545 45.1823 23.3397Z" fill="white"/>
                      <path d="M45.2629 22.7845H45.2582H45.2539L45.2448 22.7802H45.2401H45.2311L45.222 22.7755H45.213L45.2039 22.7712H45.1949H45.1858L45.1724 22.7665H45.1634L45.1543 22.7622H45.1406H45.1315H45.1181L45.1091 22.7578H45.0957H45.0866H45.0776H45.0642H45.0551H45.0461L45.037 22.7622H45.028L45.0189 22.7665H45.0099V22.7712H45.0055H45.0008L44.9961 22.7755L44.9918 22.7802H44.9874V22.7845H44.9827V22.7889C44.9284 22.8701 44.9646 22.9513 45.1185 23.0235C45.268 23.0957 45.5482 23.1545 45.6025 23.0776C45.6564 23.0011 45.584 22.8705 45.2629 22.7845Z" fill="white"/>
                      <path d="M51.6394 17.5859L51.6347 17.5993L51.6256 17.6174L51.6213 17.6311L51.6169 17.6491L51.6079 17.6672L51.6032 17.6852L51.5941 17.7033L51.5851 17.7213L51.5717 17.7574L51.5536 17.7935L51.5398 17.8343L51.5217 17.8704L51.5036 17.9112L51.4946 17.9293L51.4899 17.9473L51.4808 17.9607L51.4718 17.9787L51.4674 17.9967L51.4584 18.0105L51.4537 18.0285L51.4493 18.0422L51.4446 18.0556L51.4403 18.0646L51.4356 18.078L51.4312 18.087L51.4265 18.1007V18.1051C51.395 18.2675 51.4403 18.466 51.7342 18.6736C51.7342 18.6736 51.929 18.5382 52.001 18.3624C52.0734 18.2 52.0282 18.0827 51.9605 18.0014C51.8837 17.9108 51.7118 17.7166 51.6394 17.5859Z" fill="white"/>
                      <path d="M51.0137 18.1875L51.018 18.1965V18.2102V18.2192V18.2373V18.251L51.0227 18.2691V18.2915L51.0271 18.3095L51.0318 18.3319V18.3546L51.0361 18.3817L51.0408 18.4088L51.0452 18.4359L51.0499 18.4629L51.0589 18.49L51.0636 18.5171L51.077 18.5755L51.0908 18.634L51.1042 18.6972L51.1132 18.7243L51.1223 18.7513L51.1313 18.7828L51.1404 18.8098L51.1541 18.8369L51.1632 18.864L51.1722 18.8911L51.186 18.9134L51.1994 18.9362L51.2084 18.9586C51.3941 19.2791 51.6609 19.5228 51.9052 19.5228C52.1224 19.5228 52.1724 19.3921 52.2763 19.338C52.2763 19.338 52.222 19.0582 51.9729 18.986C51.6699 18.8914 51.2037 18.5304 51.0137 18.1875Z" fill="white"/>
                      <path d="M51.8066 18.7721L51.82 18.7631L51.8291 18.7587L51.8472 18.745L51.8562 18.736L51.8653 18.7313L51.8743 18.7223L51.8834 18.7179L51.8924 18.7089L51.9015 18.6999L51.9058 18.6955L51.9105 18.6909L51.9149 18.6862L51.9239 18.6818L51.9286 18.6775L51.933 18.6728L51.9377 18.6681L51.942 18.6638L51.9467 18.6548L51.9558 18.6501L51.9601 18.6457L51.9645 18.6367C51.9645 18.6367 52.1997 18.7223 52.3945 18.8533C52.5845 18.9843 52.7746 19.2053 52.8785 19.3183C52.8785 19.3183 52.5889 19.3905 52.4035 19.3092C52.4035 19.3092 52.3359 19.0342 52.1321 18.9122C52.0872 18.8847 51.9424 18.8396 51.8066 18.7721Z" fill="white"/>
                      <path d="M52.0508 18.5374L52.0551 18.533L52.0598 18.524L52.0642 18.5193L52.0689 18.5103L52.0732 18.5013L52.0776 18.4922L52.0823 18.4832L52.0913 18.4742L52.0957 18.4651L52.1004 18.4561L52.1047 18.4424L52.1094 18.4334L52.1185 18.4244L52.1228 18.411L52.1275 18.3976L52.1319 18.3886L52.1366 18.3753L52.1409 18.3615L52.1456 18.3478L52.15 18.3345L52.1547 18.3211L52.159 18.3074V18.2893L52.1637 18.276V18.2626L52.1681 18.2446V18.2265V18.2128L52.1728 18.1948V18.1767L52.1681 18.1587V18.1406C52.1681 18.1406 52.5753 18.398 52.6973 18.4926C52.8149 18.5919 53.073 18.8536 53.1273 18.9395C53.1273 18.9395 52.8196 18.8716 52.5843 18.7951C52.3266 18.7088 52.1456 18.5915 52.0508 18.5374Z" fill="white"/>
                      <path d="M52.7383 18.9766L52.7607 18.9856L52.7832 18.9899L52.806 18.9989L52.8331 19.008L52.8603 19.0127L52.8874 19.0217L52.9146 19.026L52.9464 19.0351L52.9736 19.0441L53.0054 19.0488L53.0688 19.0621L53.1365 19.0802L53.1998 19.0939L53.2679 19.1072L53.3312 19.1206L53.3946 19.1343L53.426 19.1387L53.4579 19.1477L53.4851 19.152L53.5122 19.161L53.5394 19.1657L53.5665 19.1701L53.5893 19.1791L53.6117 19.1838L53.6342 19.1885L53.657 19.1975C53.8604 19.2607 54.0099 19.36 54.209 19.5765C54.209 19.5765 53.9057 19.6578 53.7475 19.7119C53.7475 19.7119 53.4898 19.5357 53.2045 19.4321C53.0188 19.3603 52.9327 19.1661 52.7383 18.9766Z" fill="white"/>
                      <path d="M53.1959 20.4232L53.1868 20.4142L53.1778 20.4051L53.1687 20.3918L53.1597 20.3828L53.1553 20.3781L53.1506 20.3691L53.1463 20.3644L53.1416 20.3597V20.3506L53.1372 20.3459L53.1325 20.3369L53.1282 20.3326V20.3236L53.1235 20.3192V20.3102V20.3012L53.1191 20.2968V20.2878V20.2788V20.2698V20.2608V20.2517V20.2427L53.1235 20.2337V20.2246L53.1282 20.2109L53.1325 20.2019V20.1882C53.1687 20.1026 53.3454 20.003 53.4536 19.9535C53.6346 19.868 54.1233 19.737 54.2818 19.6875C54.2818 19.6875 54.3585 19.7821 54.4042 19.8817C54.4179 19.9221 54.3951 19.9582 54.3274 19.9673C54.1917 19.9943 53.8612 19.9312 53.7845 19.9853C53.6987 20.0395 53.7258 20.116 53.7258 20.1886C53.7258 20.1886 53.5763 20.0983 53.4815 20.121C53.3497 20.1478 53.273 20.2109 53.1959 20.4232Z" fill="white"/>
                      <path d="M53.6335 19.7487H53.6291H53.6248L53.6201 19.7534H53.611L53.6067 19.7577H53.5976L53.5886 19.762L53.5795 19.7667H53.5705L53.5567 19.7714L53.5477 19.7757L53.5343 19.7801L53.5252 19.7848L53.5118 19.7895L53.4981 19.7938L53.4847 19.8028L53.4666 19.8075L53.4528 19.8119L53.4394 19.8209L53.4213 19.8256L53.4079 19.8346L53.3898 19.8436L53.3761 19.8483L53.358 19.8573L53.3399 19.8664L53.3218 19.8754L53.3084 19.8844L53.2903 19.8934L53.2722 19.9025L53.2541 19.9158L53.236 19.9248L53.2179 19.9339C53.2179 19.9339 53.1365 19.9025 53.0956 19.8256C53.055 19.7534 52.8874 19.505 52.7383 19.4736C52.7383 19.4736 52.865 19.4646 52.9417 19.4375C52.9417 19.4375 53.1003 19.4779 53.2132 19.5277C53.3258 19.5682 53.4659 19.6404 53.6335 19.7487Z" fill="white"/>
                      <path d="M52.3719 19.4375H52.3763L52.381 19.4418H52.3853L52.39 19.4465H52.3944L52.3991 19.4512H52.4034H52.4081L52.4125 19.4555H52.4168H52.4215L52.4259 19.4599H52.4349H52.4396L52.444 19.4646H52.453H52.4577L52.4668 19.4693H52.4711H52.4802H52.4849L52.4939 19.4736H52.503H52.512H52.5211L52.5301 19.4779H52.5392H52.5529H52.562C52.562 19.4779 52.9964 19.7757 53.046 20.0692C53.0865 20.3263 52.9555 20.4483 52.8874 20.48C52.8241 20.5161 52.5254 20.6335 52.3263 20.2364C52.3263 20.2364 52.6565 20.3447 52.7517 20.1869C52.8422 20.0245 52.6974 19.7898 52.6022 19.7267C52.4845 19.6364 52.2673 19.5732 52.1406 19.6003C52.1413 19.5999 52.2452 19.5007 52.3719 19.4375Z" fill="white"/>
                      <path d="M55.1916 22.8452L55.1688 22.764L55.1507 22.6828L55.1283 22.5972L55.1149 22.5568L55.1011 22.5164L55.0921 22.4756L55.0787 22.4305L55.065 22.39L55.0469 22.3496L55.0335 22.3092L55.0154 22.2684L54.9973 22.2276L54.9792 22.1871C54.7891 21.7763 54.3095 20.68 53.3141 20.4905C53.2192 20.4681 53.4183 20.2378 53.5088 20.2512C53.5993 20.2602 53.7756 20.3775 53.8574 20.4273V20.1522C53.8617 20.1118 53.8979 20.0938 53.9341 20.0938C53.9747 20.0981 54.2147 20.0938 54.2918 20.184C54.518 20.4457 54.9028 21.1496 55.1153 21.6731C55.3368 22.1965 55.4863 22.359 55.7125 22.2778C55.9388 22.1965 56.0745 21.9348 56.477 22.016C56.6175 22.0431 56.7709 22.1381 56.889 22.2734C56.8571 22.3366 56.8166 22.4178 56.6265 22.4178C56.4408 22.4178 56.0836 22.4943 55.8845 22.6301C55.6847 22.7683 55.4765 22.8182 55.1916 22.8452Z" fill="white"/>
                      <path d="M51.2186 19.4492L51.2096 19.4806L51.2052 19.512L51.1962 19.5438L51.1871 19.5752L51.1781 19.6066L51.169 19.6384L51.1556 19.6655L51.1419 19.6972L51.1328 19.729L51.1147 19.7604L51.1013 19.7921L51.0876 19.8192L51.0695 19.8506L51.0557 19.8824L51.0195 19.9456C51.0195 19.9456 51.3225 21.3397 52.4222 21.4437C52.4222 21.4437 52.3998 21.1549 52.0736 20.9383C51.7478 20.717 51.2635 20.3741 51.3001 19.8553C51.3001 19.8553 51.3815 19.8734 51.5397 20.0676C51.6935 20.2618 52.0374 20.8257 52.567 20.83C52.567 20.83 52.4903 20.5639 52.255 20.4149C52.0197 20.2614 51.7258 20.0676 51.59 19.8329C51.4539 19.6026 51.3091 19.4943 51.2186 19.4492Z" fill="white"/>
                      <path d="M50.4902 20.8047L50.4946 20.8227L50.5036 20.8451L50.5127 20.8675L50.5264 20.8899L50.5398 20.9169L50.5579 20.944L50.5717 20.9758L50.5941 21.0072L50.6122 21.0386L50.6303 21.0704L50.6527 21.1018L50.6756 21.1379L50.698 21.174L50.7251 21.2101L50.7747 21.2779L50.8247 21.3501L50.8743 21.4176L50.9014 21.4537L50.9286 21.4855L50.951 21.5173L50.9782 21.5487L51.0006 21.5804L51.0234 21.6075L51.0459 21.6346L51.0687 21.6617L51.0911 21.6844L51.1135 21.7025L51.1316 21.7252L51.1497 21.7386C51.3264 21.9053 51.7698 22.3479 52.0094 22.5688C52.0094 22.5688 51.3354 22.4021 50.9554 22.0591C50.9554 22.0591 50.6882 22.2125 50.4938 22.2125H50.4804C50.2857 22.2125 50.0146 22.0591 50.0146 22.0591C49.6388 22.4021 48.9648 22.5688 48.9648 22.5688C49.2001 22.3479 49.6436 21.9053 49.8245 21.7386C50.0055 21.5761 50.4218 20.9895 50.4852 20.8047H50.4902Z" fill="white"/>
                      <path d="M50.4895 22.3527H50.5032H50.5166L50.53 22.3484H50.5438H50.5571H50.5709L50.598 22.3437L50.6252 22.339L50.648 22.33L50.6752 22.3253L50.6976 22.3163L50.7247 22.3119L50.7472 22.3029L50.7653 22.2939L50.7881 22.2896L50.7971 22.2849L50.8062 22.2802L50.8152 22.2759L50.8243 22.2715L50.8333 22.2668H50.8424L50.8514 22.2621L50.8605 22.2578C50.779 22.4203 50.6299 22.6866 50.4895 22.7902H50.4851C50.3403 22.6866 50.1908 22.4203 50.1094 22.2578C50.1908 22.2939 50.3309 22.3527 50.4804 22.3527H50.4895Z" fill="white"/>
                      <path d="M51.0639 25.4675L53.6973 21.7854L53.9645 21.9792L53.9826 21.1172L53.1862 21.4197L53.4349 21.6002C53.3082 21.7854 51.3401 24.5332 51.091 24.8805L49.9012 22.7236L49.7517 22.9445L48.6929 24.5466L48.4258 24.3704V25.2188L49.2312 24.9029L48.9597 24.7181C49.1092 24.5015 49.679 23.6351 49.8737 23.3463L51.0639 25.4675Z" fill="white"/>
                      <path d="M51.547 24.014L51.4793 23.9147L51.4431 23.8653L51.4116 23.8155L51.3801 23.7703L51.3483 23.7205L51.3168 23.6754L51.2897 23.6303L51.2582 23.5851L51.231 23.5447L51.2173 23.5267L51.2039 23.5039L51.1948 23.4859L51.1814 23.4678L51.1677 23.4498L51.1586 23.4317L51.1452 23.4137L51.1362 23.3999L51.1271 23.3819L51.1134 23.3682L51.1043 23.3548L51.0953 23.3415C51.0319 23.4631 50.8962 23.7068 50.7467 23.9732L50.5566 23.6393L51.0725 22.7188L51.2173 22.9397L51.7465 23.7339L51.547 24.014Z" fill="white"/>
                      <path d="M52.0908 24.2578L52.2765 24.5466L52.5433 24.3704L52.5476 25.2188L51.7422 24.9029L52.009 24.7181C51.9818 24.6776 51.9413 24.6141 51.8866 24.5329L52.0908 24.2578Z" fill="white"/>
                      <path d="M57.3894 18.6493L57.3713 18.6312L57.3489 18.6132L57.3308 18.5998L57.308 18.5864L57.2856 18.5774L57.2627 18.5684L57.2403 18.5637L57.2179 18.559L57.1951 18.5547H57.1679L57.1455 18.559H57.1183L57.0912 18.568L57.064 18.5724L57.0369 18.5814L57.0097 18.5904L56.9779 18.6041L56.9507 18.6175L56.9236 18.6312L56.8921 18.6449L56.8649 18.663L56.8334 18.681L56.8016 18.7034L56.7701 18.7215L56.7429 18.7438L56.7114 18.7662L56.6796 18.7933L56.6481 18.8157L56.6166 18.8427L56.5848 18.8698L56.5533 18.8969L56.5218 18.924C56.1822 19.2308 55.9745 19.5467 56.1732 19.7633C56.3542 19.9575 56.6981 19.7904 57.0372 19.4835C57.3713 19.177 57.5838 18.8612 57.3894 18.6493Z" fill="white"/>
                      <path d="M56.8421 22.4384L56.8464 22.434H56.8511V22.4293H56.8555V22.425H56.8602V22.4207H56.8645L56.8692 22.416H56.8736V22.4113H56.8783C57.0546 22.2535 57.1541 22.0142 57.1541 21.7525C57.1541 21.2471 56.7469 20.8366 56.2354 20.8366C55.7333 20.8366 55.3214 21.2471 55.3214 21.7525C55.3214 21.8203 55.3348 21.9286 55.3348 21.9286C55.2986 21.8521 55.1448 21.5045 55.1133 21.4413C55.249 20.9496 55.7015 20.5883 56.2354 20.5883C56.7016 20.5883 57.0998 20.8547 57.2898 21.2427C57.2174 20.7825 56.9869 20.3674 56.824 20.2139C56.6292 20.0291 56.2991 19.9703 56.2991 19.9703C56.5163 20.002 56.8012 19.871 57.1226 19.5779C57.4256 19.2981 57.5841 19.0544 57.5932 18.8516C57.5751 19.1404 57.5841 19.2981 57.6656 19.5374C57.7789 19.8533 58.0775 20.4399 57.9732 21.1034C57.9146 21.4691 57.6475 22.0106 57.204 22.4524C57.1092 22.5517 57.0727 22.7008 57.0636 22.7773C57.0636 22.7773 56.4077 22.7773 56.2582 22.7907C56.2582 22.7907 56.0997 22.7773 56.1044 22.6777C56.1044 22.5828 56.1949 22.5604 56.2673 22.5604C56.3487 22.56 56.6701 22.5647 56.8421 22.4384Z" fill="white"/>
                      <path d="M52.5439 22.6302L52.44 22.5713L52.3361 22.5081L52.2322 22.4406L52.1327 22.3684L52.0288 22.2919L51.9249 22.2107L51.8254 22.1251L51.7258 22.0349L51.6263 21.9403L51.5267 21.8367L51.4272 21.7331L51.3323 21.6201L51.2375 21.4981L51.1427 21.3761L51.0478 21.245L51.0026 21.1775L50.953 21.1053L50.8672 20.9609C51.0029 21.0194 51.1702 20.979 51.1702 20.979C51.1839 20.9923 51.2017 21.0285 51.2107 21.0422C51.6722 21.7147 52.179 22.1432 52.6948 22.4186L52.5439 22.6302Z" fill="white"/>
                      <path d="M53.2307 22.6562L53.3075 22.6833L53.3889 22.7104L53.4657 22.7331L53.5471 22.7559L53.6242 22.7783L53.7009 22.8007L53.7824 22.8187L53.8591 22.8367L53.9362 22.8548L54.013 22.8685L54.0897 22.8819L54.1664 22.8952L54.3203 22.9223L54.4694 22.945C54.7275 22.9855 54.9809 22.9992 55.2205 22.9902C55.2205 23.0306 55.2205 23.0804 55.2386 23.1208C55.2386 23.1208 55.1981 23.175 55.1981 23.2429C54.9491 23.2476 54.6913 23.2382 54.4289 23.1934C53.9945 23.1256 53.5373 23.04 53.0762 22.8685L53.2307 22.6562Z" fill="white"/>
                      <path d="M56.217 22.8637H56.2261H56.2351H56.2442H56.2532H56.267L56.2804 22.8594H56.2894H56.3032H56.3166H56.3303H56.3484H56.3622H56.3803H56.3937H56.4255L56.4617 22.8637H56.4936L56.5298 22.8684H56.5613L56.5975 22.8731H56.6109H56.629L56.6427 22.8774H56.6608L56.6742 22.8818H56.6876C56.8462 22.9041 57.0271 22.9402 57.0496 23.0261C57.0677 23.1117 57.009 23.202 56.9272 23.3283C56.8776 23.4048 56.7596 23.3644 56.692 23.3373C56.6243 23.3102 56.2485 23.1117 56.1852 23.0442C56.1265 22.981 56.1041 22.8861 56.217 22.8637Z" fill="white"/>
                      <path d="M55.8381 23.1154L55.8428 23.1111V23.1064H55.8472L55.8562 23.1021H55.8609L55.8653 23.0977L55.87 23.0931H55.8743L55.8834 23.0884H55.8881L55.8971 23.084H55.9018L55.9109 23.0797H55.9199H55.9243L55.9333 23.075H55.9424H55.9471L55.9561 23.0703H55.9652H55.9742H55.9833H55.9923H56.0014H56.0104H56.0195L56.0285 23.075H56.0376L56.0466 23.0797H56.0557L56.0647 23.084H56.0738C56.1777 23.1245 56.5491 23.3458 56.5758 23.445C56.5987 23.549 56.5487 23.6032 56.4944 23.6255C56.4311 23.6479 56.363 23.6706 56.3087 23.675C56.2182 23.6797 56.1277 23.6255 56.0825 23.5667C56.0329 23.5082 55.8609 23.3006 55.8381 23.2508C55.811 23.21 55.7976 23.1378 55.8381 23.1154Z" fill="white"/>
                      <path d="M55.7886 23.3397L55.7929 23.3444L55.802 23.3534L55.8067 23.3581L55.811 23.3671L55.8201 23.3762L55.8291 23.3852L55.8338 23.3942L55.8429 23.4032L55.8519 23.4123L55.861 23.4256L55.87 23.4346L55.8791 23.4437L55.8972 23.4661L55.9153 23.4888L55.9334 23.5115L55.9381 23.5206L55.9471 23.5296L55.9562 23.5386L55.9652 23.5476L55.9696 23.5567L55.9786 23.5657L55.9833 23.5747L55.9924 23.5837L55.9967 23.5884L56.0014 23.5974L56.0058 23.6021L56.0101 23.6068V23.6112L56.0148 23.6155C56.0282 23.6426 56.0014 23.692 55.9562 23.6834C55.9062 23.6787 55.6532 23.6516 55.508 23.5527C55.3585 23.4534 55.2637 23.2639 55.3632 23.1917C55.4628 23.1184 55.621 23.1545 55.7886 23.3397Z" fill="white"/>
                      <path d="M55.7069 22.7845H55.7116H55.7206L55.725 22.7802H55.734H55.7387L55.7478 22.7755H55.7568L55.7659 22.7712H55.7749H55.784L55.7977 22.7665H55.8068L55.8202 22.7622H55.8292H55.8383H55.852L55.8611 22.7578H55.8745H55.8835H55.8969H55.906H55.915H55.9241L55.9331 22.7622H55.9422L55.9512 22.7665H55.9603L55.965 22.7712H55.9693H55.974V22.7755H55.9784L55.9831 22.7802L55.9874 22.7845L55.9921 22.7889C56.0417 22.8701 56.0055 22.9513 55.8564 23.0235C55.7069 23.0957 55.422 23.1545 55.3677 23.0776C55.3131 23.0011 55.3902 22.8705 55.7069 22.7845Z" fill="white"/>
                      <path d="M5.625 16.918V23.2129H6.66569V21.3852C6.86913 21.5614 7.09319 21.6671 7.33319 21.6671C7.67707 21.6671 7.95977 21.4866 8.18167 21.1257C8.43071 20.7239 8.55487 20.1308 8.55487 19.3546C8.55487 18.8132 8.45532 18.3258 8.26094 17.9107L8.22691 17.8428L8.19289 17.7865L8.17008 17.7302L8.13606 17.6851L8.10203 17.6288L8.05678 17.5724L8.02276 17.5273L7.98873 17.4822L7.94348 17.4371L7.90946 17.3919L7.86421 17.3468L7.81896 17.3129L7.77371 17.2678L7.72847 17.2338L7.68322 17.1999L7.63797 17.166L7.5815 17.1436L7.53626 17.1097L7.49101 17.0873L7.43454 17.0649L7.37807 17.0421L7.3216 17.0194L7.26513 16.997L7.20866 16.9858L7.15219 16.9746L7.09573 16.9634L7.02804 16.9411H6.97157L6.90388 16.9299H6.84741L6.77936 16.9187H6.71167H5.625V16.918ZM6.60922 17.8092H6.71094C6.97338 17.8092 7.17717 17.9605 7.3216 18.2493C7.46639 18.5381 7.53662 18.9846 7.53662 19.603C7.53662 19.9369 7.49571 20.2008 7.40088 20.3813C7.31038 20.5618 7.19274 20.652 7.0617 20.652C6.93501 20.652 6.83076 20.5665 6.74497 20.4037C6.65918 20.2459 6.60922 20.0181 6.60922 19.7156V17.8092Z" fill="white"/>
                      <path d="M30.1108 16.6094L30.025 16.6949C30.025 16.6949 27.8487 18.9601 27.6859 19.1316V16.9115H26.668V23.2198H27.6859V20.6297C27.713 20.5983 29.0205 19.2172 29.17 19.0594C29.179 19.2894 29.3191 23.2198 29.3191 23.2198H30.3598L30.1108 16.6094Z" fill="white"/>
                      <path d="M25.1469 16.6953L25.1422 16.7043L25.1331 16.7087L25.1241 16.7224L25.1103 16.7357L25.0922 16.7538L25.0741 16.7718L25.0513 16.7946L25.0289 16.8173L25.0017 16.8444L24.9746 16.8762L24.9427 16.9075L24.9112 16.939L24.8794 16.9751L24.8432 17.0111L24.8026 17.0516L24.7212 17.1375L24.6354 17.2278L24.5449 17.3227L24.4501 17.422L24.3505 17.5256L24.2463 17.6292L24.1424 17.7375L24.0381 17.8505L23.8256 18.0714L23.7217 18.1797L23.6179 18.2836L23.5183 18.3872L23.4235 18.4912L23.3286 18.5862L23.2425 18.6764L23.161 18.762L23.1205 18.8024L23.0843 18.8432L23.0481 18.8793L23.0162 18.9154L22.9848 18.9471L22.9576 18.9789L22.9305 19.006L22.9033 19.0331L22.8809 19.0558L22.8628 19.0739L22.8447 19.0919L22.8309 19.11L22.8172 19.119L22.8081 19.1327V16.9126H21.7852V23.2209H22.8077V20.6308C22.8349 20.5994 24.1427 19.2182 24.2919 19.0605C24.3009 19.2904 24.4414 23.2209 24.4414 23.2209H25.4774L25.2283 16.6094L25.1469 16.6953Z" fill="white"/>
                      <path d="M19.2279 21.6567L19.1646 21.5801L19.1103 21.5036L19.056 21.4177L19.0107 21.3321L18.9655 21.2419L18.9246 21.1516L18.8837 21.0571L18.8522 20.9621L18.825 20.8585L18.7979 20.7593L18.7751 20.651L18.757 20.5426L18.7432 20.4297L18.7342 20.3167L18.7295 20.1994L18.7248 20.082C18.7248 19.4189 18.8877 18.8908 19.2044 18.5027C19.553 18.0785 20.0598 17.8666 20.7204 17.8666H20.77V16.7656H20.7204C19.6887 16.7656 18.8967 17.0815 18.3584 17.7175C17.8698 18.2908 17.6211 19.0893 17.6211 20.0911C17.6211 21.0567 17.8926 21.8552 18.4218 22.4555C18.9829 23.0873 19.7568 23.4122 20.7341 23.4122H20.7837V22.3068H20.7341C20.0923 22.3065 19.5856 22.0899 19.2279 21.6567Z" fill="white"/>
                      <path d="M11.042 16.7582C10.4947 16.7582 10.0578 17.0672 9.74104 17.6719C9.43336 18.2675 9.27734 19.0595 9.27734 20.0295C9.27734 21.0312 9.42431 21.8413 9.7186 22.4322C10.0172 23.0369 10.4628 23.3459 11.042 23.3459C11.6349 23.3459 12.103 23.0188 12.4334 22.3871C12.7411 21.7958 12.8971 20.9973 12.8971 20.0179C12.8971 19.0523 12.7386 18.2624 12.4219 17.6715L12.3878 17.6152L12.3538 17.5589L12.331 17.5026L12.2858 17.4574L12.263 17.4123L12.2177 17.3672L12.1837 17.3221L12.1497 17.2769L12.1156 17.2318L12.0704 17.1867L12.0364 17.1528L11.9911 17.1188L11.9571 17.0849L11.9118 17.0509L11.8778 17.017L11.8326 16.9831L11.7873 16.9603L11.7421 16.938L11.6968 16.9156L11.6516 16.8932L11.6063 16.8704L11.5611 16.8477L11.5158 16.8365L11.4594 16.8138L11.4141 16.8026L11.3576 16.7914L11.3124 16.7802H11.2671L11.2107 16.769L11.1542 16.7578H11.0977H11.042V16.7582ZM11.042 17.7733C11.3316 17.7733 11.5444 17.9787 11.6755 18.3938C11.8022 18.7682 11.8565 19.3144 11.8565 20.0183C11.8565 20.6995 11.7931 21.2659 11.653 21.6991L11.6302 21.7442L11.619 21.7781L11.6078 21.812L11.5965 21.846L11.5853 21.8799L11.5629 21.9138L11.5517 21.9478L11.5289 21.9705L11.5176 22.0045L11.4948 22.0272L11.4836 22.0611L11.4724 22.0839L11.4499 22.1063L11.4271 22.1286L11.4159 22.151L11.3935 22.1622L11.3823 22.1846L11.3595 22.207L11.337 22.2182L11.3142 22.2405L11.2914 22.2517L11.2686 22.2629L11.2574 22.2741L11.2346 22.2853H11.2118L11.189 22.2965L11.1665 22.3077H11.1437L11.1209 22.3189H11.0981H11.0753H11.0413C10.7926 22.3189 10.6047 22.107 10.4643 21.6871C10.3195 21.2493 10.2493 20.6876 10.2493 20.0288C10.2493 19.3566 10.3195 18.8014 10.4643 18.3819C10.5964 17.9809 10.7886 17.7733 11.042 17.7733Z" fill="white"/>
                      <path d="M15.3045 21.6567L15.2774 21.6206L15.2455 21.5801L15.1865 21.5036L15.1369 21.4177L15.087 21.3321L15.0417 21.2419L15.0012 21.1516L14.965 21.0571L14.9288 20.9621L14.9016 20.8585L14.8745 20.7593L14.8564 20.651L14.8383 20.5426L14.8249 20.4297L14.8159 20.3167L14.8068 20.1994V20.082C14.8068 19.4189 14.965 18.8908 15.2864 18.5027C15.6303 18.0785 16.1371 17.8666 16.7977 17.8666H16.852V16.7656H16.7977C15.7704 16.7656 14.974 17.0815 14.4405 17.7175C13.9471 18.2908 13.7031 19.0893 13.7031 20.0911C13.7031 21.0567 13.9699 21.8552 14.5038 22.4555C15.0602 23.0873 15.8384 23.4122 16.8162 23.4122H16.8657V22.3068H16.8162C16.1686 22.3065 15.6618 22.0899 15.3045 21.6567Z" fill="white"/>
                      <path d="M5.63672 7.66016V13.9999H6.80881V8.71135H8.25673V13.9999H9.42412V7.66016H5.63672Z" fill="white"/>
                      <path d="M13.0104 7.49647C12.3903 7.49647 11.8926 7.80764 11.5401 8.42168C11.1915 9.02201 11.0195 9.81619 11.0195 10.7905C11.0195 11.7966 11.1824 12.6204 11.5173 13.216C11.8564 13.8253 12.3589 14.1297 13.0104 14.1297C13.6754 14.1297 14.2003 13.8116 14.5713 13.1709C14.9199 12.5752 15.103 11.7699 15.103 10.7905C15.103 9.81583 14.913 9.01695 14.5601 8.42168L14.526 8.36536L14.492 8.30905L14.458 8.25273L14.4127 8.20761L14.3787 8.15129L14.3335 8.10617L14.2994 8.06104L14.2542 8.01592L14.2089 7.9708L14.1749 7.93687L14.1297 7.89174L14.0844 7.85781L14.0392 7.82388L13.9939 7.78995L13.9375 7.75602L13.8922 7.73326L13.847 7.69933L13.8017 7.67659L13.7452 7.65385L13.7 7.63146L13.6435 7.60873L13.5871 7.58599L13.5418 7.5748L13.4853 7.55206L13.4289 7.54086L13.3724 7.52967L13.3159 7.51848L13.2595 7.50729H13.1914H13.1349L13.0785 7.49609H13.0104V7.49647ZM13.022 8.56824C13.3387 8.56824 13.5628 8.77148 13.7119 9.17759C13.8477 9.54761 13.9154 10.0869 13.9154 10.7909C13.9154 11.463 13.8361 12.0251 13.6779 12.4492C13.5241 12.8644 13.3116 13.0582 13.022 13.0582H12.988H12.9652H12.9424H12.9199L12.8971 13.047L12.8743 13.0358H12.8515L12.8287 13.0247L12.8063 13.0135L12.7835 13.0023L12.7607 12.9911L12.7379 12.9799L12.715 12.9571L12.6926 12.946L12.6698 12.9236L12.647 12.9124L12.6358 12.8897L12.613 12.8673L12.5902 12.8445L12.5789 12.8218L12.5561 12.799L12.5333 12.7651L12.5221 12.7424L12.4993 12.7196L12.4881 12.6857L12.4656 12.6517L12.4544 12.629L12.4432 12.5839L12.4204 12.55L12.4092 12.516L12.398 12.4821L12.3751 12.4482C12.217 12.0103 12.1377 11.4554 12.1377 10.801C12.1377 10.1288 12.217 9.58046 12.3751 9.16532C12.5265 8.76462 12.7368 8.56824 13.022 8.56824Z" fill="white"/>
                      <path d="M21.4766 7.66016V8.71135H22.4492V13.9999H23.6213V8.71135H24.6077V7.66016H21.4766Z" fill="white"/>
                      <path d="M18.9115 9.8669H18.2737C18.0113 9.8669 17.8122 9.80842 17.6898 9.6911C17.5994 9.60988 17.5584 9.49689 17.5584 9.3525V7.66016H16.3867V9.4698C16.3867 9.88494 16.554 10.2413 16.8797 10.521C17.2008 10.7965 17.5994 10.9361 18.0562 10.9361H18.9112V13.9999H20.0833V7.66016H18.9112V9.8669H18.9115Z" fill="white"/>
                      <path d="M26.133 7.66479L25.918 14.0048H27.0832C27.0832 14.0048 27.1918 11.0426 27.1961 10.9025H28.6553C28.669 11.0335 28.9944 14.0048 28.9944 14.0048H30.1709L29.6279 9.3683V9.31198V9.25566V9.19935L29.6167 9.14303V9.08671L29.6055 9.04159V8.98527L29.5942 8.94015L29.583 8.88383L29.5718 8.83871L29.5606 8.79359L29.5494 8.74846L29.5381 8.70334L29.5153 8.65822L29.5041 8.61309L29.4929 8.56797L29.4701 8.52284L29.4473 8.48891L29.4245 8.44379L29.4132 8.40986L29.3904 8.36474L29.3676 8.3308L29.3336 8.29687L29.3108 8.25175L29.288 8.22901L29.254 8.18389L29.2312 8.16115L29.1971 8.12722L29.1631 8.09327L29.1291 8.07053L29.1063 8.0366L29.061 8.00267C28.7671 7.77236 28.3686 7.66406 27.8622 7.66406H26.133V7.66479ZM27.253 8.71383H27.6714C27.9744 8.71383 28.1782 8.81091 28.3049 8.99574C28.4091 9.14916 28.479 9.44228 28.5199 9.85309H27.2189V9.84192V9.83073V9.81953V9.80834V9.79715V9.78595V9.77476V9.74083V9.7069V9.67297L27.2301 9.63904V9.59391V9.55998V9.51486V9.46974V9.42461V9.37949V9.27805V9.1878L27.2414 9.14268V9.09755V9.05243V9.00731V8.96218V8.92825V8.88313V8.8492V8.81525L27.2526 8.79251V8.78131V8.77012V8.75895V8.74776V8.73656V8.72537V8.71383H27.253Z" fill="white"/>
                      <rect x="0.5" y="0.5" width="65.25" height="29" stroke="white"/>
                      <line x1="35.5" y1="-2.18557e-08" x2="35.5" y2="30" stroke="white"/>
                    </g>
                  </svg>
                </button>
                <button onClick={() => setPostType(2)} className='button_delivery_SDEK'>
                  <svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_794_1638)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M9.40087 11.8908H6.81362C2.77753 11.8908 5.70975 3.80625 9.10765 3.80625H13.1955C13.8509 3.80625 15.0066 3.92013 15.5758 2.39105L16.4554 0.0161133H10.8497C7.81405 0.0161133 5.451 1.02465 3.74344 2.71638C0.793978 5.61184 -0.206421 10.134 0.50076 12.2975C1.17344 14.2982 3.00176 15.6159 5.86496 15.6484L8.09001 15.6646H10.8325L11.5052 13.7615C12.0054 12.4113 10.8842 11.8908 9.40087 11.8908ZM40.8963 8.53984L41.7413 5.95346H32.7205C31.22 5.95346 30.5473 6.34386 30.2886 7.15717L29.4434 9.74359H38.4642C39.9648 9.74359 40.6375 9.35319 40.8963 8.53984ZM28.098 13.0945L27.2529 15.6809H36.2737C37.7571 15.6809 38.447 15.2905 38.7057 14.4772L39.5509 11.8908H30.5301C29.0467 11.8908 28.374 12.2812 28.098 13.0945ZM43.0005 2.61878L43.8459 0.0323797H34.8249C33.3243 0.0323797 32.6516 0.422779 32.3929 1.23611L31.5477 3.82252H40.5685C42.0521 3.82252 42.7245 3.43212 43.0005 2.61878ZM29.3744 3.13932C28.9949 0.731849 27.6323 0.0323797 24.3206 0.0323797H18.2838L14.7651 9.74359H16.9901C18.3182 9.74359 18.9909 9.75986 19.6981 7.97051L21.1642 3.80625H23.4065C25.3211 3.80625 24.8898 6.06733 23.9239 8.26331C23.0615 10.199 21.5609 11.9071 19.7498 11.9071H16.007C14.5064 11.9071 13.8165 12.2975 13.5405 13.1108L12.609 15.6972H15.3515L18.0423 15.6809C20.4225 15.6646 22.3716 15.502 24.6484 13.5663C27.0631 11.5004 29.8573 6.19744 29.3744 3.13932ZM59.697 0.0161133H54.4708L49.555 4.94491C48.9856 5.51424 48.3992 6.08357 47.8299 6.75053H47.7781L50.2274 0.0161133H45.9674L40.2236 15.6809H44.4839L46.3121 10.7521L48.2093 9.23931L49.7101 13.6964C50.1757 15.079 50.659 15.6809 51.6939 15.6809H54.9537L51.6074 6.92946L59.697 0.0161133Z" fill="#1E1B1B"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_794_1638">
                        <rect width="60" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              :
              postType === 2 ?
              <div className='btn_delivery_group'>
                <button onClick={() => setPostType(1)} className='button_delivery_postRussia_alt'>
                <svg xmlns="http://www.w3.org/2000/svg" width="67" height="30" viewBox="0 0 67 30" fill="none">
                  <g opacity="0.8">
                    <path d="M44.5206 9.75391L44.5612 9.84849L44.6111 9.93874L44.6607 10.0333L44.7197 10.1326L44.7512 10.1777L44.7874 10.2272L44.8193 10.277L44.8555 10.3268L44.896 10.3763L44.9365 10.4261L44.9771 10.4755L45.0223 10.5254L45.0719 10.5795L45.1219 10.6293L45.1718 10.6835L45.2261 10.7376L45.2851 10.7918L45.3438 10.8459L45.4071 10.9L45.4752 10.9585L45.5429 11.0127L45.6153 11.0712L45.6877 11.1297L45.7644 11.1928L45.8505 11.2516L45.932 11.3148L46.0225 11.3733L46.113 11.4365C46.3345 11.5899 46.5883 11.9105 46.3982 12.357C46.2082 12.8082 45.8553 13.44 46.0677 13.6883C46.2806 13.9363 47.0313 14.0627 47.6471 13.819C47.6471 13.819 47.7785 14.2071 48.0047 14.4551C48.0047 14.4551 47.8961 14.667 47.5432 14.5587C47.5432 14.5587 47.6746 14.8792 47.4932 15.2222C47.4932 15.2222 47.0816 15.15 46.9003 14.7709C46.9003 14.7709 46.7374 15.0688 46.4073 15.1724C46.4073 15.1724 46.1358 15.0327 46.1948 14.4912C46.1948 14.4912 45.9957 14.9742 45.3803 15.0554C45.3803 15.0554 45.0274 14.6089 45.4889 14.0085C45.4889 14.0085 45.1541 14.3017 44.5568 14.162C44.5568 14.162 44.4573 13.8551 45.005 13.431C45.005 13.431 44.3896 13.4039 44.1543 12.8895C44.1543 12.8895 44.412 12.5693 45.0502 12.6411C45.0502 12.6411 44.4576 12.5101 44.3262 11.8061C44.3262 11.8061 44.7516 11.5354 45.4303 11.9686C45.4303 11.9686 45.3126 11.743 45.0278 11.5173C44.7422 11.3018 44.3983 10.6744 44.5206 9.75391Z" fill="#1E1B1B"/>
                    <path d="M46.9913 16.8594L46.9732 16.8371L46.9598 16.8147L46.9417 16.7919L46.9284 16.7692L46.9146 16.7468L46.9008 16.7244L46.8875 16.7064L46.8741 16.684L46.8603 16.6613L46.8469 16.6385L46.8379 16.6161L46.8241 16.5937L46.8151 16.5757L46.8013 16.5533L46.7923 16.5306L46.7832 16.5078C46.8918 17.3652 46.928 17.9427 47.3895 18.4979C47.3895 18.4979 47.6748 18.1279 47.4891 17.2255C47.4167 17.2027 47.3262 17.167 47.24 17.1081C47.24 17.1081 47.3486 17.4738 47.2809 17.8435C47.2809 17.8431 47.1133 17.7211 46.9913 16.8594Z" fill="#1E1B1B"/>
                    <path d="M46.1862 16.7726L46.1819 16.7683L46.1775 16.7592L46.1732 16.7502L46.1642 16.7459L46.1598 16.7368L46.1508 16.7278L46.1461 16.7188L46.137 16.7098L46.1323 16.7008L46.1232 16.6874L46.1095 16.6694L46.0961 16.6466L46.078 16.6239L46.0646 16.6015L46.0509 16.5791L46.0328 16.5567L46.0194 16.534L46.006 16.5159L45.9922 16.4936L45.9879 16.4802L45.9832 16.4712L45.9741 16.4621L45.9698 16.4531C45.9292 16.9495 45.7978 18.2581 46.2413 18.6689C46.2413 18.6689 46.5399 18.4476 46.6485 16.539C46.5851 16.6473 46.4718 16.76 46.4175 16.8231C46.4175 16.8231 46.4899 17.4278 46.2727 17.8563C46.272 17.8556 46.0548 17.6884 46.1862 16.7726Z" fill="#1E1B1B"/>
                    <path d="M45.1417 17.7708L45.1736 17.73L45.2051 17.6805L45.2369 17.6264L45.2688 17.5679L45.2959 17.5091L45.3231 17.4459L45.3459 17.3784L45.373 17.3105L45.3958 17.2383L45.4183 17.1661L45.4364 17.0939L45.4545 17.0174L45.4907 16.8683L45.5041 16.7961L45.5222 16.7239L45.5359 16.6517L45.545 16.5842L45.5587 16.5167L45.5678 16.4488L45.5768 16.3857L45.5859 16.3272L45.5949 16.273L45.5993 16.2232L45.604 16.1738L45.6087 16.133L45.613 16.1149V16.0969L45.6174 16.0788V16.0655V16.0517L45.6221 16.0427V16.029V16.0247V16.0156V16.0113V16.007C45.6716 15.9752 45.7259 15.9348 45.7759 15.894C45.7987 15.876 45.8168 15.8622 45.8392 15.8398C45.8392 15.8893 45.8392 15.9391 45.8436 15.9889C45.7893 16.7831 45.7259 17.6044 45.4773 18.0148C45.2329 18.4166 44.7848 18.6242 44.7848 18.6242C44.3642 17.8751 44.8706 16.8734 45.2959 16.1741C45.3502 16.1561 45.4045 16.1333 45.4588 16.1062C45.3046 16.6427 45.0241 17.2788 45.1417 17.7708Z" fill="#1E1B1B"/>
                    <path d="M44.0957 15.474V15.4921V15.5054V15.5235V15.5372L44.1004 15.5506V15.5639V15.5776L44.1048 15.582V15.591V15.5953V15.5996V15.6043L44.1091 15.6134V15.6177V15.622V15.6267L44.1138 15.6314V15.6358V15.6401V15.6448L44.1185 15.6495V15.6538V15.6581V15.6628L44.1229 15.6675C44.1272 15.6765 44.1319 15.6989 44.141 15.7217C43.788 16.0603 43.5256 16.3938 43.0054 17.1158C42.4806 17.8421 41.6523 17.8692 41.6523 17.8692C41.7338 17.2826 41.8923 16.8176 42.3354 16.3213C42.7745 15.8206 43.3446 15.3646 43.7609 15.0625C43.8695 15.1123 44.0096 15.1437 44.1862 15.1213C44.1953 15.1213 44.2134 15.1166 44.2271 15.1166C44.1728 15.1888 44.1366 15.261 44.1185 15.3289C43.8018 15.5094 42.8878 15.974 42.5168 16.7187C42.5164 16.7198 43.0956 16.539 44.0957 15.474Z" fill="#1E1B1B"/>
                    <path d="M43.4398 14.7466L43.476 14.6791C43.4851 14.6611 43.4984 14.643 43.5122 14.625C43.1683 14.756 41.9557 15.2116 41.5619 15.4145C41.1727 15.6177 40.6026 16.078 40.2949 17.0166C40.2949 17.0166 41.1141 17.0887 41.7928 16.5292C42.4668 15.9744 43.2541 15.1936 43.5893 14.9456C43.5574 14.9185 43.5303 14.8871 43.5122 14.86C43.5122 14.86 42.5982 15.6679 41.6299 16.0152C41.6299 16.0152 41.838 15.6451 42.2181 15.3701C42.6844 15.0398 43.0326 14.9315 43.4398 14.7466Z" fill="#1E1B1B"/>
                    <path d="M42.7462 14.1926L42.7505 14.1836L42.7552 14.1702L42.7599 14.1569L42.7643 14.1478L42.7686 14.1345L42.7733 14.1208L42.778 14.1161V14.1114L42.7824 14.1067L42.7867 14.0977C42.3842 14.1832 41.3841 14.3006 40.9363 14.4363C40.4882 14.5717 39.6827 14.9009 39.2031 15.8127C39.2031 15.8127 39.909 16.0293 41.2349 15.4066C42.5652 14.7839 42.9815 14.6529 43.2349 14.5854C43.0311 14.5716 42.8863 14.5041 42.8139 14.459C42.2847 14.6937 41.5788 14.9734 40.8729 14.9915C40.8729 14.9915 41.2212 14.7478 41.5831 14.5944C41.9813 14.4273 42.4157 14.2785 42.7462 14.1926Z" fill="#1E1B1B"/>
                    <path d="M42.7786 13.5313L42.801 13.5446L42.8234 13.5537L42.8459 13.5627L42.873 13.5764L42.8955 13.5807L42.9179 13.5898L42.9404 13.5988L42.9632 13.6035L42.986 13.6125L43.0131 13.6172L43.0359 13.6219L43.0631 13.6266L43.0765 13.6313H43.0899L43.1036 13.636H43.1174H43.1308L43.1489 13.6407H43.1623L43.176 13.6454C43.0855 13.7042 42.9994 13.7807 42.9179 13.88C42.4473 14.0154 41.651 14.1598 40.3345 14.2547C39.0176 14.354 38.3164 14.1103 38.3164 14.1103C38.7732 13.8306 39.5062 13.5461 39.9996 13.4515C40.4973 13.3566 41.7961 13.3703 42.3615 13.2891C42.3706 13.2981 42.443 13.3522 42.5563 13.4201C42.072 13.4652 41.0541 13.5104 40.5245 13.7721C40.5249 13.7706 41.4979 13.9013 42.7786 13.5313Z" fill="#1E1B1B"/>
                    <path d="M42.6796 12.7795L42.693 12.7705L42.7068 12.7658L42.7202 12.7568L42.7336 12.7524L42.7473 12.7434L42.7611 12.7387L42.7745 12.7297L42.7926 12.7207L42.806 12.716L42.8197 12.707L42.8378 12.7023L42.8559 12.6932L42.8693 12.6842L42.8874 12.6799L42.9055 12.6709L42.9236 12.6662C42.2583 12.4676 41.3443 12.1427 39.9691 12.0525C38.9599 11.985 38.2269 12.0435 37.8516 12.1427C37.8516 12.1427 38.7699 12.7882 39.8244 13.0181C40.8741 13.2437 41.7291 13.0994 42.3492 13.1037C42.3944 13.0405 42.4397 12.9773 42.5121 12.9051C41.7837 12.9095 40.5664 12.7246 40.132 12.5802C40.1324 12.581 41.1912 12.3553 42.6796 12.7795Z" fill="#1E1B1B"/>
                    <path d="M42.6796 11.9985V12.0032V12.0079L42.6844 12.0122V12.0212L42.6887 12.0303V12.0436L42.693 12.0573L42.6977 12.0711L42.7068 12.0891L42.7111 12.1028L42.7202 12.1256L42.7292 12.1436L42.7383 12.1617L42.7473 12.1844L42.7611 12.2072L42.7745 12.2299L42.7879 12.2526L42.806 12.275L42.8197 12.3021L42.8378 12.3248L42.8603 12.3476L42.8827 12.3747L42.9055 12.3974L42.928 12.4245L42.9551 12.4472L42.9823 12.4696L43.0138 12.4923L43.0456 12.5194L43.0818 12.5375L43.1133 12.5602L43.1538 12.5826L43.1947 12.6006C42.2579 12.1945 40.4118 11.9599 39.4255 11.5447C38.0861 10.9942 37.8418 10.2993 37.8418 10.2993C39.0001 10.2676 39.9366 10.552 40.6562 10.7733C41.5068 11.0303 42.7919 11.5086 43.2396 11.7119C43.072 11.7166 42.8867 11.7617 42.7329 11.87C42.1041 11.631 41.8098 11.527 41.2354 11.3646C40.7872 11.2382 40.4524 11.2155 40.1719 11.1884C40.1719 11.1884 40.57 11.4502 41.2216 11.6577C41.8699 11.8631 42.1504 11.8992 42.6796 11.9985Z" fill="#1E1B1B"/>
                    <path d="M42.6066 10.9801L42.6113 10.9848L42.6156 10.9938L42.62 11.0029L42.6247 11.0076L42.6294 11.0166L42.6337 11.0256L42.6381 11.0347L42.6471 11.0437L42.6514 11.0527L42.6558 11.0617L42.6648 11.0751L42.6692 11.0841L42.6829 11.1069L42.701 11.1249L42.7144 11.1473L42.7282 11.1653L42.7325 11.179L42.7416 11.1881L42.7459 11.1971L42.755 11.2061L42.7597 11.2151L42.7687 11.2242L42.7734 11.2332L42.7781 11.2422L42.7825 11.2469L42.7868 11.2559L42.7915 11.2606L42.7962 11.2697C41.3165 11.0213 39.6876 9.90692 39.1855 9.21201C38.6834 8.5218 38.8279 7.48828 38.8279 7.48828C39.3165 7.58755 40.3753 8.49906 40.6468 8.77413C40.9226 9.04487 41.7552 9.85278 42.33 10.304L42.5063 10.8184C42.0176 10.426 41.4341 9.98814 41.0674 9.71307C40.7869 9.50117 40.3663 9.20769 39.9543 9.06329C39.9543 9.06329 40.3344 9.55965 40.6468 9.80331C41.0044 10.0878 41.3661 10.3315 41.6647 10.4939C41.9326 10.6372 42.0683 10.7231 42.6066 10.9801Z" fill="#1E1B1B"/>
                    <path d="M46.7796 15.1102L46.7886 15.1012L46.7977 15.0922L46.802 15.0875L46.811 15.0785L46.8201 15.0694L46.8291 15.0651L46.8339 15.0561L46.8429 15.0514L46.8476 15.0424L46.8567 15.0333L46.861 15.0287L46.8701 15.0196L46.8748 15.0149L46.8838 15.0059L46.8885 15.0012L46.8976 14.9922C46.9656 15.0824 47.1915 15.3669 47.3048 15.651C47.3906 15.8903 47.4814 16.2196 47.5267 16.4044C47.5719 16.5986 47.7033 16.9863 47.7891 17.1217C47.7891 17.1217 47.4271 17.1715 47.16 16.8556C46.8976 16.5535 46.8114 16.2466 46.7662 15.9308C46.7343 15.6835 46.7481 15.3406 46.7796 15.1102Z" fill="#1E1B1B"/>
                    <path d="M46.209 15.1562L46.2181 15.1653L46.2224 15.1743L46.2315 15.179L46.2358 15.188L46.2402 15.197L46.2492 15.2014L46.2536 15.2057L46.2579 15.2147L46.2669 15.2191L46.2713 15.2234L46.2756 15.2281L46.2803 15.2371L46.2894 15.2415L46.2937 15.2461H46.2981L46.3028 15.2508L46.3075 15.2552L46.3118 15.2595L46.3162 15.2642H46.3209V15.2689H46.3256L46.3299 15.2732H46.3343V15.2775H46.339V15.2819H46.3437V15.2866L46.398 15.3093L46.4476 15.2956C46.4928 15.2775 46.5337 15.2595 46.5699 15.2461C46.6242 15.3862 46.6423 15.4584 46.6604 15.5848C46.6738 15.7472 46.71 16.0046 46.647 16.2165C46.5703 16.4739 46.4161 16.6273 46.3303 16.7309C46.3303 16.7309 46.095 16.4692 46.0273 16.2573C45.9549 16.0363 45.9325 15.7746 46.0183 15.5082C46.0689 15.3638 46.1457 15.2328 46.209 15.1562Z" fill="#1E1B1B"/>
                    <path d="M45.3984 15.1854L45.4208 15.1807H45.4433L45.4614 15.176L45.4838 15.1716L45.5244 15.1626L45.5653 15.1536L45.6062 15.1402L45.6467 15.1312L45.6829 15.1175L45.7191 15.1041L45.7506 15.0861L45.7825 15.0727L45.8187 15.0547L45.8458 15.041L45.8773 15.0229L45.9044 15.0049L45.9316 14.9868L45.9587 14.9688C45.9906 15.05 46.0358 15.1673 46.004 15.325C45.9635 15.4875 45.8545 15.6727 45.6916 15.799C45.4473 15.9933 45.1258 16.1734 44.7642 16.1149C44.7649 16.1149 44.6835 15.8485 45.3984 15.1854Z" fill="#1E1B1B"/>
                    <path d="M45.1278 14.7344L45.1325 14.7571V14.7799L45.1372 14.7979L45.1415 14.8207L45.1459 14.8387L45.1506 14.8568L45.1553 14.8795L45.1596 14.8976L45.1687 14.9156L45.1734 14.9293L45.1777 14.9474L45.1868 14.9654L45.1915 14.9791L45.2005 14.9928L45.2049 15.0062L45.2096 15.0199L45.2186 15.0333L45.223 15.047L45.2277 15.056L45.2367 15.065L45.2411 15.0784L45.2458 15.0874L45.2505 15.0921L45.2548 15.1012L45.2592 15.1102L45.2639 15.1145L45.2686 15.1192L45.2729 15.1239L45.2773 15.1286V15.1333H45.282V15.138C45.1962 15.2372 44.8928 15.756 44.2594 15.6704C44.2594 15.6704 44.0874 15.3138 44.5084 15.034C44.7929 14.8383 44.933 14.8022 45.1278 14.7344Z" fill="#1E1B1B"/>
                    <path d="M45.1811 14.2859L45.1496 14.2949L45.1181 14.304L45.0819 14.3087L45.0457 14.3134L45.0095 14.3181L44.9733 14.3228H44.9328L44.8923 14.3271H44.8514L44.8061 14.3228H44.7656L44.7431 14.3181H44.7207L44.6935 14.3134H44.6711L44.6486 14.3087L44.6258 14.304L44.6034 14.2993L44.5763 14.2946L44.5538 14.2899L44.5267 14.2809L44.4543 14.2675L44.4315 14.1953C44.1104 14.4617 43.7212 14.5065 43.5898 14.7458C43.5898 14.7458 43.7527 15.0436 44.1734 14.9895C44.5944 14.9397 44.9111 14.7458 45.1373 14.4617C45.1402 14.4213 45.1583 14.3444 45.1811 14.2859Z" fill="#1E1B1B"/>
                    <path d="M44.453 13.9166L44.4621 13.8986L44.4758 13.8805L44.4849 13.8624L44.4939 13.8397L44.5073 13.8217L44.5164 13.7993L44.5298 13.7765L44.5388 13.7538L44.5526 13.731L44.566 13.704L44.575 13.6906L44.5841 13.6816L44.5888 13.6679L44.5978 13.6542L44.6069 13.6408L44.6159 13.6318L44.625 13.6181L44.634 13.6047L44.6478 13.5914L44.6568 13.578L44.6706 13.5643L44.6796 13.5552C44.3991 13.5054 44.1504 13.4964 43.8243 13.5643C43.4352 13.6365 43.037 13.7267 42.8379 14.3133C42.8379 14.3133 43.0866 14.5299 43.5437 14.4354C43.9238 14.3588 44.2587 14.1917 44.453 13.9166Z" fill="#1E1B1B"/>
                    <path d="M44.2863 13.2771L44.2682 13.259L44.2501 13.2457L44.232 13.2276L44.2139 13.2096L44.2001 13.1915L44.182 13.1688L44.1639 13.1507L44.1505 13.1327L44.1324 13.1099L44.119 13.0872L44.1009 13.0645L44.0872 13.0417L44.0734 13.0146L44.06 12.9919L44.0466 12.9648L44.0329 12.9377L44.0014 12.8655C43.6666 12.7302 43.3136 12.658 42.9697 12.7843C42.6259 12.915 42.5354 13.0641 42.4492 13.1904C42.4492 13.1904 42.8293 13.5106 43.2684 13.5244C43.7071 13.5341 44.1096 13.4078 44.2863 13.2771Z" fill="#1E1B1B"/>
                    <path d="M44.3907 12.5865L44.3997 12.5818L44.4131 12.5775L44.4222 12.5728H44.4355L44.4446 12.5685L44.4584 12.5638L44.4674 12.5591L44.4812 12.5544H44.4949L44.5083 12.5497L44.5217 12.545H44.5308L44.5441 12.5403L44.5579 12.5356H44.5717L44.585 12.5309C44.4403 12.4003 44.2911 12.2017 44.2184 11.8992C44.0645 11.9353 43.6935 11.9353 43.5353 11.8764C43.3724 11.8133 43.0557 11.8086 42.8066 11.9757C42.8066 11.9757 42.8881 12.4046 43.4492 12.5533C43.9791 12.6948 44.1735 12.6587 44.3907 12.5865Z" fill="#1E1B1B"/>
                    <path d="M44.1862 11.7397L44.2586 11.6899C44.2676 11.6855 44.3943 11.6086 44.6115 11.5949C44.4305 11.4552 43.9961 11.0942 43.6928 10.9271C43.3627 10.7423 42.8106 10.4896 42.5254 10.4531C42.5254 10.4531 42.7925 11.1433 43.0955 11.4235C43.417 11.7126 43.6385 11.8209 44.1862 11.7397Z" fill="#1E1B1B"/>
                    <path d="M44.7535 11.4235L44.7311 11.3917L44.7086 11.3556L44.6815 11.3195L44.6587 11.2834L44.6406 11.2427L44.6181 11.2022L44.5957 11.1614L44.5776 11.1163L44.5595 11.0712L44.537 11.026L44.5189 10.9809L44.5052 10.9311L44.4871 10.8817L44.469 10.8318L44.4556 10.7777L44.4422 10.7279C44.3741 10.4932 44.2294 9.92468 44.1436 9.77126C44.0578 9.62218 43.7454 9.10812 43.1391 8.94531C43.1391 8.94531 42.9805 9.61747 43.2839 10.159C43.5456 10.6203 44.0838 11.1076 44.7535 11.4235Z" fill="#1E1B1B"/>
                    <path d="M48.1233 14.5718L48.1414 14.5855L48.1548 14.5989L48.1686 14.6126L48.182 14.6216L48.1957 14.6353L48.2091 14.6443L48.2229 14.6581L48.2363 14.6671L48.25 14.6761L48.2681 14.6898L48.2772 14.6945L48.2862 14.6992L48.2953 14.7039L48.3043 14.7129L48.3134 14.7176L48.3224 14.7223L48.3362 14.7266L48.3452 14.7357L48.3543 14.7404L48.3677 14.7451L48.3811 14.7541L48.3901 14.7588C48.3901 14.7588 48.5849 15.1288 48.3496 15.571C48.1143 16.0176 47.9195 16.4735 47.8247 16.7262C47.8247 16.7262 47.698 16.7081 47.5579 16.0089C47.4631 15.5396 47.3454 15.4176 47.2188 15.2598C47.3545 15.3274 47.4631 15.3454 47.4768 15.3454L47.5673 15.3681L47.6126 15.2779C47.694 15.1198 47.7165 14.9664 47.7165 14.84C47.7165 14.7949 47.7165 14.7544 47.7118 14.718C47.929 14.727 48.0466 14.6144 48.0919 14.5602C48.0962 14.5512 48.1009 14.5512 48.1009 14.5469L48.1233 14.5718Z" fill="#1E1B1B"/>
                    <path d="M42.9652 9.22787V9.23691V9.25063V9.26434V9.27769V9.29142V9.30514V9.32788V9.35496V9.38202V9.41379V9.44087L42.9786 9.60764C42.6166 9.15171 42.1869 8.60157 41.7797 8.0915C41.4992 7.74422 41.2639 7.53665 40.9967 7.36482C41.223 8.00991 41.6212 8.52467 41.9017 8.77736C42.1822 9.03439 42.5894 9.46758 42.9876 9.73399L43.0238 9.86033C43.0553 9.98235 43.1006 10.104 43.1639 10.2213C43.2635 10.3838 43.3811 10.5419 43.5259 10.6996C43.4126 10.6412 43.2772 10.578 43.1458 10.5238L43.0191 10.4697C41.9285 9.89679 40.8425 8.69651 40.4085 7.86623C39.9698 7.03126 40.1504 6.06598 40.2637 5.54688C40.7615 5.96671 41.4811 6.79229 41.7888 7.23919C42.1735 7.7897 42.6622 8.57486 42.9652 9.21128V9.22787Z" fill="#1E1B1B"/>
                    <path d="M58.2908 12.7795L58.2775 12.7705L58.2684 12.7658L58.255 12.7568L58.2413 12.7524L58.2279 12.7434L58.2098 12.7387L58.196 12.7297L58.1826 12.7207L58.1689 12.716L58.1508 12.707L58.1374 12.7023L58.1193 12.6932L58.1012 12.6842L58.0831 12.6799L58.065 12.6709L58.0469 12.6662C58.7165 12.4676 59.6305 12.1427 61.0108 12.0525C62.0196 11.985 62.7436 12.0435 63.1237 12.1427C63.1237 12.1427 62.2006 12.7882 61.1509 13.0181C60.1011 13.2437 59.2458 13.0994 58.6213 13.1037C58.5808 13.0405 58.5355 12.9773 58.4632 12.9051C59.1915 12.9095 60.4041 12.7246 60.8432 12.5802C60.8428 12.581 59.784 12.3553 58.2908 12.7795Z" fill="#1E1B1B"/>
                    <path d="M58.2936 11.9985V12.0032V12.0079L58.2889 12.0122V12.0212L58.2845 12.0303L58.2798 12.0436V12.0573L58.2708 12.0711L58.2664 12.0891L58.2617 12.1028L58.2527 12.1256L58.2436 12.1436L58.2346 12.1617L58.2208 12.1844L58.2118 12.2072L58.1984 12.2299L58.1846 12.2526L58.1665 12.275L58.1484 12.3021L58.1303 12.3248L58.1122 12.3476L58.0894 12.3747L58.067 12.3974L58.0398 12.4245L58.0127 12.4472L57.9855 12.4696L57.9584 12.4923L57.9222 12.5194L57.8907 12.5375L57.8545 12.5602L57.8183 12.5826L57.7777 12.6006C58.7142 12.1945 60.5559 11.9599 61.5514 11.5447C62.8864 10.9942 63.1307 10.2993 63.1307 10.2993C61.9724 10.2676 61.0312 10.552 60.312 10.7733C59.4657 11.0303 58.181 11.5086 57.7285 11.7119C57.9005 11.7166 58.0862 11.7617 58.2396 11.87C58.8688 11.631 59.1627 11.527 59.7328 11.3646C60.1853 11.2382 60.5154 11.2155 60.7963 11.1884C60.7963 11.1884 60.4025 11.4502 59.7509 11.6577C59.0986 11.8631 58.8181 11.8992 58.2936 11.9985Z" fill="#1E1B1B"/>
                    <path d="M58.3663 10.9798V10.9845L58.3616 10.9935L58.3568 11.0025L58.3525 11.0072L58.3435 11.0162L58.3391 11.0252L58.3344 11.0343L58.3301 11.0433L58.321 11.0523L58.3163 11.0614L58.3029 11.0841L58.2892 11.1068L58.2711 11.1249L58.2577 11.1473L58.2439 11.1653L58.2396 11.179L58.2305 11.188L58.2262 11.1971L58.2171 11.2061L58.2124 11.2151L58.2034 11.2242L58.199 11.2332L58.1943 11.2422L58.19 11.2469L58.1853 11.2559L58.1762 11.2606L58.1719 11.2696C59.6513 11.0213 61.2802 9.9069 61.7826 9.21199C62.2894 8.52178 62.1446 7.48828 62.1446 7.48828C61.6559 7.58755 60.5972 8.49906 60.3213 8.77413C60.0498 9.04487 59.2173 9.85276 58.6428 10.304L58.4662 10.8184C58.9548 10.426 59.5387 9.98812 59.9003 9.71304C60.1852 9.50114 60.5971 9.20766 61.0181 9.06327C61.0181 9.06327 60.6333 9.55962 60.3257 9.80329C59.968 10.0878 59.6017 10.3314 59.3031 10.4939C59.0403 10.6368 58.9002 10.7227 58.3663 10.9798Z" fill="#1E1B1B"/>
                    <path d="M58.0091 9.22825V9.25101V9.27807V9.30514V9.32788V9.35496V9.38204V9.41381V9.44089L57.9953 9.60766C58.3529 9.15173 58.7826 8.60158 59.1898 8.0915C59.4747 7.74422 59.71 7.53666 59.9724 7.36482C59.7505 8.00991 59.3524 8.52469 59.0718 8.77739C58.7913 9.03441 58.3841 9.46759 57.9859 9.734L57.9497 9.86036C57.9135 9.98237 57.8682 10.104 57.8049 10.2213C57.7101 10.3838 57.5877 10.5419 57.4473 10.6997C57.5606 10.6412 57.6916 10.578 57.8273 10.5239L57.9497 10.4697C59.0403 9.89681 60.1263 8.69653 60.5603 7.86625C61.0084 7.03128 60.8227 6.06598 60.7051 5.54688C60.2074 5.96671 59.4924 6.79231 59.1848 7.23921C58.8003 7.78972 58.3117 8.57486 58.0083 9.21128V9.22825H58.0091Z" fill="#1E1B1B"/>
                    <path d="M44.3623 15.8085L44.3171 15.8536L44.2718 15.8987L44.2219 15.9485L44.1723 16.0027L44.118 16.0568L44.0637 16.1153L44.0094 16.1785L43.9551 16.2417L43.8964 16.3095L43.8378 16.377L43.7835 16.4492L43.7249 16.5214L43.6706 16.598L43.6119 16.6792L43.5576 16.7604L43.5033 16.8416L43.4537 16.9275L43.4041 17.0131L43.3542 17.099L43.3089 17.1893L43.2637 17.2795L43.2275 17.3745L43.1913 17.4647L43.1551 17.564L43.128 17.6585L43.1008 17.7535L43.0827 17.8527L43.0646 17.952L43.0556 18.0513L43.0512 18.1549L43.0469 18.2542L43.0559 18.3581C43.0559 18.3581 43.9337 18.0827 44.3 17.4376C44.662 16.7969 44.8839 16.3861 45.0377 16.2417C44.9382 16.2554 44.8387 16.2554 44.7482 16.2417L44.6667 16.2283C44.4405 16.6344 43.9971 17.3023 43.5989 17.5326C43.5989 17.5326 43.4993 16.9911 44.6714 15.8991C44.6895 15.854 44.712 15.8045 44.7438 15.75C44.6352 15.7904 44.5176 15.8085 44.3771 15.8041" fill="#1E1B1B"/>
                    <path d="M56.4544 9.75391L56.4092 9.84849L56.3592 9.93874L56.3096 10.0333L56.251 10.1326L56.2195 10.1777L56.1876 10.2272L56.1514 10.277L56.1152 10.3268L56.079 10.3763L56.0381 10.4261L55.9929 10.4755L55.9476 10.5254L55.9024 10.5795L55.8528 10.6293L55.8028 10.6835L55.7486 10.7376L55.6895 10.7918L55.6309 10.8459L55.5676 10.9L55.4999 10.9585L55.4322 11.0127L55.3598 11.0712L55.2874 11.1297L55.2106 11.1928L55.1292 11.2516L55.0434 11.3148L54.9529 11.3733L54.8624 11.4365C54.6362 11.5899 54.3871 11.9105 54.5772 12.357C54.7672 12.8082 55.1202 13.44 54.9073 13.6883C54.6948 13.9363 53.939 14.0627 53.3236 13.819C53.3236 13.819 53.1926 14.2071 52.9707 14.4551C52.9707 14.4551 53.0793 14.667 53.4322 14.5587C53.4322 14.5587 53.3008 14.8792 53.4818 15.2222C53.4818 15.2222 53.8938 15.15 54.07 14.7709C54.07 14.7709 54.2329 15.0688 54.5631 15.1724C54.5631 15.1724 54.8389 15.0327 54.7759 14.4912C54.7759 14.4912 54.975 14.9742 55.5947 15.0554C55.5947 15.0554 55.9476 14.6089 55.4861 14.0085C55.4861 14.0085 55.8162 14.3017 56.4182 14.162C56.4182 14.162 56.5131 13.8551 55.9701 13.431C55.9701 13.431 56.5855 13.4039 56.8207 12.8895C56.8207 12.8895 56.5583 12.5693 55.9205 12.6411C55.9205 12.6411 56.5134 12.5101 56.6445 11.8061C56.6445 11.8061 56.2191 11.5354 55.5448 11.9686C55.5448 11.9686 55.6624 11.743 55.9476 11.5173C56.2282 11.3018 56.5721 10.6744 56.4544 9.75391Z" fill="#1E1B1B"/>
                    <path d="M53.9832 16.8594L54.0013 16.8371L54.015 16.8147L54.0284 16.7919L54.0465 16.7692L54.0603 16.7468L54.0737 16.7244L54.0871 16.7064L54.1008 16.684L54.1142 16.6613L54.1233 16.6385L54.137 16.6161L54.1461 16.5937L54.1595 16.5757L54.1685 16.5533L54.1776 16.5306L54.1866 16.5078C54.0827 17.3652 54.0462 17.9427 53.5803 18.4979C53.5803 18.4979 53.2998 18.1279 53.4808 17.2255C53.5622 17.2027 53.6436 17.167 53.7298 17.1081C53.7298 17.1081 53.6259 17.4738 53.6889 17.8435C53.6893 17.8431 53.8565 17.7211 53.9832 16.8594Z" fill="#1E1B1B"/>
                    <path d="M54.7833 16.7726L54.7924 16.7683L54.7971 16.7592L54.8014 16.7502L54.8062 16.7459L54.8152 16.7368L54.8195 16.7278L54.8286 16.7188L54.8333 16.7098L54.8424 16.7008L54.8467 16.6874L54.8648 16.6694L54.8782 16.6466L54.8919 16.6239L54.91 16.6015L54.9234 16.5791L54.9372 16.5567L54.9506 16.534L54.9643 16.5159L54.9777 16.4936L54.9868 16.4802L54.9911 16.4712L54.9958 16.4621L55.0005 16.4531C55.0411 16.9495 55.1725 18.2581 54.7334 18.6689C54.7334 18.6689 54.4304 18.4476 54.3262 16.539C54.3895 16.6473 54.5028 16.76 54.5524 16.8231C54.5524 16.8231 54.48 17.4278 54.6972 17.8563C54.6972 17.8556 54.9191 17.6884 54.7833 16.7726Z" fill="#1E1B1B"/>
                    <path d="M55.8344 17.7708L55.7982 17.73L55.7667 17.6805L55.7349 17.6264L55.7077 17.5679L55.6762 17.5091L55.6538 17.4459L55.6266 17.3784L55.6038 17.3105L55.5814 17.2383L55.5586 17.1661L55.5405 17.0939L55.5177 17.0174L55.4862 16.8683L55.4681 16.7961L55.4543 16.7239L55.4409 16.6517L55.4319 16.5842L55.4185 16.5167L55.4094 16.4488L55.4004 16.3857L55.3913 16.3272L55.3823 16.273L55.3776 16.2232L55.3732 16.1738L55.3685 16.133L55.3642 16.1149V16.0969L55.3595 16.0788V16.0655V16.0517L55.3551 16.0427V16.029V16.0247V16.0156V16.0113V16.007C55.3008 15.9752 55.2513 15.9348 55.2013 15.894C55.1785 15.876 55.1561 15.8622 55.138 15.8398C55.138 15.8893 55.138 15.9391 55.1289 15.9889C55.1875 16.7831 55.2513 17.6044 55.4999 18.0148C55.7443 18.4166 56.1924 18.6242 56.1924 18.6242C56.613 17.8751 56.1066 16.8734 55.6766 16.1741C55.6223 16.1561 55.5727 16.1333 55.518 16.1062C55.6668 16.6427 55.9474 17.2788 55.8344 17.7708Z" fill="#1E1B1B"/>
                    <path d="M56.8791 15.474V15.483V15.4921V15.5054V15.5235V15.5372L56.8744 15.5506V15.5639V15.5686V15.5776L56.87 15.582V15.591V15.5953V15.5996L56.8653 15.6043V15.6134V15.6177V15.622L56.861 15.6267V15.6314V15.6358V15.6401L56.8563 15.6448V15.6495V15.6538V15.6581V15.6628L56.8519 15.6675C56.8472 15.6765 56.8429 15.6989 56.8338 15.7217C57.1868 16.0603 57.4492 16.3938 57.9694 17.1158C58.4895 17.8421 59.3224 17.8692 59.3224 17.8692C59.241 17.2826 59.0781 16.8176 58.6347 16.3213C58.1956 15.8206 57.6258 15.3646 57.2139 15.0625C57.1053 15.1123 56.9652 15.1437 56.7886 15.1213C56.7705 15.1213 56.7614 15.1166 56.748 15.1166C56.8023 15.1888 56.8385 15.261 56.8566 15.3289C57.1687 15.5094 58.0874 15.974 58.4584 16.7187C58.4584 16.7198 57.8792 16.539 56.8791 15.474Z" fill="#1E1B1B"/>
                    <path d="M57.5347 14.7466L57.4985 14.6791C57.4895 14.6611 57.4761 14.643 57.4623 14.625C57.8018 14.756 59.0141 15.2116 59.4079 15.4145C59.8018 15.6177 60.3672 16.078 60.6749 17.0166C60.6749 17.0166 59.8557 17.0887 59.1817 16.5292C58.5073 15.9744 57.7204 15.1936 57.3809 14.9456C57.4171 14.9185 57.4442 14.8871 57.4623 14.86C57.4623 14.86 58.3763 15.6679 59.3446 16.0152C59.3446 16.0152 59.1364 15.6451 58.7517 15.3701C58.2901 15.0398 57.9416 14.9315 57.5347 14.7466Z" fill="#1E1B1B"/>
                    <path d="M58.227 14.1919L58.2222 14.1829L58.2179 14.1695L58.2132 14.1561L58.2041 14.1471L58.1998 14.1338L58.1951 14.12L58.1908 14.111L58.1861 14.0977C58.5842 14.1832 59.5887 14.3005 60.0365 14.4363C60.4799 14.5716 61.29 14.9009 61.7697 15.8127C61.7697 15.8127 61.0638 16.0293 59.7335 15.4066C58.4079 14.7839 57.9917 14.6529 57.7383 14.5854C57.9417 14.5716 58.0869 14.5041 58.1589 14.459C58.6885 14.6937 59.394 14.9734 60.1002 14.9915C60.1002 14.9915 59.7516 14.7478 59.3853 14.5944C58.9918 14.4265 58.5574 14.2778 58.227 14.1919Z" fill="#1E1B1B"/>
                    <path d="M58.1955 13.5313L58.173 13.5446L58.1502 13.5537L58.1231 13.5627L58.1003 13.5764L58.0778 13.5807L58.055 13.5898L58.0326 13.5988L58.0102 13.6035L57.9874 13.6125L57.9602 13.6172L57.9378 13.6219L57.9106 13.6266L57.8835 13.6313L57.8701 13.636H57.8563H57.8382L57.8248 13.6407H57.8111L57.793 13.6454C57.8835 13.7042 57.974 13.7807 58.0554 13.88C58.5216 14.0154 59.318 14.1598 60.6345 14.2547C61.9511 14.354 62.6526 14.1103 62.6526 14.1103C62.1954 13.8306 61.4627 13.5461 60.9694 13.4515C60.4763 13.3566 59.1775 13.3703 58.6074 13.2891C58.5984 13.2981 58.535 13.3522 58.4174 13.4201C58.897 13.4652 59.9196 13.5104 60.4445 13.7721C60.4441 13.7706 59.4758 13.9013 58.1955 13.5313Z" fill="#1E1B1B"/>
                    <path d="M54.1904 15.1102L54.1766 15.0922L54.1676 15.0875L54.1585 15.0785L54.1495 15.0694L54.1452 15.0651L54.1361 15.0561L54.1314 15.0514L54.1223 15.0424L54.118 15.0333L54.109 15.0287L54.1042 15.0196L54.0952 15.0149L54.0905 15.0059L54.0814 15.0012L54.0771 14.9922C54.0047 15.0824 53.7785 15.3669 53.6652 15.651C53.5837 15.8903 53.4885 16.2196 53.448 16.4044C53.3984 16.5986 53.267 16.9863 53.1855 17.1217C53.1855 17.1217 53.5432 17.1715 53.81 16.8556C54.0724 16.5535 54.1676 16.2466 54.2038 15.9308C54.2356 15.6835 54.2266 15.3406 54.1904 15.1102Z" fill="#1E1B1B"/>
                    <path d="M54.7593 15.1562L54.7549 15.1653L54.7459 15.1743L54.7412 15.179L54.7368 15.188L54.7278 15.197L54.7231 15.2014L54.7187 15.2057L54.7097 15.2147L54.705 15.2191L54.7006 15.2234L54.6959 15.2281L54.6869 15.2371L54.6825 15.2415L54.6778 15.2461H54.6735L54.6688 15.2508L54.6644 15.2552L54.6601 15.2595L54.6554 15.2642H54.6507L54.6463 15.2689H54.642L54.6373 15.2732L54.6329 15.2775H54.6282V15.2819H54.6239H54.6192V15.2866L54.5739 15.3093L54.5196 15.2956C54.4787 15.2775 54.4382 15.2595 54.3973 15.2461C54.3473 15.3862 54.3249 15.4584 54.3111 15.5848C54.293 15.7472 54.2615 16.0046 54.3249 16.2165C54.4016 16.4739 54.5511 16.6273 54.6369 16.7309C54.6369 16.7309 54.8766 16.4692 54.9399 16.2573C55.0123 16.0363 55.0395 15.7746 54.949 15.5082C54.9041 15.3638 54.8273 15.2328 54.7593 15.1562Z" fill="#1E1B1B"/>
                    <path d="M55.5753 15.1854L55.5528 15.1807H55.5304L55.5076 15.176L55.4851 15.1716L55.4442 15.1626L55.4037 15.1536L55.3631 15.1402L55.3269 15.1312L55.2907 15.1175L55.2545 15.1041L55.2183 15.0861L55.1868 15.0727L55.1554 15.0547L55.1235 15.041L55.0964 15.0229L55.0645 15.0049L55.0374 14.9868L55.0149 14.9688C54.9834 15.05 54.9335 15.1673 54.9697 15.325C55.0059 15.4875 55.1192 15.6727 55.2817 15.799C55.5217 15.9933 55.8471 16.1734 56.2047 16.1149C56.2044 16.1149 56.2902 15.8485 55.5753 15.1854Z" fill="#1E1B1B"/>
                    <path d="M55.8472 14.7344L55.8429 14.7571L55.8382 14.7799L55.8338 14.7979V14.8207L55.8291 14.8387L55.8248 14.8568L55.8157 14.8795L55.811 14.8976L55.8067 14.9156L55.802 14.9293L55.7929 14.9474L55.7886 14.9654L55.7839 14.9791L55.7748 14.9928L55.7701 15.0062L55.761 15.0199L55.7567 15.0333L55.752 15.047L55.743 15.056L55.7386 15.065L55.7339 15.0784L55.7296 15.0874L55.7205 15.0921L55.7158 15.1012L55.7115 15.1102L55.7068 15.1145V15.1192L55.7024 15.1239L55.6977 15.1286V15.1333H55.6934V15.138C55.7748 15.2372 56.0825 15.756 56.716 15.6704C56.716 15.6704 56.8836 15.3138 56.4626 15.034C56.1777 14.8383 56.0419 14.8022 55.8472 14.7344Z" fill="#1E1B1B"/>
                    <path d="M55.7891 14.2859L55.8206 14.2949L55.8568 14.304L55.8929 14.3087L55.9292 14.3134L55.9653 14.3181L56.0015 14.3228H56.0421L56.0826 14.3271H56.1232L56.1684 14.3228H56.2093L56.2546 14.3181L56.2774 14.3134H56.3045L56.327 14.3087L56.3494 14.304L56.3722 14.2993L56.3994 14.2946L56.4218 14.2899L56.4446 14.2809L56.517 14.2675L56.5442 14.1953C56.8652 14.4617 57.2544 14.5065 57.3858 14.7458C57.3858 14.7458 57.2229 15.0436 56.8019 14.9895C56.3813 14.9397 56.0645 14.7458 55.8383 14.4617C55.8296 14.4213 55.8115 14.3444 55.7891 14.2859Z" fill="#1E1B1B"/>
                    <path d="M56.5212 13.9166L56.5165 13.9076L56.5121 13.8986L56.4984 13.8805L56.4893 13.8624L56.4803 13.8397L56.4669 13.8217L56.4578 13.7993L56.4444 13.7765L56.4307 13.7538L56.4216 13.731L56.4082 13.704L56.3992 13.6906L56.3901 13.6816L56.3854 13.6679L56.3764 13.6542L56.3673 13.6408L56.3583 13.6318L56.3492 13.6181L56.3402 13.6047L56.3264 13.5914L56.3174 13.578L56.304 13.5643L56.2949 13.5552C56.5707 13.5054 56.8241 13.4964 57.1499 13.5643C57.539 13.6365 57.9372 13.7267 58.1363 14.3133C58.1363 14.3133 57.8829 14.5299 57.4304 14.4354C57.0504 14.3588 56.7112 14.1917 56.5212 13.9166Z" fill="#1E1B1B"/>
                    <path d="M56.6855 13.2771L56.7036 13.259L56.7217 13.2457L56.7398 13.2276L56.7579 13.2096L56.776 13.1915L56.7941 13.1688L56.8079 13.1507L56.826 13.1327L56.8394 13.1099L56.8575 13.0872L56.8712 13.0645L56.8846 13.0417L56.8984 13.0146L56.9118 12.9919L56.9252 12.9648L56.9389 12.9377L56.9704 12.8655C57.3096 12.7302 57.6582 12.658 58.0021 12.7843C58.3459 12.915 58.4364 13.0641 58.5222 13.1904C58.5222 13.1904 58.1421 13.5106 57.7078 13.5244C57.2691 13.5341 56.8618 13.4078 56.6855 13.2771Z" fill="#1E1B1B"/>
                    <path d="M56.5835 12.5865L56.5611 12.5775L56.5477 12.5728H56.5386L56.5249 12.5685L56.5158 12.5638L56.5024 12.5591L56.4887 12.5544H56.4753L56.4662 12.5497L56.4528 12.545H56.4391L56.4257 12.5403L56.4119 12.5356H56.3985L56.3848 12.5309C56.5386 12.4003 56.6787 12.2017 56.7515 11.8992C56.9143 11.9353 57.2763 11.9353 57.4392 11.8764C57.6021 11.8133 57.9189 11.8086 58.1675 11.9757C58.1675 11.9757 58.0861 12.4046 57.5207 12.5533C56.9954 12.6948 56.7963 12.6587 56.5835 12.5865Z" fill="#1E1B1B"/>
                    <path d="M56.7843 11.7397L56.7119 11.6899C56.7028 11.6855 56.5761 11.6086 56.3633 11.5949C56.5443 11.4552 56.9743 11.0942 57.2773 10.9271C57.6121 10.7423 58.1641 10.4896 58.4447 10.4531C58.4447 10.4531 58.1822 11.1433 57.8745 11.4235C57.5578 11.7126 57.3363 11.8209 56.7843 11.7397Z" fill="#1E1B1B"/>
                    <path d="M56.2168 11.4235L56.2439 11.3917L56.2668 11.3556L56.2892 11.3195L56.312 11.2834L56.3344 11.2427L56.3569 11.2022L56.3797 11.1614L56.3978 11.1163L56.4159 11.0712L56.4383 11.026L56.4564 10.9809L56.4745 10.9311L56.4879 10.8817L56.506 10.8318L56.5198 10.7777L56.5379 10.7279C56.5965 10.4932 56.746 9.92468 56.8318 9.77126C56.9176 9.62218 57.23 9.10812 57.8363 8.94531C57.8363 8.94531 57.9949 9.61747 57.6915 10.159C57.4251 10.6203 56.8865 11.1076 56.2168 11.4235Z" fill="#1E1B1B"/>
                    <path d="M52.8525 14.5718L52.8344 14.5855L52.8207 14.5989L52.8073 14.6126L52.7935 14.6216L52.7802 14.6353L52.7664 14.6443L52.753 14.6581L52.7396 14.6671L52.7215 14.6761L52.7034 14.6898L52.6987 14.6945L52.6897 14.6992L52.6806 14.7039L52.6716 14.7129L52.6582 14.7176L52.6491 14.7223L52.6401 14.7266L52.631 14.7357L52.6176 14.7404L52.6086 14.7451L52.5948 14.7541L52.5814 14.7588C52.5814 14.7588 52.3914 15.1288 52.622 15.571C52.8573 16.0176 53.0563 16.4735 53.1512 16.7262C53.1512 16.7262 53.2779 16.7081 53.4183 16.0089C53.5131 15.5396 53.6355 15.4176 53.7578 15.2598C53.6174 15.3274 53.5088 15.3454 53.4998 15.3454L53.4093 15.3681L53.364 15.2779C53.2782 15.1198 53.2554 14.9664 53.2554 14.84C53.2554 14.7949 53.2601 14.7544 53.2645 14.718C53.0473 14.727 52.9253 14.6144 52.8844 14.5602C52.8797 14.5512 52.8797 14.5512 52.8706 14.5469L52.8525 14.5718Z" fill="#1E1B1B"/>
                    <path d="M56.6122 15.8085L56.6575 15.8536L56.7027 15.8987L56.7523 15.9485L56.8019 16.0027L56.8562 16.0568L56.9058 16.1153L56.9648 16.1785L57.0191 16.2417L57.0781 16.3095L57.1324 16.377L57.1914 16.4492L57.25 16.5214L57.3043 16.598L57.3586 16.6792L57.4176 16.7604L57.4672 16.8416L57.5215 16.9275L57.5711 17.0131L57.6207 17.099L57.666 17.1893L57.7065 17.2795L57.7474 17.3745L57.7836 17.4647L57.8151 17.564L57.8466 17.6585L57.8694 17.7535L57.8918 17.8527L57.9099 17.952L57.919 18.0513L57.9233 18.1549V18.2542L57.919 18.3581C57.919 18.3581 57.0365 18.0827 56.6749 17.4376C56.3129 16.7969 56.091 16.3861 55.9375 16.2417C56.0323 16.2554 56.1319 16.2554 56.2271 16.2417L56.3085 16.2283C56.5348 16.6344 56.9739 17.3023 57.372 17.5326C57.372 17.5326 57.4759 16.9911 56.3042 15.8991C56.2861 15.854 56.2633 15.8045 56.2274 15.75C56.336 15.7904 56.458 15.8085 56.5985 15.8041" fill="#1E1B1B"/>
                    <path d="M49.7969 7.08876V7.07973L49.8012 7.07069L49.8059 7.066L49.8103 7.05699L49.8193 7.05229L49.8284 7.04326L49.8374 7.03857L49.8508 7.02956L49.8646 7.02486L49.878 7.01583L49.8961 7.01114L49.9142 7.00644L49.9323 6.99741L49.9547 6.99272L49.9775 6.9884L49.9999 6.9837L50.0227 6.97901L50.0452 6.97467L50.0723 6.96998L50.0995 6.96528L50.1266 6.96059L50.1581 6.95627L50.1853 6.95158H50.2168L50.2486 6.94689L50.2801 6.94219H50.3116H50.3478L50.3793 6.9375H50.4155H50.4473H50.4835C50.8636 6.9375 51.1713 7.00499 51.1713 7.08658C51.1713 7.16311 50.8636 7.23098 50.4835 7.23098C50.1046 7.23279 49.7969 7.16529 49.7969 7.08876Z" fill="#1E1B1B"/>
                    <path d="M47.457 7.88025V7.87556L47.4614 7.87086V7.86654L47.4657 7.86185L47.4704 7.85751L47.4795 7.85282H47.4838L47.4929 7.84812L47.5019 7.84343L47.511 7.83874H47.52L47.5338 7.83404L47.5472 7.82935L47.5562 7.82466H47.5743L47.5881 7.81997H47.6015L47.6196 7.81565L47.6377 7.81095H47.651L47.6691 7.80626H47.6916H47.7097L47.7278 7.80157H47.7506H47.7687L47.7911 7.79688H47.8139H47.8364H47.8545H47.8816H47.9041C48.1437 7.79688 48.3475 7.83297 48.3475 7.87809C48.3475 7.92321 48.1437 7.95463 47.9041 7.95463C47.6561 7.9568 47.457 7.92537 47.457 7.88025Z" fill="#1E1B1B"/>
                    <path d="M50.6211 7.40984V7.41885V7.42789V7.43258V7.44159L50.6167 7.44628V7.45532L50.612 7.46001V7.46904L50.6073 7.47374V7.47843L50.603 7.48312L50.5987 7.49213L50.5939 7.49683L50.5896 7.50152L50.5849 7.50621L50.5806 7.51091L50.5758 7.5156L50.5715 7.52029L50.5668 7.52499L50.5625 7.52968L50.5577 7.53402H50.5534L50.5444 7.53834L50.5396 7.54303H50.5353L50.5263 7.54772H50.5215H50.5125L50.5082 7.55206H50.4991H50.4944H50.4854C50.413 7.55206 50.3496 7.49358 50.3496 7.41235C50.3496 7.34449 50.413 7.28132 50.4854 7.28132C50.5621 7.2788 50.6211 7.34198 50.6211 7.40984Z" fill="#1E1B1B"/>
                    <path d="M49.7457 6.94491L49.7233 6.89075L49.7052 6.84131L49.6827 6.79619L49.6646 6.74672L49.6466 6.7016L49.6285 6.65216L49.6056 6.60703L49.5923 6.56623L49.5742 6.5211L49.5561 6.48032L49.5427 6.43951L49.5246 6.39908L49.5108 6.3583L49.4974 6.32221L49.484 6.2861L49.4703 6.25001L49.4569 6.2139L49.4478 6.1825L49.4388 6.15073L49.425 6.11895L49.416 6.0919L49.4069 6.06482L49.4026 6.03774L49.3935 6.01066L49.3888 5.98792L49.3845 5.96554L49.3798 5.9428L49.3754 5.92476L49.3711 5.90671V5.88864V5.87494V5.86159C49.3754 5.78036 49.4706 5.72152 49.5611 5.69444C49.656 5.66737 49.7331 5.65833 49.7964 5.70346C49.914 5.79803 50.027 6.52904 50.0632 6.83624C49.9495 6.85898 49.8815 6.87704 49.7457 6.94491Z" fill="#1E1B1B"/>
                    <path d="M50.1681 6.82631L50.1771 6.82161H50.1862H50.1996H50.2086L50.2314 6.81692H50.2539H50.2767H50.2991L50.3444 6.81223C50.3035 6.5191 50.2039 6.0361 50.1453 5.84224C50.0863 5.64803 50.0095 5.51302 49.8828 5.47656C49.8828 5.47656 49.9824 5.74297 50.0367 6.01805C50.091 6.2942 50.1272 6.56459 50.1681 6.82631Z" fill="#1E1B1B"/>
                    <path d="M49.4395 7.8026L49.4666 7.79791L49.489 7.78888L49.5115 7.77984L49.5343 7.77083L49.5567 7.7618L49.5839 7.74809L49.6063 7.73906L49.6291 7.72533L49.6516 7.71163L49.6697 7.69828L49.6925 7.68455L49.7149 7.67082L49.733 7.65747L49.7555 7.64377L49.7736 7.63039L49.7917 7.61235L49.8098 7.59864L49.8235 7.58527L49.8416 7.57156L49.8554 7.56253L49.8688 7.54918L49.8825 7.53583L49.8959 7.52679L49.9097 7.51344L49.9187 7.50441L49.9278 7.49971L49.9321 7.4907L49.9411 7.48167L49.9455 7.47735H49.9502V7.47266C49.5437 7.53186 49.4395 7.8026 49.4395 7.8026Z" fill="#1E1B1B"/>
                    <path d="M49.8203 7.77985L49.7841 7.79789L49.7526 7.81162L49.7207 7.82966L49.6845 7.84337L49.6527 7.8571L49.6212 7.87045L49.585 7.87948L49.5535 7.89283L49.5216 7.90187L49.4901 7.90656L49.4586 7.91557L49.4268 7.92461L49.3996 7.9293L49.3681 7.93362L49.341 7.93831L49.3139 7.943H49.2867L49.2643 7.9477H49.2418L49.219 7.95239H49.1966H49.1738H49.1557H49.1419H49.1238H49.1104H49.1014H49.0923H49.0833H49.0786H49.0742C49.151 7.81232 49.2237 7.68165 49.4409 7.52823C49.6624 7.37011 50.0201 7.32968 50.1602 7.34773C50.1279 7.45495 49.9832 7.68057 49.8203 7.77985Z" fill="#1E1B1B"/>
                    <path d="M49.7404 7.96875C49.478 8.09076 48.6635 8.10412 48.2244 8.12686H48.1904H48.1452H48.1111H48.0659H48.0206H47.9866H47.9417L47.9077 8.11567H47.8625L47.8284 8.10447H47.7944L47.7604 8.09328L47.7264 8.08209H47.6923L47.6583 8.0709H47.6243L47.5903 8.0597H47.5678L47.5338 8.04853L47.5113 8.03734H47.4893L47.4665 8.02615L47.444 8.01495H47.4328H47.41L47.3988 8.00376H47.3875L47.3647 7.99257H47.3535C47.4035 8.94919 48.055 9.00803 48.055 9.00803C48.046 8.9315 48.055 8.80479 48.055 8.80479C48.2585 8.99431 48.6773 9.13185 48.6773 9.13185C48.6726 9.01453 48.632 8.91743 48.632 8.91743C48.8087 9.02103 48.9147 9.01887 48.9147 9.01887C48.8966 8.8835 48.813 8.72574 48.813 8.72574C48.9354 8.88349 49.0845 8.89504 49.0845 8.89504C49.1116 8.81851 49.0617 8.6243 49.0617 8.6243C49.125 8.70083 49.2427 8.90407 49.6047 8.98529C49.9757 9.06182 50.1364 8.84992 50.1364 8.84992C49.7835 8.87266 49.4465 8.53405 49.4465 8.53405C49.6365 8.46185 49.7067 8.33117 49.7067 8.33117C49.5934 8.23623 49.4805 8.22973 49.4805 8.22973C49.6883 8.13335 49.7404 7.96875 49.7404 7.96875ZM48.4959 8.32974V8.36367C48.4959 8.36367 47.9305 8.36584 47.9077 8.64561C47.8172 8.58676 47.772 8.47882 47.7492 8.35248C47.7495 8.35248 48.1524 8.37486 48.4959 8.32974Z" fill="#1E1B1B"/>
                    <path d="M47.2539 8.05251L47.2583 8.09329L47.263 8.1294L47.2673 8.17019L47.272 8.20628L47.2764 8.24671L47.2854 8.28282L47.2945 8.31891L47.3035 8.35502L47.3126 8.39111L47.3216 8.4272L47.3307 8.45862L47.3444 8.49039L47.3535 8.52214L47.3672 8.55391L47.3806 8.58099L47.3944 8.60807C47.3944 8.60807 47.1367 8.41855 47.0733 8.25177C47.0096 8.08428 46.9915 7.97562 47.2539 8.05251Z" fill="#1E1B1B"/>
                    <path d="M46.4953 8.23463C46.3835 8.2469 46.2803 8.29309 46.2126 8.39236C46.0812 8.58658 46.129 8.95659 46.4048 9.11434C46.4048 9.11434 46.8189 8.75554 47.321 9.10317C47.321 9.10317 47.4318 9.03781 47.6942 8.97897V8.9678H47.683V8.95661L47.6718 8.94541L47.6605 8.93422H47.6493V8.92303L47.6381 8.91184L47.6269 8.90064L47.6156 8.88945L47.6044 8.87826L47.5932 8.86706H47.5813L47.57 8.84433H47.5588L47.5476 8.82159H47.5364L47.5252 8.8104L47.5027 8.7992L47.4915 8.78801L47.4803 8.77682L47.4578 8.76565L47.4466 8.75445L47.4354 8.74326L47.4126 8.73207H47.4003L47.3778 8.72088L47.3666 8.70968C47.2128 8.64218 46.896 8.54724 46.8012 8.25845C46.7284 8.23932 46.6075 8.22236 46.4953 8.23463ZM46.7331 8.61798C46.7921 8.61798 46.8464 8.67215 46.8464 8.73099V8.74218V8.75335H46.8352V8.76455V8.77574H46.824V8.78693V8.79812H46.8128V8.80932H46.8015V8.82051H46.7903H46.7791V8.8317H46.7668H46.7556L46.7444 8.84289H46.7331C46.6745 8.84289 46.6314 8.78875 46.6314 8.72991C46.6311 8.67215 46.6741 8.61798 46.7331 8.61798Z" fill="#1E1B1B"/>
                    <path d="M47.7516 9.04688L47.7559 9.05157L47.7606 9.05589V9.06058H47.765L47.7697 9.06961L47.774 9.07431L47.7831 9.08334L47.7878 9.09704L47.7968 9.10608L47.8059 9.11509L47.8149 9.12846L47.824 9.14217L47.8377 9.1512L47.8468 9.16491L47.8602 9.17828L47.8692 9.19632L47.883 9.20968L47.9101 9.23676L47.9326 9.26383L47.9597 9.29558L47.9731 9.30931L47.9869 9.32266L48.0003 9.33639L48.014 9.34974L48.0274 9.35877L48.0408 9.37248L48.0546 9.38151L48.0679 9.39486C48.0679 9.39486 47.3892 9.38583 47.222 9.97244C47.222 9.97244 46.9911 9.61145 47.1586 9.38114C47.3396 9.13242 47.5116 9.09669 47.7516 9.04688Z" fill="#1E1B1B"/>
                    <path d="M48.4941 9.18304L48.4988 9.18773H48.5032H48.5079L48.5122 9.19242H48.5169H48.5213H48.526L48.535 9.19712L48.5441 9.20181H48.5531L48.5665 9.20615L48.5756 9.21084H48.5893L48.5984 9.21554L48.6121 9.22023L48.6346 9.22457L48.6574 9.23358L48.6845 9.23792L48.6936 9.24262L48.707 9.24731L48.716 9.252H48.7294L48.7385 9.25669L48.7475 9.26139H48.7566L48.7656 9.26608H48.7747L48.7837 9.27077C48.7837 9.27077 48.7837 9.14442 48.7794 9.08594C48.8471 9.09929 48.9242 9.12204 48.9966 9.13106C48.9966 9.13106 49.2137 9.60937 49.3857 9.80323C49.3857 9.80323 49.3947 9.91622 49.2999 10.074C49.2999 10.074 49.1732 9.79421 48.956 9.55956C48.8333 9.43141 48.7519 9.36353 48.4941 9.18304Z" fill="#1E1B1B"/>
                    <path d="M47.918 9.08594H47.9227L47.9317 9.09063H47.9451L47.9542 9.09532L47.9679 9.09964L47.9813 9.10434L47.9994 9.10903L48.0132 9.11337L48.0313 9.1224L48.0494 9.12672L48.0675 9.13141L48.0903 9.14045L48.1084 9.14514L48.1308 9.15415L48.1489 9.16319L48.1713 9.17222L48.2166 9.19026L48.2618 9.20831L48.3071 9.22635L48.3476 9.2444L48.3701 9.25813L48.3929 9.26716L48.411 9.27617L48.4334 9.28952L48.4515 9.29856L48.4696 9.30759L48.4877 9.32094L48.5011 9.32995C48.7139 9.48337 48.8587 9.65484 48.8359 9.95699C48.8359 9.95699 48.6683 9.71803 48.4783 9.60973C48.3299 9.52815 48.0584 9.29351 47.918 9.08594Z" fill="#1E1B1B"/>
                    <path d="M47.9247 6.89062H47.9294H47.9384L47.9431 6.89532H47.9522H47.9565H47.9656L47.9746 6.90001H47.9837H47.9884L47.9974 6.9047H48.0065L48.0108 6.9094H48.0199L48.0289 6.91409L48.038 6.91878L48.0423 6.92312L48.0513 6.92782L48.0561 6.93251L48.0651 6.9372L48.0694 6.9419L48.0785 6.94659L48.0832 6.95128L48.0875 6.95598L48.0923 6.96499L48.0966 6.96968L48.1013 6.97871L48.1056 6.98303V6.98773V6.99242L48.1104 6.99676V7.00145V7.00615V7.01084L48.1147 7.01553V7.02022V7.02492V7.02961C48.1147 7.13322 47.9518 7.38156 47.9066 7.48986C47.8613 7.38156 47.7031 7.13358 47.7031 7.02961C47.7031 6.92564 47.8251 6.89424 47.8885 6.89424H47.9247V6.89062Z" fill="#1E1B1B"/>
                    <path d="M47.783 7.69976L47.7786 7.68172L47.7739 7.66837V7.65032L47.7696 7.63228L47.7649 7.61421L47.7605 7.59616L47.7558 7.57343V7.55538L47.7515 7.53264L47.7468 7.51026L47.7377 7.46513L47.7243 7.42001L47.7153 7.37019L47.7062 7.32507L47.6972 7.30268L47.6925 7.27995L47.6881 7.2619L47.6791 7.23914L47.6747 7.2211L47.6657 7.20305L47.661 7.18501L47.6519 7.16696L47.6429 7.14889L47.6385 7.13554L47.6295 7.12184L47.6204 7.10811L47.6114 7.09908L47.6067 7.09007C47.5162 7.01317 47.3895 7.00414 47.3309 7.00883C47.2675 7.01352 47.1995 7.07671 47.227 7.16227C47.2545 7.24782 47.5346 7.66765 47.5708 7.7171C47.6338 7.7088 47.6201 7.70879 47.783 7.69976Z" fill="#1E1B1B"/>
                    <path d="M48.0273 7.69976L48.0317 7.68172L48.0364 7.66837V7.65032L48.0407 7.63228L48.0454 7.61421L48.0498 7.59616L48.0545 7.57343L48.0588 7.55538L48.0635 7.53264L48.0679 7.51026L48.0769 7.46513L48.086 7.42001L48.095 7.37019L48.1088 7.32507L48.1131 7.30268L48.1178 7.27995L48.1269 7.2619L48.1312 7.23914L48.1403 7.2211L48.1446 7.20305L48.1537 7.18501L48.1584 7.16696L48.1674 7.14889L48.1718 7.13554L48.1808 7.12184L48.1899 7.10811L48.1989 7.09908L48.2036 7.09007C48.2941 7.01317 48.4161 7.00414 48.4795 7.00883C48.5428 7.01352 48.6062 7.07671 48.5833 7.16227C48.5562 7.24782 48.2757 7.66765 48.2395 7.7171C48.1768 7.7088 48.1859 7.70879 48.0273 7.69976Z" fill="#1E1B1B"/>
                    <path d="M48.2083 6.38778H47.9911V6.14844H47.8148V6.38778H47.5977V6.5412H47.8148V6.8206H47.9911V6.5412H48.2083V6.38778Z" fill="#1E1B1B"/>
                    <path d="M45.5717 7.9336V7.92025V7.90221V7.88414V7.86609L45.576 7.84805V7.83V7.82531V7.81628L45.5804 7.80727V7.79823C45.5941 7.68091 45.5985 7.57732 45.5851 7.49609C45.4265 7.55493 45.318 7.59537 45.1055 7.94264C45.1055 7.94264 45.4494 7.73037 45.5717 7.9336Z" fill="#1E1B1B"/>
                    <path d="M45.8705 8.49104V8.48203L45.8752 8.46831V8.45927V8.45026V8.43653L45.8795 8.42318V8.40983L45.8839 8.39178L45.8886 8.37841V8.36037L45.8933 8.34666L45.8976 8.32859V8.31055L45.902 8.2925L45.9067 8.26977L45.9114 8.25172L45.9204 8.21561L45.9295 8.17482L45.9429 8.13402L45.9519 8.09359L45.9657 8.05281L45.9791 8.01202L45.9928 7.97159L45.9972 7.95352L46.0062 7.93548C46.0967 7.70084 46.0377 7.3579 45.9338 7.15502C45.8343 6.95215 45.7753 6.76226 45.7934 6.55469C45.7934 6.55469 45.5899 6.62255 45.4723 6.69475C45.4542 6.93842 45.5947 7.17776 45.6623 7.31313C45.7394 7.47558 45.7257 7.68316 45.7076 7.81383C45.6804 8.03548 45.7257 8.27012 45.8705 8.49104Z" fill="#1E1B1B"/>
                    <path d="M45.8914 6.64107V6.63675V6.63206V6.62737H45.8867V6.62303V6.61833V6.61364V6.60895V6.60425V6.59956V6.59487V6.59017V6.58548V6.57645V6.56744V6.5584V6.55371L45.8914 6.54902V6.54432V6.53963V6.53531V6.53062V6.52593L45.8958 6.52159V6.51689V6.5122L45.9001 6.50788C45.9001 6.50788 46.2212 6.19671 46.4883 6.1422C46.6603 6.1061 46.7642 6.18298 46.8184 6.22341C46.8184 6.22341 46.7189 6.21438 46.5785 6.3317C46.4337 6.44469 46.2574 6.58909 46.1169 6.62518C45.9866 6.65912 45.9548 6.6501 45.8914 6.64107Z" fill="#1E1B1B"/>
                    <path d="M46.8439 6.25781L46.8664 6.28057L46.8892 6.30331L46.912 6.3257L46.9301 6.34843L46.9525 6.37117L46.9706 6.39391L46.975 6.40294L46.984 6.4163L46.9931 6.42533L47.0021 6.43868L47.0068 6.45239L47.0159 6.46142L47.0206 6.47515L47.0296 6.48416L47.034 6.49319L47.0387 6.5069L47.043 6.51593L47.0477 6.52928L47.0521 6.53831L47.0568 6.55167L47.0611 6.5607V6.5744L47.0658 6.58344V6.59245V6.60618V6.61521C47.0611 6.7282 46.9301 7.01663 46.8577 7.12492C46.7896 7.23322 46.6901 7.37761 46.6991 7.54908C46.7039 7.63933 46.7668 7.72958 46.8396 7.80178C46.8396 7.80178 46.4458 7.7072 46.4009 7.46787C46.3556 7.25127 46.4461 7.09785 46.5095 6.9762C46.5681 6.86321 46.7538 6.60149 46.7943 6.49319C46.8396 6.3932 46.8577 6.31196 46.8439 6.25781Z" fill="#1E1B1B"/>
                    <path d="M49.1426 8.98047L49.1563 9.02991L49.1744 9.08407L49.1925 9.13351L49.2106 9.18333L49.2287 9.23279L49.2515 9.28261L49.274 9.33242L49.2964 9.37755L49.3192 9.42267L49.3417 9.47214L49.3645 9.51726L49.3869 9.55804L49.4141 9.60317L49.4365 9.64395L49.4593 9.68438L49.4865 9.72049C49.6041 9.90532 49.6765 10.2125 49.6674 10.3161C49.6674 10.3161 49.8437 9.92336 49.7532 9.64829C49.6855 9.4317 49.4912 9.11584 49.1426 8.98047Z" fill="#1E1B1B"/>
                    <path d="M49.5801 9.07031H49.5844H49.5891H49.5935H49.5982H49.6072H49.6163L49.6253 9.07501H49.6344H49.6481H49.6615H49.6753L49.6887 9.0797H49.7024H49.7158L49.7339 9.08439H49.7473L49.7654 9.08908H49.7792L49.7973 9.09378L49.8154 9.0981L49.8288 9.10279L49.8469 9.10748H49.8603L49.8783 9.11652L49.8921 9.12121L49.9102 9.12553L49.9236 9.13022L49.9374 9.13925L49.9507 9.14395L49.9641 9.15298L49.9779 9.15767C50.1408 9.26597 50.2946 9.49626 50.3536 9.77603C50.3536 9.77603 50.0774 9.73091 49.9464 9.59554C49.8107 9.46269 49.6478 9.25983 49.5801 9.07031Z" fill="#1E1B1B"/>
                    <path d="M48.1871 9.55859H48.1824H48.178H48.1733H48.1643H48.1599L48.1509 9.56293H48.1418H48.1375L48.1284 9.56763H48.1194H48.1103L48.1013 9.57232L48.0832 9.57664L48.0651 9.58133H48.047L48.0289 9.58602L48.0108 9.59072L47.9927 9.59541L47.9836 9.6001H47.9746L47.9655 9.6048H47.9565L47.9521 9.60949H47.9431H47.934L47.9297 9.61418C47.9297 9.61418 48.1603 9.68168 48.2736 9.77229C48.3684 9.85351 48.4546 9.97987 48.4951 10.1874C48.5541 10.4806 48.7261 10.7964 48.7756 10.8914L48.7985 10.887C48.7623 10.6838 48.8889 10.2687 48.9251 10.2102C48.9251 10.2102 48.7394 10.0929 48.6584 9.97555C48.5943 9.88783 48.49 9.74342 48.1871 9.55859Z" fill="#1E1B1B"/>
                    <path d="M49.3803 10.1875L49.3894 10.2012L49.3941 10.2149L49.4031 10.233L49.4122 10.251L49.4212 10.2647L49.4256 10.2828L49.4346 10.3056L49.4484 10.3236L49.4574 10.3416L49.4665 10.3644L49.4846 10.4052L49.5027 10.446L49.5074 10.4684L49.5164 10.4911L49.5255 10.5182L49.5298 10.5409L49.5389 10.5636L49.5436 10.586L49.5479 10.6131L49.5526 10.6359L49.557 10.6629L49.5617 10.69V10.7124V10.7395V10.7665L49.557 10.7936V10.8163L49.5526 10.8434L49.5479 10.8705L49.5389 10.8976C49.4574 11.1817 49.2764 11.3037 49.1588 11.5427C49.1588 11.5427 49.0683 11.1095 49.0864 10.7756C49.1088 10.4359 49.2308 10.3095 49.3803 10.1875Z" fill="#1E1B1B"/>
                    <path d="M49.9007 10.1953V10.2L49.9051 10.2047V10.2094L49.9098 10.2141V10.2184L49.9141 10.2231L49.9188 10.2274V10.2364L49.9232 10.2455L49.9322 10.2588L49.9413 10.2769L49.9503 10.2996L49.9637 10.3224L49.9728 10.3448L49.9865 10.3718L49.9999 10.3946L50.0137 10.4216L50.0227 10.4531L50.0361 10.4801L50.0499 10.5072L50.068 10.539L50.0951 10.6021L50.1179 10.661L50.1313 10.6924L50.1451 10.7195L50.1585 10.7465L50.1675 10.7783L50.1766 10.8054L50.1856 10.8281L50.1947 10.8552L50.2037 10.8779L50.2081 10.9007L50.2171 10.9187C50.2624 11.122 50.2171 11.2526 50.1266 11.3743C50.0361 11.4963 49.8823 11.6317 49.7375 11.8844C49.7375 11.8844 49.5746 11.6678 49.5521 11.5551C49.534 11.4378 49.5655 11.1671 49.6017 11.0541C49.6379 10.9411 49.7787 10.57 49.9007 10.1953Z" fill="#1E1B1B"/>
                    <path d="M50.0371 11.8516L50.0328 11.8563H50.0281V11.8606L50.0238 11.8653L50.019 11.87L50.0147 11.879L50.0009 11.888L49.9919 11.9014L49.9785 11.9151L49.9647 11.9332L49.9514 11.9469L49.938 11.9649L49.9242 11.983L49.9061 12.0057L49.888 12.0238L49.8746 12.0461L49.8565 12.0642L49.8203 12.1093L49.7888 12.1498L49.7707 12.1725L49.757 12.1952L49.7389 12.218L49.7255 12.236L49.7121 12.2584L49.6983 12.2764L49.6849 12.2945L49.6712 12.3126L49.6578 12.3306L49.6487 12.3487L49.6397 12.362L49.635 12.3754L49.6306 12.3844L49.6259 12.3891V12.3938L49.6216 12.3985C49.5811 12.5115 49.5083 12.7143 49.6035 12.8587C49.7074 13.0031 49.9246 13.2334 50.1193 13.3327C50.1193 13.3327 49.9112 13.0035 49.9293 12.7324C49.9427 12.5566 50.0784 12.4032 50.1056 12.2992C50.1276 12.1945 50.1095 12.014 50.0371 11.8516Z" fill="#1E1B1B"/>
                    <path d="M49.4214 11.6445L49.4257 11.6492L49.4304 11.6582L49.4348 11.6629L49.4395 11.672L49.4485 11.681L49.4529 11.69L49.4576 11.699L49.4619 11.7081L49.4666 11.7218L49.4757 11.7308L49.48 11.7445L49.4847 11.7536L49.4891 11.7673L49.4934 11.7763L49.5072 11.8034L49.5162 11.8304L49.5253 11.8575L49.5343 11.8846L49.5434 11.9117L49.5524 11.9387V11.9568L49.5568 11.9705L49.5615 11.9839V11.9976V12.0156L49.5658 12.0293C49.5749 12.1828 49.5524 12.3091 49.4663 12.5391C49.3624 12.8232 49.1948 13.1437 49.1177 13.2881C49.1177 13.2881 48.9682 13.0762 48.8867 12.891C48.8867 12.891 49.1177 12.5752 49.2125 12.3542C49.3309 12.0911 49.3761 11.8474 49.4214 11.6445Z" fill="#1E1B1B"/>
                    <path d="M49.3183 11.5195V11.5239L49.3136 11.5286V11.5423V11.556L49.3093 11.574L49.3046 11.5921L49.3002 11.6192L49.2955 11.6462L49.2865 11.678L49.2821 11.7094L49.2731 11.7455L49.264 11.7863L49.2506 11.8267L49.2416 11.8675L49.2278 11.9126L49.2144 11.9621L49.1963 12.0072L49.1826 12.0567L49.1645 12.1108L49.142 12.1603L49.1239 12.2144L49.1015 12.2686L49.0787 12.3227L49.0516 12.3769L49.0244 12.431L48.9925 12.4852L48.9654 12.5393L48.9292 12.5978L48.8973 12.652L48.8611 12.7014L48.825 12.7556L48.7844 12.8097C48.4905 13.1797 48.2505 13.3108 48.0923 13.3108C47.8523 13.3108 47.6127 13.1075 47.5312 12.8819C47.4498 12.6563 47.4769 12.3 47.6308 12.178C47.6308 12.178 47.7756 12.6202 48.0785 12.6382C48.4268 12.6567 48.8702 12.2686 49.3183 11.5195Z" fill="#1E1B1B"/>
                    <path d="M48.9602 11.2109L48.9645 11.2246V11.238L48.9692 11.2517V11.2651L48.9783 11.2922L48.9826 11.3239V11.3553L48.9873 11.3871L48.9916 11.4189V11.455V11.4867V11.5228L48.9873 11.5589L48.9826 11.595V11.6311L48.9735 11.6672L48.9692 11.7033L48.9602 11.7394C48.924 11.8838 48.8335 12.0733 48.6662 12.1816C48.5167 12.2719 48.1729 12.2946 48.0371 11.965C48.0371 11.9643 48.6706 12.0141 48.9602 11.2109Z" fill="#1E1B1B"/>
                    <path d="M49.9967 14.1875L50.0057 14.2055L50.0148 14.2283L50.0282 14.251L50.0372 14.2734L50.051 14.2961L50.0644 14.3189L50.0825 14.3416L50.0959 14.3644L50.1277 14.4095L50.1592 14.4546L50.1954 14.4998L50.2269 14.5449L50.2587 14.59L50.2768 14.608L50.2902 14.6304L50.3036 14.6532L50.3174 14.6759L50.3308 14.694L50.3445 14.7163L50.3536 14.7344L50.3626 14.7571L50.3717 14.7752L50.3807 14.7932L50.3854 14.8113L50.3901 14.8293V14.8474L50.3945 14.8611C50.3854 15.3214 50.1816 15.6011 50.1368 15.7546C50.1368 15.7546 49.7701 15.4614 49.7067 15.1275C49.6709 14.9546 49.7071 14.4402 49.9967 14.1875Z" fill="#1E1B1B"/>
                    <path d="M49.5859 14.5114L49.5906 14.498L49.595 14.4843L49.5997 14.471L49.6087 14.4572L49.6134 14.4392L49.6225 14.4258L49.6268 14.4078L49.6359 14.3897L49.6449 14.3717L49.654 14.3493L49.663 14.3313L49.6721 14.3132L49.6902 14.2724L49.713 14.232L49.7354 14.1869L49.7578 14.1461L49.7716 14.128L49.7807 14.11L49.794 14.0919L49.8078 14.0739L49.8212 14.0558L49.8346 14.0378L49.8483 14.0197L49.8617 14.006L49.8755 13.9923L49.8889 13.9789L49.9026 13.9652L49.916 13.9515C49.916 13.9515 50.1198 13.3649 49.4773 13.0039C49.4773 13.0039 49.2102 13.3515 49.1425 13.6855C49.0701 14.015 49.423 14.2136 49.5859 14.5114Z" fill="#1E1B1B"/>
                    <path d="M48.7796 13.0234L48.7568 13.0505L48.7344 13.0733L48.7163 13.1003L48.6939 13.1274L48.6711 13.1498L48.6486 13.1769L48.6215 13.2039L48.5987 13.2267L48.5762 13.2494L48.5538 13.2765L48.54 13.2855L48.5266 13.2946L48.5176 13.3083L48.5038 13.3173L48.4948 13.3263L48.4814 13.3353L48.4723 13.3444L48.4589 13.3491L48.4499 13.3581L48.4361 13.3671L48.4271 13.3715L48.4137 13.3761C48.4137 13.3761 48.3728 13.6379 48.3999 13.8137C48.4318 14.071 48.599 14.3371 48.6714 14.5356C48.6714 14.5356 48.9519 14.1656 48.9834 13.949C49.0153 13.7324 49.0106 13.5566 49.0196 13.4527C49.0196 13.4519 48.8658 13.1992 48.7796 13.0234Z" fill="#1E1B1B"/>
                    <path d="M48.0317 14.289L48.0227 14.2757L48.0136 14.262L48.0046 14.2439L47.9955 14.2302L47.9818 14.2075L47.9727 14.1894L47.9593 14.1667L47.9456 14.1443L47.9322 14.1172L47.9184 14.0945L47.905 14.0674L47.8916 14.0356L47.8779 14.0086L47.8645 13.9768L47.8464 13.9497L47.8326 13.9183L47.8055 13.8552L47.7784 13.7876L47.7555 13.7198L47.7465 13.6884L47.7331 13.6566L47.7241 13.6205L47.715 13.5887L47.7103 13.5573L47.7013 13.5212L47.6965 13.4895L47.6918 13.4581V13.4263L47.6875 13.3945C47.6875 13.3945 48.0086 13.566 48.2757 13.4397C48.2757 13.4397 48.3076 13.8638 48.2576 14.0039C48.2171 14.1219 48.1628 14.1898 48.0317 14.289Z" fill="#1E1B1B"/>
                    <path d="M48.1055 14.3758L48.1145 14.3711L48.1192 14.3668L48.1236 14.3578L48.1326 14.3531L48.1373 14.3484L48.1464 14.3437L48.1511 14.3347L48.1601 14.33L48.1692 14.321L48.1735 14.3163L48.1916 14.2982L48.2054 14.2845L48.2235 14.2665L48.2416 14.2527L48.255 14.2347L48.2731 14.221L48.2821 14.212L48.2865 14.2076L48.2955 14.1986L48.3046 14.1896L48.3093 14.1849L48.3136 14.1759L48.3227 14.1712L48.3274 14.1621L48.3317 14.1574L48.3364 14.1484C48.3364 14.1484 48.5489 14.5365 48.5851 14.6495C48.5851 14.6495 48.4812 14.6722 48.3636 14.591C48.2503 14.5112 48.2184 14.4885 48.1055 14.3758Z" fill="#1E1B1B"/>
                    <path d="M49.0879 14.1484L49.0835 14.1531V14.1578L49.0788 14.1669V14.1716L49.0745 14.1806L49.0698 14.1853L49.0654 14.1943L49.0607 14.2033L49.0517 14.2214L49.0426 14.2394L49.0289 14.2618L49.0155 14.2798L49.0017 14.3026L48.9883 14.3297L48.9702 14.3524L48.9565 14.3795L48.9384 14.4022L48.9203 14.4293L48.8841 14.4791L48.8479 14.5333L48.8298 14.556L48.8117 14.5831L48.7983 14.6055L48.7802 14.6282L48.7621 14.6509L48.7483 14.6737L48.7349 14.6917L48.7168 14.7098L48.7078 14.7278L48.6944 14.7412L48.6897 14.7459L48.6854 14.7549L48.6806 14.7596L48.6763 14.7643L48.6716 14.769L48.6673 14.7737L48.6625 14.7784L48.6582 14.7827C48.6582 14.7827 48.6854 14.9542 48.812 15.1978C48.9387 15.4462 49.115 15.6177 49.2102 15.7032C49.2102 15.7032 49.4184 15.3376 49.4817 15.1798C49.5541 14.995 49.5451 14.8098 49.5132 14.7419C49.4408 14.5816 49.1965 14.3517 49.0879 14.1484Z" fill="#1E1B1B"/>
                    <path d="M49.5974 15.3828L49.6018 15.4009L49.6108 15.4142L49.6199 15.4323L49.6289 15.446L49.6423 15.464L49.6514 15.4821L49.6651 15.4958L49.6742 15.5138L49.6876 15.5319L49.7013 15.5546L49.7285 15.5907L49.7513 15.6312L49.7784 15.6673L49.8056 15.7077L49.8327 15.7481L49.8555 15.7886L49.8646 15.8113L49.878 15.8294L49.887 15.8517L49.8961 15.8698L49.9051 15.8878L49.9142 15.9106L49.9189 15.9286L49.9232 15.951L49.9323 15.9691V15.9871C49.9594 16.1405 49.937 16.2669 49.8961 16.3752C49.8508 16.4788 49.7151 16.6864 49.6246 16.8445C49.6246 16.8445 49.3126 16.5513 49.2445 16.3438C49.1949 16.1857 49.213 15.9647 49.2898 15.8203C49.3622 15.6803 49.5251 15.5315 49.5974 15.3828Z" fill="#1E1B1B"/>
                    <path d="M48.7152 15.3438L48.7243 15.3618L48.7333 15.3798L48.7424 15.3932L48.7514 15.4112L48.7605 15.4293L48.7695 15.443L48.792 15.4744L48.8148 15.5105L48.8372 15.5423L48.8644 15.5741L48.8868 15.6055L48.9096 15.6369L48.932 15.6686L48.9545 15.7L48.9635 15.7134L48.9726 15.7314L48.9816 15.7452L48.9907 15.7632L48.9997 15.7766L49.0088 15.7899L49.0135 15.808L49.0225 15.8217L49.0269 15.8354L49.0316 15.8535C49.0812 16.0296 49.0993 16.1466 49.0135 16.3588C48.932 16.5437 48.8416 16.652 48.8097 16.7649C48.8097 16.7649 48.5563 16.4401 48.5111 16.3047C48.4705 16.1646 48.4568 15.9437 48.5067 15.804C48.5614 15.6596 48.6519 15.4744 48.7152 15.3438Z" fill="#1E1B1B"/>
                    <path d="M49.9621 16.5898V16.5942H49.9574V16.5985L49.953 16.6032L49.9483 16.6079L49.944 16.6122L49.9396 16.6166L49.9349 16.6256L49.9306 16.6303L49.9215 16.6393L49.9168 16.6483L49.9078 16.6574L49.8944 16.6754L49.8806 16.6982L49.8625 16.7209L49.8444 16.7433L49.8263 16.7703L49.8082 16.7974L49.7854 16.8245L49.7673 16.8563L49.7449 16.8877L49.7268 16.9191L49.7043 16.9508L49.6862 16.9823L49.6681 17.0183L49.65 17.0498L49.6319 17.0815L49.6138 17.1176L49.6005 17.149L49.5867 17.1804L49.5733 17.2165L49.5643 17.2483L49.5505 17.2797L49.5462 17.3068L49.5415 17.3382L49.5371 17.3515V17.3653V17.379V17.3923C49.5505 17.74 49.7814 17.9656 49.8129 18.1822C49.8129 18.1822 50.0439 17.9251 50.1297 17.7537C50.2245 17.5551 50.3016 17.3927 50.2745 17.1718C50.247 16.9508 50.1883 16.8108 49.9621 16.5898Z" fill="#1E1B1B"/>
                    <path d="M49.1603 16.5352L49.1647 16.5442L49.1737 16.5579L49.1828 16.5716L49.1918 16.585L49.2009 16.5983L49.2099 16.6164L49.219 16.6344L49.228 16.6525L49.2418 16.6705L49.2508 16.6886L49.2599 16.711L49.2733 16.729L49.2823 16.7514L49.2914 16.7741L49.3095 16.8193L49.3185 16.8463L49.3276 16.8691L49.3366 16.8918L49.3457 16.9142L49.35 16.9413L49.3591 16.9637L49.3638 16.9864L49.3681 17.0088L49.3728 17.0358L49.3771 17.0586V17.081V17.1033V17.1214V17.1438L49.3728 17.1665L49.3681 17.1846C49.3138 17.3878 49.0966 17.6719 49.0242 17.7983C49.0242 17.7983 48.8523 17.4463 48.8161 17.2838C48.7803 17.1218 48.8121 16.86 49.1603 16.5352Z" fill="#1E1B1B"/>
                    <path d="M53.5115 7.88025V7.87556L53.5068 7.87086V7.86654L53.5024 7.86185L53.4977 7.85751L53.4934 7.85282H53.4843L53.4753 7.84812L53.4662 7.84343L53.4572 7.83874H53.4481L53.4344 7.83404L53.4253 7.82935L53.4119 7.82466H53.3986L53.3805 7.81997H53.3667L53.3486 7.81565L53.3352 7.81095H53.3171L53.299 7.80626H53.2809H53.2628L53.2404 7.80157H53.2223H53.1995L53.1814 7.79688H53.1586H53.1361H53.1133H53.0909H53.0684C52.8241 7.79688 52.625 7.83297 52.625 7.87809C52.625 7.92321 52.8241 7.95463 53.0684 7.95463C53.3124 7.9568 53.5115 7.92537 53.5115 7.88025Z" fill="#1E1B1B"/>
                    <path d="M50.4902 6.52382L50.4945 6.50577L50.4992 6.48301L50.5039 6.46028L50.5083 6.43754L50.5173 6.41515L50.5264 6.39242L50.5354 6.36534L50.5445 6.33826L50.5535 6.3112L50.5625 6.28412L50.585 6.22997L50.6074 6.17149L50.6302 6.11264L50.6527 6.0585L50.6755 5.99965L50.6845 5.97258L50.6979 5.94552L50.707 5.91844L50.7117 5.89136L50.7207 5.86862L50.7298 5.84155L50.7341 5.81881L50.7385 5.79607L50.7432 5.77331L50.7475 5.75095V5.73288V5.71483C50.7475 5.57044 50.6299 5.45312 50.4851 5.45312C50.3403 5.45312 50.2227 5.57044 50.2227 5.71483C50.2227 5.89966 50.4532 6.32382 50.4804 6.52237H50.4902V6.52382Z" fill="#1E1B1B"/>
                    <path d="M51.2234 6.94491L51.2459 6.89075L51.2687 6.84131L51.2868 6.79619L51.3096 6.74672L51.3277 6.7016L51.3458 6.65216L51.3639 6.60703L51.382 6.56623L51.4001 6.5211L51.4138 6.48032L51.4319 6.43951L51.4453 6.39908L51.4634 6.3583L51.4772 6.32221L51.4906 6.2861L51.5043 6.25001L51.5134 6.2139L51.5271 6.1825L51.5362 6.15073L51.5452 6.11895L51.5543 6.0919L51.5633 6.06482L51.5724 6.03774L51.5767 6.01066L51.5858 5.98792L51.5905 5.96554L51.5948 5.9428V5.92476L51.5995 5.90671V5.88864V5.87494V5.86159C51.5995 5.78036 51.5 5.72152 51.4138 5.69444C51.3143 5.66737 51.2372 5.65833 51.1738 5.70346C51.0562 5.79803 50.9476 6.52904 50.9023 6.83624C51.02 6.85898 51.0924 6.87704 51.2234 6.94491Z" fill="#1E1B1B"/>
                    <path d="M50.8052 6.82631L50.7914 6.82161H50.7824H50.769H50.7599L50.7371 6.81692H50.7147H50.6923H50.6694L50.647 6.81223H50.6289C50.6651 6.5191 50.7647 6.0361 50.8237 5.84224C50.887 5.64803 50.9594 5.51302 51.0861 5.47656C51.0861 5.47656 50.9913 5.74297 50.937 6.01805C50.8823 6.2942 50.8414 6.56459 50.8052 6.82631Z" fill="#1E1B1B"/>
                    <path d="M51.0195 7.47266H51.0242V7.47735H51.0286V7.48169H51.0333L51.0376 7.49072L51.0467 7.49973L51.0557 7.50443L51.0648 7.51346L51.0782 7.52681L51.0872 7.53585L51.101 7.5492L51.1144 7.56255L51.1325 7.57158L51.1462 7.58529L51.1643 7.59864L51.1824 7.61237L51.2005 7.63041L51.2186 7.64376L51.2414 7.65749L51.2595 7.67084L51.282 7.68457L51.3001 7.69827L51.3225 7.71165L51.3453 7.72535L51.3678 7.73908L51.3906 7.74809L51.413 7.76182L51.4354 7.77085L51.4626 7.77986L51.4854 7.7889L51.5078 7.79791L51.5303 7.8026C51.5307 7.80188 51.4268 7.53114 51.0195 7.47266Z" fill="#1E1B1B"/>
                    <path d="M50.8105 7.34844H50.8243H50.8377L50.8558 7.34375H50.8739H50.892H50.9101L50.9282 7.34844H50.9506H50.9731L50.9959 7.35314H51.0183L51.0411 7.35783L51.0636 7.36252H51.0907L51.1132 7.36722L51.1403 7.37156L51.1631 7.38057L51.1903 7.38526L51.2174 7.38995L51.2402 7.39899L51.2674 7.408L51.2945 7.41269L51.3173 7.42172L51.3445 7.43076L51.3716 7.43977L51.3941 7.45312L51.4165 7.46216L51.4436 7.47588L51.4665 7.48923L51.4889 7.49825L51.507 7.51631L51.5294 7.52966C51.7513 7.68308 51.8237 7.81376 51.9005 7.95382C51.9005 7.95382 51.5023 7.98524 51.1541 7.78236C50.9868 7.68237 50.842 7.45674 50.8105 7.34844Z" fill="#1E1B1B"/>
                    <path d="M51.2338 7.96875C51.2338 7.96875 51.279 8.13336 51.4824 8.2283C51.4824 8.2283 51.3717 8.23516 51.2674 8.32974C51.2674 8.32974 51.3351 8.4604 51.5161 8.5326C51.5161 8.5326 51.1834 8.8712 50.8262 8.84846C50.8262 8.84846 50.9912 9.06072 51.3579 8.98383C51.7246 8.90261 51.8332 8.69937 51.9009 8.62284C51.9009 8.62284 51.8556 8.8167 51.8781 8.89359C51.8781 8.89359 52.0341 8.8824 52.1608 8.72428C52.1608 8.72428 52.0659 8.88204 52.0475 9.01741C52.0475 9.01741 52.1492 9.01958 52.3302 8.91597C52.3302 8.91597 52.2897 9.01308 52.2849 9.1304C52.2849 9.1304 52.7103 8.99286 52.9184 8.80334V8.81453V8.82572V8.83692V8.84811V8.8593V8.8705V8.88169V8.89288V8.90407V8.91527V8.92644V8.9492L52.9072 8.96039V8.97156V8.98275V8.99395V9.00514C52.9072 9.00514 53.5587 8.9463 53.6083 7.98968C53.6083 7.98968 53.1808 8.14743 52.7374 8.12505C52.3038 8.10411 51.4915 8.0904 51.2338 7.96875ZM52.4667 8.32974C52.8105 8.37486 53.2246 8.35248 53.2246 8.35248V8.37486H53.2134V8.3976L53.2022 8.42034V8.44308L53.191 8.45427V8.46546V8.47666L53.1798 8.48785V8.49904V8.51023H53.1685V8.52143L53.1573 8.5326V8.54379L53.1461 8.55498L53.1349 8.56618V8.57737H53.1236L53.1124 8.58856V8.59975H53.1001L53.0889 8.61095V8.62214H53.0777L53.0665 8.63333L53.0552 8.64452C53.0328 8.36476 52.467 8.36259 52.467 8.36259V8.32974H52.4667Z" fill="#1E1B1B"/>
                    <path d="M53.7201 8.05251L53.7154 8.09329L53.7111 8.1294L53.7064 8.17019L53.7021 8.20628L53.693 8.24671L53.6883 8.28282L53.6792 8.31891L53.6702 8.35502L53.6612 8.39111L53.6521 8.4272L53.6387 8.45862L53.6297 8.49039L53.6163 8.52214L53.6072 8.55391L53.5935 8.58099L53.5801 8.60807C53.5801 8.60807 53.8378 8.41855 53.9011 8.25177C53.9645 8.08428 53.9782 7.97562 53.7201 8.05251Z" fill="#1E1B1B"/>
                    <path d="M54.2669 8.23828L54.2441 8.24947H54.2329H54.2217L54.1989 8.26064H54.1877H54.1764C54.0816 8.54944 53.758 8.64438 53.5994 8.71188C53.4318 8.7931 53.2715 8.98262 53.2715 8.98262C53.5339 9.04146 53.6563 9.10682 53.6563 9.10682C54.1583 8.75955 54.5724 9.11799 54.5724 9.11799C54.8439 8.95988 54.9004 8.58987 54.7646 8.39602L54.7422 8.37328L54.731 8.35089L54.7198 8.33972L54.697 8.32853L54.6857 8.31734L54.6633 8.30614L54.6521 8.29495L54.6296 8.28376L54.6072 8.27256L54.596 8.26137H54.5735L54.5511 8.25018H54.5283L54.5171 8.23899H54.4946H54.4718H54.4494H54.4266H54.4041H54.3817H54.3686H54.3462H54.3238H54.3125H54.2901H54.2669V8.23828ZM54.2329 8.62163H54.2441H54.2553H54.2666V8.63283H54.2778H54.289V8.64402H54.3002L54.3115 8.65521V8.6664H54.3227V8.6776H54.3339V8.68879V8.69998V8.71118V8.72237L54.3451 8.73356C54.3451 8.7924 54.2908 8.84655 54.2318 8.84655C54.1732 8.84655 54.1301 8.7924 54.1301 8.73356C54.1312 8.6758 54.1743 8.62163 54.2329 8.62163Z" fill="#1E1B1B"/>
                    <path d="M53.2226 9.04688H53.2179V9.05157H53.2136V9.05589L53.2089 9.06058L53.2045 9.06961L53.1955 9.07431L53.1911 9.08334L53.1821 9.09704L53.173 9.10608L53.1683 9.11509L53.1549 9.12846L53.1459 9.14217L53.1368 9.1512L53.1231 9.16491L53.114 9.17828L53.1006 9.19632L53.0916 9.20968L53.0644 9.23676L53.0373 9.26383L53.0148 9.29558L53.0011 9.30931L52.9877 9.32266L52.9739 9.33639L52.9605 9.34974L52.9472 9.35877L52.9334 9.37248L52.92 9.38151L52.9062 9.39486C52.9062 9.39486 53.5806 9.38583 53.7526 9.97244C53.7526 9.97244 53.9835 9.61145 53.8159 9.38114C53.6346 9.13242 53.4626 9.09669 53.2226 9.04688Z" fill="#1E1B1B"/>
                    <path d="M52.4813 9.18304H52.4769V9.18773H52.4722H52.4679H52.4632V9.19242H52.4588H52.4541H52.4451L52.4407 9.19712L52.4317 9.20181H52.4179L52.4089 9.20615L52.3998 9.21084H52.3861L52.377 9.21554L52.3636 9.22023L52.3408 9.22457L52.3137 9.23358L52.2912 9.23792L52.2778 9.24262L52.2688 9.24731L52.255 9.252H52.246L52.2326 9.25669L52.2235 9.26139H52.2145L52.2054 9.26608H52.1964L52.1917 9.27077C52.1917 9.27077 52.1873 9.14442 52.1964 9.08594C52.1287 9.09929 52.0469 9.12204 51.9745 9.13106C51.9745 9.13106 51.762 9.60937 51.5901 9.80323C51.5901 9.80323 51.581 9.91622 51.6759 10.074C51.6759 10.074 51.7982 9.79421 52.0197 9.55956C52.1421 9.43141 52.2235 9.36353 52.4813 9.18304Z" fill="#1E1B1B"/>
                    <path d="M53.0555 9.08594H53.0464L53.0374 9.09063H53.0283L53.0149 9.09532L53.0012 9.09964L52.9878 9.10434L52.974 9.10903L52.9559 9.11337L52.9378 9.1224L52.9197 9.12672L52.9016 9.13141L52.8835 9.14045L52.8607 9.14514L52.8426 9.15415L52.8198 9.16319L52.7974 9.17222L52.7521 9.19026L52.7069 9.20831L52.6664 9.22635L52.6211 9.2444L52.5987 9.25813L52.5806 9.26716L52.5578 9.27617L52.5397 9.28952L52.5216 9.29856L52.5035 9.30759L52.4854 9.32094L52.4716 9.32995C52.2591 9.48337 52.114 9.65484 52.1324 9.95699C52.1324 9.95699 52.3044 9.71803 52.4944 9.60973C52.6436 9.52815 52.915 9.29351 53.0555 9.08594Z" fill="#1E1B1B"/>
                    <path d="M53.0514 6.89062H53.0467H53.0376L53.0333 6.89532H53.0242H53.0195H53.0105L53.0014 6.90001H52.9924H52.988L52.979 6.9047H52.9699L52.9656 6.9094H52.9565L52.9475 6.91409L52.9384 6.91878L52.9337 6.92312L52.9247 6.92782L52.9203 6.93251L52.9113 6.9372L52.9066 6.9419L52.8975 6.94659L52.8932 6.95128L52.8885 6.95598L52.8841 6.96499L52.8794 6.96968L52.8751 6.97871L52.8704 6.98303V6.98773V6.99242L52.866 6.99676V7.00145V7.00615V7.01084L52.8613 7.01553V7.02022V7.02492V7.02961C52.8613 7.13322 53.0242 7.38156 53.0695 7.48986C53.1147 7.38156 53.2729 7.13358 53.2729 7.02961C53.2729 6.92564 53.1505 6.89424 53.0829 6.89424H53.0514V6.89062Z" fill="#1E1B1B"/>
                    <path d="M53.1914 7.69976L53.1958 7.68172V7.66837L53.2001 7.65032L53.2048 7.63228V7.61421L53.2091 7.59616L53.2139 7.57343L53.2182 7.55538L53.2229 7.53264L53.2272 7.51026L53.2363 7.46513L53.2453 7.42001L53.2544 7.37019L53.2681 7.32507L53.2725 7.30268L53.2815 7.27995L53.2862 7.2619L53.2906 7.23914L53.2996 7.2211L53.3043 7.20305L53.3134 7.18501L53.3224 7.16696L53.3271 7.14889L53.3362 7.13554L53.3452 7.12184L53.3496 7.10811L53.3586 7.09908L53.3677 7.09007C53.4535 7.01317 53.5805 7.00414 53.6439 7.00883C53.7072 7.01352 53.7706 7.07671 53.7478 7.16227C53.7206 7.24782 53.4401 7.66765 53.4039 7.7171C53.3405 7.7088 53.3496 7.70879 53.1914 7.69976Z" fill="#1E1B1B"/>
                    <path d="M52.9406 7.69976V7.68172L52.9363 7.66837L52.9316 7.65032L52.9272 7.63228V7.61421L52.9225 7.59616L52.9182 7.57343L52.9135 7.55538L52.9091 7.53264L52.9044 7.51026L52.8954 7.46513L52.8863 7.42001L52.8729 7.37019L52.8639 7.32507L52.8548 7.30268L52.8505 7.27995L52.8458 7.2619L52.8367 7.23914L52.8324 7.2211L52.8233 7.20305L52.8186 7.18501L52.8096 7.16696L52.8005 7.14889L52.7962 7.13554L52.7871 7.12184L52.7781 7.10811L52.7734 7.09908L52.7643 7.09007C52.6786 7.01317 52.5562 7.00414 52.4885 7.00883C52.4299 7.01352 52.3618 7.07671 52.389 7.16227C52.4114 7.24782 52.6919 7.66765 52.7328 7.7171C52.7958 7.7088 52.7824 7.70879 52.9406 7.69976Z" fill="#1E1B1B"/>
                    <path d="M52.7656 6.38778H52.9781V6.14844H53.1591V6.38778H53.3763V6.5412H53.1591V6.8206H52.9781V6.5412H52.7656V6.38778Z" fill="#1E1B1B"/>
                    <path d="M50.1465 4.96555H50.3684V4.72656H50.5403V4.96555H50.7619V5.11897H50.5403V5.39872H50.3684V5.11897H50.1465V4.96555Z" fill="#1E1B1B"/>
                    <path d="M55.4029 7.9336V7.92025V7.90221L55.3985 7.88414V7.86609V7.84805V7.83902V7.83L55.3938 7.82531V7.81628V7.80727V7.79823C55.3757 7.68091 55.3714 7.57732 55.3848 7.49609C55.5433 7.55493 55.6519 7.59537 55.8644 7.94264C55.8644 7.94264 55.5205 7.73037 55.4029 7.9336Z" fill="#1E1B1B"/>
                    <path d="M55.105 8.49104L55.1006 8.48203V8.46831V8.45927V8.45026L55.0963 8.43653V8.42318L55.0916 8.40983V8.39178L55.0872 8.37841V8.36037L55.0825 8.34666L55.0782 8.32859L55.0735 8.31055L55.0691 8.2925L55.0644 8.26977L55.0601 8.25172L55.051 8.21561L55.042 8.17482L55.0329 8.13402L55.0192 8.09359L55.0101 8.05281L54.9967 8.01202L54.9833 7.97159L54.9786 7.95352L54.9696 7.93548C54.8838 7.70084 54.9334 7.3579 55.0373 7.15502C55.1412 6.95215 55.2002 6.76226 55.1777 6.55469C55.1777 6.55469 55.3859 6.62255 55.4988 6.69475C55.5169 6.93842 55.3765 7.17776 55.3135 7.31313C55.232 7.47558 55.2454 7.68316 55.2682 7.81383C55.295 8.03548 55.2498 8.27012 55.105 8.49104Z" fill="#1E1B1B"/>
                    <path d="M55.0813 6.64107V6.63675V6.63206V6.62737V6.62303V6.61833V6.61364V6.60895V6.60425V6.59956V6.59487V6.59017V6.58548V6.57645V6.56744V6.5584V6.55371V6.54902L55.0769 6.54432V6.53963V6.53531V6.53062V6.52593V6.52159L55.0722 6.51689V6.5122V6.50788C55.0722 6.50788 54.7464 6.19671 54.4793 6.1422C54.3117 6.1061 54.2035 6.18298 54.1582 6.22341C54.1582 6.22341 54.2487 6.21438 54.3935 6.3317C54.5383 6.44469 54.7146 6.58909 54.8507 6.62518C54.9861 6.65912 55.0132 6.6501 55.0813 6.64107Z" fill="#1E1B1B"/>
                    <path d="M54.1254 6.25781L54.1026 6.28057L54.0801 6.30331L54.0621 6.3257L54.0396 6.34843L54.0215 6.37117L54.0034 6.39391L53.9944 6.40294L53.9853 6.4163L53.9763 6.42533L53.9716 6.43868L53.9625 6.45239L53.9535 6.46142L53.9487 6.47515L53.9444 6.48416L53.9354 6.49319L53.9306 6.5069L53.9263 6.51593L53.9216 6.52928L53.9173 6.53831V6.55167L53.9125 6.5607L53.9082 6.5744V6.58344V6.59245V6.60618V6.61521C53.9082 6.7282 54.044 7.01663 54.1116 7.12492C54.1797 7.23322 54.2883 7.37761 54.2745 7.54908C54.2655 7.63933 54.2068 7.72958 54.1341 7.80178C54.1341 7.80178 54.5279 7.7072 54.5728 7.46787C54.6181 7.25127 54.5232 7.09785 54.4595 6.9762C54.4009 6.86321 54.2199 6.60149 54.1746 6.49319C54.1344 6.3932 54.112 6.31196 54.1254 6.25781Z" fill="#1E1B1B"/>
                    <path d="M51.8337 8.98047L51.8156 9.02991L51.7975 9.08407L51.7837 9.13351L51.7613 9.18333L51.7432 9.23279L51.7251 9.28261L51.7027 9.33242L51.6799 9.37755L51.6574 9.42267L51.6346 9.47214L51.6122 9.51726L51.585 9.55804L51.5626 9.60317L51.5398 9.64395L51.5126 9.68438L51.4902 9.72049C51.3678 9.90532 51.2911 10.2125 51.3092 10.3161C51.3092 10.3161 51.1282 9.92336 51.2187 9.64829C51.2907 9.4317 51.4808 9.11584 51.8337 8.98047Z" fill="#1E1B1B"/>
                    <path d="M51.39 9.07031H51.3852H51.3809H51.3762H51.3719H51.3671H51.3581L51.349 9.07501H51.3357H51.3266H51.3128H51.2995L51.2857 9.0797H51.2723H51.2542L51.2408 9.08439H51.2227L51.209 9.08908H51.1909L51.1775 9.09378L51.1594 9.0981L51.1413 9.10279L51.1279 9.10748H51.1098L51.0917 9.11652L51.0779 9.12121L51.0645 9.12553L51.0464 9.13022L51.033 9.13925L51.0193 9.14395L51.0059 9.15298L50.9921 9.15767C50.8336 9.26597 50.6754 9.49626 50.6211 9.77603C50.6211 9.77603 50.8926 9.73091 51.0283 9.59554C51.159 9.46269 51.3266 9.25983 51.39 9.07031Z" fill="#1E1B1B"/>
                    <path d="M52.7885 9.55859H52.7928H52.8019H52.8066H52.8109H52.82L52.8247 9.56293H52.8337H52.8381L52.8471 9.56763H52.8562H52.8652L52.8743 9.57232H52.8833L52.8924 9.57664L52.9105 9.58133H52.9286L52.9467 9.58602L52.9648 9.59072L52.9829 9.59541L52.9919 9.6001H53.001L53.01 9.6048H53.0191L53.0238 9.60949H53.0328H53.0372L53.0462 9.61418C53.0462 9.61418 52.8156 9.68168 52.6976 9.77229C52.6028 9.85351 52.5166 9.97987 52.4805 10.1874C52.4218 10.4806 52.2499 10.7964 52.1956 10.8914L52.1775 10.887C52.209 10.6838 52.0826 10.2687 52.0508 10.2102C52.0508 10.2102 52.2318 10.0929 52.3176 9.97555C52.3769 9.88783 52.4855 9.74342 52.7885 9.55859Z" fill="#1E1B1B"/>
                    <path d="M51.5935 10.1875L51.5845 10.2012L51.5801 10.2149L51.5711 10.233L51.562 10.251L51.553 10.2647L51.5439 10.2828L51.5349 10.3056L51.5258 10.3236L51.5168 10.3416L51.5077 10.3644L51.4896 10.4052L51.4715 10.446L51.4625 10.4684L51.4534 10.4911L51.4444 10.5182L51.4401 10.5409L51.431 10.5636L51.4263 10.586L51.422 10.6131L51.4173 10.6359L51.4129 10.6629L51.4082 10.69V10.7124V10.7395V10.7665V10.7936V10.8163L51.4129 10.8434L51.422 10.8705L51.4263 10.8976C51.5121 11.1817 51.6978 11.3037 51.8154 11.5427C51.8154 11.5427 51.9059 11.1095 51.8831 10.7756C51.865 10.4359 51.743 10.3095 51.5935 10.1875Z" fill="#1E1B1B"/>
                    <path d="M50.4896 10.9724V10.9634V10.9587L50.494 10.9406L50.503 10.9226L50.5074 10.8998L50.5208 10.8818L50.5298 10.8591L50.5436 10.8363L50.557 10.8136L50.5707 10.7865L50.5841 10.7641L50.6022 10.737L50.6203 10.71L50.6384 10.6829L50.6518 10.6558L50.688 10.6017L50.7242 10.5475L50.7423 10.5204L50.7604 10.4887L50.7738 10.4616L50.7919 10.4345L50.8057 10.4074L50.819 10.3804L50.8324 10.3533L50.8415 10.3309L50.8505 10.3038L50.8596 10.2811L50.8643 10.254L50.8686 10.2316V10.2089V10.1909C50.8462 9.97426 50.561 9.82083 50.4886 9.78906H50.4842C50.4118 9.82083 50.1222 9.97426 50.1041 10.1909C50.086 10.4075 50.4708 10.8089 50.4799 10.9713H50.4896V10.9724Z" fill="#1E1B1B"/>
                    <path d="M51.0732 10.1953L51.0689 10.2V10.2047V10.2094L51.0641 10.2141L51.0594 10.2184V10.2231L51.0551 10.2274L51.0508 10.2364L51.0461 10.2455L51.0417 10.2588L51.0327 10.2769L51.0189 10.2996L51.0099 10.3224L50.9965 10.3448L50.9874 10.3718L50.9737 10.3946L50.9603 10.4216L50.9465 10.4531L50.9331 10.4801L50.9197 10.5072L50.906 10.539L50.8788 10.6021L50.8517 10.661L50.8383 10.6924L50.8292 10.7195L50.8158 10.7465L50.8068 10.7783L50.793 10.8054L50.784 10.8281L50.7749 10.8552L50.7706 10.8779L50.7615 10.9007L50.7568 10.9187C50.7116 11.122 50.7525 11.2526 50.843 11.3743C50.9378 11.4963 51.0917 11.6317 51.2321 11.8844C51.2321 11.8844 51.3993 11.6678 51.4174 11.5551C51.4355 11.4378 51.4084 11.1671 51.3679 11.0541C51.3356 10.94 51.1952 10.57 51.0732 10.1953Z" fill="#1E1B1B"/>
                    <path d="M50.4883 12.2063L50.5107 12.1835L50.5335 12.1608L50.556 12.1384L50.5741 12.1157L50.5969 12.0929L50.615 12.0702L50.6374 12.0474L50.6555 12.0204L50.6689 11.998L50.687 11.9752L50.6917 11.9619L50.7008 11.9529L50.7098 11.9391L50.7142 11.9301L50.7185 11.9168L50.7276 11.9077L50.7319 11.8987L50.7362 11.885L50.7409 11.876L50.7453 11.8669L50.75 11.8579L50.7543 11.8489C50.7862 11.754 50.7952 11.6186 50.7138 11.515C50.619 11.393 50.4966 11.2533 50.4876 11.1406H50.4785C50.4742 11.2536 50.3518 11.3933 50.2523 11.515C50.1708 11.6186 50.1799 11.754 50.2161 11.8489C50.2523 11.9391 50.3609 12.0973 50.4832 12.2052H50.4883V12.2063Z" fill="#1E1B1B"/>
                    <path d="M50.9381 11.8516L50.9424 11.8563L50.9468 11.8606L50.9515 11.8653L50.9562 11.87L50.9605 11.879L50.9696 11.888L50.9786 11.9014L50.992 11.9151L51.0058 11.9332L51.0192 11.9469L51.0329 11.9649L51.051 11.983L51.0644 12.0057L51.0825 12.0238L51.1006 12.0461L51.114 12.0642L51.1502 12.1093L51.1821 12.1498L51.2002 12.1725L51.2183 12.1952L51.232 12.218L51.2454 12.236L51.2635 12.2584L51.2773 12.2764L51.2906 12.2945L51.2997 12.3126L51.3131 12.3306L51.3221 12.3487L51.3312 12.362L51.3402 12.3754L51.3449 12.3844V12.3891L51.3493 12.3938V12.3985C51.3945 12.5115 51.4626 12.7143 51.3674 12.8587C51.2725 13.0031 51.0463 13.2334 50.8516 13.3327C50.8516 13.3327 51.0644 13.0035 51.0416 12.7324C51.0278 12.5566 50.8968 12.4032 50.8697 12.2992C50.8429 12.1945 50.861 12.014 50.9381 11.8516Z" fill="#1E1B1B"/>
                    <path d="M51.5484 11.6445L51.5441 11.6492L51.5394 11.6582L51.535 11.6629L51.5303 11.672L51.526 11.681L51.5169 11.69L51.5122 11.699L51.5079 11.7081L51.5035 11.7218L51.4988 11.7308L51.4898 11.7445L51.4854 11.7536L51.4807 11.7673L51.4764 11.7763L51.4626 11.8034L51.4536 11.8304L51.4445 11.8575L51.4355 11.8846L51.4264 11.9117L51.4174 11.9387L51.413 11.9568V11.9705L51.4083 11.9839V11.9976L51.404 12.0156V12.0293C51.3949 12.1828 51.4221 12.3091 51.5079 12.5391C51.6118 12.8232 51.7747 13.1437 51.8565 13.2881C51.8565 13.2881 52.0013 13.0762 52.0827 12.891C52.0827 12.891 51.8518 12.5752 51.7526 12.3542C51.6389 12.0911 51.5937 11.8474 51.5484 11.6445Z" fill="#1E1B1B"/>
                    <path d="M51.6562 11.5195V11.5239V11.5286L51.6606 11.5423V11.556L51.6653 11.574L51.6696 11.5921L51.6744 11.6192L51.6787 11.6462L51.6834 11.678L51.6924 11.7094L51.7015 11.7455L51.7105 11.7863L51.7196 11.8267L51.733 11.8675L51.7464 11.9126L51.7601 11.9621L51.7735 12.0072L51.7916 12.0567L51.8097 12.1108L51.8278 12.1603L51.8503 12.2144L51.8731 12.2686L51.8955 12.3227L51.9227 12.3769L51.9498 12.431L51.977 12.4852L52.0085 12.5393L52.0403 12.5978L52.0765 12.652L52.1127 12.7014L52.1489 12.7556L52.1894 12.8097C52.4837 13.1797 52.7234 13.3108 52.8819 13.3108C53.1216 13.3108 53.3615 13.1075 53.443 12.8819C53.5201 12.6563 53.4973 12.3 53.3434 12.178C53.3434 12.178 53.1987 12.6202 52.891 12.6382C52.5478 12.6567 52.0997 12.2686 51.6562 11.5195Z" fill="#1E1B1B"/>
                    <path d="M52.0162 11.2109L52.0115 11.2246L52.0072 11.238V11.2517L52.0025 11.2651L51.9981 11.2788V11.2925L51.9934 11.3243L51.9891 11.3557L51.9844 11.3875V11.4192V11.4553V11.4871V11.5232V11.5593L51.9891 11.5954L51.9934 11.6315L51.9981 11.6676L52.0072 11.7037L52.0162 11.7398C52.0524 11.8842 52.1386 12.0737 52.3102 12.182C52.4549 12.2722 52.8032 12.295 52.9346 11.9654C52.9346 11.9643 52.3011 12.0141 52.0162 11.2109Z" fill="#1E1B1B"/>
                    <path d="M50.4838 13.4016L50.5019 13.3925L50.5153 13.3835L50.5334 13.3788L50.5468 13.3698L50.5649 13.3607L50.5787 13.3517L50.5877 13.347L50.5968 13.338L50.6058 13.3333L50.6105 13.3286L50.6196 13.3196L50.6286 13.3149L50.6377 13.3059L50.6467 13.3012L50.6558 13.2922L50.6648 13.2831L50.6692 13.2741L50.6782 13.2651L50.6873 13.2561L50.6963 13.2427L50.7054 13.2337L50.7144 13.22L50.7235 13.2109L50.7325 13.1976L50.7416 13.1839L50.755 13.1658C50.8317 13.0442 50.9088 12.8186 50.8679 12.6561C50.8136 12.4576 50.7007 12.3763 50.6373 12.25C50.5877 12.2995 50.5559 12.3355 50.4878 12.3763H50.4835C50.4201 12.3359 50.3839 12.2998 50.3296 12.25C50.2619 12.3763 50.1577 12.4576 50.1034 12.6561C50.0629 12.8186 50.1353 13.0442 50.2167 13.1658C50.3162 13.3294 50.3933 13.3564 50.4838 13.4016Z" fill="#1E1B1B"/>
                    <path d="M50.4857 14.6437L50.49 14.6256L50.4991 14.6076L50.5081 14.5848L50.5172 14.5621L50.5306 14.5393L50.5443 14.5166L50.5577 14.4942L50.5758 14.4715L50.5939 14.4444L50.612 14.4173L50.6301 14.3946L50.6482 14.3675L50.6844 14.3134L50.725 14.2592L50.7612 14.2051L50.7793 14.178L50.7974 14.1509L50.8155 14.1238L50.8289 14.1011L50.8426 14.074L50.856 14.047L50.8694 14.0246L50.8785 13.9975L50.8875 13.9748L50.8966 13.952L50.9013 13.9296V13.9069V13.8979V13.8888V13.8798V13.8708C50.8426 13.5596 50.6432 13.6224 50.4893 13.4961H50.485C50.3311 13.6224 50.1273 13.5593 50.073 13.8708C50.0332 14.0708 50.4405 14.4498 50.4857 14.6437Z" fill="#1E1B1B"/>
                    <path d="M50.9725 14.1875L50.9681 14.2055L50.9547 14.2283L50.9457 14.251L50.9323 14.2734L50.9189 14.2961L50.9051 14.3189L50.8917 14.3416L50.878 14.3644L50.8465 14.4095L50.8103 14.4546L50.7788 14.4998L50.7426 14.5449L50.7111 14.59L50.6974 14.608L50.6793 14.6304L50.6659 14.6532L50.6521 14.6759L50.6387 14.694L50.6297 14.7163L50.6163 14.7344L50.6072 14.7571L50.5982 14.7752L50.5935 14.7932L50.5891 14.8113L50.5844 14.8293L50.5801 14.8474V14.8611C50.5891 15.3214 50.7882 15.6011 50.8335 15.7546C50.8335 15.7546 51.2045 15.4614 51.2678 15.1275C51.3026 14.9546 51.262 14.4402 50.9725 14.1875Z" fill="#1E1B1B"/>
                    <path d="M51.3914 14.5114L51.3867 14.498L51.3823 14.4843L51.3776 14.471L51.3686 14.4572L51.3642 14.4392L51.3552 14.4258L51.3505 14.4078L51.3414 14.3897L51.3324 14.3717L51.3233 14.3493L51.3143 14.3313L51.3052 14.3132L51.2871 14.2724L51.2647 14.232L51.2423 14.1869L51.2195 14.1461L51.2061 14.128L51.1923 14.11L51.1789 14.0919L51.1655 14.0739L51.1518 14.0558L51.1384 14.0378L51.1246 14.0197L51.1112 14.006L51.0975 13.9923L51.0841 13.9789L51.0707 13.9652L51.0526 13.9515C51.0526 13.9515 50.8535 13.3649 51.496 13.0039C51.496 13.0039 51.7628 13.3515 51.8355 13.6855C51.9072 14.015 51.5543 14.2136 51.3914 14.5114Z" fill="#1E1B1B"/>
                    <path d="M52.1959 13.0234L52.214 13.0505L52.2364 13.0733L52.2592 13.1003L52.2817 13.1274L52.3041 13.1498L52.3269 13.1769L52.3494 13.2039L52.3722 13.2267L52.3993 13.2494L52.4217 13.2765L52.4308 13.2855L52.4442 13.2946L52.4579 13.3083L52.467 13.3173L52.4804 13.3263L52.4894 13.3353L52.5032 13.3444L52.5122 13.3491L52.5256 13.3581L52.5347 13.3671L52.5481 13.3715L52.5571 13.3761C52.5571 13.3761 52.598 13.6379 52.5752 13.8137C52.5437 14.071 52.3718 14.3371 52.3037 14.5356C52.3037 14.5356 52.0189 14.1656 51.9917 13.949C51.9555 13.7324 51.9602 13.5566 51.9512 13.4527C51.9515 13.4519 52.1097 13.1992 52.1959 13.0234Z" fill="#1E1B1B"/>
                    <path d="M52.9366 14.289L52.9457 14.2757L52.9547 14.262L52.9638 14.2439L52.9772 14.2302L52.9862 14.2075L52.9996 14.1894L53.0134 14.1667L53.0224 14.1443L53.0358 14.1172L53.0496 14.0945L53.063 14.0674L53.0811 14.0356L53.0944 14.0086L53.1082 13.9768L53.1216 13.9497L53.1353 13.9183L53.1625 13.8552L53.1896 13.7876L53.2121 13.7198L53.2255 13.6884L53.2345 13.6566L53.2436 13.6205L53.2526 13.5887L53.2617 13.5573L53.2664 13.5212L53.2707 13.4895L53.2754 13.4581V13.4263V13.3945C53.2754 13.3945 52.9587 13.566 52.6963 13.4397C52.6963 13.4397 52.6601 13.8638 52.71 14.0039C52.7509 14.1219 52.8099 14.1898 52.9366 14.289Z" fill="#1E1B1B"/>
                    <path d="M52.8656 14.3758L52.8608 14.3711L52.8565 14.3668L52.8475 14.3578L52.8431 14.3531L52.8341 14.3484L52.8294 14.3437L52.8203 14.3347L52.816 14.33L52.8069 14.321L52.7979 14.3163L52.7932 14.3073L52.7841 14.2982L52.766 14.2845L52.7479 14.2665L52.7345 14.2527L52.7164 14.2347L52.703 14.221L52.694 14.212L52.6849 14.2076L52.6802 14.1986L52.6712 14.1896L52.6621 14.1849L52.6578 14.1759L52.6531 14.1712L52.644 14.1621L52.6397 14.1574L52.635 14.1484C52.635 14.1484 52.4268 14.5365 52.3906 14.6495C52.3906 14.6495 52.4945 14.6722 52.6078 14.591C52.7251 14.5112 52.7523 14.4885 52.8656 14.3758Z" fill="#1E1B1B"/>
                    <path d="M51.8867 14.1484L51.8914 14.1531V14.1578L51.8957 14.1669V14.1716L51.9005 14.1806L51.9048 14.1853V14.1943L51.9095 14.2033L51.9186 14.2214L51.9319 14.2394L51.941 14.2618L51.9548 14.2798L51.9681 14.3026L51.9862 14.3297L51.9996 14.3524L52.0177 14.3795L52.0358 14.4022L52.0496 14.4293L52.0858 14.4791L52.122 14.5333L52.1401 14.556L52.1582 14.5831L52.1763 14.6055L52.1897 14.6282L52.2078 14.6509L52.2215 14.6737L52.2396 14.6917L52.253 14.7098L52.2668 14.7278L52.2758 14.7412L52.2802 14.7459L52.2892 14.7549L52.2939 14.7596L52.2983 14.7643L52.3026 14.769V14.7737L52.3073 14.7784L52.3117 14.7827C52.3117 14.7827 52.2845 14.9542 52.1625 15.1978C52.0311 15.4462 51.8592 15.6177 51.7643 15.7032C51.7643 15.7032 51.5562 15.3376 51.4929 15.1798C51.4205 14.995 51.4248 14.8098 51.461 14.7419C51.5291 14.5816 51.7734 14.3517 51.8867 14.1484Z" fill="#1E1B1B"/>
                    <path d="M50.4908 16.9524L50.4951 16.9387L50.4998 16.9254L50.5046 16.912L50.5089 16.8983L50.5179 16.8893L50.5223 16.8759L50.5313 16.8622L50.5404 16.8485L50.5494 16.8351L50.5585 16.8214L50.5722 16.8034L50.5813 16.7896L50.6037 16.7626L50.6262 16.7308L50.649 16.6994L50.6761 16.668L50.6986 16.6319L50.721 16.5958L50.7348 16.5777L50.7438 16.5597L50.7572 16.5416L50.7663 16.5236L50.7753 16.5055L50.7844 16.4832L50.7934 16.4651L50.8025 16.4427C50.8477 16.3121 50.8477 16.1539 50.7934 16.0457C50.7348 15.9236 50.5129 15.6168 50.4857 15.5312C50.4586 15.6172 50.2414 15.924 50.178 16.0457C50.1238 16.1539 50.1238 16.3117 50.1733 16.4427C50.2548 16.6683 50.4495 16.8218 50.481 16.9524H50.4908Z" fill="#1E1B1B"/>
                    <path d="M51.3712 15.3828L51.3665 15.4009L51.3575 15.4142L51.3484 15.4323L51.3394 15.446L51.3303 15.464L51.3169 15.4821L51.3079 15.4958L51.2941 15.5138L51.2807 15.5319L51.2674 15.5546L51.2445 15.5907L51.2174 15.6312L51.1903 15.6673L51.1631 15.7077L51.1403 15.7481L51.1179 15.7886L51.1041 15.8113L51.0951 15.8294L51.086 15.8517L51.0726 15.8698L51.0683 15.8878L51.0592 15.9106L51.0502 15.9286L51.0455 15.951L51.0411 15.9691L51.0364 15.9871C51.0093 16.1405 51.0364 16.2669 51.0726 16.3752C51.1179 16.4788 51.2536 16.6864 51.3441 16.8445C51.3441 16.8445 51.6561 16.5513 51.7242 16.3438C51.7737 16.1857 51.7604 15.9647 51.6836 15.8203C51.6065 15.6803 51.4436 15.5315 51.3712 15.3828Z" fill="#1E1B1B"/>
                    <path d="M52.2589 15.3438L52.2498 15.3618L52.2408 15.3798L52.2317 15.3932L52.2227 15.4112L52.2136 15.4293L52.2046 15.443L52.1822 15.4744L52.1593 15.5105L52.1322 15.5423L52.1098 15.5741L52.0826 15.6055L52.0602 15.6369L52.0374 15.6686L52.0149 15.7L52.0059 15.7134L51.9968 15.7314L51.9878 15.7452L51.9787 15.7632L51.9697 15.7766L51.9606 15.7899L51.9563 15.808L51.9516 15.8217L51.9425 15.8354L51.9382 15.8535C51.8882 16.0296 51.8748 16.1466 51.9606 16.3588C52.0374 16.5437 52.1326 16.652 52.1644 16.7649C52.1644 16.7649 52.4178 16.4401 52.4583 16.3047C52.5036 16.1646 52.517 15.9437 52.4627 15.804C52.4127 15.6596 52.3222 15.4744 52.2589 15.3438Z" fill="#1E1B1B"/>
                    <path d="M50.4839 19.4563L50.4882 19.4383L50.4925 19.4202L50.4972 19.4022L50.5063 19.3798L50.5153 19.3574L50.5244 19.3347L50.5334 19.3076L50.5468 19.2762L50.5606 19.2491L50.574 19.2173L50.5877 19.186L50.6011 19.1498L50.6192 19.1137L50.6326 19.0776L50.6507 19.0415L50.6645 19.0011L50.6826 18.965L50.7007 18.9246L50.7141 18.8795L50.7278 18.839L50.7459 18.7939L50.7597 18.7488L50.7731 18.7037L50.7868 18.6585L50.8002 18.6134L50.8093 18.5683L50.8183 18.5185L50.8274 18.4734L50.8364 18.4235L50.8411 18.3741L50.8455 18.329V18.2795C50.8589 18.0178 50.6192 17.6026 50.4878 17.4492H50.4788C50.3474 17.6026 50.1168 18.0178 50.1212 18.2795C50.14 18.7975 50.4701 19.2939 50.4839 19.4563Z" fill="#1E1B1B"/>
                    <path d="M51.0153 16.5898V16.5942H51.0197V16.5985L51.0244 16.6032L51.0287 16.6079L51.0334 16.6122L51.0378 16.6166L51.0425 16.6256L51.0468 16.6303L51.0559 16.6393L51.0602 16.6483L51.0649 16.6574L51.083 16.6754L51.0964 16.6982L51.1145 16.7209L51.1326 16.7433L51.1507 16.7703L51.1688 16.7974L51.1913 16.8245L51.2094 16.8563L51.2275 16.8877L51.2503 16.9191L51.2684 16.9508L51.2908 16.9823L51.3089 17.0183L51.327 17.0498L51.3451 17.0815L51.3585 17.1176L51.3766 17.149L51.3904 17.1804L51.4038 17.2165L51.4128 17.2483L51.4219 17.2797L51.4309 17.3068L51.4352 17.3382V17.3515V17.3653V17.379V17.3923C51.4262 17.74 51.1956 17.9656 51.1594 18.1822C51.1594 18.1822 50.9332 17.9251 50.8474 17.7537C50.7478 17.5551 50.6707 17.3927 50.7026 17.1718C50.7258 16.9508 50.7848 16.8108 51.0153 16.5898Z" fill="#1E1B1B"/>
                    <path d="M51.8153 16.5352L51.8062 16.5442L51.8015 16.5579L51.7925 16.5716L51.7834 16.585L51.7744 16.5983L51.7653 16.6164L51.7563 16.6344L51.7429 16.6525L51.7338 16.6705L51.7248 16.6886L51.7157 16.711L51.7023 16.729L51.6933 16.7514L51.6842 16.7741L51.6614 16.8193L51.6524 16.8463L51.6433 16.8691L51.6343 16.8918L51.6299 16.9142L51.6209 16.9413L51.6166 16.9637L51.6075 16.9864L51.6028 17.0088L51.5985 17.0358V17.0586L51.5938 17.081V17.1033V17.1214L51.5985 17.1438V17.1665L51.6075 17.1846C51.6571 17.3878 51.8743 17.6719 51.9467 17.7983C51.9467 17.7983 52.1186 17.4463 52.1592 17.2838C52.1953 17.1218 52.1635 16.86 51.8153 16.5352Z" fill="#1E1B1B"/>
                    <path d="M50.7836 20.2954L50.7883 20.2593L50.7926 20.2232L50.7973 20.1871L50.8017 20.151L50.8107 20.1149L50.8154 20.0831L50.8245 20.0517L50.8335 20.02L50.8473 19.9882L50.8563 19.9568L50.8654 19.9297L50.8788 19.8983L50.8925 19.8712L50.9016 19.8442L50.9287 19.79L50.9512 19.7402L50.9783 19.6907L51.0008 19.6456L51.0236 19.6005L51.0326 19.5778L51.0417 19.555L51.0507 19.5326L51.0598 19.5102L51.0688 19.4922L51.0732 19.4698L51.0775 19.4518L51.0822 19.4337C51.1275 19.1991 51.1137 18.9778 50.9284 18.707C50.7836 19.2081 50.6207 19.5734 50.4983 19.8261C50.4936 19.8351 50.4893 19.8395 50.485 19.8485C50.485 19.8395 50.4802 19.8348 50.4759 19.8261C50.3492 19.5734 50.1863 19.2077 50.0415 18.707C49.8605 18.9778 49.8424 19.1987 49.8877 19.4337C49.9286 19.6413 50.1592 19.8893 50.1863 20.2954C50.2135 20.7062 49.752 20.8596 49.752 20.8596C49.9105 20.9138 50.0868 20.9409 50.3673 20.6748C50.4169 20.625 50.4578 20.5755 50.485 20.5167C50.5164 20.5752 50.5573 20.625 50.6026 20.6748C50.8875 20.9409 51.0641 20.9138 51.218 20.8596C51.218 20.8596 50.7608 20.7062 50.7836 20.2954Z" fill="#1E1B1B"/>
                    <path d="M49.3303 17.5859L49.335 17.5993L49.344 17.6174L49.3484 17.6311L49.3531 17.6491L49.3621 17.6672L49.3712 17.6852L49.3755 17.7033L49.3846 17.7213L49.398 17.7574L49.4161 17.7935L49.4342 17.8343L49.4479 17.8704L49.466 17.9112L49.4751 17.9293L49.4794 17.9473L49.4885 17.9607L49.4975 17.9787L49.5019 17.9967L49.5066 18.0105L49.5156 18.0285L49.52 18.0422L49.5247 18.0556L49.529 18.0646L49.5337 18.078L49.5381 18.087V18.1007L49.5428 18.1051C49.579 18.2675 49.529 18.466 49.2351 18.6736C49.2351 18.6736 49.0451 18.5382 48.9683 18.3624C48.8959 18.2 48.9412 18.0827 49.0136 18.0014C49.086 17.9108 49.2626 17.7166 49.3303 17.5859Z" fill="#1E1B1B"/>
                    <path d="M49.9552 18.1875V18.1965V18.2102V18.2192L49.9509 18.2373V18.251V18.2691L49.9462 18.2915V18.3095L49.9418 18.3319L49.9375 18.3546L49.9328 18.3817L49.9285 18.4088L49.9237 18.4359L49.9194 18.4629L49.9147 18.49L49.9104 18.5171L49.8966 18.5755L49.8832 18.634L49.8651 18.6972L49.8561 18.7243L49.8514 18.7513L49.838 18.7828L49.8289 18.8098L49.8199 18.8369L49.8108 18.864L49.7974 18.8911L49.7884 18.9134L49.7746 18.9362L49.7656 18.9586C49.5802 19.2791 49.3087 19.5228 49.0687 19.5228C48.8515 19.5228 48.8063 19.3921 48.6934 19.338C48.6934 19.338 48.7477 19.0582 48.9963 18.986C49.2993 18.8914 49.7652 18.5304 49.9552 18.1875Z" fill="#1E1B1B"/>
                    <path d="M49.1639 18.7721L49.1548 18.7631L49.1458 18.7587L49.1277 18.745L49.1186 18.736L49.1096 18.7313L49.0958 18.7223L49.0868 18.7179L49.0777 18.7089L49.0687 18.6999L49.0643 18.6955L49.0596 18.6909L49.0553 18.6862L49.0506 18.6818L49.0415 18.6775L49.0368 18.6728L49.0325 18.6681L49.0278 18.6638L49.0234 18.6548L49.0144 18.6501L49.0097 18.6457L49.0053 18.6367C49.0053 18.6367 48.7744 18.7223 48.5753 18.8533C48.3853 18.9843 48.1952 19.2053 48.0957 19.3183C48.0957 19.3183 48.3806 19.3905 48.5663 19.3092C48.5663 19.3092 48.634 19.0342 48.8377 18.9122C48.8877 18.8847 49.0281 18.8396 49.1639 18.7721Z" fill="#1E1B1B"/>
                    <path d="M48.9206 18.5374L48.9159 18.533L48.9116 18.524L48.9069 18.5193L48.9025 18.5103L48.8978 18.5013L48.8935 18.4922L48.8888 18.4832L48.8844 18.4742L48.8797 18.4651L48.8707 18.4561L48.8664 18.4424L48.8616 18.4334L48.8573 18.4244L48.8526 18.411L48.8483 18.3976L48.8435 18.3886L48.8345 18.3753L48.8302 18.3615V18.3478L48.8254 18.3345L48.8211 18.3211L48.8168 18.3074L48.8121 18.2893V18.276L48.8077 18.2626V18.2446V18.2265L48.803 18.2128V18.1948V18.1767L48.8077 18.1587V18.1406C48.8077 18.1406 48.4005 18.398 48.2781 18.4926C48.1558 18.5919 47.9028 18.8536 47.8438 18.9395C47.8438 18.9395 48.1514 18.8716 48.3911 18.7951C48.6492 18.7088 48.8254 18.5915 48.9206 18.5374Z" fill="#1E1B1B"/>
                    <path d="M48.2325 18.9766L48.2144 18.9856L48.1916 18.9899L48.1645 18.9989L48.142 19.008L48.1149 19.0127L48.0877 19.0217L48.0562 19.026L48.0291 19.0351L47.9976 19.0441L47.9657 19.0488L47.9024 19.0621L47.839 19.0802L47.7713 19.0939L47.708 19.1072L47.6403 19.1206L47.577 19.1343L47.5498 19.1387L47.5183 19.1477L47.4912 19.152L47.4597 19.161L47.4325 19.1657L47.4097 19.1701L47.3826 19.1791L47.3601 19.1838L47.3377 19.1885L47.3196 19.1975C47.1115 19.2607 46.962 19.36 46.7676 19.5765C46.7676 19.5765 47.0662 19.6578 47.2291 19.7119C47.2291 19.7119 47.4868 19.5357 47.7674 19.4321C47.952 19.3603 48.0378 19.1661 48.2325 18.9766Z" fill="#1E1B1B"/>
                    <path d="M47.7736 20.4232L47.7827 20.4142L47.7917 20.4051L47.8008 20.3918L47.8098 20.3828L47.8145 20.3781L47.8189 20.3691L47.8232 20.3644L47.8279 20.3597V20.3506L47.8323 20.3459L47.837 20.3369L47.8413 20.3326V20.3236L47.846 20.3192V20.3102V20.3012L47.8504 20.2968V20.2878V20.2788V20.2698V20.2608V20.2517V20.2427L47.846 20.2337V20.2246L47.8413 20.2109V20.2019L47.837 20.1882C47.8051 20.1026 47.6241 20.003 47.5159 19.9535C47.3393 19.868 46.851 19.737 46.6924 19.6875C46.6924 19.6875 46.611 19.7821 46.5704 19.8817C46.5476 19.9221 46.5748 19.9582 46.6428 19.9673C46.7833 19.9943 47.109 19.9312 47.1901 19.9853C47.2759 20.0395 47.2444 20.116 47.2444 20.1886C47.2444 20.1886 47.3939 20.0983 47.4888 20.121C47.6198 20.1478 47.7012 20.2109 47.7736 20.4232Z" fill="#1E1B1B"/>
                    <path d="M47.3398 19.7487H47.3445H47.3489L47.3532 19.7534H47.3579L47.367 19.7577H47.376L47.3851 19.762L47.3941 19.7667H47.4032L47.4122 19.7714L47.4256 19.7757L47.4347 19.7801L47.4484 19.7848L47.4618 19.7895L47.4756 19.7938L47.489 19.8028L47.5024 19.8075L47.5161 19.8119L47.5342 19.8209L47.5476 19.8256L47.5657 19.8346L47.5791 19.8436L47.5972 19.8483L47.6153 19.8573L47.6291 19.8664L47.6472 19.8754L47.6653 19.8844L47.6834 19.8934L47.6968 19.9025L47.7149 19.9158L47.733 19.9248L47.7511 19.9339C47.7511 19.9339 47.8325 19.9025 47.8734 19.8256C47.914 19.7534 48.0816 19.505 48.2354 19.4736C48.2354 19.4736 48.104 19.4646 48.0273 19.4375C48.0273 19.4375 47.8734 19.4779 47.7558 19.5277C47.6432 19.5682 47.5027 19.6404 47.3398 19.7487Z" fill="#1E1B1B"/>
                    <path d="M48.6017 19.4375H48.597L48.588 19.4418L48.5833 19.4465H48.5789H48.5742L48.5699 19.4512H48.5652H48.5608L48.5561 19.4555H48.5518H48.5471L48.5427 19.4599H48.538H48.529L48.5246 19.4646H48.5156H48.5109L48.5065 19.4693H48.4975H48.4884H48.4837L48.4747 19.4736H48.4656H48.4566H48.4475L48.4385 19.4779H48.4294H48.4204H48.4066C48.4066 19.4779 47.977 19.7757 47.927 20.0692C47.8818 20.3263 48.0175 20.4483 48.0809 20.48C48.1486 20.5161 48.4475 20.6335 48.6419 20.2364C48.6419 20.2364 48.3118 20.3447 48.2166 20.1869C48.1308 20.0245 48.2752 19.7898 48.3661 19.7267C48.4884 19.6364 48.7009 19.5732 48.8319 19.6003C48.8323 19.5999 48.7237 19.5007 48.6017 19.4375Z" fill="#1E1B1B"/>
                    <path d="M45.7841 22.8452L45.8022 22.764L45.8246 22.6828L45.8474 22.5972L45.8565 22.5568L45.8698 22.5164L45.8832 22.4756L45.897 22.4305L45.9108 22.39L45.9241 22.3496L45.9422 22.3092L45.9556 22.2684L45.9737 22.2276L45.9918 22.1871C46.1862 21.7763 46.6662 20.68 47.6613 20.4905C47.7518 20.4681 47.5527 20.2378 47.4622 20.2512C47.3717 20.2602 47.1954 20.3775 47.1183 20.4273C47.1183 20.4273 47.1183 20.1927 47.1136 20.1522C47.1136 20.1118 47.0774 20.0938 47.0369 20.0938C47.0007 20.0981 46.7563 20.0938 46.6839 20.184C46.453 20.4457 46.0729 21.1496 45.8557 21.6731C45.6385 22.1965 45.489 22.359 45.2585 22.2778C45.0322 22.1965 44.8965 21.9348 44.494 22.016C44.3582 22.0431 44.2044 22.1381 44.082 22.2734C44.1182 22.3366 44.1588 22.4178 44.3445 22.4178C44.5298 22.4178 44.8918 22.4943 45.0865 22.6301C45.2867 22.7683 45.4945 22.8182 45.7841 22.8452Z" fill="#1E1B1B"/>
                    <path d="M49.7566 19.4492L49.7613 19.4806L49.7656 19.512L49.7747 19.5438L49.7837 19.5752L49.7928 19.6066L49.8065 19.6384L49.8156 19.6655L49.829 19.6972L49.8424 19.729L49.8561 19.7604L49.8695 19.7921L49.8876 19.8192L49.9014 19.8506L49.9195 19.8824L49.9328 19.9138L49.9509 19.9452C49.9509 19.9452 49.6523 21.3394 48.553 21.4433C48.553 21.4433 48.5754 21.1545 48.8969 20.9379C49.2226 20.7166 49.7113 20.3737 49.6751 19.855C49.6751 19.855 49.5846 19.873 49.4308 20.0672C49.2769 20.2614 48.9331 20.8253 48.4082 20.8296C48.4082 20.8296 48.4849 20.5636 48.7202 20.4145C48.9512 20.2611 49.2498 20.0672 49.3855 19.8326C49.5213 19.6026 49.6617 19.4943 49.7566 19.4492Z" fill="#1E1B1B"/>
                    <path d="M49.2363 24.531L49.906 25.4649L50.4128 24.5671L50.2318 24.2422C50.1004 24.4812 49.9556 24.7342 49.8789 24.8786C49.8202 24.7884 49.6526 24.5537 49.4311 24.2422L49.2363 24.531Z" fill="#1E1B1B"/>
                    <path d="M49.0689 23.7434L48.9469 23.5719L48.8202 23.3961L48.6935 23.2199L48.5668 23.0441L48.4401 22.868L48.3178 22.6965L48.2591 22.6153L48.2001 22.5341L48.1415 22.4528L48.0872 22.3763L48.0329 22.2994L47.9829 22.2272L47.9333 22.155L47.8834 22.0875L47.8381 22.0244L47.7929 21.9655L47.7523 21.907L47.7161 21.8572L47.6799 21.8077L47.6481 21.7626L47.6209 21.7218L47.5985 21.6857L47.5757 21.6587L47.5576 21.6316L47.5442 21.6135L47.5352 21.6002L47.7885 21.4197L46.9922 21.1172L47.0056 21.9792L47.2771 21.785L48.8788 24.0322L49.0689 23.7434Z" fill="#1E1B1B"/>
                    <path d="M43.5846 18.6493L43.6027 18.6312L43.6251 18.6132L43.6432 18.5998L43.666 18.5864L43.6884 18.5774L43.7109 18.5684L43.7333 18.5637L43.7561 18.559L43.7789 18.5547H43.8061L43.8289 18.559H43.856L43.8832 18.568L43.9103 18.5724L43.9375 18.5814L43.9646 18.5904L43.9918 18.6041L44.0236 18.6175L44.0508 18.6312L44.0826 18.6449L44.1098 18.663L44.1416 18.681L44.1731 18.7034L44.2003 18.7215L44.2321 18.7438L44.2636 18.7662L44.2951 18.7933L44.327 18.8157L44.3585 18.8427L44.3856 18.8698L44.4171 18.8969L44.4486 18.924C44.7834 19.2308 44.9959 19.5467 44.8015 19.7633C44.6205 19.9575 44.2676 19.7904 43.9371 19.4835C43.5983 19.177 43.3902 18.8612 43.5846 18.6493Z" fill="#1E1B1B"/>
                    <path d="M44.1285 22.4384L44.1241 22.434L44.1198 22.4293L44.1151 22.425H44.1104V22.4207H44.106V22.416H44.1017V22.4113H44.097C43.9203 22.2535 43.8165 22.0142 43.8165 21.7525C43.8165 21.2471 44.228 20.8366 44.7305 20.8366C45.2372 20.8366 45.6492 21.2471 45.6492 21.7525C45.6492 21.8203 45.6354 21.9286 45.6354 21.9286C45.6759 21.8521 45.8302 21.5045 45.8573 21.4413C45.7216 20.9496 45.2691 20.5883 44.7305 20.5883C44.2689 20.5883 43.8755 20.8547 43.6807 21.2427C43.7531 20.7825 43.9837 20.3674 44.1466 20.2139C44.3413 20.0291 44.6758 19.9703 44.6758 19.9703C44.4543 20.002 44.1737 19.871 43.8476 19.5779C43.5446 19.2981 43.3908 19.0544 43.377 18.8516C43.3951 19.1404 43.3904 19.2981 43.3093 19.5374C43.196 19.8533 42.893 20.4399 43.0016 21.1034C43.0603 21.4691 43.3227 22.0106 43.7708 22.4524C43.8613 22.5517 43.8975 22.7008 43.9113 22.7773C43.9113 22.7773 44.5629 22.7773 44.7214 22.7907C44.7214 22.7907 44.8752 22.7773 44.8662 22.6777C44.8662 22.5828 44.7804 22.5604 44.708 22.5604C44.6262 22.56 44.3004 22.5647 44.1285 22.4384Z" fill="#1E1B1B"/>
                    <path d="M47.7384 22.6562L47.6616 22.6833L47.5802 22.7104L47.5035 22.7331L47.4267 22.7559L47.3453 22.7783L47.2682 22.8007L47.1914 22.8187L47.1147 22.8367L47.0332 22.8548L46.9565 22.8685L46.8798 22.8819L46.803 22.8952L46.6535 22.9223L46.4997 22.945C46.2463 22.9855 45.9929 22.9992 45.7486 22.9902C45.7529 23.0306 45.7529 23.0804 45.7305 23.1208C45.7305 23.1208 45.7757 23.175 45.7757 23.2429C46.0248 23.2476 46.2825 23.2382 46.5402 23.1934C46.9746 23.1256 47.4318 23.04 47.8886 22.8685L47.7384 22.6562Z" fill="#1E1B1B"/>
                    <path d="M48.4267 22.6302L48.5306 22.5713L48.6345 22.5081L48.7384 22.4406L48.8423 22.3684L48.9418 22.2919L49.0457 22.2107L49.1452 22.1251L49.2491 22.0349L49.3487 21.9403L49.4435 21.8367L49.5431 21.7331L49.6379 21.6201L49.7374 21.4981L49.8323 21.3761L49.9228 21.245L49.9724 21.1775L50.0176 21.1053L50.1034 20.9609C49.972 21.0194 49.8004 20.979 49.8004 20.979C49.7914 20.9923 49.7689 21.0285 49.7552 21.0422C49.3027 21.7147 48.7959 22.1432 48.2754 22.4229L48.4267 22.6302Z" fill="#1E1B1B"/>
                    <path d="M44.7525 22.8637H44.7435H44.7388H44.7254H44.7164H44.7073L44.6935 22.8594H44.6802H44.6668H44.653H44.6392H44.6259H44.6125H44.5944H44.5806H44.5444L44.5129 22.8637H44.4767L44.4449 22.8684H44.4087L44.3772 22.8731H44.3591H44.3457L44.3276 22.8774H44.3138L44.3001 22.8818H44.2867C44.1238 22.9041 43.9428 22.9402 43.92 23.0261C43.9066 23.1117 43.9605 23.202 44.0424 23.3283C44.0967 23.4048 44.2143 23.3644 44.2776 23.3373C44.3457 23.3102 44.7258 23.1117 44.7844 23.0442C44.843 22.981 44.8658 22.8861 44.7525 22.8637Z" fill="#1E1B1B"/>
                    <path d="M45.1364 23.1154L45.1317 23.1111L45.127 23.1064H45.1226L45.1183 23.1021H45.1136L45.1089 23.0977L45.1045 23.0931H45.0955L45.0908 23.0884H45.0817L45.077 23.084H45.068L45.0636 23.0797H45.0546H45.0455L45.0412 23.075H45.0321H45.0231L45.014 23.0703H45.005H44.9959H44.9869H44.9778H44.9688H44.9597H44.9507L44.9416 23.075H44.9326L44.9235 23.0797H44.9145L44.9054 23.084H44.8964C44.7925 23.1245 44.4215 23.3458 44.3943 23.445C44.3715 23.549 44.4215 23.6032 44.4801 23.6255C44.5387 23.6479 44.6068 23.6706 44.6611 23.675C44.7516 23.6797 44.8421 23.6255 44.892 23.5667C44.9373 23.5082 45.1092 23.3006 45.1364 23.2508C45.1588 23.21 45.1769 23.1378 45.1364 23.1154Z" fill="#1E1B1B"/>
                    <path d="M45.1823 23.3397L45.1779 23.3444L45.1736 23.3534L45.1645 23.3581L45.1602 23.3671L45.1512 23.3762L45.1421 23.3852L45.1378 23.3942L45.1287 23.4032L45.1197 23.4123L45.1106 23.4256L45.1016 23.4346L45.0925 23.4437L45.0791 23.4661L45.061 23.4888L45.052 23.4978L45.0429 23.5115L45.0339 23.5206L45.0248 23.5296L45.0201 23.5386L45.0111 23.5476L45.002 23.5567L44.9973 23.5657L44.9883 23.5747L44.9836 23.5837L44.9788 23.5884L44.9745 23.5974L44.9702 23.6021V23.6068H44.9655V23.6112L44.9607 23.6155C44.9426 23.6426 44.9698 23.692 45.015 23.6834C45.065 23.6787 45.318 23.6516 45.4675 23.5527C45.6123 23.4534 45.7119 23.2639 45.6123 23.1917C45.5081 23.1184 45.3542 23.1545 45.1823 23.3397Z" fill="#1E1B1B"/>
                    <path d="M45.2629 22.7845H45.2582H45.2539L45.2448 22.7802H45.2401H45.2311L45.222 22.7755H45.213L45.2039 22.7712H45.1949H45.1858L45.1724 22.7665H45.1634L45.1543 22.7622H45.1406H45.1315H45.1181L45.1091 22.7578H45.0957H45.0866H45.0776H45.0642H45.0551H45.0461L45.037 22.7622H45.028L45.0189 22.7665H45.0099V22.7712H45.0055H45.0008L44.9961 22.7755L44.9918 22.7802H44.9874V22.7845H44.9827V22.7889C44.9284 22.8701 44.9646 22.9513 45.1185 23.0235C45.268 23.0957 45.5482 23.1545 45.6025 23.0776C45.6564 23.0011 45.584 22.8705 45.2629 22.7845Z" fill="#1E1B1B"/>
                    <path d="M51.6394 17.5859L51.6347 17.5993L51.6256 17.6174L51.6213 17.6311L51.6169 17.6491L51.6079 17.6672L51.6032 17.6852L51.5941 17.7033L51.5851 17.7213L51.5717 17.7574L51.5536 17.7935L51.5398 17.8343L51.5217 17.8704L51.5036 17.9112L51.4946 17.9293L51.4899 17.9473L51.4808 17.9607L51.4718 17.9787L51.4674 17.9967L51.4584 18.0105L51.4537 18.0285L51.4493 18.0422L51.4446 18.0556L51.4403 18.0646L51.4356 18.078L51.4312 18.087L51.4265 18.1007V18.1051C51.395 18.2675 51.4403 18.466 51.7342 18.6736C51.7342 18.6736 51.929 18.5382 52.001 18.3624C52.0734 18.2 52.0282 18.0827 51.9605 18.0014C51.8837 17.9108 51.7118 17.7166 51.6394 17.5859Z" fill="#1E1B1B"/>
                    <path d="M51.0137 18.1875L51.018 18.1965V18.2102V18.2192V18.2373V18.251L51.0227 18.2691V18.2915L51.0271 18.3095L51.0318 18.3319V18.3546L51.0361 18.3817L51.0408 18.4088L51.0452 18.4359L51.0499 18.4629L51.0589 18.49L51.0636 18.5171L51.077 18.5755L51.0908 18.634L51.1042 18.6972L51.1132 18.7243L51.1223 18.7513L51.1313 18.7828L51.1404 18.8098L51.1541 18.8369L51.1632 18.864L51.1722 18.8911L51.186 18.9134L51.1994 18.9362L51.2084 18.9586C51.3941 19.2791 51.6609 19.5228 51.9052 19.5228C52.1224 19.5228 52.1724 19.3921 52.2763 19.338C52.2763 19.338 52.222 19.0582 51.9729 18.986C51.6699 18.8914 51.2037 18.5304 51.0137 18.1875Z" fill="#1E1B1B"/>
                    <path d="M51.8066 18.7721L51.82 18.7631L51.8291 18.7587L51.8472 18.745L51.8562 18.736L51.8653 18.7313L51.8743 18.7223L51.8834 18.7179L51.8924 18.7089L51.9015 18.6999L51.9058 18.6955L51.9105 18.6909L51.9149 18.6862L51.9239 18.6818L51.9286 18.6775L51.933 18.6728L51.9377 18.6681L51.942 18.6638L51.9467 18.6548L51.9558 18.6501L51.9601 18.6457L51.9645 18.6367C51.9645 18.6367 52.1997 18.7223 52.3945 18.8533C52.5845 18.9843 52.7746 19.2053 52.8785 19.3183C52.8785 19.3183 52.5889 19.3905 52.4035 19.3092C52.4035 19.3092 52.3359 19.0342 52.1321 18.9122C52.0872 18.8847 51.9424 18.8396 51.8066 18.7721Z" fill="#1E1B1B"/>
                    <path d="M52.0508 18.5374L52.0551 18.533L52.0598 18.524L52.0642 18.5193L52.0689 18.5103L52.0732 18.5013L52.0776 18.4922L52.0823 18.4832L52.0913 18.4742L52.0957 18.4651L52.1004 18.4561L52.1047 18.4424L52.1094 18.4334L52.1185 18.4244L52.1228 18.411L52.1275 18.3976L52.1319 18.3886L52.1366 18.3753L52.1409 18.3615L52.1456 18.3478L52.15 18.3345L52.1547 18.3211L52.159 18.3074V18.2893L52.1637 18.276V18.2626L52.1681 18.2446V18.2265V18.2128L52.1728 18.1948V18.1767L52.1681 18.1587V18.1406C52.1681 18.1406 52.5753 18.398 52.6973 18.4926C52.8149 18.5919 53.073 18.8536 53.1273 18.9395C53.1273 18.9395 52.8196 18.8716 52.5843 18.7951C52.3266 18.7088 52.1456 18.5915 52.0508 18.5374Z" fill="#1E1B1B"/>
                    <path d="M52.7383 18.9766L52.7607 18.9856L52.7832 18.9899L52.806 18.9989L52.8331 19.008L52.8603 19.0127L52.8874 19.0217L52.9146 19.026L52.9464 19.0351L52.9736 19.0441L53.0054 19.0488L53.0688 19.0621L53.1365 19.0802L53.1998 19.0939L53.2679 19.1072L53.3312 19.1206L53.3946 19.1343L53.426 19.1387L53.4579 19.1477L53.4851 19.152L53.5122 19.161L53.5394 19.1657L53.5665 19.1701L53.5893 19.1791L53.6117 19.1838L53.6342 19.1885L53.657 19.1975C53.8604 19.2607 54.0099 19.36 54.209 19.5765C54.209 19.5765 53.9057 19.6578 53.7475 19.7119C53.7475 19.7119 53.4898 19.5357 53.2045 19.4321C53.0188 19.3603 52.9327 19.1661 52.7383 18.9766Z" fill="#1E1B1B"/>
                    <path d="M53.1959 20.4232L53.1868 20.4142L53.1778 20.4051L53.1687 20.3918L53.1597 20.3828L53.1553 20.3781L53.1506 20.3691L53.1463 20.3644L53.1416 20.3597V20.3506L53.1372 20.3459L53.1325 20.3369L53.1282 20.3326V20.3236L53.1235 20.3192V20.3102V20.3012L53.1191 20.2968V20.2878V20.2788V20.2698V20.2608V20.2517V20.2427L53.1235 20.2337V20.2246L53.1282 20.2109L53.1325 20.2019V20.1882C53.1687 20.1026 53.3454 20.003 53.4536 19.9535C53.6346 19.868 54.1233 19.737 54.2818 19.6875C54.2818 19.6875 54.3585 19.7821 54.4042 19.8817C54.4179 19.9221 54.3951 19.9582 54.3274 19.9673C54.1917 19.9943 53.8612 19.9312 53.7845 19.9853C53.6987 20.0395 53.7258 20.116 53.7258 20.1886C53.7258 20.1886 53.5763 20.0983 53.4815 20.121C53.3497 20.1478 53.273 20.2109 53.1959 20.4232Z" fill="#1E1B1B"/>
                    <path d="M53.6335 19.7487H53.6291H53.6248L53.6201 19.7534H53.611L53.6067 19.7577H53.5976L53.5886 19.762L53.5795 19.7667H53.5705L53.5567 19.7714L53.5477 19.7757L53.5343 19.7801L53.5252 19.7848L53.5118 19.7895L53.4981 19.7938L53.4847 19.8028L53.4666 19.8075L53.4528 19.8119L53.4394 19.8209L53.4213 19.8256L53.4079 19.8346L53.3898 19.8436L53.3761 19.8483L53.358 19.8573L53.3399 19.8664L53.3218 19.8754L53.3084 19.8844L53.2903 19.8934L53.2722 19.9025L53.2541 19.9158L53.236 19.9248L53.2179 19.9339C53.2179 19.9339 53.1365 19.9025 53.0956 19.8256C53.055 19.7534 52.8874 19.505 52.7383 19.4736C52.7383 19.4736 52.865 19.4646 52.9417 19.4375C52.9417 19.4375 53.1003 19.4779 53.2132 19.5277C53.3258 19.5682 53.4659 19.6404 53.6335 19.7487Z" fill="#1E1B1B"/>
                    <path d="M52.3719 19.4375H52.3763L52.381 19.4418H52.3853L52.39 19.4465H52.3944L52.3991 19.4512H52.4034H52.4081L52.4125 19.4555H52.4168H52.4215L52.4259 19.4599H52.4349H52.4396L52.444 19.4646H52.453H52.4577L52.4668 19.4693H52.4711H52.4802H52.4849L52.4939 19.4736H52.503H52.512H52.5211L52.5301 19.4779H52.5392H52.5529H52.562C52.562 19.4779 52.9964 19.7757 53.046 20.0692C53.0865 20.3263 52.9555 20.4483 52.8874 20.48C52.8241 20.5161 52.5254 20.6335 52.3263 20.2364C52.3263 20.2364 52.6565 20.3447 52.7517 20.1869C52.8422 20.0245 52.6974 19.7898 52.6022 19.7267C52.4845 19.6364 52.2673 19.5732 52.1406 19.6003C52.1413 19.5999 52.2452 19.5007 52.3719 19.4375Z" fill="#1E1B1B"/>
                    <path d="M55.1916 22.8452L55.1688 22.764L55.1507 22.6828L55.1283 22.5972L55.1149 22.5568L55.1011 22.5164L55.0921 22.4756L55.0787 22.4305L55.065 22.39L55.0469 22.3496L55.0335 22.3092L55.0154 22.2684L54.9973 22.2276L54.9792 22.1871C54.7891 21.7763 54.3095 20.68 53.3141 20.4905C53.2192 20.4681 53.4183 20.2378 53.5088 20.2512C53.5993 20.2602 53.7756 20.3775 53.8574 20.4273V20.1522C53.8617 20.1118 53.8979 20.0938 53.9341 20.0938C53.9747 20.0981 54.2147 20.0938 54.2918 20.184C54.518 20.4457 54.9028 21.1496 55.1153 21.6731C55.3368 22.1965 55.4863 22.359 55.7125 22.2778C55.9388 22.1965 56.0745 21.9348 56.477 22.016C56.6175 22.0431 56.7709 22.1381 56.889 22.2734C56.8571 22.3366 56.8166 22.4178 56.6265 22.4178C56.4408 22.4178 56.0836 22.4943 55.8845 22.6301C55.6847 22.7683 55.4765 22.8182 55.1916 22.8452Z" fill="#1E1B1B"/>
                    <path d="M51.2186 19.4492L51.2096 19.4806L51.2052 19.512L51.1962 19.5438L51.1871 19.5752L51.1781 19.6066L51.169 19.6384L51.1556 19.6655L51.1419 19.6972L51.1328 19.729L51.1147 19.7604L51.1013 19.7921L51.0876 19.8192L51.0695 19.8506L51.0557 19.8824L51.0195 19.9456C51.0195 19.9456 51.3225 21.3397 52.4222 21.4437C52.4222 21.4437 52.3998 21.1549 52.0736 20.9383C51.7478 20.717 51.2635 20.3741 51.3001 19.8553C51.3001 19.8553 51.3815 19.8734 51.5397 20.0676C51.6935 20.2618 52.0374 20.8257 52.567 20.83C52.567 20.83 52.4903 20.5639 52.255 20.4149C52.0197 20.2614 51.7258 20.0676 51.59 19.8329C51.4539 19.6026 51.3091 19.4943 51.2186 19.4492Z" fill="#1E1B1B"/>
                    <path d="M50.4902 20.8047L50.4946 20.8227L50.5036 20.8451L50.5127 20.8675L50.5264 20.8899L50.5398 20.9169L50.5579 20.944L50.5717 20.9758L50.5941 21.0072L50.6122 21.0386L50.6303 21.0704L50.6527 21.1018L50.6756 21.1379L50.698 21.174L50.7251 21.2101L50.7747 21.2779L50.8247 21.3501L50.8743 21.4176L50.9014 21.4537L50.9286 21.4855L50.951 21.5173L50.9782 21.5487L51.0006 21.5804L51.0234 21.6075L51.0459 21.6346L51.0687 21.6617L51.0911 21.6844L51.1135 21.7025L51.1316 21.7252L51.1497 21.7386C51.3264 21.9053 51.7698 22.3479 52.0094 22.5688C52.0094 22.5688 51.3354 22.4021 50.9554 22.0591C50.9554 22.0591 50.6882 22.2125 50.4938 22.2125H50.4804C50.2857 22.2125 50.0146 22.0591 50.0146 22.0591C49.6388 22.4021 48.9648 22.5688 48.9648 22.5688C49.2001 22.3479 49.6436 21.9053 49.8245 21.7386C50.0055 21.5761 50.4218 20.9895 50.4852 20.8047H50.4902Z" fill="#1E1B1B"/>
                    <path d="M50.4895 22.3527H50.5032H50.5166L50.53 22.3484H50.5438H50.5571H50.5709L50.598 22.3437L50.6252 22.339L50.648 22.33L50.6752 22.3253L50.6976 22.3163L50.7247 22.3119L50.7472 22.3029L50.7653 22.2939L50.7881 22.2896L50.7971 22.2849L50.8062 22.2802L50.8152 22.2759L50.8243 22.2715L50.8333 22.2668H50.8424L50.8514 22.2621L50.8605 22.2578C50.779 22.4203 50.6299 22.6866 50.4895 22.7902H50.4851C50.3403 22.6866 50.1908 22.4203 50.1094 22.2578C50.1908 22.2939 50.3309 22.3527 50.4804 22.3527H50.4895Z" fill="#1E1B1B"/>
                    <path d="M51.0639 25.4675L53.6973 21.7854L53.9645 21.9792L53.9826 21.1172L53.1862 21.4197L53.4349 21.6002C53.3082 21.7854 51.3401 24.5332 51.091 24.8805L49.9012 22.7236L49.7517 22.9445L48.6929 24.5466L48.4258 24.3704V25.2188L49.2312 24.9029L48.9597 24.7181C49.1092 24.5015 49.679 23.6351 49.8737 23.3463L51.0639 25.4675Z" fill="#1E1B1B"/>
                    <path d="M51.547 24.014L51.4793 23.9147L51.4431 23.8653L51.4116 23.8155L51.3801 23.7703L51.3483 23.7205L51.3168 23.6754L51.2897 23.6303L51.2582 23.5851L51.231 23.5447L51.2173 23.5267L51.2039 23.5039L51.1948 23.4859L51.1814 23.4678L51.1677 23.4498L51.1586 23.4317L51.1452 23.4137L51.1362 23.3999L51.1271 23.3819L51.1134 23.3682L51.1043 23.3548L51.0953 23.3415C51.0319 23.4631 50.8962 23.7068 50.7467 23.9732L50.5566 23.6393L51.0725 22.7188L51.2173 22.9397L51.7465 23.7339L51.547 24.014Z" fill="#1E1B1B"/>
                    <path d="M52.0908 24.2578L52.2765 24.5466L52.5433 24.3704L52.5476 25.2188L51.7422 24.9029L52.009 24.7181C51.9818 24.6776 51.9413 24.6141 51.8866 24.5329L52.0908 24.2578Z" fill="#1E1B1B"/>
                    <path d="M57.3894 18.6493L57.3713 18.6312L57.3489 18.6132L57.3308 18.5998L57.308 18.5864L57.2856 18.5774L57.2627 18.5684L57.2403 18.5637L57.2179 18.559L57.1951 18.5547H57.1679L57.1455 18.559H57.1183L57.0912 18.568L57.064 18.5724L57.0369 18.5814L57.0097 18.5904L56.9779 18.6041L56.9507 18.6175L56.9236 18.6312L56.8921 18.6449L56.8649 18.663L56.8334 18.681L56.8016 18.7034L56.7701 18.7215L56.7429 18.7438L56.7114 18.7662L56.6796 18.7933L56.6481 18.8157L56.6166 18.8427L56.5848 18.8698L56.5533 18.8969L56.5218 18.924C56.1822 19.2308 55.9745 19.5467 56.1732 19.7633C56.3542 19.9575 56.6981 19.7904 57.0372 19.4835C57.3713 19.177 57.5838 18.8612 57.3894 18.6493Z" fill="#1E1B1B"/>
                    <path d="M56.8421 22.4384L56.8464 22.434H56.8511V22.4293H56.8555V22.425H56.8602V22.4207H56.8645L56.8692 22.416H56.8736V22.4113H56.8783C57.0546 22.2535 57.1541 22.0142 57.1541 21.7525C57.1541 21.2471 56.7469 20.8366 56.2354 20.8366C55.7333 20.8366 55.3214 21.2471 55.3214 21.7525C55.3214 21.8203 55.3348 21.9286 55.3348 21.9286C55.2986 21.8521 55.1448 21.5045 55.1133 21.4413C55.249 20.9496 55.7015 20.5883 56.2354 20.5883C56.7016 20.5883 57.0998 20.8547 57.2898 21.2427C57.2174 20.7825 56.9869 20.3674 56.824 20.2139C56.6292 20.0291 56.2991 19.9703 56.2991 19.9703C56.5163 20.002 56.8012 19.871 57.1226 19.5779C57.4256 19.2981 57.5841 19.0544 57.5932 18.8516C57.5751 19.1404 57.5841 19.2981 57.6656 19.5374C57.7789 19.8533 58.0775 20.4399 57.9732 21.1034C57.9146 21.4691 57.6475 22.0106 57.204 22.4524C57.1092 22.5517 57.0727 22.7008 57.0636 22.7773C57.0636 22.7773 56.4077 22.7773 56.2582 22.7907C56.2582 22.7907 56.0997 22.7773 56.1044 22.6777C56.1044 22.5828 56.1949 22.5604 56.2673 22.5604C56.3487 22.56 56.6701 22.5647 56.8421 22.4384Z" fill="#1E1B1B"/>
                    <path d="M52.5439 22.6302L52.44 22.5713L52.3361 22.5081L52.2322 22.4406L52.1327 22.3684L52.0288 22.2919L51.9249 22.2107L51.8254 22.1251L51.7258 22.0349L51.6263 21.9403L51.5267 21.8367L51.4272 21.7331L51.3323 21.6201L51.2375 21.4981L51.1427 21.3761L51.0478 21.245L51.0026 21.1775L50.953 21.1053L50.8672 20.9609C51.0029 21.0194 51.1702 20.979 51.1702 20.979C51.1839 20.9923 51.2017 21.0285 51.2107 21.0422C51.6722 21.7147 52.179 22.1432 52.6948 22.4186L52.5439 22.6302Z" fill="#1E1B1B"/>
                    <path d="M53.2307 22.6562L53.3075 22.6833L53.3889 22.7104L53.4657 22.7331L53.5471 22.7559L53.6242 22.7783L53.7009 22.8007L53.7824 22.8187L53.8591 22.8367L53.9362 22.8548L54.013 22.8685L54.0897 22.8819L54.1664 22.8952L54.3203 22.9223L54.4694 22.945C54.7275 22.9855 54.9809 22.9992 55.2205 22.9902C55.2205 23.0306 55.2205 23.0804 55.2386 23.1208C55.2386 23.1208 55.1981 23.175 55.1981 23.2429C54.9491 23.2476 54.6913 23.2382 54.4289 23.1934C53.9945 23.1256 53.5373 23.04 53.0762 22.8685L53.2307 22.6562Z" fill="#1E1B1B"/>
                    <path d="M56.217 22.8637H56.2261H56.2351H56.2442H56.2532H56.267L56.2804 22.8594H56.2894H56.3032H56.3166H56.3303H56.3484H56.3622H56.3803H56.3937H56.4255L56.4617 22.8637H56.4936L56.5298 22.8684H56.5613L56.5975 22.8731H56.6109H56.629L56.6427 22.8774H56.6608L56.6742 22.8818H56.6876C56.8462 22.9041 57.0271 22.9402 57.0496 23.0261C57.0677 23.1117 57.009 23.202 56.9272 23.3283C56.8776 23.4048 56.7596 23.3644 56.692 23.3373C56.6243 23.3102 56.2485 23.1117 56.1852 23.0442C56.1265 22.981 56.1041 22.8861 56.217 22.8637Z" fill="#1E1B1B"/>
                    <path d="M55.8381 23.1154L55.8428 23.1111V23.1064H55.8472L55.8562 23.1021H55.8609L55.8653 23.0977L55.87 23.0931H55.8743L55.8834 23.0884H55.8881L55.8971 23.084H55.9018L55.9109 23.0797H55.9199H55.9243L55.9333 23.075H55.9424H55.9471L55.9561 23.0703H55.9652H55.9742H55.9833H55.9923H56.0014H56.0104H56.0195L56.0285 23.075H56.0376L56.0466 23.0797H56.0557L56.0647 23.084H56.0738C56.1777 23.1245 56.5491 23.3458 56.5758 23.445C56.5987 23.549 56.5487 23.6032 56.4944 23.6255C56.4311 23.6479 56.363 23.6706 56.3087 23.675C56.2182 23.6797 56.1277 23.6255 56.0825 23.5667C56.0329 23.5082 55.8609 23.3006 55.8381 23.2508C55.811 23.21 55.7976 23.1378 55.8381 23.1154Z" fill="#1E1B1B"/>
                    <path d="M55.7886 23.3397L55.7929 23.3444L55.802 23.3534L55.8067 23.3581L55.811 23.3671L55.8201 23.3762L55.8291 23.3852L55.8338 23.3942L55.8429 23.4032L55.8519 23.4123L55.861 23.4256L55.87 23.4346L55.8791 23.4437L55.8972 23.4661L55.9153 23.4888L55.9334 23.5115L55.9381 23.5206L55.9471 23.5296L55.9562 23.5386L55.9652 23.5476L55.9696 23.5567L55.9786 23.5657L55.9833 23.5747L55.9924 23.5837L55.9967 23.5884L56.0014 23.5974L56.0058 23.6021L56.0101 23.6068V23.6112L56.0148 23.6155C56.0282 23.6426 56.0014 23.692 55.9562 23.6834C55.9062 23.6787 55.6532 23.6516 55.508 23.5527C55.3585 23.4534 55.2637 23.2639 55.3632 23.1917C55.4628 23.1184 55.621 23.1545 55.7886 23.3397Z" fill="#1E1B1B"/>
                    <path d="M55.7069 22.7845H55.7116H55.7206L55.725 22.7802H55.734H55.7387L55.7478 22.7755H55.7568L55.7659 22.7712H55.7749H55.784L55.7977 22.7665H55.8068L55.8202 22.7622H55.8292H55.8383H55.852L55.8611 22.7578H55.8745H55.8835H55.8969H55.906H55.915H55.9241L55.9331 22.7622H55.9422L55.9512 22.7665H55.9603L55.965 22.7712H55.9693H55.974V22.7755H55.9784L55.9831 22.7802L55.9874 22.7845L55.9921 22.7889C56.0417 22.8701 56.0055 22.9513 55.8564 23.0235C55.7069 23.0957 55.422 23.1545 55.3677 23.0776C55.3131 23.0011 55.3902 22.8705 55.7069 22.7845Z" fill="#1E1B1B"/>
                    <path d="M5.625 16.918V23.2129H6.66569V21.3852C6.86913 21.5614 7.09319 21.6671 7.33319 21.6671C7.67707 21.6671 7.95977 21.4866 8.18167 21.1257C8.43071 20.7239 8.55487 20.1308 8.55487 19.3546C8.55487 18.8132 8.45532 18.3258 8.26094 17.9107L8.22691 17.8428L8.19289 17.7865L8.17008 17.7302L8.13606 17.6851L8.10203 17.6288L8.05678 17.5724L8.02276 17.5273L7.98873 17.4822L7.94348 17.4371L7.90946 17.3919L7.86421 17.3468L7.81896 17.3129L7.77371 17.2678L7.72847 17.2338L7.68322 17.1999L7.63797 17.166L7.5815 17.1436L7.53626 17.1097L7.49101 17.0873L7.43454 17.0649L7.37807 17.0421L7.3216 17.0194L7.26513 16.997L7.20866 16.9858L7.15219 16.9746L7.09573 16.9634L7.02804 16.9411H6.97157L6.90388 16.9299H6.84741L6.77936 16.9187H6.71167H5.625V16.918ZM6.60922 17.8092H6.71094C6.97338 17.8092 7.17717 17.9605 7.3216 18.2493C7.46639 18.5381 7.53662 18.9846 7.53662 19.603C7.53662 19.9369 7.49571 20.2008 7.40088 20.3813C7.31038 20.5618 7.19274 20.652 7.0617 20.652C6.93501 20.652 6.83076 20.5665 6.74497 20.4037C6.65918 20.2459 6.60922 20.0181 6.60922 19.7156V17.8092Z" fill="#1E1B1B"/>
                    <path d="M30.1108 16.6094L30.025 16.6949C30.025 16.6949 27.8487 18.9601 27.6859 19.1316V16.9115H26.668V23.2198H27.6859V20.6297C27.713 20.5983 29.0205 19.2172 29.17 19.0594C29.179 19.2894 29.3191 23.2198 29.3191 23.2198H30.3598L30.1108 16.6094Z" fill="#1E1B1B"/>
                    <path d="M25.1469 16.6953L25.1422 16.7043L25.1331 16.7087L25.1241 16.7224L25.1103 16.7357L25.0922 16.7538L25.0741 16.7718L25.0513 16.7946L25.0289 16.8173L25.0017 16.8444L24.9746 16.8762L24.9427 16.9075L24.9112 16.939L24.8794 16.9751L24.8432 17.0111L24.8026 17.0516L24.7212 17.1375L24.6354 17.2278L24.5449 17.3227L24.4501 17.422L24.3505 17.5256L24.2463 17.6292L24.1424 17.7375L24.0381 17.8505L23.8256 18.0714L23.7217 18.1797L23.6179 18.2836L23.5183 18.3872L23.4235 18.4912L23.3286 18.5862L23.2425 18.6764L23.161 18.762L23.1205 18.8024L23.0843 18.8432L23.0481 18.8793L23.0162 18.9154L22.9848 18.9471L22.9576 18.9789L22.9305 19.006L22.9033 19.0331L22.8809 19.0558L22.8628 19.0739L22.8447 19.0919L22.8309 19.11L22.8172 19.119L22.8081 19.1327V16.9126H21.7852V23.2209H22.8077V20.6308C22.8349 20.5994 24.1427 19.2182 24.2919 19.0605C24.3009 19.2904 24.4414 23.2209 24.4414 23.2209H25.4774L25.2283 16.6094L25.1469 16.6953Z" fill="#1E1B1B"/>
                    <path d="M19.2279 21.6567L19.1646 21.5801L19.1103 21.5036L19.056 21.4177L19.0107 21.3321L18.9655 21.2419L18.9246 21.1516L18.8837 21.0571L18.8522 20.9621L18.825 20.8585L18.7979 20.7593L18.7751 20.651L18.757 20.5426L18.7432 20.4297L18.7342 20.3167L18.7295 20.1994L18.7248 20.082C18.7248 19.4189 18.8877 18.8908 19.2044 18.5027C19.553 18.0785 20.0598 17.8666 20.7204 17.8666H20.77V16.7656H20.7204C19.6887 16.7656 18.8967 17.0815 18.3584 17.7175C17.8698 18.2908 17.6211 19.0893 17.6211 20.0911C17.6211 21.0567 17.8926 21.8552 18.4218 22.4555C18.9829 23.0873 19.7568 23.4122 20.7341 23.4122H20.7837V22.3068H20.7341C20.0923 22.3065 19.5856 22.0899 19.2279 21.6567Z" fill="#1E1B1B"/>
                    <path d="M11.042 16.7582C10.4947 16.7582 10.0578 17.0672 9.74104 17.6719C9.43336 18.2675 9.27734 19.0595 9.27734 20.0295C9.27734 21.0312 9.42431 21.8413 9.7186 22.4322C10.0172 23.0369 10.4628 23.3459 11.042 23.3459C11.6349 23.3459 12.103 23.0188 12.4334 22.3871C12.7411 21.7958 12.8971 20.9973 12.8971 20.0179C12.8971 19.0523 12.7386 18.2624 12.4219 17.6715L12.3878 17.6152L12.3538 17.5589L12.331 17.5026L12.2858 17.4574L12.263 17.4123L12.2177 17.3672L12.1837 17.3221L12.1497 17.2769L12.1156 17.2318L12.0704 17.1867L12.0364 17.1528L11.9911 17.1188L11.9571 17.0849L11.9118 17.0509L11.8778 17.017L11.8326 16.9831L11.7873 16.9603L11.7421 16.938L11.6968 16.9156L11.6516 16.8932L11.6063 16.8704L11.5611 16.8477L11.5158 16.8365L11.4594 16.8138L11.4141 16.8026L11.3576 16.7914L11.3124 16.7802H11.2671L11.2107 16.769L11.1542 16.7578H11.0977H11.042V16.7582ZM11.042 17.7733C11.3316 17.7733 11.5444 17.9787 11.6755 18.3938C11.8022 18.7682 11.8565 19.3144 11.8565 20.0183C11.8565 20.6995 11.7931 21.2659 11.653 21.6991L11.6302 21.7442L11.619 21.7781L11.6078 21.812L11.5965 21.846L11.5853 21.8799L11.5629 21.9138L11.5517 21.9478L11.5289 21.9705L11.5176 22.0045L11.4948 22.0272L11.4836 22.0611L11.4724 22.0839L11.4499 22.1063L11.4271 22.1286L11.4159 22.151L11.3935 22.1622L11.3823 22.1846L11.3595 22.207L11.337 22.2182L11.3142 22.2405L11.2914 22.2517L11.2686 22.2629L11.2574 22.2741L11.2346 22.2853H11.2118L11.189 22.2965L11.1665 22.3077H11.1437L11.1209 22.3189H11.0981H11.0753H11.0413C10.7926 22.3189 10.6047 22.107 10.4643 21.6871C10.3195 21.2493 10.2493 20.6876 10.2493 20.0288C10.2493 19.3566 10.3195 18.8014 10.4643 18.3819C10.5964 17.9809 10.7886 17.7733 11.042 17.7733Z" fill="#1E1B1B"/>
                    <path d="M15.3045 21.6567L15.2774 21.6206L15.2455 21.5801L15.1865 21.5036L15.1369 21.4177L15.087 21.3321L15.0417 21.2419L15.0012 21.1516L14.965 21.0571L14.9288 20.9621L14.9016 20.8585L14.8745 20.7593L14.8564 20.651L14.8383 20.5426L14.8249 20.4297L14.8159 20.3167L14.8068 20.1994V20.082C14.8068 19.4189 14.965 18.8908 15.2864 18.5027C15.6303 18.0785 16.1371 17.8666 16.7977 17.8666H16.852V16.7656H16.7977C15.7704 16.7656 14.974 17.0815 14.4405 17.7175C13.9471 18.2908 13.7031 19.0893 13.7031 20.0911C13.7031 21.0567 13.9699 21.8552 14.5038 22.4555C15.0602 23.0873 15.8384 23.4122 16.8162 23.4122H16.8657V22.3068H16.8162C16.1686 22.3065 15.6618 22.0899 15.3045 21.6567Z" fill="#1E1B1B"/>
                    <path d="M5.63672 7.66016V13.9999H6.80881V8.71135H8.25673V13.9999H9.42412V7.66016H5.63672Z" fill="#1E1B1B"/>
                    <path d="M13.0104 7.49647C12.3903 7.49647 11.8926 7.80764 11.5401 8.42168C11.1915 9.02201 11.0195 9.81619 11.0195 10.7905C11.0195 11.7966 11.1824 12.6204 11.5173 13.216C11.8564 13.8253 12.3589 14.1297 13.0104 14.1297C13.6754 14.1297 14.2003 13.8116 14.5713 13.1709C14.9199 12.5752 15.103 11.7699 15.103 10.7905C15.103 9.81583 14.913 9.01695 14.5601 8.42168L14.526 8.36536L14.492 8.30905L14.458 8.25273L14.4127 8.20761L14.3787 8.15129L14.3335 8.10617L14.2994 8.06104L14.2542 8.01592L14.2089 7.9708L14.1749 7.93687L14.1297 7.89174L14.0844 7.85781L14.0392 7.82388L13.9939 7.78995L13.9375 7.75602L13.8922 7.73326L13.847 7.69933L13.8017 7.67659L13.7452 7.65385L13.7 7.63146L13.6435 7.60873L13.5871 7.58599L13.5418 7.5748L13.4853 7.55206L13.4289 7.54086L13.3724 7.52967L13.3159 7.51848L13.2595 7.50729H13.1914H13.1349L13.0785 7.49609H13.0104V7.49647ZM13.022 8.56824C13.3387 8.56824 13.5628 8.77148 13.7119 9.17759C13.8477 9.54761 13.9154 10.0869 13.9154 10.7909C13.9154 11.463 13.8361 12.0251 13.6779 12.4492C13.5241 12.8644 13.3116 13.0582 13.022 13.0582H12.988H12.9652H12.9424H12.9199L12.8971 13.047L12.8743 13.0358H12.8515L12.8287 13.0247L12.8063 13.0135L12.7835 13.0023L12.7607 12.9911L12.7379 12.9799L12.715 12.9571L12.6926 12.946L12.6698 12.9236L12.647 12.9124L12.6358 12.8897L12.613 12.8673L12.5902 12.8445L12.5789 12.8218L12.5561 12.799L12.5333 12.7651L12.5221 12.7424L12.4993 12.7196L12.4881 12.6857L12.4656 12.6517L12.4544 12.629L12.4432 12.5839L12.4204 12.55L12.4092 12.516L12.398 12.4821L12.3751 12.4482C12.217 12.0103 12.1377 11.4554 12.1377 10.801C12.1377 10.1288 12.217 9.58046 12.3751 9.16532C12.5265 8.76462 12.7368 8.56824 13.022 8.56824Z" fill="#1E1B1B"/>
                    <path d="M21.4766 7.66016V8.71135H22.4492V13.9999H23.6213V8.71135H24.6077V7.66016H21.4766Z" fill="#1E1B1B"/>
                    <path d="M18.9115 9.8669H18.2737C18.0113 9.8669 17.8122 9.80842 17.6898 9.6911C17.5994 9.60988 17.5584 9.49689 17.5584 9.3525V7.66016H16.3867V9.4698C16.3867 9.88494 16.554 10.2413 16.8797 10.521C17.2008 10.7965 17.5994 10.9361 18.0562 10.9361H18.9112V13.9999H20.0833V7.66016H18.9112V9.8669H18.9115Z" fill="#1E1B1B"/>
                    <path d="M26.133 7.66479L25.918 14.0048H27.0832C27.0832 14.0048 27.1918 11.0426 27.1961 10.9025H28.6553C28.669 11.0335 28.9944 14.0048 28.9944 14.0048H30.1709L29.6279 9.3683V9.31198V9.25566V9.19935L29.6167 9.14303V9.08671L29.6055 9.04159V8.98527L29.5942 8.94015L29.583 8.88383L29.5718 8.83871L29.5606 8.79359L29.5494 8.74846L29.5381 8.70334L29.5153 8.65822L29.5041 8.61309L29.4929 8.56797L29.4701 8.52284L29.4473 8.48891L29.4245 8.44379L29.4132 8.40986L29.3904 8.36474L29.3676 8.3308L29.3336 8.29687L29.3108 8.25175L29.288 8.22901L29.254 8.18389L29.2312 8.16115L29.1971 8.12722L29.1631 8.09327L29.1291 8.07053L29.1063 8.0366L29.061 8.00267C28.7671 7.77236 28.3686 7.66406 27.8622 7.66406H26.133V7.66479ZM27.253 8.71383H27.6714C27.9744 8.71383 28.1782 8.81091 28.3049 8.99574C28.4091 9.14916 28.479 9.44228 28.5199 9.85309H27.2189V9.84192V9.83073V9.81953V9.80834V9.79715V9.78595V9.77476V9.74083V9.7069V9.67297L27.2301 9.63904V9.59391V9.55998V9.51486V9.46974V9.42461V9.37949V9.27805V9.1878L27.2414 9.14268V9.09755V9.05243V9.00731V8.96218V8.92825V8.88313V8.8492V8.81525L27.2526 8.79251V8.78131V8.77012V8.75895V8.74776V8.73656V8.72537V8.71383H27.253Z" fill="#1E1B1B"/>
                    <rect x="0.5" y="0.5" width="65.25" height="29" stroke="#1E1B1B"/>
                    <line x1="35.5" y1="-2.18557e-08" x2="35.5" y2="30" stroke="#1E1B1B"/>
                  </g>
                </svg>
                </button>
                <button onClick={() => setPostType(2)} className='button_delivery_SDEK_alt'>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="16" viewBox="0 0 60 16" fill="none">
                  <g clipPath="url(#clip0_794_1638)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.40087 11.8908H6.81362C2.77753 11.8908 5.70975 3.80625 9.10765 3.80625H13.1955C13.8509 3.80625 15.0066 3.92013 15.5758 2.39105L16.4554 0.0161133H10.8497C7.81405 0.0161133 5.451 1.02465 3.74344 2.71638C0.793978 5.61184 -0.206421 10.134 0.50076 12.2975C1.17344 14.2982 3.00176 15.6159 5.86496 15.6484L8.09001 15.6646H10.8325L11.5052 13.7615C12.0054 12.4113 10.8842 11.8908 9.40087 11.8908ZM40.8963 8.53984L41.7413 5.95346H32.7205C31.22 5.95346 30.5473 6.34386 30.2886 7.15717L29.4434 9.74359H38.4642C39.9648 9.74359 40.6375 9.35319 40.8963 8.53984ZM28.098 13.0945L27.2529 15.6809H36.2737C37.7571 15.6809 38.447 15.2905 38.7057 14.4772L39.5509 11.8908H30.5301C29.0467 11.8908 28.374 12.2812 28.098 13.0945ZM43.0005 2.61878L43.8459 0.0323797H34.8249C33.3243 0.0323797 32.6516 0.422779 32.3929 1.23611L31.5477 3.82252H40.5686C42.0521 3.82252 42.7245 3.43212 43.0005 2.61878ZM29.3744 3.13932C28.9949 0.731849 27.6323 0.0323797 24.3206 0.0323797H18.2838L14.7651 9.74359H16.9901C18.3182 9.74359 18.9909 9.75986 19.6981 7.97051L21.1642 3.80625H23.4065C25.3211 3.80625 24.8898 6.06733 23.9239 8.26331C23.0615 10.199 21.5609 11.9071 19.7498 11.9071H16.007C14.5064 11.9071 13.8165 12.2975 13.5405 13.1108L12.609 15.6972H15.3515L18.0423 15.6809C20.4225 15.6646 22.3716 15.502 24.6484 13.5663C27.0631 11.5004 29.8573 6.19744 29.3744 3.13932ZM59.697 0.0161133H54.4708L49.555 4.94491C48.9856 5.51424 48.3993 6.08357 47.8299 6.75053H47.7781L50.2274 0.0161133H45.9674L40.2236 15.6809H44.4839L46.3121 10.7521L48.2093 9.23931L49.7101 13.6964C50.1757 15.079 50.659 15.6809 51.6939 15.6809H54.9537L51.6074 6.92946L59.697 0.0161133Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_794_1638">
                      <rect width="60" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                </button>
              </div>
            : null
              }
            <p className='modal-feedback-text'>Комментарий</p>
            <hr className="modal-feedback-hr" />
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} style={{height: '150px'}} className="modal-feedback-input" placeholder="Комментарий к заказу" />
            <hr className="modal-feedback-hr" />
            <button onClick={() => checkDelivery()} className='modal-btn-submit'>Продолжить</button>
            <p className='modal-feedback-notify'>Нажимая «Продолжить», вы соглашаетесь с Политикой конфиденциальности</p>
          </div>
          :
          <div className="modal-content">
            <span  className="close">&times;</span>
            <h3 className='modal-feedback-header'>Проверка заказа</h3>
            <hr className="modal-feedback-hr" />
            <input value={lastName} disabled className="modal-feedback-input" placeholder="Фамилия" />
            <input value={firstName} disabled className="modal-feedback-input" placeholder="Имя" />
            <input value={surName} disabled className="modal-feedback-input" placeholder="Отчество" />
            <input type="email" value={email} disabled className="modal-feedback-input" placeholder="E-Mail" />
            <input value={phone} disabled className="modal-feedback-input" placeholder="Номер телефона" />
            <input value={country} disabled className="modal-feedback-input" placeholder="Страна" />
            <input value={state} disabled className="modal-feedback-input" placeholder="Область/край" />
            <input value={city} disabled className="modal-feedback-input" placeholder="Город" />
            <input value={address} disabled className="modal-feedback-input" placeholder="Адрес" />
            <input value={indexPurchase} disabled className="modal-feedback-input" placeholder="Индекс" />
            <textarea value={description} disabled style={{height: '150px'}} className="modal-feedback-input" placeholder="Комментарий к заказу" />
            <div style={{marginTop: '50px'}} className='text-purchase_group'>
              <p className='text-purchase_init'>Доставка</p>
              <p className='text-purchase_init'>{postType === 1 ? `Почтой России` : `СДЭК`}</p>
            </div>
            <div className='text-purchase_group'>
              <p className='text-purchase_init'>Сроки доставки</p>
              <p className='text-purchase_init'>{countDelivery} дней</p>
            </div>
            <div className='text-purchase_group'>
              <p className='text-purchase_init'>Стоимость заказа</p>
              <p className='text-purchase_init'>{items.cost} ₽</p>
            </div>
            <div className='text-purchase_group'>
              <p className='text-purchase_init'>Стоимость доставки</p>
              <p className='text-purchase_init'>{deliveryAmount} ₽</p>
            </div>
            <div style={{marginTop: '50px'}} className='text-purchase_group'>
              <p className='text-purchase_init'>ИТОГО</p>
              <p className='text-purchase_init'>{Number(items.cost) + Number(deliveryAmount)} ₽</p>
            </div>
            <div className="btn-purchase_group">
              <button onClick={() => setOrderMode(false)} className='modal-btn-submit'>Изменить</button>
              <button onClick={() => initPayment()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
            <p className='modal-feedback-notify'>Нажимая «Перейти к оплате», вы будете направлены на страницу оплаты</p>
          </div>
          }
        </div>
    <section className="news">
        <div className="container">
          <div className="books-header-box">
            <div className="books-header-box_i">
              <Link to="/books"><button className="cabinet_back">Назад</button></Link>
            </div>
            <div className="books-header-box_i">
            <img className="books-card-logotype-i" src={items.img === null || items.img === undefined ? 'загрузка' : IMG_URL + `/` + items.img} />
            </div>
            <div className="books-header-box_i">
              <h3 className="books-header-box_header">{items.title}</h3>
              <p className="books-header-box_author">Автор – {items.autor}</p>
              <p className="books-header-box_desc">{items.description}</p>
              <p className="books-header-box_special">{items.special}</p>
              <p className="books-header-box_amount">{items.cost} ₽</p>
              {items.books_id === 3 ?
              <button onClick={() => errorNotify('Воспользуйтесь обратной связью для приобретения данной книги')} className="books-header-box_btn">Только по записи</button>
              :
              <button onClick={() => showModalFeedback()} className="books-header-box_btn">Заказать</button>
              }
            </div>
          </div>
          <h3 className='blog-header-other'>Другие книги: </h3>
        </div>

        <div className="container">
          <div className="books-cards_wrapper">
          {otherNews.map((otherNews) => {
            return (
              otherNews.published == true ?
                otherNews.books_id !== items.books_id ?
                <div className="books-cards_item" key={otherNews.books_id}>
                  <div className="books-cards_item-content">
                    <img className="books-cards_item_card" src={IMG_URL + `/` + otherNews.img} />
                    <div className="books-cards_item-title">{otherNews.title}</div>
                    <div style={{marginTop: "20px"}} className="card_text_blog">
                      {otherNews.description}
                    </div>
                    <Link onClick={() => changeNews(otherNews.books_id)} to={`/books/` + otherNews.books_id}><button className="card_btn_blog">Подробнее</button></Link>
                  </div>
                </div>
                : null
              : null
              )
            })}
          </div>
        </div>
    </section>
    </>
  );
}

export default (BookPageId);