import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../http';
import axios from 'axios';
import errorNotify from '../../services/notify';
import { successNotify } from '../../services/notify';
import { IMG_URL } from '../../http';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const CabinetMoviesPage = () => {
    const {store} = useContext(Context);

    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    
    const [activated, setActivated] = useState(true);

    const [role, setRole] = useState(0);
    const [verify, setVerify] = useState(false);

    const [items, setItems] = useState([]);

    const getMediaFiles = async () => {
      const res = await fetch(
        API_URL + `/media/all`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
      const data = await res.json();
      setItems(data);
    };
  
    useEffect(() => {
      getMediaFiles();
    }, [setItems]);

    const [modalVideo, setModalVideo] = useState(true);
    function showModalVideo(ititle, ilink) {
      setTitle(ititle);
      setLink(ilink);
      setModalVideo(!modalVideo);
      if(modalVideo) document.getElementById("myVideo").style.display = "block";
      else document.getElementById("myVideo").style.display = "none";
    }
    function closeModalVideo() {
      setTitle('');
      setLink('');
      setModalVideo(!modalVideo);
      if(modalVideo) document.getElementById("myVideo").style.display = "block";
      else document.getElementById("myVideo").style.display = "none";
    }

    useEffect(() => {
        document.body.style = 'background: #F7F7F5;';
        document.querySelector('footer').style = 'background: #F7F7F5;';
        getProfile();
    }, [])

    async function getProfile() {
      const formData = new FormData()
      formData.append('user', store.user.email)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/getprofile', formData)
      .then(res => {
        setActivated(res?.data?.isActivated);
        setRole(res?.data?.role);
        setVerify(res?.data?.verify);
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    return (
        <>
        <div id="myVideo" className="modal">
          <div className="modal-content-media">
            <span onClick={() => closeModalVideo()} className="close">&times;</span>
            <h3 className='modal-feedback-header' style={{marginBottom: '30px'}}>{title}</h3>
            {link !== '' ?
            <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                // Disable right click
                onContextMenu={e => e.preventDefault()}

                // Your props
                url={'https://ms.elenaera.ru/' + link}
                className="react-player"
                controls
                width="100%"
                height="460px"
            /> 
            :
            null}
          </div>
        </div>

            <div className="cabinet-section">
              <div className="cabinet-header">
                <div className="cabinet-header_item">
                  <h3 className="cabinet-title">Личный кабинет</h3>
                </div>
                <div className="cabinet-header_item">
                  <button onClick={() => store.logout()} className="cabinet-button-exit">Выход</button>
                </div>
              </div>
              <div className="cabinet-btn-group">
                  <Link to="/cabinet"><button className="btn-cabinet">Профиль</button></Link>
                  <Link to="/cabinet/purchase"><button className="btn-cabinet">Мои заказы</button></Link>
                  <button className="btn-cabinet">Уведомления</button>
                  {role >= 1 ? <Link to="/cabinet/videos"><button className="btn-cabinet-active">Учебные материалы</button></Link> : null}
                  {role >= 2 ? <Link to="/cabinet/course"><button className="btn-cabinet">Добавить курс</button></Link> : null}
              </div>
                {activated === false ?
                  <div className='cabinet-activate'>
                    <p>Ваша учетная запись не активирована. Для активации, подтвердите ваш действующий адрес электронной почты!
                      <br />На ваш адрес электронной почты было направлено письмо с инструкцией по подтверждению.
                    </p>
                  </div>
                : role > 0 ?
                    verify === false ?
                    <div className='cabinet-activate'>
                      <p>Ваша учетная запись не проверена модератором! Для прохождения модерации необходимо заполнить все поля,
                        затем подать заявку на модерацию, нажав на кнопку "Отправить"
                      </p>
                    </div>
                    :
                    ``
                    :
                    ``
                 }
                
              <div className="card_block-light" style={{marginTop: '50px'}}>
              {role > 0 ?
                items.map((item) => {
                  return (
                  <div key={item._id} className="card_alt" style={{background: 'white'}}>
                    <div className="card_alt_item">
                      <div className="card_alt_content">
                        <img className="card_alt_logotype" src='https://api.elenaera.ru/video.jpg' style={{marginBottom: '30px', paddingBottom: '0', cursor: 'pointer'}} onClick={() => showModalVideo(item.title, item.link)} />
                        <div className="card_alt_title-light">{item.title}</div>
                      </div>
                    </div>
                  </div>
                  )
                })
              :
              <div className='cabinet-activate' style={{marginBottom: '30px'}}>
                <p>Недостаточно прав!<br /> Если вы считаете, что вам доступен медиа-контент, свяжитесь с администрацией сайта.
                </p>
              </div>
            
              }
              </div>
            </div>
        </>
    );
};

export default observer(CabinetMoviesPage);