import React, { useState, useEffect, useRef } from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../../http/index';
import titleApp from '../../services/title';

function StaticPage() {

  const {id} = useParams();
  const [title, setTitle] = useState('')
  const [shortNews, setShortNews] = useState('')
  const [file, setFile] = useState(null)
  const [fullNews, setFullNews] = useState('')
  const [published, setPublished] = useState('false');
  const [imgTitle, setImgTitle] = useState(null);
  const [items, setItems] = useState([]);
  const [otherNews, setOtherNews] = useState([]);
  const parse = require('html-react-parser');

  const page = {id};

  let limit = 4;
  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 4;
      else if (windowSize.current >= 1024) limit = 4;
      else if (windowSize.current >= 640) limit = 3;
      else if (windowSize.current >= 360) limit = 3;
  }
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/p/${id}`
    );
    const data = await res.json();
    setItems(data);
  };

  
    useEffect(() => {
      getOneNews();
    },[setItems]);

    useEffect(() => {
      document.title = `Новость №${id} | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);

  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 

  if (items.published == false) return (
    <div>Отсутствует страница!</div>
  )

  return (
    <section className="news">
        <div className="container">
          <h2 className="dark_header-blog-i">{items.title}</h2>
          <div className="card_box-blog-i"><p>{items.pubDate === null || items.pubDate === undefined ? 'загрузка' : items.pubDate.split(' ')[0]}</p></div>
          <div className="text-blog-i">  {items.content !== undefined ? parse(items.content) : null}</div>
        </div>
    </section>
  );
}

export default (StaticPage);