import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import titleApp from '../../services/title';
import logo from '../../assets/logo.svg';
import { API_URL } from '../../http';
import { Link } from 'react-router-dom';

export function errorNotify(errorData) {
    toast.error(errorData);
}

export function successNotify(errorData) {
    toast.success(errorData);
}
const AuthPage = () => {

  useEffect(() => {
    document.title = `${titleApp()}`
    setType(0);
  }, []);
  const {store} = useContext(Context);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [type, setType] = useState(0);
  const [role, setRole] = useState(0);

  const [selectCity, setSelectCity] = useState('');
  const [selectCountry, setSelectCountry] = useState('');

  const [vk, setVk] = useState('');
  const [tg, setTg] = useState('');
  const [wp, setWp] = useState('');


  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('');

  const [description, setDescription] = useState('');

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const getDefaultCountry = async () => {
    const res = await fetch(
      API_URL + `/c/default`
    );
    const data = await res.json();
    setCurrentCountry(data._id);
  };

  useEffect(() => {
    getCountry();
  }, [setCountry]);

  useEffect(() => {
    getCity();
  }, [setCity]);

  useEffect(() => {
    getDefaultCountry();
  }, [setCurrentCountry]);


  function changeType(x) {
    setType(x);
  }

  async function validateRegister() {
    if(role == 0) {
      if(email === '') {
        return errorNotify(`Заполните E-Mail!`);
      }
      if(firstName === '') {
        return errorNotify(`Заполните имя!`);
      }
      if(lastName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(password1 === '') {
        return errorNotify(`Заполните пароль`);
      }
      if(password2 === '') {
        return errorNotify(`Заполните повторяющийся пароль!`);
      }
      if (password1 != password2) {
        return errorNotify(`Пароли не совпадают!`);
      }
      if(password1.length < 6) {
        return errorNotify(`Минимальная длина пароля 6 символов!`);
      }
      return store.registration(email, firstName, lastName, role, password1);
    }
    if (role == 1) {
      if(email === '') {
        return errorNotify(`Заполните E-Mail!`);
      }
      if(firstName === '') {
        return errorNotify(`Заполните имя!`);
      }
      if(lastName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(surName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(selectCountry == '') {
        return errorNotify(`Выберите страну!`);
      }
      if(selectCity == '') {
        return errorNotify(`Выберите город!`);
      }
      if(description == '') {
        return errorNotify(`Заполните поле о вас!`);
      }
      if(vk == '') {
        return errorNotify(`Заполните поле Вконтакте в формате логина!`);
      }
      if(tg == '') {
        return errorNotify(`Заполните поле Telegram в формате логина!`);
      }
      if(wp == '') {
        return errorNotify(`Заполните поле WhatsApp в формате номера телефона!`);
      }
      if(password1 === '') {
        return errorNotify(`Заполните пароль`);
      }
      if(password2 === '') {
        return errorNotify(`Заполните повторяющийся пароль!`);
      }
      if (password1 != password2) {
        return errorNotify(`Пароли не совпадают!`);
      }
      if(password1.length < 6) {
        return errorNotify(`Минимальная длина пароля 6 символов!`);
      }
      let roleType = 0;
      return store.registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, role, password1, roleType);
    }
    if (role == 2) {
      if(email === '') {
        return errorNotify(`Заполните E-Mail!`);
      }
      if(firstName === '') {
        return errorNotify(`Заполните имя!`);
      }
      if(lastName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(surName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(selectCountry == '') {
        return errorNotify(`Выберите страну!`);
      }
      if(selectCity == '') {
        return errorNotify(`Выберите город!`);
      }
      if(description == '') {
        return errorNotify(`Заполните поле о вас!`);
      }
      if(vk == '') {
        return errorNotify(`Заполните поле Вконтакте в формате логина!`);
      }
      if(tg == '') {
        return errorNotify(`Заполните поле Telegram в формате логина!`);
      }
      if(wp == '') {
        return errorNotify(`Заполните поле WhatsApp в формате номера телефона!`);
      }
      if(password1 === '') {
        return errorNotify(`Заполните пароль`);
      }
      if(password2 === '') {
        return errorNotify(`Заполните повторяющийся пароль!`);
      }
      if (password1 != password2) {
        return errorNotify(`Пароли не совпадают!`);
      }
      if(password1.length < 6) {
        return errorNotify(`Минимальная длина пароля 6 символов!`);
      }
      let roleType = 1;
      return store.registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, 1, password1, roleType);
    }
    if (role == 3) {
      if(email === '') {
        return errorNotify(`Заполните E-Mail!`);
      }
      if(firstName === '') {
        return errorNotify(`Заполните имя!`);
      }
      if(lastName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(surName === '') {
        return errorNotify(`Заполните фамилию!`);
      }
      if(selectCountry == '') {
        return errorNotify(`Выберите страну!`);
      }
      if(selectCity == '') {
        return errorNotify(`Выберите город!`);
      }
      if(description == '') {
        return errorNotify(`Заполните поле о вас!`);
      }
      if(vk == '') {
        return errorNotify(`Заполните поле Вконтакте в формате логина!`);
      }
      if(tg == '') {
        return errorNotify(`Заполните поле Telegram в формате логина!`);
      }
      if(wp == '') {
        return errorNotify(`Заполните поле WhatsApp в формате номера телефона!`);
      }
      if(password1 === '') {
        return errorNotify(`Заполните пароль`);
      }
      if(password2 === '') {
        return errorNotify(`Заполните повторяющийся пароль!`);
      }
      if (password1 != password2) {
        return errorNotify(`Пароли не совпадают!`);
      }
      if(password1.length < 6) {
        return errorNotify(`Минимальная длина пароля 6 символов!`);
      }
      let roleType = 2;
      return store.registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, 1, password1, roleType);
    }
  }

    if (type === 1) {
      return (
      <div className="router">
        <ToastContainer />
        <section className="form-reg">
          <div className="form-container">
            <div className="form-card">
              <Link to ="/"><img src={logo} className="form-logo" /></Link>
              <div className="form-btn-group">
                <div className="form-btn-wrapper">
                  <div className="form-btn-l" onClick={() => changeType(0)}>
                    Вход
                  </div>
                  <div className="form-btn-active-r" onClick={() => changeType(1)}>
                    Регистрация
                  </div>
                </div>
              </div>
              
              <div className="form-input-wrapper">
                <p className="form-info">Выберите тип пользования</p>
                <div className="select-wrapper">
                  <select defaultValue={role} onChange={e => setRole(e.target.value)} className="form-select">
                    <option value={0}>Обычный пользователь </option>
                    <option value={1}>Практик «Сияющие руки»</option>
                    <option value={2}>Практик «Соединения миров»</option>
                    <option value={3}>Практик «Сияющие руки» и «Соединения миров»</option>
                  </select>
                </div>
                <p className="form-info_2">Заполните обязательные поля</p>
                <input value={email} onChange={e => setEmail(e.target.value)} className="form-input" placeholder="E-Mail" type="email" />
                <input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-input" placeholder="Имя" type="text" />
                <input value={lastName} onChange={e => setLastName(e.target.value)} className="form-input" placeholder="Фамилия" type="text" />
                {role >= 1 ?
                  <>
                    <input value={surName} onChange={e => setSurName(e.target.value)} className="form-input" placeholder="Отчество" type="text" />
                    <div className="select-wrapper">
                      <select defaultValue={selectCountry} onChange={e => setSelectCountry(e.target.value)} className="form-select">
                      <option value='' disabled>Выберите страну</option>
                        {country.map((country) => {
                          return (
                            <option key={country._id} value={country._id}>{country.name}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="select-wrapper">
                      <select defaultValue={selectCity} onChange={e => setSelectCity(e.target.value)} className="form-select">
                        <option value='' disabled>Выберите город</option>
                        {city.map((city) => {
                          return (
                            selectCountry === city.cid ?
                              <option key={city._id} value={city._id}>{city.name}</option>
                            :
                            ``
                          )
                        })}
                      </select>
                    </div>
                    <textarea style={{ height: '150px' }} className="form-input" placeholder="О себе" type="text" value={description} onChange={e => setDescription(e.target.value)} />
                    <div className='social-wrapper-vk'>
                       <input value={vk} onChange={e => setVk(e.target.value)} className="form-input" style={{ paddingLeft: '50px' }} placeholder="Вконтакте" type="text" />
                    </div>
                    <div className='social-wrapper-tg'>
                       <input value={tg} onChange={e => setTg(e.target.value)} className="form-input" style={{ paddingLeft: '50px' }} placeholder="Telegram" type="text" />
                    </div>
                    <div className='social-wrapper-wp'>
                       <input value={wp} onChange={e => setWp(e.target.value)} className="form-input" style={{ paddingLeft: '50px' }} placeholder="WhatsApp" type="text" />
                    </div>
                  </>
                  :
                  ``
                }
                <input value={password1} onChange={e => setPassword1(e.target.value)} className="form-input" placeholder="Пароль" type="password" />
                <input value={password2} onChange={e => setPassword2(e.target.value)} className="form-input" placeholder="Повторите пароль" type="password" />
                <button className="form-btn-submit-r" onClick={() => validateRegister()}>Отправить</button>
              </div>
            </div>
            <div className="form-footer">
              <p>
                © 2023, Елена Эра
              </p>
            </div>
          </div>
        </section>
      </div>
      );
    }

    if (type === 2) {
      return (
        <div className="router">
        <ToastContainer />
        <section className="form">
          <div className="form-container">
            <div className="form-card">
            <Link to ="/"><img src={logo} className="form-logo" /></Link>
              <div className="form-btn-group">
                <div className="form-btn-wrapper">
                  <div className="form-btn-active" onClick={() => changeType(0)}>
                    Вход
                  </div>
                  <div className="form-btn" onClick={() => changeType(1)}>
                    Регистрация
                  </div>
                </div>
              </div>
              <div className="form-input-wrapper">
              <p className="form-info">Введите почту для восстановления</p>
                <input className="form-input" placeholder="E-Mail" type="email" onChange={e => setEmail(e.target.value)} value={email} />
                <button className="form-btn-submit-alt" onClick={() => store.restore(email)}>Восстановить пароль</button>
              </div>
            </div>
            <div className="form-footer">
              <p>
                © 2023, Елена Эра
              </p>
            </div>
          </div>
        </section>
      </div>
      );
    }

    return (
      <div className="router">
        <ToastContainer />
        <section className="form">
          <div className="form-container">
            <div className="form-card">
            <Link to ="/"><img src={logo} className="form-logo" /></Link>
              <div className="form-btn-group">
                <div className="form-btn-wrapper">
                  <div className="form-btn-active" onClick={() => changeType(0)}>
                    Вход
                  </div>
                  <div className="form-btn" onClick={() => changeType(1)}>
                    Регистрация
                  </div>
                </div>
              </div>
              <div className="form-input-wrapper">
                <input className="form-input" placeholder="E-Mail" type="email" onChange={e => setEmail(e.target.value)} value={email} />
                <input className="form-input" placeholder="Пароль" type="password" onChange={e => setPassword(e.target.value)} value={password} />
                <a onClick={() => changeType(2)} className="form-anchor">Не помню</a>
                <button className="form-btn-submit" onClick={() => store.login(email, password)}>Войти</button>
              </div>
            </div>
            <div className="form-footer">
              <p>
                © 2023, Елена Эра
              </p>
            </div>
          </div>
        </section>
      </div>
    );
};

export default observer(AuthPage);