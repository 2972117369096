import React from 'react';
import axios from 'axios';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logo.svg';
import { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';

const Header = () => {
    const [navbar, setNavbar] = useState(true);
    const [practics, setPractics] = useState(true);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [settings, setSettings] = useState([]);
    const getSettings = async () => {
      const res = await fetch(
        API_URL + `/s`, {
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
         });
      const data = await res.json();
      setSettings(data);
    };
  
    useEffect(() => {
      getSettings();
    }, [setSettings]);


    const [modalFeedback, setModalFeedback] = useState(true);
    function showModalFeedback() {
      setModalFeedback(!modalFeedback);
      if(modalFeedback) document.getElementById("myModal").style.display = "block";
      else document.getElementById("myModal").style.display = "none";
    }

    function postTicket() {
      if(name === '') {
        return errorNotify('Заполните имя!');
      }
      if(phone === '') {
        return errorNotify('Заполните номер телефона!');
      }
      if(message === '') {
        return errorNotify('Заполните комментарий!');
      }
      const formData = new FormData()
      formData.append('name', name)
      formData.append('phone', phone)
      formData.append('message', message)
      formData.append('ticketStatus', 1)
      axios.post(API_URL + '/t/create', formData)
      .then(res => {
        successNotify('Спасибо! Мы обязательно свяжемся с вами!');
        setName('');
        setPhone('');
        setMessage('');
        showModalFeedback();
      })
      .catch(err => errorNotify(err.response.data.message));
    }


    function showpractics() {
      setPractics(!practics);
      if(practics) document.getElementById("sidenavdrop").style.display = "block";
      else document.getElementById("sidenavdrop").style.display = "none";
    }


    const windowSize = useRef(window.innerWidth);
    function toggleNavbar() {
      setNavbar(!navbar);
      if (!navbar) document.getElementById("mySidenav").style.width = "0";
      else {
        if (windowSize.current >= 1024) document.getElementById("mySidenav").style.width = "440px";
        else if (windowSize.current >= 640) document.getElementById("mySidenav").style.width = "385px";
        else if (windowSize.current >= 360) document.getElementById("mySidenav").style.width = "310px";
      }
    }
    return (
      <>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <span onClick={() => showModalFeedback()} className="close">&times;</span>
            <h3 className='modal-feedback-header'>Заказать звонок</h3>
            <p className='modal-feedback-text'>Оставьте свои данные и мы вам перезвоним</p>
            <hr className="modal-feedback-hr" />
            <input onChange={e => setName(e.target.value)} value={name} className="modal-feedback-input" placeholder="Имя" />
            <input onChange={e => setPhone(e.target.value)} value={phone} className="modal-feedback-input" placeholder="Номер телефона" />
            <textarea onChange={e => setMessage(e.target.value)} value={message} style={{height: '150px'}} className="modal-feedback-input" placeholder="Комментарий" />
            <button onClick={() => postTicket()} className='modal-btn-submit'>Отправить</button>
            <p className='modal-feedback-notify'>Нажимая «Отправить», вы соглашаетесь с Политикой конфиденциальности</p>
          </div>
        </div>
        <div id="mySidenav" className="sidenav">
          <div className="sidenav-group">
            <Link onClick={() => toggleNavbar()} to="/"><img className="sidenav_block-logotype" src={Logo} /></Link>
            <a onClick={() => toggleNavbar()} className="closebtn">&times;</a>
          </div>
          <div className="">
            <hr className="sidenav-hr" />
          </div>
          <a onClick={() => toggleNavbar()} href="/#programs">Курсы</a>
          <a onClick={() => toggleNavbar()} href="/#programs">Семинары</a>
          <Link onClick={() => toggleNavbar()} to="/schedule">Расписание</Link>
          <a onClick={() => showpractics()}>Практики</a>
          <div id="sidenavdrop">
            <Link onClick={() => toggleNavbar()} to="/shining-hands">- Практики метода «Сияющие руки»</Link>
            <Link onClick={() => toggleNavbar()} to="/connectionoftheworlds">- Практики метода «Соединение миров»</Link>
          </div>
          <Link onClick={() => toggleNavbar()} to="/masters">Мастера</Link>
          <Link onClick={() => toggleNavbar()} to="/books">Книги</Link>
          <Link onClick={() => toggleNavbar()} to="/blog">Блог</Link>
          <div className="sidenav-cursive">
            <p>
              Остались вопросы?<br />Пишите — мы всегда на связи!
            </p>
            <div className="sidenav-vector">
              <svg width="17" height="41" viewBox="0 0 17 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3703 38.8342C16.7636 38.7043 16.9771 38.2801 16.8471 37.8868L14.7296 31.4775C14.5997 31.0842 14.1755 30.8707 13.7822 31.0007C13.3889 31.1306 13.1754 31.5548 13.3053 31.9481L15.1876 37.6452L9.49043 39.5274C9.09713 39.6574 8.88363 40.0815 9.01357 40.4748C9.14351 40.8681 9.56768 41.0816 9.96099 40.9517L16.3703 38.8342ZM8.91014 0.451707C4.92144 4.17446 0.410896 10.5842 0.0334529 17.7012C-0.156427 21.2816 0.702693 25.0206 3.17289 28.6417C5.63677 32.2535 9.67806 35.7118 15.7978 38.792L16.4722 37.4521C10.5117 34.4521 6.69518 31.1433 4.41203 27.7964C2.13519 24.4588 1.3581 21.0474 1.53135 17.7807C1.88016 11.2036 6.09321 5.13264 9.93361 1.54829L8.91014 0.451707Z" fill="#939393"/>
              </svg>
            </div>
          </div>
          <div className="sidenav-social-block">
            <a style={{margin: 0, padding: 0}} href={settings.l_telegram} target='_blank'>
              <button className="sidenav_button">
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.28861 6.66471C5.80178 4.6984 8.81127 3.40208 10.3171 2.77577C14.6165 0.98751 15.5098 0.676871 16.0921 0.666613C16.2202 0.664357 16.5065 0.696096 16.692 0.846606C16.8486 0.973694 16.8917 1.14537 16.9124 1.26586C16.933 1.38636 16.9587 1.66084 16.9383 1.87532C16.7053 4.32331 15.6971 10.2639 15.1843 13.0057C14.9673 14.1659 14.5399 14.5549 14.1263 14.5929C13.2272 14.6757 12.5445 13.9988 11.6738 13.428C10.3112 12.5348 9.54142 11.9788 8.2188 11.1072C6.6903 10.1 7.68116 9.54634 8.55226 8.64159C8.78023 8.40481 12.7414 4.80181 12.8181 4.47496C12.8277 4.43408 12.8366 4.28171 12.746 4.20125C12.6555 4.12079 12.5219 4.1483 12.4255 4.17019C12.2889 4.2012 10.1121 5.63994 5.89532 8.48639C5.27747 8.91066 4.71783 9.11738 4.21642 9.10655C3.66365 9.0946 2.60034 8.794 1.80988 8.53705C0.84035 8.2219 0.0697868 8.05527 0.136886 7.52004C0.171835 7.24126 0.555744 6.95615 1.28861 6.66471Z" fill="white"/>
                </svg>
              </button>
            </a>
            <a style={{margin: 0, padding: 0}} href={settings.l_whatsapp} target='_blank'>
              <button className="sidenav_button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.476562 23.0541L2.10571 17.1382C1.0985 15.4018 0.570128 13.4353 0.575632 11.4249C0.575632 5.12557 5.72725 0.00390625 12.0512 0.00390625C15.1224 0.00390625 18.0064 1.19257 20.1694 3.35079C22.3379 5.50901 23.5323 8.37933 23.5268 11.4304C23.5268 17.7298 18.3751 22.8515 12.0457 22.8515H12.0402C10.1193 22.8515 8.23151 22.3694 6.55283 21.4601L0.476562 23.0541ZM6.84454 19.395L7.19128 19.6032C8.65531 20.4686 10.334 20.9233 12.0457 20.9288H12.0512C17.3074 20.9288 21.5894 16.6726 21.5894 11.4359C21.5894 8.89971 20.5987 6.51691 18.7989 4.72022C16.9992 2.92353 14.5995 1.93754 12.0512 1.93754C6.795 1.93206 2.51299 6.18825 2.51299 11.4249C2.51299 13.2162 3.01385 14.9635 3.97152 16.4754L4.19718 16.8369L3.234 20.3372L6.84454 19.395Z" fill="white"/>
                  <path d="M0.878906 22.6543L2.45301 16.941C1.47883 15.2703 0.966968 13.3696 0.966968 11.4304C0.972472 5.35018 5.94247 0.403809 12.0518 0.403809C15.0183 0.403809 17.7978 1.55413 19.8893 3.63566C21.9807 5.71719 23.131 8.48891 23.131 11.4359C23.131 17.5162 18.1555 22.4626 12.0518 22.4626H12.0463C10.1915 22.4626 8.36967 21.997 6.75153 21.1205L0.878906 22.6543Z" fill="#7788BB"/>
                  <path d="M0.476562 23.0541L2.10571 17.1382C1.0985 15.4018 0.570128 13.4353 0.575632 11.4249C0.575632 5.12557 5.72725 0.00390625 12.0512 0.00390625C15.1224 0.00390625 18.0064 1.19257 20.1694 3.35079C22.3379 5.50901 23.5323 8.37933 23.5268 11.4304C23.5268 17.7298 18.3751 22.8515 12.0457 22.8515H12.0402C10.1193 22.8515 8.23151 22.3694 6.55283 21.4601L0.476562 23.0541ZM6.84454 19.395L7.19128 19.6032C8.65531 20.4686 10.334 20.9233 12.0457 20.9288H12.0512C17.3074 20.9288 21.5894 16.6726 21.5894 11.4359C21.5894 8.89971 20.5987 6.51691 18.7989 4.72022C16.9992 2.92353 14.5995 1.93754 12.0512 1.93754C6.795 1.93206 2.51299 6.18825 2.51299 11.4249C2.51299 13.2162 3.01385 14.9635 3.97152 16.4754L4.19718 16.8369L3.234 20.3372L6.84454 19.395Z" fill="url(#paint0_linear_193_123)"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.18374 6.64837C8.96909 6.17181 8.74343 6.16086 8.53978 6.15538C8.37467 6.1499 8.18203 6.1499 7.9894 6.1499C7.79676 6.1499 7.48855 6.22111 7.22436 6.50595C6.96017 6.79079 6.22266 7.48099 6.22266 8.88876C6.22266 10.2911 7.25188 11.6495 7.39498 11.8412C7.53808 12.033 9.38188 15.0074 12.2934 16.1522C14.7151 17.1053 15.2105 16.9136 15.7333 16.8643C16.2562 16.815 17.4285 16.1741 17.6707 15.5058C17.9074 14.8376 17.9074 14.2679 17.8358 14.1474C17.7643 14.0269 17.5716 13.9556 17.2854 13.8132C16.9992 13.6708 15.5902 12.9806 15.3261 12.882C15.0619 12.7889 14.8692 12.7396 14.6821 13.0244C14.4895 13.3093 13.9391 13.9502 13.774 14.1419C13.6088 14.3336 13.4382 14.3555 13.152 14.2131C12.8658 14.0707 11.9412 13.7694 10.8459 12.7944C9.99281 12.0384 9.4149 11.1018 9.24978 10.8169C9.08467 10.5321 9.23327 10.3787 9.37637 10.2363C9.50296 10.1103 9.66257 9.90214 9.80567 9.73781C9.94877 9.57347 9.99831 9.45296 10.0919 9.26124C10.1854 9.06952 10.1414 8.90519 10.0699 8.76277C9.99831 8.62583 9.43691 7.21258 9.18374 6.64837Z" fill="white"/>
                  <defs>
                    <linearGradient id="paint0_linear_193_123" x1="12.0039" y1="23.0519" x2="12.0039" y2="0.00390625" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#F9F9F9"/>
                      <stop offset="1" stopColor="white"/>
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </a>
          </div>
        </div>
        <header>
          <div className="container">
            <div className="header_block">
              <Link to="/"><img className="header_block-logotype" src={Logo} /></Link>
              <div className="nav-group">
                <a href="/#programs">Курсы</a>
                <a href="/#programs">Семинары</a>
                <Link to="/schedule">Расписание</Link>
                <div className="dropdown">
                  <button className="dropbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropdown-content">
                    <Link to="/shining-hands">Практики метода «Сияющие руки»</Link>
                    <Link to="/connectionoftheworlds">Практики метода «Соединение миров»</Link>
                  </div>
                </div>
                <Link to="/masters">Мастера</Link>
                <Link to="/books" className="header_block-p">Книги</Link>
                <Link to="/blog">Блог</Link>
              </div>
              <div className="btn-group-nav">
                <button onClick={() => toggleNavbar()} type="button" className="btn_3-sm">
                  &nbsp;
                </button>
                <button type="button" onClick={() => showModalFeedback()} className="btn_1-sm">
                  &nbsp;
                </button>
                <Link to="/cabinet">
                  <button className="btn_2-sm">
                    &nbsp;
                  </button>
                </Link>
                <button onClick={() => toggleNavbar()} type="button" className="btn_3">
                  Меню
                </button>
                <button type="button" onClick={() => showModalFeedback()} className="btn_1">
                  Заказать звонок
                </button>
                <NavLink to="/cabinet">
                <button className="btn_2">
                  Профиль
                </button>
                </NavLink>
              </div>
            </div>
          </div>
        </header>
      </>
    );
};

export default observer(Header);