import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {API_URL} from '../../http/index';
import { IMG_URL } from '../../http/index';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const VideosPage = () => {
  const [items, setItems] = useState([]);
  const [settings, setSettings] = useState([]);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const getMediaFiles = async () => {
    const res = await fetch(
      API_URL + `/videos`);
    const data = await res.json();
    setItems(data);
  };

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);

  useEffect(() => {
    getMediaFiles();
  }, [setItems]);

  const [modalVideo, setModalVideo] = useState(true);
  function showModalVideo(ititle, ilink) {
    setTitle(ititle);
    setLink(ilink);
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  function closeModalVideo() {
    setTitle('');
    setLink('');
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }

  useEffect(() => {
    document.body.style = 'background: #F7F7F5;';
    document.querySelector('footer').style = 'background: #F7F7F5;';
  }, [])

  return (
<>
  <div id="myVideo" className="modal">
    <div className="modal-content-media">
      <span onClick={() => closeModalVideo()} className="close">&times;</span>
      <h3 className='modal-feedback-header' style={{marginBottom: '30px'}}>{title}</h3>
      {link !== '' ?
      <ReactPlayer
          // Disable download button
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}

          // Disable right click
          onContextMenu={e => e.preventDefault()}

          // Your props
          url={'https://ms.elenaera.ru/' + link}
          className="react-player"
          controls
          width="100%"
          height="460px"
      /> 
      :
      null}
    </div>
  </div>
  <div className="cabinet-section">
    <div className="cabinet-header">
      <div className="cabinet-header_item">
        <h3 className="cabinet-title">Наши видеоматериалы</h3>
      </div>
    </div>
    <div className='blog-provider'>
      <div className="blog-btn-group">
        <Link to="/blog"><button className="btn-cabinet-alt">Наши события</button></Link>
        <Link to="/videos"><button className="btn-cabinet-active-alt">Наши видеоматериалы</button></Link>
      </div>
      <div className="blog-btn-group">
        <a style={{textDecoration: 'none'}} href={settings.l_zen}><button className="blog-btn-zen">Читайте нас</button></a>
      </div>
    </div>
    <div className="cabinet-table">
    <div className="card_block-alt">
    {items.map((item) => {
      return (
        item.published == true ?
          <div key={item._id} className="card_alt" style={{background: 'white'}}>
            <div className="card_alt_item">
              <div className="card_alt_content">
                <img className="card_alt_logotype" src='https://api.elenaera.ru/video.jpg' style={{marginBottom: '30px', paddingBottom: '0', cursor: 'pointer'}} onClick={() => showModalVideo(item.title, item.link)} />
                <div className="card_alt_title-light">{item.title}</div>
              </div>
            </div>
          </div>
        : null
        )
      })}
    </div>
    </div>
  </div>
</>
  );
};

export default VideosPage;