import React from 'react';
import {observer} from "mobx-react-lite";
import platform1 from '../assets/platform_1.png';
import platform2 from '../assets/platform_2.jpg';
import platform3 from '../assets/platform_3-alt.png';

const SMP = () => {
  return (
    <section className="platform">
      <div className="container">
        <h2 className="dark_header"><span>Платформа создана </span> для того, чтобы передать авторский опыт удивительных жизненных трансформаций</h2>
        <p className="platform_text">... которые доступны каждому. Но это естественное знание вытравлено из нас предрассудками, «рациональностью» и неверием в собственные силы.</p>
        <div className="platform_cursive">
          <p>Фото со встреч<br/>наших учеников</p>
        </div>
        <div className="platform_vector">
          <svg width="16" height="56" viewBox="0 0 16 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6179 54.0655C15.1634 53.9793 15.5358 53.4673 15.4497 52.9217L14.046 44.0319C13.9599 43.4863 13.4478 43.1139 12.9023 43.2001C12.3568 43.2862 11.9843 43.7983 12.0705 44.3438L13.3182 52.2459L5.41603 53.4936C4.87051 53.5797 4.4981 54.0918 4.58423 54.6373C4.67037 55.1829 5.18243 55.5553 5.72796 55.4691L14.6179 54.0655ZM9.14559 0C5.23612 5.52426 0.771478 14.8223 0.0891875 24.8221C-0.597172 34.8816 2.55386 45.6538 13.8737 53.8864L15.0501 52.269C4.36986 44.5016 1.43757 34.4405 2.08454 24.9583C2.73559 15.4164 7.02094 6.46441 10.7782 1.15534L9.14559 0Z" fill="#B5B5B5"/>
          </svg>
        </div>
        <div className="platform_block">
          <img className="platform_item_1" src={platform1} />
          <img className="platform_item_2" src={platform2} />
          <img className="platform_item_3" src={platform3} />
        </div>
      </div>
    </section>
    );
};

export default observer(SMP);