import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../http';
import axios from 'axios';
import errorNotify from '../../services/notify';
import { successNotify } from '../../services/notify';
import { IMG_URL } from '../../http';
import { Link } from 'react-router-dom';

const CabinetPage = () => {
    const {store} = useContext(Context);
    
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [activated, setActivated] = useState(true);

    const [role, setRole] = useState(0);
    const [verify, setVerify] = useState(false);

    const [oldpassword, setOldpassword] = useState('');
    const [checkpassword, setCheckpassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [description, setDescription] = useState('');

    const [vk, setVk] = useState('');
    const [tg, setTg] = useState('');
    const [wp, setWp] = useState('');

    const [city, setCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [currentCountry, setCurrentCountry] = useState('');
    const [selectCity, setSelectCity] = useState('');
    const [selectCountry, setSelectCountry] = useState('');
    const [img, setImg] = useState('');
    const [file, setFile] = useState(null);
    const selectFile = e => {
      setFile(e.target.files[0])
    }

    const getCountry = async () => {
      const res = await fetch(
        API_URL + `/c`
      );
      const data = await res.json();
      setCountry(data);
    };
  
    const getCity = async () => {
      const res = await fetch(
        API_URL + `/c/city`
      );
      const data = await res.json();
      setCity(data);
    };
  
    const getDefaultCountry = async () => {
      const res = await fetch(
        API_URL + `/c/default`
      );
      const data = await res.json();
      setCurrentCountry(data._id);
    };
  
    useEffect(() => {
      getCountry();
    }, [setCountry]);
  
    useEffect(() => {
      getCity();
    }, [setCity]);
  
    useEffect(() => {
      getDefaultCountry();
    }, [setCurrentCountry]);

    useEffect(() => {
        document.body.style = 'background: #F7F7F5;';
        document.querySelector('footer').style = 'background: #F7F7F5;';
        getProfile();
    }, [])

    async function getProfile() {
      const formData = new FormData()
      formData.append('user', store.user.email)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/getprofile', formData)
      .then(res => {
        setLastName(res?.data?.lastName);
        setFirstName(res?.data?.firstName);
        setSurName(res?.data?.surName);
        setPhone(res?.data?.phone);
        setEmail(res?.data?.email);
        setCheckpassword(res?.data?.password);
        setActivated(res?.data?.isActivated);
        setRole(res?.data?.role);
        setVerify(res?.data?.verify);
        setSelectCountry(res?.data?.countryid);
        setSelectCity(res?.data?.cityid);
        setDescription(res?.data?.description);
        setVk(res?.data?.vk);
        setTg(res?.data?.tg);
        setWp(res?.data?.wp);
        setImg(res?.data?.img);
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    async function verifyPassword() {
      if(oldpassword === '' || oldpassword === null) {
        return errorNotify('Введите текущий пароль!');
      }
      const formData = new FormData()
      formData.append('uid', store.user.id)
      formData.append('password', oldpassword)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/verifyuserpassword', formData)
      .then(res => {
        changePassword();
      })
      .catch(err => errorNotify('Текущий пароль указан неверно!'));
    }

    async function updateUser() {
      if(lastName === '' || lastName === null) {
        return errorNotify('Введите фамилию!');
      }
      if(firstName === '' || firstName === null) {
        return errorNotify('Введите Имя!');
      }
      if(email == '' || email === null) {
        return errorNotify('Введите E-Mail!');
      }
      const formData = new FormData()
      formData.append('uid', store.user.id)
      formData.append('lastName', lastName)
      formData.append('firstName', firstName)
      formData.append('surName', surName)
      formData.append('phone', phone)
      formData.append('email', email)

      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/updateuser', formData)
      .then(res => {
        successNotify('Данные профиля успешно изменены!');
      })
      .catch(err => errorNotify(err.response.data.message));
    }
    async function updatePractic() {
      if(lastName === '' || lastName === null) {
        return errorNotify('Заполните фамилию!');
      }
      if(firstName === '' || firstName === null) {
        return errorNotify('Заполните имя!');
      }
      if(surName === '' || surName === null) {
        return errorNotify('Заполните отчество!');
      }
      if(email == '' || email === null) {
        return errorNotify('Заполните E-Mail!');
      }
      if(phone == '' || phone === null) {
        return errorNotify('Введите номер телефона');
      }
      if(description == '' || description === null) {
        return errorNotify('Заполните поле о себе');
      }
      if(vk == '' || vk === null) {
        return errorNotify('Заполните адрес VK');
      }
      if(tg == '' || tg === null) {
        return errorNotify('Заполните адрес Telegram');
      }
      if(wp == '' || wp === null) {
        return errorNotify('Заполните адрес WhatsApp');
      }
      const formData = new FormData()
      formData.append('uid', store.user.id)
      formData.append('lastName', lastName)
      formData.append('firstName', firstName)
      formData.append('surName', surName)
      formData.append('phone', phone)
      formData.append('email', email)
      formData.append('description', description)
      formData.append('vk', vk)
      formData.append('tg', tg)
      formData.append('wp', wp)
      formData.append('countryid', selectCountry)
      formData.append('cityid', selectCity)
      if(file != null) {
        formData.append('img', file)
      }

      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/updatepractic', formData)
      .then(res => {
        successNotify('Данные профиля успешно изменены!');
        getProfile();
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    async function postModeration() {
      const formData = new FormData()
      formData.append('uid', store.user.id)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/m/postmoderation', formData)
      .then(res => {
        successNotify('Заявка отправлена на модерацию!');
      })
      .catch(err => errorNotify(err.response.data.message));
    }





    async function changePassword() {
      if(password1 === '' || password1 === null) {
        return errorNotify('Введите новый пароль!');
      }
      if(password2 == '' || password2 === null) {
        return errorNotify('Введите подтверждение пароля!');
      }
      if(password1 != password2) {
        return errorNotify('Пароли не совпадают!');
      }
      if(oldpassword === password2) {
        return errorNotify('Пароль должен отличаться от текущего!');
      }
      const formData = new FormData()
      formData.append('uid', store.user.id)
      formData.append('password', password2)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/changeuserpassword', formData)
      .then(res => {
        setPassword1('');
        setPassword2('');
        setOldpassword('');
        successNotify('Пароль успешно изменен!');
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    return (
        <>
            <div className="cabinet-section">
              <div className="cabinet-header">
                <div className="cabinet-header_item">
                  <h3 className="cabinet-title">Личный кабинет</h3>
                </div>
                <div className="cabinet-header_item">
                  <button onClick={() => store.logout()} className="cabinet-button-exit">Выход</button>
                </div>
              </div>
              <div className="cabinet-btn-group">
                  <Link to="/cabinet"><button className="btn-cabinet-active">Профиль</button></Link>
                  <Link to="/cabinet/purchase"><button className="btn-cabinet">Мои заказы</button></Link>
                  <button className="btn-cabinet">Уведомления</button>
                  {role >= 1 ? <Link to="/cabinet/videos"><button className="btn-cabinet">Учебные материалы</button></Link> : null}
                  {role >= 2 ? <Link to="/cabinet/course"><button className="btn-cabinet">Добавить курс</button></Link> : null}
              </div>
                {activated === false ?
                  <div className='cabinet-activate'>
                    <p>Ваша учетная запись не активирована. Для активации, подтвердите ваш действующий адрес электронной почты!
                      <br />На ваш адрес электронной почты было направлено письмо с инструкцией по подтверждению.
                    </p>
                  </div>
                : role > 0 ?
                    verify === false ?
                    <div className='cabinet-activate'>
                      <p>Ваша учетная запись не проверена модератором! Для прохождения модерации необходимо заполнить все поля,
                        затем подать заявку на модерацию, нажав на кнопку "Отправить"
                      </p>
                    </div>
                    :
                    ``
                    :
                    ``
                 }
              <div className="cabinet-settings">
                <div className="cabinet-settings-item">
                  <h5 className="cabinet-settings-title">Мои данные</h5>
                  <input onChange={e => setLastName(e.target.value)} value={lastName} placeholder="Фамилия" className="cabinet-settings-input" />
                  <input onChange={e => setFirstName(e.target.value)} value={firstName} placeholder="Имя" className="cabinet-settings-input" />
                  <input onChange={e => setSurName(e.target.value)} value={surName} placeholder={role > 0 ? `Отчество` : `Отчество (не обязательно)`}  className="cabinet-settings-input" />
                  {role > 0 ?
                    <>
                      <div className="select-wrapper">
                        <select defaultValue={selectCountry} onChange={e => setSelectCountry(e.target.value)} className="cabinet-settings-input">
                        <option value='' disabled>Выберите страну</option>
                          {country.map((country) => {
                            return (
                              <option key={country._id} value={country._id}>{country.name}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="select-wrapper">
                        <select defaultValue={selectCity} onChange={e => setSelectCity(e.target.value)} className="cabinet-settings-input">
                          <option value='' disabled>Выберите город</option>
                          {city.map((city) => {
                            return (
                              selectCountry === city.cid ?
                                <option key={city._id} value={city._id}>{city.name}</option>
                              :
                              ``
                            )
                          })}
                        </select>
                      </div>
                      <textarea style={{ height: '236px' }} className="cabinet-settings-input" placeholder="О себе" type="text" value={description} onChange={e => setDescription(e.target.value)} />
                      <div className='social-wrapper-vk'>
                       <input value={vk} onChange={e => setVk(e.target.value)} className="cabinet-settings-input" style={{ paddingLeft: '50px' }} placeholder="Вконтакте" type="text" />
                      </div>
                      <div className='social-wrapper-tg'>
                        <input value={tg} onChange={e => setTg(e.target.value)} className="cabinet-settings-input" style={{ paddingLeft: '50px' }} placeholder="Telegram" type="text" />
                      </div>
                      <div className='social-wrapper-wp'>
                        <input value={wp} onChange={e => setWp(e.target.value)} className="cabinet-settings-input" style={{ paddingLeft: '50px' }} placeholder="WhatsApp" type="text" />
                      </div>
                    </>
                  :
                  ``
                  }
                  <input onChange={e => setPhone(e.target.value)} value={phone} placeholder="Номер телефона" className="cabinet-settings-input" />
                  <input onChange={e => setEmail(e.target.value)} value={email} type="email" placeholder="E-Mail" className="cabinet-settings-input" />
                  {role > 0 ?
                  <div className='cabinet-image'>
                    <p>Фото</p>
                    <div className="image-upload">
                      <label htmlFor="file-input">
                        <img src={img === null ? `${IMG_URL}no-avatar.jpg` : `${IMG_URL}${img}`} style={{borderRadius: '10px', maxWidth: '130px', maxHeight: '150px', cursor: 'pointer'}}/>
                      </label>
                      <input id="file-input" onChange={selectFile} type="file" />
                    </div>
                  </div>
                  :
                  ``
                  }
                  <div className="cabinet-btn-row">
                    <button onClick={role > 0 ? () => updatePractic() : () => updateUser()} className="cabinet-submit-btn">Сохранить</button>
                    {
                      role > 0 ?
                      verify === false ?
                      <button onClick={() => postModeration()} style={{marginLeft: '10px'}} className="cabinet-submit-btn">Отправить</button>
                      :
                      ``
                      :
                      ``
                    }
                  </div>
                </div>
                <div className="cabinet-settings-item">
                  <h5 className="cabinet-settings-title">Изменение пароля</h5>
                  <input onChange={e => setOldpassword(e.target.value)} value={oldpassword} placeholder="Текущий пароль" type="password" className="cabinet-settings-input_sm" />
                  <input onChange={e => setPassword1(e.target.value)} value={password1} placeholder="Новый пароль" type="password" className="cabinet-settings-input_sm" />
                  <input onChange={e => setPassword2(e.target.value)} value={password2} placeholder="Повторите пароль" type="password" className="cabinet-settings-input_sm" />
                  <div className="cabinet-btn-row">
                    <button onClick={() => verifyPassword()} className="cabinet-submit-btn">Сохранить</button>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
};

export default observer(CabinetPage);