import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { API_URL } from '../http';
import { IMG_URL } from '../http';


const SMB = () => {
  const [items, setItems] = useState([]);
  const limit = 3;

  const getOtherNews = async () => {
    const res = await fetch(
      API_URL + `/b?page=1&limit=${limit}`
    );
    const data = await res.json();
    setItems(data);
  }

  useEffect(() => {
    getOtherNews();
  }, [setItems]);

    return (
      <section id="books" className="books">
      <div className="container">
        <h2 className="dark_header">Мои книги</h2>
          <div className="card_block-light">
          {items.map((item) => {
            return (
            <div key={item.books_id} className="card_alt">
              <div className="card_alt_item">
                <div className="card_alt_content">
                  <img className="card_alt_logotype" src={IMG_URL + item.img} />
                  <div className="card_alt_title">{item.title}</div>
                  <div className="card_alt_text-light">
                    {item.description.slice(0, 145)} ...
                  </div>
                  <Link to={`/books/` + item.books_id}><button className="card_btn-alt">Узнать подробнее</button></Link>
                </div>
              </div>
            </div>
              )
            })}
          </div>
        </div>
      </section>
    );
};

export default observer(SMB);