import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import titleApp from '../../services/title';

const Page404 = () => {
    useEffect(() => {
      document.title = `Страница не найдена! | ${titleApp()}`
      document.body.style = 'background: #F7F7F5;';
      document.querySelector('footer').style = 'background: #F7F7F5;';
    }, []);
    return (
      <section className="main" style={{marginBottom: '100px'}}>
        <div className="container">
          <h1 className="main_header">404 <br />Страница не найдена!</h1>
          <div className="main_text">
          <p>Ошибка! Данная страница не найдена!<br />Если вы считаете что данная страница есть, но не открылась по какой-то ошибке - свяжитесь с нами!</p>
          </div>
          <div className="group_circle-btn">
            <Link to="/">
              <button className="circle-btn">
                На главную
              </button>
            </Link>
          </div>
        </div>
      </section>
    );
};

export default observer(Page404);