import React from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import Hero from "../assets/hero.jpg"

const SMA = () => {
    return (
      <section className="about">
        <div className="container">
          <div className="about_cursive">
            <p>Мое имя – Елена Эра</p>
          </div>
          <h3 className="about_title-sm">
            Я психолог, преподаватель психологических дисциплин, писатель, философ и метафизик
          </h3>
          <div className="about_block">
            <img className="about_hero" src={Hero} />
            <div className="about_description">
              <h3 className="about_title">
                Я психолог, преподаватель психологических дисциплин, писатель, философ и метафизик
              </h3>
              <div className="about_progress">
                <p>
                  Мои курсы и семинары объединили уже десятки практиков и мастеров, которые сами перешагнули через собственные страхи и самоограничения, а теперь помогают сделать это другим людям.
                </p>
                <p>
                  Я являюсь автором таких комплексных методов, как «Сияющие руки» и «Его Величество Тело», 
                  а также множества трансформационных семинаров, фасилитатором которых являюсь.
                </p>
              </div>
              <div className="about_achievement">
                <div className="about_achivement_item">
                  <h3>
                    30+
                  </h3>
                  <p>
                    Лет стажа
                  </p>
                </div>
                <div className="about_achivement_item">
                  <h3>
                    1000+
                  </h3>
                  <p>
                    Людей прошли мои программы
                  </p>
                </div>
                <div className="about_achivement_item">
                  <h3>
                    100+
                  </h3>
                  <p>
                    Созданных курсов
                  </p>
                </div>
              </div>
            </div>
          </div>	
          <div className="about_achievement-sm">
            <div className="about_achivement_item-sm">
              <h3>
                30+
              </h3>
              <p>
                Лет стажа
              </p>
            </div>
            <div className="about_achivement_item-sm">
              <h3>
                1000+
              </h3>
              <p>
                Людей прошли мои программы
              </p>
            </div>
            <div className="about_achivement_item-sm">
              <h3>
                100+
              </h3>
              <p>
                Созданных курсов
              </p>
            </div>
          </div>
        </div>
      </section>
    );
};

export default observer(SMA);