import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {API_URL} from '../../http/index';
import { IMG_URL } from '../../http/index';
import { Link } from 'react-router-dom';

const BlogsPage = () => {
  const [items, setItems] = useState([]);
  const [settings, setSettings] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 6;

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`
    );
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`);
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);

  window.scrollTo(0, 0);
  useEffect(() => {
    getNews();
  }, [limit]);

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/n?page=${currentPage}&limit=${limit}`
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchNews(currentPage);

    setItems(commentsFormServer);
  };

  useEffect(() => {
    document.body.style = 'background: #F7F7F5;';
    document.querySelector('footer').style = 'background: #F7F7F5;';
  }, [])

  return (
<>
  <div className="cabinet-section">
    <div className="cabinet-header">
      <div className="cabinet-header_item">
        <h3 className="cabinet-title">Блог</h3>
      </div>
    </div>
    <div className='blog-provider'>
      <div className="blog-btn-group">
        <Link to="/blog"><button className="btn-cabinet-active-alt">Наши события</button></Link>
        <Link to="/videos"><button className="btn-cabinet-alt">Наши видеоматериалы</button></Link>
      </div>
      <div className="blog-btn-group">
        <a style={{textDecoration: 'none'}} href={settings.l_zen}><button className="blog-btn-zen">Читайте нас</button></a>
      </div>
    </div>
    <div className="cabinet-table">
    <div className="card_block-alt">
    {items.map((item) => {
      return (
        item.published == true ?
          <div className="cardblog" key={item.news_id}>
            <div className="card_item">
              <div className="card_content card_content-spacing">
                <div className="card_box_blog-alt">
                  <p>{item.pubDate.split(' ')[0]}</p>
                </div>
                <img className="card_logotype  card_logotype_blog-main" src={IMG_URL + `/` + item.img} />
                <div className="card_title_blog">{item.title}</div>
                <div className="card_text_blog">
                  {item.fullNews}
                </div>
                <Link to={`/blog/` + item.news_id}><button className="card_btn_blog">Узнать подробнее</button></Link>
              </div>
            </div>
          </div>
        : null
        )
      })}
    </div>
            <div className="paginate-controller">
              <ReactPaginate
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={null}
                  nextLabel={null}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLabel={<p className="page-link">...</p>}
                  pageClassName="page-item"
                  previousClassName={null}
                  nextClassName={null}
                  pageLinkClassName="page-link"
                  previousLinkClassName={null}
                  nextLinkClassName={null}
                  activeClassName="page-link-active"
                  prevRel={null}
                  nextRel={null}
              />
            </div>
    </div>
  </div>
</>
  );
};

export default BlogsPage;



/*
import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import 'boxicons/css/boxicons.css';
import 'boxicons';
import CardGroup from '../card-group';
import Destinations from '../destinations';
import Advantage from '../advantage';
import Faq from '../faq';
import { Card, Button } from 'react-bootstrap';
import Logo from '../../assets/logo.png';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom'
import { API_URL, IMG_URL } from '../../http/api';
import titleApp from '../../services/title';




const NewsPages = () => {


  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 6;


  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`
    );
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  useEffect(() => {
    getNews();
  }, [limit]);

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/n?page=${currentPage}&limit=${limit}`
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchNews(currentPage);

    setItems(commentsFormServer);
  };

  useEffect(() => {
    document.title = `Новости компании | ${titleApp()}`
  }, []);

    return (
      <section className="news">
        <div className="container">
          <div className="mb-5">
            <div className="destinations-header">
              <div className="destinations-text">
                <i className='bx bxs-news h1 bx-fw'></i>
                <h1>Новости компании</h1>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {items.map((item) => {
              return (
                item.published == true ?
                <Card key={item.news_id} className="col-4 shadow m-2" style={{ width: '25rem' }}>
                  <Card.Img className="mt-2 rounded shadow-sm" variant="top" height={250} src={IMG_URL + `/` + item.img} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text style={{ whiteSpace: "pre-line" }}>
                      {item.shortNews}
                    </Card.Text>
                    <div className="d-flex justify-content-end">
                      <Link to={`/news/` + item.news_id}><Button variant="outline-primary outline-btn mt-4"><i className='bx bx-right-arrow-alt bx-fw h5' ></i> Читать новость</Button></Link>
                    </div>
                    <hr />
                    <p className="text-muted"><i className='bx bxs-calendar bx-fw' ></i> {item.pubDate}</p>
                  </Card.Body>
                </Card>
                : null
              )
            })}
          </div>
          <div className="d-flex justify-content-center mt-2">
            <ReactPaginate
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                previousLabel={"Назад"}
                nextLabel={"Вперед"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                breakClassName="page-item"
                breakLabel={<a className="page-link">...</a>}
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                activeClassName="active paginate-btn-active"
            />
          </div>
        </div>
      </section>
    );
};

export default observer(NewsPages);
*/