import React, {useState, useRef, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { API_URL, IMG_URL } from '../http';

const SMT = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [type, setType] = useState(1);
  const [typeRole, setTypeRole] = useState(0);
  const [typeBtn, setTypeBtn] = useState(1);
  let limit = 3;
  let oneType = 1;
  let oneRole = 0;

  async function changeType(x) {
    if(x === 1) {
      setType(1);
      setTypeRole(0);
      oneType = 1;
      oneRole = 0;
      setTypeBtn(1);
      return getNews();
    }
    if (x === 2) {
      setType(1);
      setTypeRole(1);
      oneType = 1;
      oneRole = 1;
      setTypeBtn(2);
      return getNews();
    }
    if (x === 3) {
      setType(2);
      setTypeRole(0);
      oneType = 2;
      oneRole = 0;
      setTypeBtn(3);
      return getNews();
    }
  }

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/u/practicles?limit=${limit}&type=${oneRole}&role=${oneType}&altType=2`
    );
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data.users);
  };

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 3;
      else if (windowSize.current >= 1024) limit = 3;
      else if (windowSize.current >= 640) limit = 2;
      else if (windowSize.current >= 360) limit = 2;
  }

  useEffect(() => {
    checkMonitor();
    getCountry();
    getCity();
    getNews();
  }, [limit]);

    return (
      <section className="tutor">
        <div className="container">
          <div className="tutor-container">
            <h2>Наши специалисты</h2>
            <div className="btn-tutor-group btn-v-2">
              <button className={typeBtn === 1 ? "btn-tutor-1 btn-tutor-active" : "btn-tutor-1"} onClick={() => changeType(1)}>
                Практики метода «Сияющие руки»
              </button>
              <button className={typeBtn === 2 ? "btn-tutor-2 btn-tutor-active" : "btn-tutor-2"} onClick={() => changeType(2)}>
                Практики метода «Соединение миров»
              </button>
              <button className={typeBtn === 3 ? "btn-tutor-3 btn-tutor-active" : "btn-tutor-3"} onClick={() => changeType(3)}>
                Мастера-фасилитаторы
              </button>
            </div>
            <div className="tutor-description">
              <p>Ваши руки – это идеальный проводник энергий,которые можно и нужно использовать себе во благо. Наши сертифицированные специалисты в течение 3-х дней поделятся с вами системными и понятными знаниями, которые вы сможете сразу применять на практике.</p>
            </div>
            <div className="card_block-alt">
              {items.map((item) => {
                return (
                  <div className="card_alt" key={item._id}>
                    <div className="card_alt_item">
                      <div className="card_alt_content">
                        <img className="card_tutor_logotype" src={IMG_URL + item.img} />
                        <div className="card_alt_title">{item.lastName + ' ' + item.firstName}</div>
                        {country !== '' ?
                        country?.map((country) => {
                          return (
                            item?.countryid === country?._id ?
                            city !== '' ?
                            city?.map((city) => {
                                return (
                                  item?.cityid === city?._id ?
                                  <div key={country?._id} className="card_alt_description">
                                  {country?.name + ', ' + city?.name}
                                  </div>
                                  :
                                  null
                                )
                              })
                              :
                              null
                            :
                            null
                          )
                        }) : null}
                        <div className="card_alt_text">
                          {item.description.slice(0, 95)} ... 
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
            </div>
            <div className="tutor_cursive">
              {typeBtn === 3 ? 
              <p>... 8 мастеров-<br/>фассилитаторов</p>
              : 
              <p>... и еще более 100<br/> специалистов</p>
              }
            </div>
            <div className="tutor_vector">
              <svg width="55" height="18" viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M54.8613 7.14657C54.8808 6.59465 54.4493 6.1314 53.8973 6.11181L44.903 5.79346C44.351 5.77388 43.8877 6.20551 43.8682 6.75744C43.8487 7.30934 44.2803 7.77271 44.8322 7.79218L52.8272 8.0752L52.5442 16.0702C52.5246 16.6221 52.9562 17.0854 53.5081 17.105C54.0601 17.1245 54.5234 16.6929 54.5429 16.141L54.8613 7.14657ZM0.745062 2.20212C5.42186 7.09384 13.6971 13.2506 23.383 15.8284C33.1267 18.4216 44.3023 17.3839 54.5435 7.84293L53.1803 6.37953C43.5178 15.3814 33.082 16.3401 23.8975 13.8957C14.6551 11.4359 6.6853 5.52121 2.19068 0.819999L0.745062 2.20212Z" fill="#B5B5B5"/>
              </svg>
            </div>
            <div className="tutor_link">
            {typeBtn === 1 ?
              <Link to="/shining-hands"><button className="tutor_btn">Подобрать практика</button></Link>
              :
              typeBtn === 2 ?
              <Link to="/connectionoftheworlds"><button className="tutor_btn">Подобрать практика</button></Link>
              :
              typeBtn === 3 ?
              <Link to="/masters"><button className="tutor_btn">Подобрать мастера</button></Link>
              :
              null
            }
            </div>
          </div>
        </div>
      </section>
    );
};

export default observer(SMT);