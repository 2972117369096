import React, {useState, useEffect, useRef} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import Blog1 from '../assets/blog-1.jpg';
import Blog2 from '../assets/blog-2.jpg';
import Blog3 from '../assets/blog-3.jpg';
import { API_URL, IMG_URL } from '../http';

const SMBL = () => {

  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 3;

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`
    );
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  const windowSize = useRef(window.innerWidth);
  function checkMonitor() {
      if (windowSize.current >= 1440) limit = 3;
      else if (windowSize.current >= 1024) limit = 3;
      else if (windowSize.current >= 640) limit = 2;
      else if (windowSize.current >= 360) limit = 2;
  }

  useEffect(() => {
    checkMonitor();
    getNews();
  }, [limit]);

    return (
      <section className="tutor">
        <div className="container">
          <div className="blog-container">
          <h2 className="dark_header-blog">Читайте о последних <span>новостях и событиях</span> в нашем блоге</h2>
            <div className="card_block-alt">
            {items.map((item) => {
              return (
                item.published == true ?
                  <div className="card" key={item.news_id}>
                    <div className="card_item">
                      <div className="card_content card_content-spacing">
                        <div className="card_box_blog">
                          <p>{item.pubDate.split(' ')[0]}</p>
                        </div>
                        <img className="card_logotype card_logotype_blog-main" src={IMG_URL + `/` + item.img} />
                        <div className="card_title_blog">{item.title}</div>
                        <div className="card_text_blog">
                          {item.fullNews}
                        </div>
                        <Link to={`/blog/` + item.news_id}><button className="card_btn_blog">Узнать подробнее</button></Link>
                      </div>
                    </div>
                  </div>
                : null
                )
              })}
            </div>
            <div className="blog_cursive">
              <p>Читайте подробнее<br/>в нашем блоге</p>
            </div>
            <div className="tutor_vector">
              <svg width="55" height="18" viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M54.8613 7.14657C54.8808 6.59465 54.4493 6.1314 53.8973 6.11181L44.903 5.79346C44.351 5.77388 43.8877 6.20551 43.8682 6.75744C43.8487 7.30934 44.2803 7.77271 44.8322 7.79218L52.8272 8.0752L52.5442 16.0702C52.5246 16.6221 52.9562 17.0854 53.5081 17.105C54.0601 17.1245 54.5234 16.6929 54.5429 16.141L54.8613 7.14657ZM0.745062 2.20212C5.42186 7.09384 13.6971 13.2506 23.383 15.8284C33.1267 18.4216 44.3023 17.3839 54.5435 7.84293L53.1803 6.37953C43.5178 15.3814 33.082 16.3401 23.8975 13.8957C14.6551 11.4359 6.6853 5.52121 2.19068 0.819999L0.745062 2.20212Z" fill="#B5B5B5"/>
              </svg>
            </div>
            <div className="tutor_link">
              <Link to="/blog"><button className="tutor_btn">Перейти в блог</button></Link>
            </div>
          </div>
        </div>
      </section>
    );
};

export default observer(SMBL);