import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../http';
import axios from 'axios';
import errorNotify from '../../services/notify';
import { successNotify } from '../../services/notify';
import { IMG_URL } from '../../http';
import { Link } from 'react-router-dom';
import CourseCreate from '../../assets/course-create.png';
import CourseModerate from '../../assets/course-moderate.png';

const CoursePage = () => {
    const {store} = useContext(Context);

    const [onlineCourse, setOnlineCourse] = useState(false);

    const [schedule, setSchedule] = useState([]);

    const [city, setCity] = useState('');
    const [inDate, setInDate] = useState('');
    const [leading, setLeading] = useState('');
    
    const [activated, setActivated] = useState(true);

    const [role, setRole] = useState(0);
    const [verify, setVerify] = useState(false);
    const [editCourse, setEditCourse] = useState(false);

    const toggleCourse = () => {
      setOnlineCourse(!onlineCourse);
      if (!onlineCourse) setCity('Онлайн');
      if (onlineCourse) setCity('');
    }

    const toggleEditCourse = () => {
      setEditCourse(!editCourse);
    }

    const getOneSchedule = async () => {
      const res = await fetch(
        API_URL + `/schedule/one/` + store.user.id, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
      const data = await res.json();
      setCity(data.place);
      if(data.place === 'Онлайн') {
        if (onlineCourse === false) toggleCourse();
      }
      setInDate(data.inDate);
      setLeading(data.leading);
      setSchedule(data);
    };
  
    useEffect(() => {
      getOneSchedule();
    }, [setSchedule]);


    useEffect(() => {
        document.body.style = 'background: #F7F7F5;';
        document.querySelector('footer').style = 'background: #F7F7F5;';
        getProfile();
    }, [])

    async function getProfile() {
      const formData = new FormData()
      formData.append('user', store.user.email)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/u/getprofile', formData)
      .then(res => {
        setActivated(res?.data?.isActivated);
        setRole(res?.data?.role);
        setVerify(res?.data?.verify);
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    async function addSchedule() {
      if (city === null || city === '' || city === undefined) return errorNotify('Не указан город или режим онлайн!');
      if (inDate === null || inDate === '' || inDate === undefined) return errorNotify('Не выбрана дата!');
      if (leading === null || leading === '' || leading === undefined) return errorNotify('Не указан ведущий!');
      if (editCourse === true) toggleEditCourse();
      const formData = new FormData()
      formData.append('title', 'Сияющие руки')
      formData.append('inDate', inDate)
      formData.append('leading', leading)
      formData.append('place', city)
      formData.append('actual', "false")
      formData.append('creator', store.user.id)
      formData.append('verify', false)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/schedule/ucreate', formData)
      .then(res => {
        getOneSchedule();
        successNotify('Курс направлен на модерацию!');
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    async function deleteSchedule() {
      const formData = new FormData()
      formData.append('creator', store.user.id)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/schedule/udelete', formData)
      .then(res => {
        if (editCourse === false) toggleEditCourse();
        if (onlineCourse === false) setCity('');
        setInDate('');
        setLeading('');
        getOneSchedule();
        setSchedule([{"verify": undefined}])
        successNotify('Курс был удален!');
      })
      .catch(err => errorNotify(err.response.data.message));
    }

    return (
        <>
            <div className="cabinet-section">
              <div className="cabinet-header">
                <div className="cabinet-header_item">
                  <h3 className="cabinet-title">Личный кабинет</h3>
                </div>
                <div className="cabinet-header_item">
                  <button onClick={() => store.logout()} className="cabinet-button-exit">Выход</button>
                </div>
              </div>
              <div className="cabinet-btn-group">
                  <Link to="/cabinet"><button className="btn-cabinet">Профиль</button></Link>
                  <Link to="/cabinet/purchase"><button className="btn-cabinet">Мои заказы</button></Link>
                  <button className="btn-cabinet">Уведомления</button>
                  {role >= 1 ? <Link to="/cabinet/videos"><button className="btn-cabinet">Учебные материалы</button></Link> : null}
                  {role >= 2 ? <Link to="/cabinet/course"><button className="btn-cabinet-active">Добавить курс</button></Link> : null}
              </div>
                {activated === false ?
                  <div className='cabinet-activate'>
                    <p>Ваша учетная запись не активирована. Для активации, подтвердите ваш действующий адрес электронной почты!
                      <br />На ваш адрес электронной почты было направлено письмо с инструкцией по подтверждению.
                    </p>
                  </div>
                : role > 0 ?
                    verify === false ?
                    <div className='cabinet-activate'>
                      <p>Ваша учетная запись не проверена модератором! Для прохождения модерации необходимо заполнить все поля,
                        затем подать заявку на модерацию, нажав на кнопку "Отправить"
                      </p>
                    </div>
                    :
                    ``
                    :
                    ``
                 }
                
              <div className="card_block-light" style={{marginTop: '50px'}}>
              {role === 2 ?
                <div className="block_schedule">
                  <img className="block_schedule-img" src={schedule.verify === false ? CourseModerate : CourseCreate} />
                  <h3 className="block_schedule-header">Сияющие руки</h3>
                  <p className="block_schedule-description">Сертификационный курс</p>
                  {schedule.verify === false ?
                  <div>
                    {editCourse === false ?
                      <button onClick={() => toggleEditCourse()} className="block_schedule-btn-edit">Редактировать</button>
                      :
                      <div>
                      <div className="block_schedule-input-group">
                        <input value={city} disabled={onlineCourse === true ? 'disabled' : ''} onChange={(e) => setCity(e.target.value)} className="block_schedule-input" placeholder='Введите город' />
                        <input value={inDate} onChange={(e) => setInDate(e.target.value)} className="block_schedule-input" placeholder='Выберите дату' />
                        <input value={leading} onChange={(e) => setLeading(e.target.value)} className="block_schedule-input" placeholder='Ведущий' />
                      </div>
                      <div className="block_schedule-btn-group">
                        <button onClick={onlineCourse === false ? () => toggleCourse() : null} className={onlineCourse === true ? `block_schedule-btn-enable` : `block_schedule-btn-disable`}>Онлайн</button>
                        <button onClick={onlineCourse === true ? () => toggleCourse() : null} className={onlineCourse === false ? `block_schedule-btn-enable` : `block_schedule-btn-disable`}>Оффлайн</button>
                      </div>
                      <div className="block_schedule-btn-group">
                        <button onClick={() => addSchedule()} className="block_schedule-btn-save">Сохранить</button>
                        <button onClick={() => deleteSchedule()} className="block_schedule-btn-delete"></button>
                      </div>
                      </div>
                    }
                  </div>
                  : schedule.verify === true ?
                  <div>test</div> :
                  <div>
                    <div className="block_schedule-input-group">
                      <input value={city} disabled={onlineCourse === true ? 'disabled' : ''} onChange={(e) => setCity(e.target.value)} className="block_schedule-input" placeholder='Введите город' />
                      <input value={inDate} onChange={(e) => setInDate(e.target.value)} className="block_schedule-input" placeholder='Выберите дату' />
                      <input value={leading} onChange={(e) => setLeading(e.target.value)} className="block_schedule-input" placeholder='Ведущий' />
                    </div>
                    <div className="block_schedule-btn-group">
                      <button onClick={onlineCourse === false ? () => toggleCourse() : null} className={onlineCourse === true ? `block_schedule-btn-enable` : `block_schedule-btn-disable`}>Онлайн</button>
                      <button onClick={onlineCourse === true ? () => toggleCourse() : null} className={onlineCourse === false ? `block_schedule-btn-enable` : `block_schedule-btn-disable`}>Оффлайн</button>
                    </div>
                    <button onClick={() => addSchedule()} className="block_schedule-btn">Добавить курс</button>
                  </div>
                    }
                </div>
              :
              <div className='cabinet-activate' style={{marginBottom: '30px'}}>
                <p>Недостаточно прав!<br /> Если вы считаете, что вам доступен медиа-контент, свяжитесь с администрацией сайта.
                </p>
              </div>
            
              }
              </div>
            </div>
        </>
    );
};

export default observer(CoursePage);