import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import course1 from '../assets/course-1.jpg';
import course2 from '../assets/course-2.jpg';
import course3 from '../assets/course-3.jpg';

import seminar1 from '../assets/seminar-1.png';
import seminar2 from '../assets/seminar-2.png';
import seminar3 from '../assets/seminar-3.png';
import { API_URL } from '../http';
import { IMG_URL } from '../http';

const SMPR = () => {
    const [count, setCount] = useState(1);
    function changeState(x) {
      if (x === 1) return setCount(1);
      else if (x === 2) return setCount(2);
    }
    const [items, setItems] = useState([]);
  
    const getLands = async () => {
      const res = await fetch(
        API_URL + `/lands/`
      );
      const data = await res.json();
      setItems(data);
    }
  
    useEffect(() => {
      getLands();
    }, [setItems]);


    return (
      <section id="programs" className="programms">
      <div className="container">
      <div className="programms-container">
      <h2>Доступные программы</h2>
      <div className="btn-group-alt btn-v-1">
        <button onClick={() => changeState(1)} className={count === 1 ? "btn-program-alt btn-program-alt-active" : "btn-program-alt"}>
          Мои курсы
        </button>
        <button onClick={() => changeState(2)} className={count === 2 ? "btn-program-alt-2 btn-program-alt-active" : "btn-program-alt-2"}>
          Мои семинары
        </button>
      </div>
      {count === 1 ?
          <div className="programms-description">
            <p><span>Дополнительное образование для практикующих специалистов</span>, обучение людей, которые чувствуют способности в тонких материях, но не вполне умеют ими пользоваться. </p>
            <p>Наши мастера помогут вам научиться нести свет в этот мир и осветить пространство своей талантливой и прекрасной души!</p>
          </div>
         : 
          <div className="programms-description">
            <p><span>Для вас и вместе с участниками программы</span> я делюсь своим практическим опытом и провожу работу по жизненно важным запросам.</p>
            <p>Пространством изучения и трансформации могут быть разнообразные сферы от эмоционального восприятия и физического состояния человека.</p>
          </div>
         }
         {count === 1 ?
         <div className="card_block">
          {items.map((item) => {
            return (
              item.typeCourse === false ?
              <div key={item._id} className="card">
                <div className="card_item">
                  <div className="card_content">
                    <div className="card_box">
                      <p>{item.formatCourse}</p>
                    </div>
                    <img className="card_logotype card-img_course" src={IMG_URL + item.img} />
                    <div className="card_title">{item.title}</div>
                    <div className="card_description">{item.modelCourse}</div>
                    <div className="card_text">
                      {item.description}
                    </div>
                    <a href={item.linkCourse} target="_blank"><button className="card_btn">Узнать подробнее</button></a>
                  </div>
                </div>
              </div>
            : null
              )
            })}
        </div>
        :
        <div className="card_block">
          {items.map((item) => {
            return (
              item.typeCourse === true ?
              <div key={item._id} className="card">
                <div className="card_item">
                  <div className="card_content">
                    <div className="card_box">
                      <p>{item.formatCourse}</p>
                    </div>
                    <img className="card_logotype card-img_course" src={IMG_URL + item.img} />
                    <div className="card_title">{item.title}</div>
                    <div className="card_description">{item.modelCourse}</div>
                    <div className="card_text">
                      {item.description}
                    </div>
                    <a href={item.linkCourse} target="_blank"><button className="card_btn">Узнать подробнее</button></a>
                  </div>
                </div>
              </div>
            : null
              )
            })}
        </div>
        }
      </div>
      </div>
    </section>
    );
};

export default observer(SMPR);