import React from 'react';
import {observer} from "mobx-react-lite";
import { useEffect, useState } from "react";
import { API_URL } from '../http';

const SMC = () => {
  const [settings, setSettings] = useState([]);
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);
  return (
    <section className="courses">
      <div className="container">
        <h2 className="dark_header"><span>Для кого подойдут </span> мои программы?</h2>
        <div className="courses-block">
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">{settings.s_card_1}</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">{settings.s_card_2}</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">{settings.s_card_3}</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">{settings.s_card_4}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default observer(SMC);