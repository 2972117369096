import {makeAutoObservable} from "mobx";
import AuthService from "../services/auth-service";
import axios from 'axios';
//import {AuthResponse} from "../models/response/AuthResponse";
import errorNotify from "../components/error-notify";
import {API_URL} from "../http";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { successNotify } from "../pages/cabinet-page";

export default class Store {
    user = {};
    isAuth = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    async login(email, password) {
        try {
            if(email === '') {
                return errorNotify('Заполните поле E-Mail!')
            }
            if(password === '') {
                return errorNotify('Заполните поле Пароль!')
            }
            const response = await AuthService.login(email, password);
            //console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            //console.log(e.response?.data?.message);
            errorNotify(e.response?.data?.message);
        }
    }

    async registration(email, firstName, lastName, role, password1) {
        try {
            const response = await AuthService.registration(email, firstName, lastName, role, password1);
            //console.log(response)
            window.location.reload();
        } catch (e) {
            //console.log(e.response?.data?.message);
            errorNotify(e.response?.data?.message);
        }
    }

    async registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, role, password1, roleType) {
        try {
            const response = await AuthService.registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, role, password1, roleType);
            //console.log(response)
            window.location.reload();
        } catch (e) {
            //console.log(e.response?.data?.message);
            errorNotify(e.response?.data?.message);
        }
    }

    async restore(email) {
        try {
            if(email === '') {
                return errorNotify('Заполните поле E-Mail!')
            }
            const response = await AuthService.restore(email);
            successNotify(response?.data?.message);
        } catch (e) {
            //console.log(e.response?.data?.message);
            errorNotify(e.response?.data?.message);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({});
        } catch (e) {
            //console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/u/refresh`, {withCredentials: true})
            //console.log(response);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            //console.log(e.response?.data?.message);
            errorNotify(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }
}
