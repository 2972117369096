import React from 'react';
import {observer} from "mobx-react-lite";
import { useEffect, useState } from "react";
import { API_URL } from '../http';

const SMM = () => {
  const [settings, setSettings] = useState([]);
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };

  useEffect(() => {
    getSettings();
  }, [setSettings]);

    return (
      <section className="main">
        <div className="container">
          <h1 className="main_header">{settings.s_title}</h1>
          <div className="main_text">
          <p>{settings.s_desc}</p>
          </div>
          <div className="group_circle-btn">
            <a href="#programs">
              <button className="circle-btn">
                Программы<br /> Елены Эра
              </button>
            </a>
            <a href="#books">
              <button className="circle-btn">
                Книги<br /> Елены Эра
              </button>
            </a>
          </div>
        </div>
      </section>
    );
};

export default observer(SMM);