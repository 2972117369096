import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {API_URL} from '../../http/index';
import { IMG_URL } from '../../http/index';
import { Link } from 'react-router-dom';

const BooksPage = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 6;

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/b?page=1&limit=${limit}`
    );
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };
  window.scrollTo(0, 0);
  useEffect(() => {
    getNews();
  }, [limit]);

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/b?page=${currentPage}&limit=${limit}`
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchNews(currentPage);

    setItems(commentsFormServer);
  };

  useEffect(() => {
    document.body.style = 'background: #F7F7F5;';
    document.querySelector('footer').style = 'background: #F7F7F5;';
  }, [])

  return (
<>
  <div className="cabinet-section">
    <div className="cabinet-header">
      <div className="cabinet-header_item">
        <h3 className="cabinet-title">Книги</h3>
      </div>
    </div>
    <div className='blog-provider'>
    </div>
    <div className="cabinet-table">
    <div className="card_block-alt">
    {items.map((item) => {
      return (
        item.published == true ?
          <div className="cardblog" key={item.books_id}>
            <div className="card_item">
              <div className="card_content">
                <div className="card_box_blog-alt">
                  <p>{item.autor}</p>
                </div>
                <img className="card_logotype" src={IMG_URL + `/` + item.img} />
                <div className="card_title_blog">{item.title}</div>
                <div className="card_text_blog">
                  {item.description}
                </div>
                <Link to={`/books/` + item.books_id}><button className="card_btn_blog">Узнать подробнее</button></Link>
              </div>
            </div>
          </div>
        : null
        )
      })}
    </div>
            <div className="paginate-controller">
              <ReactPaginate
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={null}
                  nextLabel={null}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLabel={<p className="page-link">...</p>}
                  pageClassName="page-item"
                  previousClassName={null}
                  nextClassName={null}
                  pageLinkClassName="page-link"
                  previousLinkClassName={null}
                  nextLinkClassName={null}
                  activeClassName="page-link-active"
                  prevRel={null}
                  nextRel={null}
              />
            </div>
    </div>
  </div>
</>
  );
};

export default BooksPage;